import React from 'react';
import classNames from 'classnames';

export interface ICardwrapperProps {
    className?: string;
    variant?: 'default' | 'development' | 'success' | 'warning' | 'danger' | 'secondary';
}

export default class Cardwrapper extends React.Component<ICardwrapperProps> {
    static displayName = 'Cardwrapper';

    public static defaultProps = {
        variant: "default"
    };
    public classes() {
        const classes = classNames(
            'content-card',
            'p-4',
            this.props.className,
            `state-${this.props.variant}-block`,
        );
        return classes;
    }

    public render() {
        return (
            <div
                className={this.classes()}
            >
                {this.props.children}
            </div>
        );
    }
}
