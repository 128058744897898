import * as React from 'react';
import { Translation } from 'react-i18next';

interface IGenericModalProps {
    title: string;
    body: string;
    showModal: boolean;
    showCancelButton?: boolean;
    deleteButtonClass?: string;
    onClose?: () => void;
    closeText?: string;
    onSuccess?: () => void;
    successText?: string;
    onSuccessDisabled?: boolean;
    saveIcon?: any;
    overrideClose?: boolean;
    cancelClassName?: string;
    large?: boolean;
}

interface IGenericModalState {
    isOpen?: boolean;
}

export default class GenericModal extends React.Component<IGenericModalProps, IGenericModalState> {
    static displayName = 'GenericModal';

    public static defaultProps: Partial<IGenericModalProps> = {
        showCancelButton: true
    };
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: props.showModal || false
        }
    }

    public componentDidUpdate(prevProps: IGenericModalProps) {
        if (this.props.showModal === true && this.props.showModal !== prevProps.showModal) {
            this.setState({ isOpen: true });
        }
        else if (this.props.showModal === false && this.props.showModal !== prevProps.showModal) {
            this.setState({ isOpen: false });
        }
    }

    private closeModal = () => {
        this.setState({ isOpen: false });
    }

    private closeWithCloseCallback = () => {
        this.setState({ isOpen: false });
        if (this.props.onClose) {
            this.props.onClose!();
        }
    }

    private closeWithSuccessCallback = () => {
        if (this.props.overrideClose) {
            if (this.props.onSuccess) {
                this.props.onSuccess!();
            } else {
                this.setState({ isOpen: this.props.showModal });
            }
        }
        else {
            if (this.props.onSuccess) {
                this.props.onSuccess!();
            } else {

                this.setState({ isOpen: false });
            }
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <div className={this.state.isOpen ? "reveal-overlay reveal-overlay__open" : ""}>
                            <div className={this.state.isOpen ? `reveal text-color-black text-left overflow-visible-large reveal__open ${this.props.large ? "large" : ""}` : "reveal text-color-black text-left overflow-visible"} id="user-add-modal">
                                <h2>{t(this.props.title)}</h2>
                                <div>
                                    {t(this.props.body)}
                                    {this.props.children}
                                    <div />
                                    <div className="grid-x grid-padding-x padding-top-2">
                                        <div className="small-12 cell text-right">
                                            {
                                                this.props.onClose ?
                                                    (
                                                        <button onClick={this.closeWithCloseCallback} className={this.props.cancelClassName ? this.props.cancelClassName : "clear button"}>{this.props.closeText ? t(this.props.closeText) : t('common:Action.Cancel')}</button>
                                                    ) : (
                                                        (this.props.showCancelButton &&
                                                            <button onClick={this.closeModal} className={this.props.cancelClassName ? this.props.cancelClassName : "clear button"}>{this.props.closeText ? t(this.props.closeText) : t('common:Action.Cancel')}</button>
                                                        )
                                                    )
                                            }
                                            {this.props.onSuccess &&
                                                <button onClick={this.closeWithSuccessCallback} disabled={this.props.onSuccessDisabled ? this.props.onSuccessDisabled : false} className={this.props.deleteButtonClass ? this.props.deleteButtonClass : "button svg-vertical-align-top radius"}>{this.props.saveIcon} {this.props.successText ? t(this.props.successText) : " Ok "}</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
            </Translation>
        );
    }
}
