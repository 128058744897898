import { ICosmosGenericPagedResult, ICustomer } from "../Interface/Interfaces";

export class CosmosCustomerPagedResult implements ICosmosGenericPagedResult<ICustomer> {
    public hasNextPage: boolean = false;
    public nextPageToken: string = "";
    public results: ICustomer[] = [];

    constructor(oldCustomers?: ICustomer[], newCustomers?: ICustomer[], hasNextPage?: boolean, nextPageToken?: string) {
        if (oldCustomers) {
            this.results = [...oldCustomers!, ...newCustomers!];
            this.hasNextPage = hasNextPage!;
            this.nextPageToken = nextPageToken!;
        }
    }
}
