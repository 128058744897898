import { IAppThunkAction } from '../';
import { KnownAction } from '../Notification';
import NotificationService from '../../apiServices/NotificationService';
import StorageService from '../../apiServices/StorageService';
import { INotification, ICosmosPagedResult } from '../../Interface/Interfaces';

export const NotificationActions = {
    getNotifications: (update?: boolean): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        NotificationService.GetMyNotifications(null).then((result: ICosmosPagedResult) => {
            dispatch({
                type: 'GE_MY_NOTIFICATIONS',
                allNotifications: result.results,
                hasNextPage: result.hasNextPage,
                nextPageToken: result.nextPageToken
            });
        });
        if (!update) {
            if (StorageService.GetStorageData()) {
                const count = StorageService.GetParsedStorageData().unreadNotificationsCount;
                dispatch({
                    type: 'UNREAD_NOTIFICATION_COUNT',
                    unreadCount: count
                });
            }
        } else {
            const state = getState().notification;
            dispatch({
                type: 'UNREAD_NOTIFICATION_COUNT',
                unreadCount: state.unreadCount + 1
            });
        }
    },
    clearNotifications: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        NotificationService.ClearMyNotificationsCount().then((updatedUser: any) => {
            const storageData = StorageService.GetParsedStorageData();
            storageData.unreadNotificationsCount = 0;
            StorageService.SaveStorageData(storageData);
            dispatch({
                type: 'CLEAR_NOTIFICATIONS'
            });
        });
    },
    newNotification: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        // TODO:
        // This should be removed in the future when we have a Azure function for the signalR on the changefeed. Right now if we remove this the
        // notification wont be on the user object and we will get the wrong one.
        setTimeout(() => {
            NotificationService.GetLatestNotifications().then((notification: INotification) => {
                const storageData = StorageService.GetParsedStorageData();
                storageData.unreadNotificationsCount++;
                StorageService.SaveStorageData(storageData);
                dispatch({
                    type: 'NEW_NOTIFICATION',
                    notfication: notification
                });
            });
        }, 4000);
    },
    getMore: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        NotificationService.GetMyNotifications(getState().notification.nextPageToken).then((result: ICosmosPagedResult) => {
            dispatch({
                type: 'GET_MORE_NOTIFICATIONS',
                hasNextPage: result.hasNextPage,
                moreNotifications: result.results,
                nextPageToken: result.nextPageToken
            });
        });
    }
};
