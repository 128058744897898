import { INotificationState, KnownAction } from '../Notification';

export function NotificationReducer(state: INotificationState, action: KnownAction) {
    switch (action.type) {
        case 'GE_MY_NOTIFICATIONS':
            return { allNotifications: action.allNotifications, unreadCount: state.unreadCount, hasNextPage: action.hasNextPage, nextPageToken: action.nextPageToken, allNotificationsLoaded: action.hasNextPage ? false : true };
        case 'CLEAR_NOTIFICATIONS':
            return { allNotifications: state.allNotifications, unreadCount: 0, hasNextPage: state.hasNextPage, nextPageToken: state.nextPageToken, allNotificationsLoaded: state.allNotificationsLoaded };
        case 'UNREAD_NOTIFICATION_COUNT':
            return { allNotifications: state.allNotifications, unreadCount: action.unreadCount, hasNextPage: state.hasNextPage, nextPageToken: state.nextPageToken, allNotificationsLoaded: state.allNotificationsLoaded };
        case 'NEW_NOTIFICATION':
            const updatedNotifications = [action.notfication, ...state.allNotifications];
            return { allNotifications: updatedNotifications, unreadCount: state.unreadCount + 1, hasNextPage: state.hasNextPage, nextPageToken: state.nextPageToken, allNotificationsLoaded: state.allNotificationsLoaded };
        case 'GET_MORE_NOTIFICATIONS':
            const moreNotifications = [...state.allNotifications, ...action.moreNotifications];
            return { allNotifications: moreNotifications, unreadCount: state.unreadCount, hasNextPage: action.hasNextPage, nextPageToken: action.nextPageToken, allNotificationsLoaded: action.hasNextPage ? false : true };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return state || { allNotifications: [], unreadCount: 0, hasNextPage: false, nextPageToken: "", allNotificationsLoaded: false };
}
