import * as React from 'react';
import { Translation } from 'react-i18next';
import * as NotificationStore from '../../store/Notification';
import { IApplicationState } from '../../store';
import { connect } from 'react-redux';
import * as TicketsStore from '../../store/Tickets';
import * as CustomerStore from '../../store/Customer';
import { withRouter } from 'react-router';
import AppInsightsUtils from '../../Utils/AppInsightsUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { Dropdown, Badge, NavItem, Row } from 'react-bootstrap';
import NotificationsList from './smallComponents/NotificationsList';

type INotificationsProps = NotificationStore.INotificationState
    & TicketsStore.ITicketsState
    & typeof NotificationStore.actionCreators
    & CustomerStore.ICustomerState
    & typeof CustomerStore.actionCreators;

interface INotificationsState {
    open: boolean;
}

class Notifications extends React.Component<INotificationsProps, INotificationsState> {
    static displayName = 'Notifications';

    constructor(props: INotificationsProps) {
        super(props);
        this.state = { open: false };
    }

    public componentDidUpdate(prevProps: INotificationsProps) {
        if (this.props.updateBell !== prevProps.updateBell && this.props.updateBell) {
            this.props.getNotifications(true);
            this.props.changeUpdateBellState();
        }
    }

    private onToggle = (isOpen: any) => {
        this.setState({ open: !isOpen });
        if (isOpen) {
            this.props.clearNotifications();
            AppInsightsUtils.TrackEvent("Updates opened", {});
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Dropdown alignRight as={NavItem} onToggle={this.onToggle}>
                            <Dropdown.Toggle id="notifications-dropdown">
                                <span className="position-relative d-inline-block">
                                    <FontAwesomeIcon
                                        icon={faBell}
                                        size="lg"
                                        className="mr-1"
                                        fixedWidth
                                    />
                                    {this.props.unreadCount > 0 &&
                                        <Badge
                                            pill
                                            className="menu-notification bell"
                                            variant="danger"
                                        >
                                            {this.props.unreadCount > 9 ? "9+" : this.props.unreadCount}
                                        </Badge>
                                    }
                                    <span>{t('common:NavBar.Alerts')}</span>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Row className="no-gutters">
                                    <NotificationsList open={this.state.open} />
                                </Row>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }
            }
            </Translation>
        );
    }
}
export default withRouter(connect(
    (state: IApplicationState) => ({ ...state.notification, ...state.tickets, ...state.customer }), ({ ...NotificationStore.actionCreators, ...CustomerStore.actionCreators })
)(Notifications) as any);

