import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket, IFileAttachmentBase, IFileRequests } from '../../../../Interface/Interfaces';
import GenericFilePond from '../../../CommonComponents/Inputs/TicketFilePond';
import { API_ENDPOINT } from '../../../../Utils/config';
import { Button, Row, Col } from 'react-bootstrap';
import { TicketStatus } from 'src/Interface/Enum';

interface TicketFileRequestProps {
    ticket: ITicket;
    tempFileRequest: IFileRequests;
    fileCount: (fileCount: number) => void;
    fileRequestFileAdded: (file: IFileAttachmentBase) => void;
    openMatchFilesModal: () => void;
    matchFilesModalOpen: boolean;
    matchSelected: (fileId: string, file: IFileAttachmentBase) => void;
    matchingFileRequest: IFileRequests;
    acceptedFileTypes: string[];
}

export default class TicketFileRequest extends React.Component<TicketFileRequestProps, {}> {
    static displayName = 'TicketFileRequest';

    get canEdit() {
        return this.props.ticket.ticketStatus === TicketStatus.WaitingForPartner;
    }

    public render() {
        return (
            <Translation>
                {
                    (t) => {
                        return (
                            <>
                                <Row>
                                    <Col>
                                        <GenericFilePond
                                            label={t('tickets:Filerequest.Filepond.MultipleFiles')}
                                            uploadUrl={`${API_ENDPOINT}/api/v2/filerequests/${this.props.ticket.id}/files`}
                                            customerId={this.props.ticket.customer!.id}
                                            removeFiles
                                            ticketRef={this.props.ticket.refNumber}
                                            filesWhereSaved={(success: boolean) => { return }}
                                            getFileCount={this.props.fileCount}
                                            fileSizeLimit={50}
                                            disableFilePond={!this.canEdit}
                                            fileAdded={this.props.fileRequestFileAdded}
                                            acceptedFileTypes={this.props.acceptedFileTypes}
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify-content-end">
                                    <Col xs="auto">
                                        <Button
                                            onClick={this.props.openMatchFilesModal}
                                            disabled={!this.canEdit}
                                            className="button radiussvg-vertical-align-top"
                                        >
                                            {t('tickets:Filerequest.MatchUploadedFiles')}
                                            {this.props.tempFileRequest?.unmatchedFiles?.length > 0 ? ` (${this.props.tempFileRequest?.unmatchedFiles?.length})` : ""}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        );
                    }
                }
            </Translation>
        );
    }
}
