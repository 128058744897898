import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

export default class TableHead extends React.Component {
    static displayName = 'TableHead';

    public render() {
        return (
            <Row className="tbody">
                <Col>
                    {this.props.children}
                </Col>
            </Row>
        );
    }
}
