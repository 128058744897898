import HttpHelper from './HttpHelper';
import { IFileRequests, IFileRequestMatchCollecton } from './../Interface/Interfaces';
import { API_ENDPOINT } from '../Utils/config';
import { FileRequestStateEnum } from 'src/Interface/Enum';
import FileUtils from "src/Utils/FileUtils";

// New stuff below and functions above this should go away
export default class FileRequestService {

    public static GetTicketFileRequest(ticketId: string): Promise<IFileRequests> {
        return HttpHelper.Get(`${API_ENDPOINT}/api/v2/filerequests/${ticketId}`);
    }

    public static GetTempFileRequestRow(ticketId: string, fileRequestRowId: string) {
        return HttpHelper.Get(API_ENDPOINT + `/api/v2/filerequests/${ticketId}/${fileRequestRowId}`);
    }

    public static UnmatchTempFileRequestRow(ticketId: string, fileRequestRowId: string) {
        return HttpHelper.Put(API_ENDPOINT + `/api/v2/filerequests/${ticketId}/${fileRequestRowId}/unmatch`);
    }

    public static CreateTempFileRequest(ticketId: string): Promise<IFileRequests> {
        return HttpHelper.Post(`${API_ENDPOINT}/api/v2/filerequests/${ticketId}`);
    }

    public static UpdateFileRequestState(ticketId: string, state: FileRequestStateEnum): Promise<IFileRequests> {
        return HttpHelper.Put(`${API_ENDPOINT}/api/v2/filerequests/${ticketId}?state=${state}`);
    }

    public static RemoveUnusedFileRequestFiles(ticketId: string): Promise<IFileRequests> {
        return HttpHelper.Delete(`${API_ENDPOINT}/api/v2/filerequests/${ticketId}/files`);
    }

    public static UpdateFileRequestMatches(ticketId: string, matchCollecton: IFileRequestMatchCollecton): Promise<IFileRequests> {
        return HttpHelper.Post(`${API_ENDPOINT}/api/v2/filerequests/${ticketId}/match`, JSON.stringify(matchCollecton));
    }

    public static UpdateFileRequestRowMatch(ticketId: string, fileRequestRowId: string, unmatchedFileId: string): Promise<IFileRequests> {
        return HttpHelper.Post(`${API_ENDPOINT}/api/v2/filerequests/${ticketId}/${fileRequestRowId}/match/${unmatchedFileId}`);
    }

    public static DownloadTemplateFile(ticketId: string, fileId: string, originalFileName: string, fromModal: boolean = false): any {
        let responseOK: boolean = false;
        let total = 1;
        let loaded = 0;
        let progressId = fromModal ? "modal-" + fileId : fileId;
        FileUtils.progress({ total, loaded, fileId: progressId });
        return fetch(API_ENDPOINT + `/api/v2/filerequests/${ticketId}/template/${fileId}`,
            HttpHelper.CreateDownloadGetRequestInit()).then(response => {
                if (!response.ok) {
                    throw Error(response.status + ' ' + response.statusText)
                }
                // ensure ReadableStream is supported
                if (!response.body) {
                    throw Error('ReadableStream not yet supported in this browser.')
                }
                // store the size of the entity-body, in bytes
                const contentLength = response.headers.get('content-length');
                // ensure contentLength is available
                if (!contentLength) {
                    throw Error('Content-Length response header unavailable');
                }
                // parse the integer into a base-10 number
                total = parseInt(contentLength, 10);

                responseOK = true;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body!.getReader();

                            read();
                            function read() {
                                reader.read().then(({ done, value }) => {
                                    if (done) {
                                        controller.close();
                                        return;
                                    }
                                    loaded += value!.byteLength;
                                    FileUtils.progress!({ loaded, total, fileId: progressId });
                                    controller.enqueue(value!);
                                    read();
                                }).catch(error => {
                                    console.error(error);
                                    controller.error(error)
                                })
                            }
                        }
                    })
                );
            }).then(response =>
                // construct a blob from the data
                response.blob()
            ).then((blob) => {
                HttpHelper.CreateDownloadResponse(responseOK, blob, originalFileName);
            });
    }
}
