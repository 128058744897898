import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import ActivateELogin from "./CommonComponents/ActivateELogin";
import * as AuthenticatedRouteStore from "../store/AuthenticatedRoute";
import { IApplicationState } from "../store";
import { connect } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import Cardwrapper from '../components/CommonComponents/Containers/Cardwrapper';
import ErrorBoundary from "./HOC/ErrorBoundary";
import DesktopLogo from './CommonComponents/Logos/DesktopLogo';

type ActivateAccountProps = AuthenticatedRouteStore.IAuthenticatedRouteState &
    typeof AuthenticatedRouteStore.actionCreators &
    RouteComponentProps<{}>;

export class ActivateAccount extends React.Component<ActivateAccountProps> {
    static displayName = 'ActivateAccount';

    public token: string;

    constructor(props: any) {
        super(props);
        this.token = this.props.match.params!["guid"];
    }

    public activateAccount = (loginMethod: string) => {
        if (this.token) {
            this.props.signIn(loginMethod, "/activating/" + this.token);
        }
    }

    public render() {
        return (
            <ErrorBoundary>
                <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <Col xs={12} md={8} lg={6}>
                        <Cardwrapper>
                            <Row className="justify-content-center mb-3">
                                <Col xs={8} xl={6} className="old">
                                    <DesktopLogo className="text-primary" svgClassName="text-primary" />
                                </Col>
                            </Row>
                            <ActivateELogin
                                isNewUser
                                onActivateAccount={this.activateAccount}
                                showCancelButton={false}
                            />
                        </Cardwrapper>
                    </Col>
                </Row>
            </ErrorBoundary>
        );
    }
}

export default connect(
    (state: IApplicationState) => state.authenticatedRoute,
    AuthenticatedRouteStore.actionCreators
)(ActivateAccount);
