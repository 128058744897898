import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket } from '../../../../Interface/Interfaces';
import { TypeOfTicket, TicketStatus, FiscalYearEnum } from '../../../../Interface/Enum';
import { Button, OverlayTrigger, Tooltip, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faCheckCircle, faUndoAlt } from '@fortawesome/pro-solid-svg-icons';

interface DefaultTicketActionHeaderProps {
    newTicket: boolean;
    ticket: ITicket;
    postAction?: (isDraft: boolean) => void;
    loading?: boolean;
    isCompleteNewTicket?: boolean;
    closeAction?: () => void;
    isTicketCreated?: boolean;
    reOpenAction?: () => void;
    fileCount?: number;
    selectedFiscalYearIsClosed?: boolean;
    canSaveFileRequest?: boolean;
    setIsNewlyCreated: (isNew: boolean) => void;
    isNewlyCreatedTicket: boolean;
    canSaveDraft?: boolean;
    removeTicketDraft?: () => void;
}

export default class DefaultTicketActionHeader extends React.Component<DefaultTicketActionHeaderProps, {}> {
    static displayName = 'DefaultTicketActionHeader';

    public postAction = () => {
        this.props.setIsNewlyCreated(true);
        if (this.props.postAction) {
            this.props.postAction!(false);
        }
    }

    public postDraftAction = () => {
        if (this.props.postAction) {
            this.props.postAction!(true);
        }
    }

    public render() {
        if (
            this.props.ticket.typeOfTicket === TypeOfTicket.PreparedByClient ||
            this.props.ticket.typeOfTicket === TypeOfTicket.PreparedByPartner) {
            return "";
        }
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            {this.props.newTicket &&
                                (!this.props.isCompleteNewTicket! || this.props.loading! || this.props.fileCount! > 0 ? (
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip
                                                id={`send-ticket-${this.props.ticket.id}`}
                                            >
                                                {t('tickets:Ticket:Tooltip:SaveTicket')}
                                            </Tooltip>
                                        }
                                    >
                                        <div className="d-inline-block">
                                            <Button
                                                disabled
                                                className="btn-sm-lg mr-1"
                                                style={{ pointerEvents: 'none' }}
                                            >
                                                {t('tickets:NewTicket.CreateTicket')} <FontAwesomeIcon icon={faPaperPlane} className="ml-3" />
                                            </Button>
                                        </div>
                                    </OverlayTrigger>
                                ) :
                                    (
                                        <Button
                                            onClick={this.postAction}
                                            className="btn-sm-lg mr-1"
                                        >
                                            {t('tickets:NewTicket.CreateTicket')} <FontAwesomeIcon icon={faPaperPlane} className="ml-3" />
                                        </Button>
                                    )
                                )
                            }
                            {(!this.props.newTicket && this.props.closeAction && this.props.ticket.ticketStatus !== TicketStatus.Resolved) &&
                                <Button
                                    onClick={this.props.closeAction}
                                    disabled={this.props.loading! || this.props.isNewlyCreatedTicket || (this.props.ticket.typeOfTicket === TypeOfTicket.PartnerTicket && this.props.ticket.fiscalYear === FiscalYearEnum.NotSet)}
                                    className="btn-sm-lg"
                                >
                                    {t('tickets:Ticket.ResolveTicket')} <FontAwesomeIcon icon={faCheckCircle} className="ml-3" />
                                </Button>
                            }
                            {(!this.props.newTicket && this.props.reOpenAction && this.props.ticket.ticketStatus === TicketStatus.Resolved) &&
                                <Button
                                    className="btn-sm-lg"
                                    onClick={this.props.reOpenAction}
                                    disabled={this.props.loading!}
                                >
                                    {t('tickets:Ticket.ReOpen')} <FontAwesomeIcon icon={faUndoAlt} className="ml-3" />
                                </Button>
                            }
                            {this.props.newTicket && (
                                <Dropdown
                                    as={ButtonGroup}
                                    size="sm"
                                    alignRight
                                    className="mr-3"
                                >
                                    <Button
                                        onClick={this.postDraftAction}
                                        disabled={!this.props.canSaveDraft || this.props.loading! || this.props.isTicketCreated!}
                                        variant="outline-primary ml-3"
                                    >
                                        {t('tickets:NewTicket.SaveTicketDraft')}
                                    </Button>
                                    {(this.props.ticket.ticketStatus === TicketStatus.Draft) &&
                                        <>
                                            <Dropdown.Toggle
                                                split
                                                variant="outline-primary"
                                                id="ticket-action-dropdown"
                                            />
                                            <Dropdown.Menu className="dropdown-menu-sm dropdown-menu-width-100">
                                                <Dropdown.Item
                                                    onClick={this.props.removeTicketDraft}
                                                    className="text-danger"
                                                >
                                                    {t('tickets:NewTicket.RemoveTicketDraft')}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </>
                                    }
                                </Dropdown>)
                            }
                        </>
                    );
                }
            }
            </Translation>
        );
    }
}
