import { IFileRequestState, KnownAction } from '../FileRequest';
import { FileRequests } from '../../classes/FileRequests';
import { FileRequest } from 'src/classes/FileRequest';

export function FileRequestReducer(state: IFileRequestState, action: KnownAction) {
    switch (action.type) {
        case 'LOADING':
            return { ...state, isLoading: action.loading };
        case 'GOT_A_FILEREQUEST':
            return { ...state, fileRequest: action.result };
        case 'GOT_A_TEMP_FILEREQUEST':
            return { ...state, tempFileRequest: action.result };
        case 'GOT_A_MATCHING_FILEREQUEST':
            return { ...state, matchingFileRequest: action.result };
        case 'GOT_A_FILEREQUEST_ROW':
            return { ...state, selectedFileRequestRow: action.result };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return state || {
        fileRequest: new FileRequests(),
        tempFileRequest: new FileRequests(),
        matchingFileRequest: new FileRequests(),
        selectedFileRequestRow: new FileRequest()
    };
}
