import { IFileRequests, IFileRequest, IFileAttachmentBase } from "../Interface/Interfaces";
import { DeliveredStatusEnum } from "src/Interface/Enum";


export class FileRequests implements IFileRequests {
    public id: string = "";
    public fileRequests: IFileRequest[] = [];
    public deliveredStatus: DeliveredStatusEnum = DeliveredStatusEnum.NotDelivered;
    public unmatchedFiles: IFileAttachmentBase[] = [];
    public eTag:string = "";

    constructor(fileReq: IFileRequests = { fileRequests: [], id: '', unmatchedFiles: [], eTag: "", deliveredStatus: DeliveredStatusEnum.NotDelivered }){
        this.id = fileReq.id;
        this.fileRequests = fileReq.fileRequests;
        this.deliveredStatus = fileReq.deliveredStatus;
        this.unmatchedFiles = fileReq.unmatchedFiles;
    }
}
