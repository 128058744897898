import * as React from 'react';
import { Translation } from 'react-i18next';
import Comment from './Comment';
import AddComment from './AddComment';
import { IComment, ITicket, ITicketAttachments, IFileAttachmentBase } from '../../../Interface/Interfaces';
import { Col, Row } from 'react-bootstrap';

interface ICommentsProps {
    comments: IComment[];
    saveComment: (value: string) => void;
    isCommentSaved: boolean;
    loading: boolean;
    ticket: ITicket;
    getFileCount: (fileCount: number) => void;
    filesWhereSaved: (success: boolean) => void;
    downloadFiles: (e: any, comment: any, originalFileName: string) => void;
    attachedFiles: ITicketAttachments[];
    attachedFileAdded: (file: IFileAttachmentBase) => void;
    removeNewCommentFile?: (file: ITicketAttachments) => void;
    fileCount: number;
    addCommentIsDirty: (isAddCommentDirty: boolean) => void;
    cancelComment: () => void;
}

export default class Comments extends React.Component<ICommentsProps, {}> {
    static displayName = 'Comments';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <AddComment
                                        fileCount={this.props.fileCount}
                                        downloadFiles={this.props.downloadFiles}
                                        removeNewCommentFile={this.props.removeNewCommentFile}
                                        attachedFileAdded={this.props.attachedFileAdded}
                                        attachedFiles={this.props.attachedFiles}
                                        isCommentSaved={this.props.isCommentSaved}
                                        postComment={this.props.saveComment}
                                        loading={this.props.loading}
                                        comments={this.props.comments}
                                        ticket={this.props.ticket}
                                        customerId={this.props.ticket.customer!.id}
                                        ticketId={this.props.ticket.refNumber!}
                                        getFileCount={this.props.getFileCount}
                                        filesWhereSaved={this.props.filesWhereSaved}
                                        addCommentIsDirty={this.props.addCommentIsDirty}
                                        cancelComment={this.props.cancelComment}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.props.comments.map((x, idx) => {
                                        return (
                                            <Comment
                                                comment={x}
                                                idx={idx}
                                                key={idx}
                                                downloadFiles={this.props.downloadFiles}
                                            />
                                        )
                                    })}
                                </Col>
                            </Row>
                        </>
                    );
                }
            }
            </Translation>
        );
    }
}
