import './assets/scss/foundation-app.scss';
import './assets/scss/app.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import './Utils/Extensions';
import * as React from 'react';
import { Route, Switch, RouteComponentProps, withRouter } from 'react-router';
import Home from './components/Home';
import Layout from './components/Layout';
import AuthenticatedRoute from './components/smallComponents/AuthenticatedRoute';
import Login from './components/Login';
import SetPassword from './components/SetPassword';
import NewTicketView from './components/TicketComponents/NewTicketView';
import TicketView from './components/TicketComponents/TicketView';
import MyProfile from './components/MyProfile';
import Tickets from './components/TicketComponents/Tickets';
import EmailSettings from './components/UserComponents/EmailSettings';
import Customers from './components/Customers';
import Customer from './components/CustomersComponents/Customer';
import NotificationsPage from './components/navigationComponents/smallComponents/NotificationsPage';
import Help from './components/Help';
import TelemetryProvider from './components/HOC/TelemetryProvider';
import { getAppInsights } from './services/TelemetryService';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import NotFound from './components/NotFound';
import Unauthorized from './components/Unauthorized';
import ActivateAccount from './components/ActivateAccount';
import ActivatingAccount from './components/ActivatingAccount';

class App extends React.Component<RouteComponentProps, any> {
    static displayName = 'App';

    private appInsights: ApplicationInsights;

    public render() {
        return (
            <TelemetryProvider after={() => { this.appInsights = getAppInsights() }}>
                <Layout>
                    <Switch>
                        <Route path='/login' component={Login} />
                        <Route path='/setpassword/:guid' component={SetPassword} />
                        <Route path='/activating/:guid' component={ActivatingAccount} />
                        <Route path='/activate/:guid' component={ActivateAccount} />
                        <Route component={Unauthorized} path="/unauthorized" />

                        <AuthenticatedRoute exact component={Home} path="/" history={this.props.history} location={this.props.location} match={this.props.match} />
                        <AuthenticatedRoute exact component={NewTicketView} path="/tickets/new" history={this.props.history} location={this.props.location} match={this.props.match} />
                        <AuthenticatedRoute exact component={Tickets} path="/tickets" history={this.props.history} location={this.props.location} match={this.props.match} />
                        <AuthenticatedRoute exact component={Customers} path="/customers" history={this.props.history} location={this.props.location} match={this.props.match} />
                        <AuthenticatedRoute exact component={Customer} path="/customers/:customerId" history={this.props.history} location={this.props.location} match={this.props.match} />
                        <AuthenticatedRoute component={TicketView} path="/tickets/:refNumber" history={this.props.history} location={this.props.location} match={this.props.match} />
                        <AuthenticatedRoute exact component={MyProfile} path="/user/myprofile" history={this.props.history} location={this.props.location} match={this.props.match} />
                        <AuthenticatedRoute exact component={EmailSettings} path="/user/emailsettings" history={this.props.history} location={this.props.location} match={this.props.match} />

                        <AuthenticatedRoute exact component={NotificationsPage} path="/notifications" history={this.props.history} location={this.props.location} match={this.props.match} />
                        <AuthenticatedRoute exact component={Help} path="/help" history={this.props.history} location={this.props.location} match={this.props.match} />
                        <AuthenticatedRoute component={NotFound} path="*" history={this.props.history} location={this.props.location} match={this.props.match} />
                    </Switch>
                </Layout>
            </TelemetryProvider>
        );
    }
}

export default withRouter(App);
