import HttpHelper from './HttpHelper';
import StorageService from './StorageService';
import { API_ENDPOINT } from '../Utils/config';
import { IMe } from '../Interface/Interfaces';

export function LogOut(): Promise<Response> {
    return HttpHelper.PostThatNeedsMigration(API_ENDPOINT + '/api/user/logout');
}

export async function activateAccount(token: string): Promise<any> {
    const postTask = await HttpHelper.PostThatNeedsMigration(API_ENDPOINT + '/api/user/activateaccount', token);
    return postTask;
}

export function updateLanguage(newLanguage: string) {
    HttpHelper.PostThatNeedsMigration(API_ENDPOINT + '/api/user/ChangeLanguage?newLanguage=' + newLanguage);
}

export async function getEmailSettings(): Promise<any> {
    return HttpHelper.Get(API_ENDPOINT + '/api/user/getemailsettings');
}

export async function updateEmailSettings(emailSettings: any): Promise<any> {
    return HttpHelper.Post(API_ENDPOINT + '/api/user/changeemailsettings', JSON.stringify(emailSettings));
}

export function getToken(): Promise<string> {
    return HttpHelper.getValidAccessToken();
}

export function getUserId(): string {
    if (StorageService.GetStorageData()) {
        const user = StorageService.GetParsedStorageData();
        const userId = user.userId;
        return userId;
    } else {
        return "";
    }
}

export function GetMeFromStorage() {
    const me: IMe = StorageService.GetParsedStorageData();
    return me;
}

export class UserService {
    public static GetMe() {
        const getTask = HttpHelper.Get(API_ENDPOINT + '/api/v2/user/me');
        return getTask;
    }
}
