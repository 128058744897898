import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket, IFileAttachmentBase, IFileRequests, IFileRequest } from '../../../Interface/Interfaces';
import { Row, Col } from 'react-bootstrap';
import FileRequestRowDetailsModal from './FileRequests/FileRequestRowDetailsModal';
import FileRequestTable from "./FileRequests/FileRequestTable";
import FileRequestForm from './FileRequests/FileRequestForm';
import FileRequestService from 'src/apiServices/FileRequestService';
import FileRequestMatchModal from './FileRequests/FileRequestMatchModal';

interface ITicketFileRequestProps {
    ticket: ITicket;
    tempFileRequest: IFileRequests;
    fileCount: (fileCount: number) => void;
    matchFiles: (removedIds: string[]) => void;
    cancelMatch: () => void;
    fileRequestFileAdded: (file: IFileAttachmentBase) => void;
    openMatchFilesModal: () => void;
    matchFilesModalOpen: boolean;
    matchSelected: (fileId: string, file: IFileAttachmentBase) => void;
    matchingFileRequest: IFileRequests;
    selectedFileRequestRow: IFileRequest;
    getFileRequestRow: (ticketId: string, fileRequestRowId: string) => void;
    unmatchTempFileRequestRow: (ticketId: string, fileRequestRowId: string) => void;
    matchTempFileRequestRow: (ticketId: string, fileRequestRowId: string, unmatchedFileId: string) => void;
    uniqueFileRequestCategories: string[];
}

interface ITicketFileRequestState {
    fileRequestRowModalOpen: boolean;
    fileRequestRowId: string | null;
}

export default class TicketFileRequest extends React.Component<ITicketFileRequestProps, ITicketFileRequestState> {
    static displayName = 'TicketFileRequest';

    acceptedFileTypes: string[] = ['application/msword', 'application/rtf', 'application/postscript',
        'application/pdf', 'application/octet-stream', 'application/mshelp', 'application/msexcel',
        'application/mspowerpoint', 'application/mif', 'application/dxf', 'image/*', 'audio/*', 'text/*', '.sie', '.ktr',
        'message/*', 'model/*', 'multipart/*', 'video/*', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-word.document.macroEnabled.12', 'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.oasis.opendocument.text',
        'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.presentation',
        'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.template', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.ms-powerpoint.addin.macroEnabled.12', 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        'application/vnd.ms-powerpoint.template.macroEnabled.12', 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template', 'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12', 'application/vnd.ms-excel.addin.macroEnabled.12', 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'];

    constructor(props: ITicketFileRequestProps) {
        super(props);

        this.state = {
            fileRequestRowModalOpen: false,
            fileRequestRowId: null
        };
    }

    componentDidUpdate(prevProps: ITicketFileRequestProps) {
        if (prevProps.selectedFileRequestRow.id !== this.props.selectedFileRequestRow.id
            && this.props.selectedFileRequestRow.id === this.state.fileRequestRowId) {
            this.setState({ fileRequestRowModalOpen: true });
        }
    }

    public downloadTemplateFile = (file: IFileAttachmentBase, fromModal: boolean = false) => {
        FileRequestService.DownloadTemplateFile(this.props.ticket.id, file.id, file.originalFileName, fromModal);
    };

    public openFileRequestRowModal = (fileRequestRowId: string) => {
        this.props.getFileRequestRow(this.props.ticket.id, fileRequestRowId);
        if (this.props.selectedFileRequestRow.id === fileRequestRowId) {
            this.setState({ fileRequestRowModalOpen: true });
            return;
        }
        this.setState({ fileRequestRowId: fileRequestRowId })
    }

    public unmatchTempFileRequestRow = (fileRequestRowId: string) => {
        this.props.unmatchTempFileRequestRow(this.props.ticket.id, fileRequestRowId);
    }

    public matchTempFileRequestRow = (fileRequestRowId: string, unmatchedFileId: string) => {
        this.props.matchTempFileRequestRow(this.props.ticket.id, fileRequestRowId, unmatchedFileId);
    }

    public render() {
        return (
            <Translation>
                {
                    (t) => {
                        return (
                            <>
                                <Row className="padding-1">
                                    <Col>
                                        <FileRequestForm acceptedFileTypes={this.acceptedFileTypes} {...this.props} />
                                        <Row>
                                            <Col>
                                                <FileRequestTable
                                                    fileRequest={this.props.tempFileRequest}
                                                    openFileRequestRowModal={this.openFileRequestRowModal}
                                                    downloadTemplateFile={this.downloadTemplateFile}
                                                    uniqueFileRequestCategories={this.props.uniqueFileRequestCategories}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <FileRequestMatchModal acceptedFileTypes={this.acceptedFileTypes} {...this.props} />
                                <FileRequestRowDetailsModal
                                    downloadTemplateFile={this.downloadTemplateFile}
                                    fileRequestRowModalOpen={this.state.fileRequestRowModalOpen}
                                    ticket={this.props.ticket}
                                    closeModal={() => this.setState({ fileRequestRowModalOpen: false })}
                                    selectedFileRequestRow={this.props.selectedFileRequestRow}
                                    unmatchTempFileRequestRow={this.unmatchTempFileRequestRow}
                                    matchTempFileRequestRow={this.matchTempFileRequestRow}
                                    fileCount={this.props.fileCount}
                                    fileRequestFileAdded={this.props.fileRequestFileAdded}
                                    unmatchedFiles={this.props.tempFileRequest.unmatchedFiles}
                                    acceptedFileTypes={this.acceptedFileTypes}
                                />
                            </>
                        );
                    }
                }
            </Translation>
        );
    }
}
