import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket, ICustomer } from '../../../Interface/Interfaces';
import { Table } from '@kpmgportals/genericcomponents';
import { Col, Row, Button } from 'react-bootstrap';
import { FixedSizeList } from 'react-window';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import TicketRow from '../../TicketComponents/TicketListing/List/TicketRow';
import TicketListTableHeader from '../../TicketComponents/TicketListing/List/TicketTableHeader';

interface ICustomerTicketProps {
    tickets: ITicket[];
    customer: ICustomer;
}

export default class CustomerTickets extends React.Component<ICustomerTicketProps, {}> {
    static displayName = 'CustomerTickets';

    public renderRow = ({ ...props }) => (
        <TicketRow ticket={this.props.tickets[props.index]} style={props.style} />
    );

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            <Row className="justify-content-between">
                                <Col xs="auto">
                                    <h2>{t('customers:Customer.CustomerTickets.Title')}</h2>
                                </Col>
                                <Col xs="auto">
                                    <NavLink exact to={{ pathname: "/tickets/new", state: { customer: this.props.customer, setCustomerFilter: true } }}>
                                        <Button
                                            variant="primary"
                                            size="sm"
                                        >
                                            {t('customers:Customer.CustomerTickets.CreateTicket')}
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                className="ml-3"
                                            />
                                        </Button>
                                    </NavLink>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table minWidth="lg">
                                        <TicketListTableHeader ticketsLength={this.props.tickets.length} />
                                        <Table.Body>
                                            <FixedSizeList
                                                style={{}}
                                                height={400}
                                                itemCount={this.props.tickets.length}
                                                itemSize={55}
                                                width="initial"
                                                key="virtualList"
                                                className="list-container"
                                            >
                                                {this.renderRow}
                                            </FixedSizeList>
                                        </Table.Body>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-right">
                                    <NavLink exact to={{ pathname: "/tickets", state: { customer: this.props.customer, setCustomerFilter: true } }}>
                                        {t('customers:Customer.CustomerTickets.SeeAllTickets')}
                                    </NavLink>
                                </Col>
                            </Row>
                        </>
                    )
                }}
            </Translation>
        );
    }
}

