import * as React from "react";
import { Translation } from 'react-i18next';
import { ITicket, IFileAttachmentBase, IFileRequest } from "../../../../Interface/Interfaces";
import GenericFilePond from "../../../CommonComponents/Inputs/TicketFilePond";
import { API_ENDPOINT } from "../../../../Utils/config";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import AttachedFile from "../AttachedFile";
import { FileRequestStatusEnum, TicketStatus } from "src/Interface/Enum";
import ButtonCancel from "src/components/CommonComponents/Buttons/ButtonCancel";
import GenericReactSelect from '../../../CommonComponents/Inputs/GenericReactSelect';
import i18n from '../../../translationcomponent/i18n';

interface FileRequestRowDetailsModalProps {
    selectedFileRequestRow: IFileRequest;
    fileRequestFileAdded: (file: IFileAttachmentBase) => void;
    unmatchTempFileRequestRow: (fileRequestRowId: string) => void;
    downloadTemplateFile: (file: IFileAttachmentBase, fromModal: boolean) => void;
    fileRequestRowModalOpen: boolean;
    ticket: ITicket;
    closeModal: () => void;
    fileCount: (fileCount: number) => void;
    unmatchedFiles: IFileAttachmentBase[];
    matchTempFileRequestRow: (fileRequestRowId: string, unmatchedFileId: string) => void;
    acceptedFileTypes: string[];
}

enum UpdateOnCloseType {
    Nothing,
    Unmatch,
    Match
}

interface IFileRequestRowDetailsModalState {
    updateOnClose: UpdateOnCloseType;
    selectedFileId: string;
    selectedFileLabel: string;
}

export default class FileRequestRowDetailsModal extends React.Component<FileRequestRowDetailsModalProps, IFileRequestRowDetailsModalState> {
    static displayName = 'FileRequestRowDetailsModal';

    constructor(props: FileRequestRowDetailsModalProps) {
        super(props);

        this.state = {
            updateOnClose: UpdateOnCloseType.Nothing,
            selectedFileId: "",
            selectedFileLabel: `${i18n.t('tickets:Filerequest.MatchRequest')}...`,
        };
    }

    updateFileRequestRow = () => {
        switch (this.state.updateOnClose) {
            case UpdateOnCloseType.Unmatch:
                this.unmatchTempFileRequestRow();
                break;
            case UpdateOnCloseType.Match:
                this.matchTempFileRequestRow();
                break;
        }

        this.closeModal()
    }

    closeModal() {
        this.props.closeModal();
        this.setState({
            updateOnClose: UpdateOnCloseType.Nothing,
            selectedFileId: "",
            selectedFileLabel: `${i18n.t('tickets:Filerequest.MatchRequest')}...`
        });
    }

    unmatchTempFileRequestRow = () => {
        if (!this.props.selectedFileRequestRow.uploadedFile) {
            return;
        }

        this.props.unmatchTempFileRequestRow(this.props.selectedFileRequestRow.id);
    }

    matchTempFileRequestRow = () => {
        if (this.props.selectedFileRequestRow.uploadedFile) {
            return;
        }

        this.props.matchTempFileRequestRow(this.props.selectedFileRequestRow.id, this.state.selectedFileId);
    }

    unmatch() {
        this.setState({ updateOnClose: UpdateOnCloseType.Unmatch })
    }

    canMatch(request: IFileRequest) {
        if (this.props.ticket.ticketStatus !== TicketStatus.WaitingForPartner) {
            return false;
        }

        if (request.status === FileRequestStatusEnum.Matched || request.status === FileRequestStatusEnum.Rejected) {
            return true;
        }

        return false;
    }

    get canEdit() {
        if (this.props.ticket.ticketStatus === TicketStatus.WaitingForPartner) {
            return true;
        }

        return false;
    }

    fileRequestFileAdded(file: IFileAttachmentBase) {
        this.props.fileRequestFileAdded(file);
        this.setMatch(file.id, file.originalFileName);
    }

    matchSelected(e: any) {
        if (e) {
            const value: string = e.value || '';
            const label: string = e.label || `${i18n.t('tickets:Filerequest.MatchRequest')}...`;
            this.setMatch(value, label);
        } else {
            this.setMatch('', `${i18n.t('tickets:Filerequest.MatchRequest')}...`);
        }
    }

    setMatch(value: string, label: string) {
        if (!value) {
            this.setState({
                selectedFileId: value,
                selectedFileLabel: label,
                updateOnClose: UpdateOnCloseType.Nothing
            });
            return;
        }

        this.setState({
            selectedFileId: value,
            selectedFileLabel: label,
            updateOnClose: UpdateOnCloseType.Match
        });
    }

    public render() {
        return (
            <Translation>
                {t => {
                    return (
                        <Modal
                            show={this.props.fileRequestRowModalOpen}
                            size="lg"
                            onHide={() => this.closeModal()}
                        >
                            <Modal.Header>
                                <Modal.Title>
                                    {t('tickets:Filerequest.FileRequest')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('tickets:Filerequest.Category')}</Form.Label>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={this.props.selectedFileRequestRow.category}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('tickets:Filerequest.Request')}</Form.Label>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={this.props.selectedFileRequestRow.request}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('tickets:Filerequest.Title')}</Form.Label>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={this.props.selectedFileRequestRow.title}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t('tickets:Filerequest.Description')}</Form.Label>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                as="textarea"
                                                value={this.props.selectedFileRequestRow.description}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {this.props.selectedFileRequestRow.templateFile &&
                                    <Row>
                                        <Col>
                                            <Form.Label>{t('tickets:Filerequest.Template')}</Form.Label>
                                            <AttachedFile
                                                attachFile={{ ...this.props.selectedFileRequestRow.templateFile!, downloadedBy: [] }}
                                                groupId={this.props.selectedFileRequestRow.id}
                                                downloadFiles={
                                                    (originalFileName: string) =>
                                                        this.props.downloadTemplateFile(this.props.selectedFileRequestRow.templateFile!, true)
                                                }
                                                progressPrefix="modal-"
                                            />
                                        </Col>
                                    </Row>
                                }
                                {this.props.selectedFileRequestRow.status === FileRequestStatusEnum.Rejected &&
                                    <Row>
                                        <Col>
                                            <Alert variant="danger">
                                                <Alert.Heading as="h5">{t('tickets:Filerequest:RejectedFile')}</Alert.Heading>
                                                <p>{this.props.selectedFileRequestRow.rejectedFile}</p>
                                                <Alert.Heading as="h5">{t('tickets:Filerequest:Comment')}</Alert.Heading>
                                                <p className="mb-0">{this.props.selectedFileRequestRow.comment}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                                {(!this.props.selectedFileRequestRow.uploadedFile || this.state.updateOnClose !== UpdateOnCloseType.Nothing)
                                    && (this.props.selectedFileRequestRow.status !== FileRequestStatusEnum.Delivered) &&
                                    <>
                                        <Row>
                                            <Col>
                                                <GenericFilePond
                                                    label={t('tickets:Filerequest.Filepond.SingleMatching')}
                                                    uploadUrl={`${API_ENDPOINT}/api/v2/filerequests/${this.props.ticket.id}/files`}
                                                    customerId={this.props.ticket.customer!.id}
                                                    removeFiles
                                                    ticketRef={this.props.ticket.refNumber}
                                                    filesWhereSaved={(success: boolean) => { return }}
                                                    getFileCount={this.props.fileCount}
                                                    fileSizeLimit={50}
                                                    disableFilePond={!this.canEdit}
                                                    allowMultiple={false}
                                                    fileAdded={(file) => this.fileRequestFileAdded(file)}
                                                    acceptedFileTypes={this.props.acceptedFileTypes}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="filerequest-match-row-dropdown">
                                                    <Form.Label>{t('tickets:Filerequest.UploadedFiles')}</Form.Label>
                                                    <GenericReactSelect
                                                        inputId="filerequest-match-row-dropdown"
                                                        options={
                                                            this.props.unmatchedFiles?.map((file: IFileAttachmentBase) => {
                                                                return { value: file.id, label: file.originalFileName };
                                                            })
                                                        }
                                                        onChange={(e: any) => this.matchSelected(e)}
                                                        placeholder={`${t('tickets:Filerequest.MatchRequest')}...`}
                                                        value={{ value: this.state.selectedFileId, label: this.state.selectedFileLabel }}
                                                        isClearable
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                {(this.props.selectedFileRequestRow.uploadedFile && this.state.updateOnClose === UpdateOnCloseType.Nothing) &&
                                    <Row>
                                        <Col>
                                            <h5>{t('tickets:Filerequest.MatchedFile')}</h5>
                                            <Alert variant="success">
                                                <Row className="align-items-center">
                                                    <Col xs="auto">
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                    </Col>
                                                    <Col>
                                                        {this.props.selectedFileRequestRow.uploadedFile.originalFileName}
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button
                                                            variant="light"
                                                            size="sm"
                                                            disabled={!this.canMatch(this.props.selectedFileRequestRow)}
                                                            title={t('tickets:Filerequest.RemoveMatch')}
                                                            onClick={() => this.unmatch()}
                                                        >
                                                            <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Alert>
                                        </Col>
                                    </Row>
                                }
                            </Modal.Body>
                            <Modal.Footer className="justify-content-between">
                                <Col xs="auto">
                                    {this.props.selectedFileRequestRow.status !== FileRequestStatusEnum.Delivered &&
                                        <ButtonCancel onClick={() => this.closeModal()}>
                                            {t('common:Action.Cancel')}
                                        </ButtonCancel>
                                    }
                                </Col>
                                <Col xs="auto">
                                    {this.props.selectedFileRequestRow.status === FileRequestStatusEnum.Delivered ? (
                                        <Button variant="primary" className="ml-1" onClick={() => this.updateFileRequestRow()}>
                                            {t('common:Action.Close')}
                                        </Button>
                                    ) :
                                        (
                                            <Button variant="primary" className="ml-1" onClick={() => this.updateFileRequestRow()}>
                                                {t('common:Action.Save')}
                                            </Button>
                                        )
                                    }
                                </Col>
                            </Modal.Footer>
                        </Modal>
                    );
                }}
            </Translation>
        );
    }
}
