import i18n from "../components/translationcomponent/i18n";

export function isEmptyOrSpaces(str: string) {
    if (str !== null) {
        const t = str.trim();
        if (t !== "") {
            return str === null;
        }
        return true;
    } else {
        return true;
    }
}

export function changePageTitle(pageName: string) {
    const portalTitle = i18n.t('common:KPMGTitle');
    const pageTitle = i18n.t(pageName);
    document.title = portalTitle + " | " + pageTitle;
}

export function handleKeyPress(e: any, callBack: any, searchText: string) {
    if (e.key === 'Enter') {
        callBack(searchText);
    }
}

export function isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function stringIncludesLetters(value: string) {
    if (/^[a-zA-Z]/.test(value)) {
        return true;
    } else {
        return false;
    }
}
