import * as React from 'react';
import { Translation } from 'react-i18next';
import ErrorBoundary from '../HOC/ErrorBoundary';

export default class TwitterFeed extends React.Component<{}, {}> {
    static displayName = 'TwitterFeed';

    componentDidMount() {
        if ((typeof window.twttr !== 'undefined') && (typeof window.twttr.widgets !== 'undefined')) {
            window.twttr.widgets.createTimeline(
                {
                    sourceType: "profile",
                    screenName: "KPMGSweden"
                },
                document.getElementById("twitter-timeline"),
                {
                    dnt: true,
                    linkColor: "#005eb8",
                    chrome: "nofooter transparent noborders noheader",
                    height: '500'
                }
            );
        }
    }
    public render() {
        return (
            <ErrorBoundary>
                <Translation>{
                    (t) => {
                        return (
                            <>
                                <h3>Twitter</h3>
                                <a
                                    id="twitter-timeline"
                                    className="twitter-timeline"
                                    href="https://twitter.com/KPMGSweden?ref_src=twsrc%5Etfw"
                                >
                                    <span className="sr-only">
                                        {t('common:TwitterTimeline')}
                                    </span>
                                </a>
                            </>
                        )
                    }
                }
                </Translation>
            </ErrorBoundary>
        )
    }
}
