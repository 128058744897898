import { IAppThunkAction } from '..';
import { KnownAction } from '../CustomerHub';
import { getToken } from '../../apiServices/UserService';
import * as signalR from '@microsoft/signalr';
import Logger from '../../Utils/Logger';

export const CustomerHubActions = {
    connectCustomer: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        const connectionCustomer = new signalR.HubConnectionBuilder()
            .withUrl("/customer", { accessTokenFactory: () => getToken() })
            .configureLogging((window as any).KPMGDEBUG ? signalR.LogLevel.Trace : signalR.LogLevel.Trace)
            .withAutomaticReconnect()
            .build();

        const start = async () => {
            try {
                await connectionCustomer.start();
                dispatch({
                    type: 'CUSTOMERHUB_CONNECT_HUB',
                    connectionCustomer: connectionCustomer
                });
            } catch (err) {
                Logger.log("error connecting to SignalR /customer");
                Logger.error(err);
                setTimeout(() => start(), 5000);
            }
        };

        start();
    },
};
