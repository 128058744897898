import { Reducer } from 'redux';
import { ProfileActions } from './actions/ProfileActions';
import { ProfileReducer } from './reducers/ProfileReducers';
import { IUserOrganization } from '../../src/Interface/Interfaces';

export interface IProfileState {
    email: string;
    FirstName: string;
    LastName: string;
    organizationID: string;
    organizationOfficeID: string;
    pictureUrl?: any;
    phoneNumber?: any;
    whiteList?: any[];
    officeLocations?: any[];
    userOrganization: IUserOrganization;
}

interface IProfileUpdateUser { type: 'UPDATE_PROFILE'; profile: IProfileState; }

interface IProfileGetUser { type: 'GET_PROFILE'; profile: IProfileState; }

export type KnownAction = IProfileUpdateUser | IProfileGetUser;

export const actionCreators = ProfileActions;

export const reducer: Reducer<IProfileState> = (state: IProfileState, action: KnownAction) => ProfileReducer(state, action);
