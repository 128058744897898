import * as React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import EmailSetting from './SmallComponents/EmailSetting';
import GenericSaveCancel from '../CommonComponents/GenericSaveCancel';
import { updateEmailSettings, getEmailSettings } from '../../apiServices/UserService';
import MyHistory from '../../history';
import { RouteComponentProps } from 'react-router';
import * as ToastsStore from '../../store/Toasts';
import { ToastMessage } from '../../classes/ToastMessage';
import { IApplicationState } from '../../store';
import { changePageTitle } from '../../Utils/CommonUtils';
import AppInsightsUtils from '../../Utils/AppInsightsUtils';
import ErrorBoundary from '../HOC/ErrorBoundary';

interface EmailSettingsState {
    allTicketSetting?: number;
    newTicketSetting?: number;
    updatedTicketSetting?: number;
    resolvedTicketSetting?: number;
    loading?: boolean;
}

type IEmailSettingsProps = RouteComponentProps<{}>
    & ToastsStore.IToastsState
    & typeof ToastsStore.actionCreators;

class EmailSettings extends React.Component<IEmailSettingsProps, EmailSettingsState> {
    static displayName = 'EmailSettings';

    constructor(props: IEmailSettingsProps) {
        super(props);
        this.state = {
            allTicketSetting: 9,
            newTicketSetting: 9,
            resolvedTicketSetting: 9,
            updatedTicketSetting: 9,
            loading: true
        }
        getEmailSettings().then((result) => {
            this.setState({
                allTicketSetting: result.settings['Ticket.AllTickets'],
                newTicketSetting: result.settings['Ticket.NewTickets'],
                resolvedTicketSetting: result.settings['Ticket.ResolvedTickets'],
                updatedTicketSetting: result.settings['Ticket.UpdatedTickets'],
                loading: false
            });
        });
    }

    public updateState = (e: any) => {
        var stateValue = e.target.dataset.value;
        var indexValue = e.target.value;
        this.setState({ [e.target.dataset.value]: e.target.value }, () => {
            if (stateValue === "allTicketSetting") {
                this.setState({ newTicketSetting: indexValue, resolvedTicketSetting: indexValue, updatedTicketSetting: indexValue })
            }
            else {
                this.setState({ allTicketSetting: -1 })
            }
        });
    }

    public post = () => {
        this.setState({ loading: true }, () => {
            var emailSettings = {
                settings: {}
            };
            emailSettings.settings['Ticket.NewTickets'] = this.state.newTicketSetting;
            emailSettings.settings['Ticket.AllTickets'] = this.state.allTicketSetting;
            emailSettings.settings['Ticket.UpdatedTickets'] = this.state.updatedTicketSetting;
            emailSettings.settings['Ticket.ResolvedTickets'] = this.state.resolvedTicketSetting;
            updateEmailSettings(emailSettings).then((data: any) => {
                this.setState({ loading: false });
                this.props.addToastMessage(new ToastMessage("ToastBar.EmailSettings.Success.Subject", "ToastBar.EmailSettings.Success.BodyText", "success", 5000));
                AppInsightsUtils.TrackEvent("Email settings changed", {});
            }).catch((err) => {
                this.props.addToastMessage(new ToastMessage("ToastBar.EmailSettings.Alert.Subject", "ToastBar.EmailSettings.Alert.BodyText", "alert"));
            })
        })
    }

    public cancel = () => {
        MyHistory.goBack();
    }

    public render() {
        return (
            <ErrorBoundary>
                <Translation>{
                    (t) => {
                        changePageTitle('profile:EmailSettings.Title');
                        return (
                            <div className="cell auto cell-block-container cell-block-y width-100">
                                <div className="grid-container">
                                    <div className="grid-x grid-padding-x padding-bottom-3 padding-bottom-medium-down-8 padding-top-1">
                                        <GenericSaveCancel
                                            cancelButtonText={t('common:Action.Cancel')}
                                            saveButtonText={t('common:Action.SaveChanges')}
                                            cancelCallBack={this.cancel}
                                            saveCallback={this.post}
                                            cancelClassName="clear button alert"
                                            divClassName="cell text-right padding-top-2"
                                            saveClassName="button radius text-uppercase svg-vertical-align-top"
                                            disabled={this.state.loading!}
                                        />
                                        <div className="cell">
                                            <h1>{t('profile:EmailSettings.Title')}</h1>
                                            <p>{t('profile:EmailSettings.TitleBody')}</p>
                                            <div className="grid-x grid-margin-x">
                                                <div className="cell large-6 shadow radius padding-1">
                                                    <h2>{t('profile:EmailSettings.Tickets.Title')}</h2>
                                                    <div className="grid-x grid-padding-x">
                                                        <EmailSetting
                                                            labelId="alltickets"
                                                            type={t('profile:EmailSettings.Tickets.All')}
                                                            changeCallback={this.updateState}
                                                            stateKey="allTicketSetting"
                                                            selectedValue={this.state.allTicketSetting!}
                                                            loading={this.state.loading!}
                                                        />
                                                        <EmailSetting
                                                            labelId="newtickets"
                                                            type={t('profile:EmailSettings.Tickets.New')}
                                                            changeCallback={this.updateState}
                                                            stateKey="newTicketSetting"
                                                            selectedValue={this.state.newTicketSetting!}
                                                            loading={this.state.loading!}
                                                        />
                                                        <EmailSetting
                                                            labelId="updatedtickets"
                                                            type={t('profile:EmailSettings.Tickets.Updated')}
                                                            changeCallback={this.updateState}
                                                            stateKey="updatedTicketSetting"
                                                            selectedValue={this.state.updatedTicketSetting!}
                                                            loading={this.state.loading!}
                                                        />
                                                        <EmailSetting
                                                            labelId="resolvedtickets"
                                                            type={t('profile:EmailSettings.Tickets.Resolved')}
                                                            changeCallback={this.updateState}
                                                            stateKey="resolvedTicketSetting"
                                                            selectedValue={this.state.resolvedTicketSetting!}
                                                            loading={this.state.loading!}
                                                        />
                                                    </div>
                                                    <GenericSaveCancel
                                                        cancelButtonText={t('common:Action.Cancel')}
                                                        saveButtonText={t('common:Action.SaveChanges')}
                                                        cancelCallBack={this.cancel}
                                                        saveCallback={this.post}
                                                        cancelClassName="clear button alert"
                                                        divClassName="cell text-right padding-top-2"
                                                        saveClassName="button radius text-uppercase svg-vertical-align-top"
                                                        disabled={this.state.loading!}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }
                </Translation>
            </ErrorBoundary>
        );
    }
}
export default connect(
    (state: IApplicationState) => state.toasts, ToastsStore.actionCreators
)(EmailSettings);
