import * as React from 'react';
import { Translation } from 'react-i18next';
import i18n_ from '../../translationcomponent/i18n';
import { NavLink } from 'react-router-dom';
import 'core-js';
import { Redirect } from 'react-router';
import { USER_IMAGE_CDN, SUPPORT_EMAIL, PORTAL_NAME } from '../../../Utils/config'
import * as defaultAvatar from '../../../assets/img/profile-large.png'
import { Nav, Button } from 'react-bootstrap';
import KPMGImageFallback from '../../CommonComponents/KPMGImageFallback';
import { updateLanguage } from '../../../apiServices/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import StorageService from '../../../apiServices/StorageService';

export interface INavMobileSecondaryProps {
    toggleSideNav: () => void;
    FirstName: string;
    LastName: string;
    firstName: string;
    lastName: string;
    logOut: () => void;
}

interface INavMobileSecondaryState {
    redirect?: boolean;
    userPicture: string;
    langChanged?: boolean;
}

export default class NavMobileSecondaryItems extends React.Component<INavMobileSecondaryProps, INavMobileSecondaryState> {
    static displayName = 'NavMobileSecondaryItems';

    private lang: string;
    constructor(props: any) {
        super(props)
        this.state = {
            redirect: false,
            userPicture: defaultAvatar
        };
        this.lang = localStorage!.getItem('i18nextLng')!;
    }

    public componentDidMount() {
        this.checkUserPicture();
    }

    public checkUserPicture = () => {
        const user = StorageService.GetParsedStorageData();
        if (user.userPicture !== null && user.userPicture !== "" && user.userPicture !== undefined) {
            this.setState({ userPicture: USER_IMAGE_CDN + user.userPicture + "_s.jpg" });
        }
    }

    public changeLang(e: any) {
        if (e.target.dataset.value === "sv-SE") {
            i18n_.changeLanguage('sv-SE');
        } else {
            i18n_.changeLanguage('en-US');
        }
        this.lang = e.target.dataset.value;
        this.setState({ langChanged: !this.state.langChanged });
        updateLanguage(this.lang);
    }

    private signOut = () => {
        this.setState({ redirect: true }, () => this.props.toggleSideNav());
        this.props.logOut();
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            {this.state.redirect &&
                                <Redirect
                                    to='/logout'
                                />
                            }
                            <Nav.Link
                                as={NavLink}
                                to="/help"
                                activeClassName="active"
                                onClick={this.props.toggleSideNav}
                            >
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    size="lg"
                                    className="mr-1"
                                    fixedWidth
                                />
                                <span>{t('common:NavBar.Help')}</span>
                            </Nav.Link>
                            <Button
                                href={`mailto:${SUPPORT_EMAIL}?subject=${PORTAL_NAME}`}
                            >
                                <FontAwesomeIcon icon={faEnvelope} size="lg" className="mr-1" />
                                <span> {t('common:NavBar.Support')}</span>
                            </Button>
                            <div className="dropdown-divider my-3" />
                            <span className="nav-link">
                                <picture>
                                    <KPMGImageFallback
                                        sourceSet={this.state.userPicture + "_s.jpg, " + this.state.userPicture + "_l.jpg 2x"}
                                        src={this.state.userPicture + "_s.jpg"}
                                        fallbackImage={defaultAvatar}
                                        initialImage={defaultAvatar}
                                        alt={`
											${this.props.FirstName !== "" ? this.props.FirstName + ' ' + this.props.LastName : this.props.FirstName === "" ? this.props.firstName + ' ' + this.props.lastName : ''
                                            }
                                            `}
                                        className="profile-image rounded-circle bordered mr-1"
                                        style={{ width: '1.3333333333em', height: '1.3333333333em', verticalAlign: '-0.225em' }}
                                    />
                                </picture>
                                {this.props.FirstName !== "" &&
                                    this.props.FirstName.charAt(0).toUpperCase() + " " + this.props.LastName.charAt(0).toUpperCase()
                                }
                                {this.props.FirstName === "" &&
                                    this.props.firstName!.charAt(0).toUpperCase() + " " + this.props.lastName!.charAt(0).toUpperCase()
                                }
                            </span>
                            <Nav className="flex-column px-3 nav-mobile">
                                <Nav.Link
                                    as={NavLink}
                                    to="/user/myprofile/"
                                    onClick={this.props.toggleSideNav}
                                >
                                    {t('common:NavBar.User.MyProfile')}
                                </Nav.Link>
                                <Nav.Link
                                    as={NavLink}
                                    to="/user/emailsettings"
                                    onClick={this.props.toggleSideNav}
                                >
                                    {t('common:NavBar.User.EmailSettings')}
                                </Nav.Link>
                                <Nav.Link
                                    onClick={this.signOut}
                                >
                                    {t('common:NavBar.User.Logout')}
                                </Nav.Link>
                            </Nav>
                            <div className="dropdown-divider my-3" />
                            <span className="nav-link">
                                Language
                            </span>
                            <Nav className="flex-column px-3 nav-mobile">
                                <Nav.Link
                                    className={this.lang! === 'en-US' ? 'active' : ''}
                                    onClick={(e: any) => {
                                        this.changeLang(e);
                                        this.props.toggleSideNav();
                                    }}
                                    data-value="en-US"
                                >
                                    {t('profile:User.EnglishLanguage')}
                                </Nav.Link>
                                <Nav.Link
                                    className={this.lang! === 'sv-SE' ? 'active' : ''}
                                    onClick={(e: any) => {
                                        this.changeLang(e);
                                        this.props.toggleSideNav();
                                    }}
                                    data-value="sv-SE"
                                >
                                    {t('profile:User.SwedishLanguage')}
                                </Nav.Link>
                            </Nav>
                        </>
                    )
                }
            }
            </Translation>
        );
    }
}
