import HttpHelper from './HttpHelper';
import { ITickets, ITicket, ICosmosGenericPagedResult } from '../Interface/Interfaces';
import { TicketStatus, TypeOfTicket } from '../Interface/Enum';
import f from 'odata-filter-builder';
import { API_ENDPOINT } from '../Utils/config';
import DateTimeHelper from '../Utils/DateTimeHelper';
import { stringIncludesLetters } from "src/Utils/CommonUtils";

export default class TicketsService {
    public static GetAllNonResolvedTickets(nextPageToken: string | null, searchText: string = "", status?: TicketStatus, selectedCustomerId: string = "", takeCount: number = 0, ticketType: TypeOfTicket | undefined = undefined): Promise<ITickets> {
        const queryString = this.CreateQueryStringFromSearchTextAndStatusAndPageToken(searchText, status!, nextPageToken, selectedCustomerId, takeCount, ticketType);

        return HttpHelper.Get(API_ENDPOINT + '/api/v2/tickets' + queryString);
    }
    public static GetAllResolvedTickets(nextPageToken: string | null, searchText: string = "", status: TicketStatus | null = null, selectedCustomerId: string = "", takeCount: number = 0): Promise<ITickets> {
        const queryString = this.CreateQueryStringFromSearchTextAndStatusAndPageToken(searchText, TicketStatus.Resolved, nextPageToken, selectedCustomerId, takeCount, undefined);

        return HttpHelper.Get(API_ENDPOINT + '/api/v2/tickets' + queryString);
    }
    public static GetTicket(ticketId: string): Promise<ITicket> {
        return HttpHelper.Get(API_ENDPOINT + '/api/v2/tickets/' + ticketId);
    }
    public static GetMyTicket(ticketId: string): Promise<any> {
        let queryString = "";
        const params: string[] = ["$filter=id eq '" + ticketId + "'"];
        queryString = params.join('&');

        if (queryString.length > 0) {
            queryString = '?' + queryString;
        }

        return HttpHelper.Get(API_ENDPOINT + '/api/v2/tickets' + queryString);
    }

    public static GetUserBySearch(searchText: string): Promise<any> {
        return HttpHelper.Get(API_ENDPOINT + '/api/users/searchdropdownusers?searchText=' + searchText);
    }

    public static GetMyTickets(customerId?: string): Promise<any> {
        let queryString = "";
        const params: string[] = ["$orderby=edited desc", "$top=3"];
        if (customerId) {
            params.push("$filter=customer/id eq '" + customerId + "'");
        }

        queryString = params.join('&');

        if (queryString.length > 0) {
            queryString = '?' + queryString;
        }

        return HttpHelper.Get(API_ENDPOINT + '/api/v2/tickets' + queryString);
    }

    public static GetACustomerTickets(nextPageToken: string | null, selectedCustomerId: string = "", searchText: string = "", status: TicketStatus = TicketStatus.Created, takeCount: number = 0, confidential: boolean = false, ticketType: TypeOfTicket | undefined = undefined): Promise<ICosmosGenericPagedResult<ITicket>> {
        const queryString = this.CreateQueryStringFromSearchTextAndStatusAndPageToken(searchText, status!, nextPageToken, selectedCustomerId, takeCount, ticketType, confidential);

        return HttpHelper.Get(API_ENDPOINT + '/api/v2/customers/' + selectedCustomerId + '/tickets' + queryString);
    }

    public static GetAllCustomersTickets(nextPageToken: string | null, searchText: string = "", status: TicketStatus = TicketStatus.Created, selectedCustomerId: string = "", takeCount: number = 0, confidential: boolean = false, ticketType: TypeOfTicket | undefined = undefined): Promise<ICosmosGenericPagedResult<ITicket>> {
        const queryString = this.CreateQueryStringFromSearchTextAndStatusAndPageToken(searchText, status!, nextPageToken, selectedCustomerId, takeCount, ticketType, confidential);

        return HttpHelper.Get(API_ENDPOINT + '/api/v2/tickets' + queryString);
    }

    public static GetTeamMembers(customerNumber: string): Promise<any> {
        return HttpHelper.Get(API_ENDPOINT + '/api/v2/customers/' + customerNumber + '/team/teammembers');
    }

    private static CompileFilter(searchText: string = "", selectedCustomerId: string, status: TicketStatus, confidential: boolean = false, ticketType: TypeOfTicket | undefined): string {
        const searchTextUpperCase = searchText.toUpperCase();

        let filter = f('or');
        const date = new Date(searchText);
        if (searchText !== undefined && searchText !== "") {
            filter = filter
                .contains('normalizedTitle', searchTextUpperCase)
                .contains('normalizedDescription', searchTextUpperCase)
                .contains('normalizedAuthorText', searchTextUpperCase)
                .contains('normalizedEditedByText', searchTextUpperCase)
                .contains('customer/normalizedName', searchTextUpperCase)
                .contains('refNumber', searchTextUpperCase);
            if (DateTimeHelper.isValid(date) && !stringIncludesLetters(searchText)) {
                filter = filter
                    .or((x: any) => x.gt('created', `${DateTimeHelper.FormatYearMonthDay(date)}T00:00:00Z`, false))
                    .and((x: any) => x.le('created', `${DateTimeHelper.FormatYearMonthDay(DateTimeHelper.AddDays(date, 1))}T00:00:00Z`, false));
            }
        }
        if (selectedCustomerId !== "") {
            filter = filter.and((x: any) => x.eq('customer/id', selectedCustomerId));
        }

        if (status !== undefined && status !== TicketStatus.Created) {
            filter = filter
                .and((x: any) => x.eq('TicketStatus', "KPMG.Common.Enumerations.TicketStatusEnum'" + TicketStatus[status] + "'", false));
        } else {
            filter = filter
                .and((x: any) => x.ne('TicketStatus', "KPMG.Common.Enumerations.TicketStatusEnum'" + TicketStatus[TicketStatus.Resolved] + "'", false));
        }

        if (ticketType) {
            filter = filter.and((x: any) => x.eq('TypeOfTicket', "KPMG.Common.Constants.TypeOfTicketEnum'" + TypeOfTicket[ticketType] + "'", false))
        }

        if (confidential) {
            filter = filter.and((x: any) => x.eq('Confidential', true, false));
        }

        return filter.toString();
    }

    private static CreateQueryStringFromSearchTextAndStatusAndPageToken(searchText: string, status: TicketStatus, continuationToken: string | null, selectedCustomerId: string, takeCount: number, ticketType: TypeOfTicket | undefined, confidential: boolean = false) {
        const filter = this.CompileFilter(searchText, selectedCustomerId, status, confidential, ticketType);

        let queryString = "";
        let params: string[] = [];

        params.push("$orderby=edited desc");

        if (filter !== "") {
            params.push('$filter=' + encodeURIComponent(filter));
        }
        if (takeCount !== 0) {
            params.push("$top=" + takeCount);
        }

        if (continuationToken !== null) {
            params.push('continuationToken=' + encodeURIComponent(continuationToken));
        }

        queryString = params.join('&');

        if (queryString.length > 0) {
            queryString = '?' + queryString;
        }

        return queryString;
    }
}
