import React from 'react';
import Select, { components } from 'react-select';
import {
    ControlComponent,
    ValueContainerComponent,
    IndicatorsContainerComponent,
    DropdownIndicatorComponent,
    ClearIndicatorComponent,
    resetStyles,
    setFilterOption
} from '../../../Utils/ReactSelectHelper';

interface GenericReactSelectProps {
    inputId: string | number,
    options: any,
    onChange?: any;
    noOptionsMessage?: any;
    value?: any;
    isMulti?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    isOptionDisabled?: any;
    placeholder?: any;
    isDisabled?: boolean;
    optionComponent?: any;
    filterOption?: any;
}

function GenericReactSelect(props: any) {

    return (
        <Select
            inputId={props.inputId}
            options={props.options}
            value={props.value}
            onChange={props.onChange}
            noOptionsMessage={() => props.noOptionsMessage}
            isMulti={props.isMulti}
            components={{
                Control: ControlComponent,
                ValueContainer: ValueContainerComponent,
                IndicatorsContainer: IndicatorsContainerComponent,
                DropdownIndicator: DropdownIndicatorComponent,
                ClearIndicator: ClearIndicatorComponent,
                Option: props.optionComponent ? props.optionComponent : components.Option
            }}
            styles={resetStyles}
            isClearable={props.isClearable}
            isSearchable={props.isSearchable}
            isOptionDisabled={props.isOptionDisabled}
            placeholder={props.placeholder}
            isDisabled={props.isDisabled}
            filterOption={setFilterOption(props.filterOption)}
            {...props}
        />
    )
};

GenericReactSelect.displayName = 'GenericReactSelect';

export default GenericReactSelect;
