import { ICustomer, IMe} from "../Interface/Interfaces";

export class Me implements IMe {
    public phoneNumber: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public id: string;
    public defaultCustomer?: ICustomer | null;
    public myCustomers: ICustomer[];
    public pictureUrl: string;
    public hasPersonalIdentityNumber : boolean;

    constructor() {
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.id = "";
        this.defaultCustomer = null;
        this.myCustomers = [];
        this.pictureUrl = "";
        this.phoneNumber = "";
        this.hasPersonalIdentityNumber  = false;
    }
}
