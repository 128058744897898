import * as React from "react";
import { Translation } from 'react-i18next';
import { IFileRequest } from "../../../../Interface/Interfaces";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FileRequestStatusEnum } from "../../../../Interface/Enum";
import i18n from '../../../translationcomponent/i18n';
import { Col, Row } from 'react-bootstrap';

interface FileRequestTableRowToolTipProps {
    request: IFileRequest;
}


export default class FileRequestTableRowToolTip extends React.Component<FileRequestTableRowToolTipProps> {
    static displayName = 'FileRequestTableRowToolTip';

    get toolTipMessage() {
        if (this.props.request.status === FileRequestStatusEnum.Rejected) {
            return (
                <Row className="text-left">
                    <Col>
                        <Row className="mb-2">
                            <Col>
                                <Row>
                                    <Col>
                                        <strong>{i18n.t('tickets:Filerequest.RejectedFile')}</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.props.request.rejectedFile}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <strong>{i18n.t('tickets:Filerequest.Comment')}</strong>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.props.request.comment}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        }

        if (this.props.request.status === FileRequestStatusEnum.Delivered) {
            return i18n.t('tickets:Filerequest.Tooltip.Delivered');
        }

        if (this.props.request.isRecentlyAdded) {
            return i18n.t('tickets:Filerequest.Tooltip.NewRequest');
        }

        return "";
    }

    public render() {
        return (
            <Translation>
                {t => {
                    return (
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Tooltip
                                    id={`request-${this.props.request.id}-description-popover`}
                                    hidden={!this.toolTipMessage}
                                >
                                    {this.toolTipMessage}
                                </Tooltip>
                            }
                        >
                            {this.props.children as any}
                        </OverlayTrigger>
                    );
                }
                }
            </Translation>
        );
    }
}
