import * as React from 'react';
import { Translation } from 'react-i18next';

export interface IEmailSettingsDropDownProps {
    labelId: string;
    changeCallback: (e: any) => void;
    stateKey: string;
    selectedValue: number;
    loading: boolean;
}

export default class EmailSettingDropDown extends React.Component<IEmailSettingsDropDownProps, {}> {
    static displayName = 'EmailSettingDropDown';

    private dropDown: HTMLSelectElement;

    public componentDidUpdate(prevProps: IEmailSettingsDropDownProps) {
        if (prevProps !== this.props) {
            if (this.props.selectedValue !== -1 && this.props.selectedValue !== 9) {
                this.dropDown.selectedIndex = this.props.selectedValue / 10 + 1
            }
            else if (this.props.selectedValue.toString() === "9") {
                this.dropDown.selectedIndex = 0
            }
            else {
                this.dropDown.selectedIndex = 6;
            }
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <select disabled={this.props.loading ? true : false} placeholder={t('profile:EmailSettings.Common.Change')} id={this.props.labelId} ref={select => this.dropDown! = select!} name={this.props.labelId} data-value={this.props.stateKey} className="margin-0 email-frequency-select" onChange={this.props.changeCallback}>
                            <option data-value={this.props.stateKey} hidden value="">{t('profile:EmailSettings.Common.Change')}</option>
                            <option data-value={this.props.stateKey} value="0">{t('profile:EmailSettings.Common.Instant')}</option>
                            <option data-value={this.props.stateKey} value="10">{t('profile:EmailSettings.Common.Daily')}</option>
                            <option data-value={this.props.stateKey} value="20">{t('profile:EmailSettings.Common.TwoAWeek')}</option>
                            <option data-value={this.props.stateKey} value="30">{t('profile:EmailSettings.Common.Weekly')}</option>
                            <option data-value={this.props.stateKey} value="40">{t('profile:EmailSettings.Common.Never')}</option>
                            {/*This should only be visible to all settings*/}
                            <option data-value={this.props.stateKey} value="40" hidden>{t('profile:EmailSettings.Common.Custom')}</option>
                        </select>
                    );
                }
            }
            </Translation>
        );
    }
}

