import * as React from 'react';
import { Translation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/pro-solid-svg-icons';

export default class Events extends React.Component<{}, {}> {
    static displayName = 'Events';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Col>
                            <Row className="align-items-center">
                                <Col>
                                    <h2 className="mb-0"><FontAwesomeIcon icon={faCalendarDay} size="lg" className="mr-1" fixedWidth />{t('common:Events.UpcomingTitle')}</h2>
                                </Col>
                                <Col xs="auto" className="font-italic">
                                    Coming soon
                                </Col>
                            </Row>
                        </Col>
                    )
                }
            }
            </Translation>
        );

    }
}
