import CustomerService from "../apiServices/CustomerService";
import { AuthenticatedKnownAction } from "../store/AuthenticatedRoute";
import AppInsightsUtils from "./AppInsightsUtils";
import { ICustomer } from "../Interface/Interfaces";
import { IApplicationState } from "../store";

export function AddOrUpdateCustomer(dispatch: (action: AuthenticatedKnownAction) => void, getState: () => IApplicationState, customerId: string) {
    customerId = customerId.split("-external")[0];
    const me = {...getState().authenticatedRoute.me};
    CustomerService.GetACustomer(customerId).then((result) => {
        const customer: ICustomer = result;
        customer.isMember = true;
        var customerIndex = me.myCustomers.findIndex(c => c.id === customer.id);
        if (customerIndex !== -1) {
            me.myCustomers[customerIndex] = customer;
        }
        else {
            me.myCustomers.push(customer);
        }

        if (customer.id === me.defaultCustomer?.id) {
            me.defaultCustomer = customer;
        }

        dispatch({
            type: 'AUTHENTICATED_ROUTE_UPDATE_ME',
            me: me
        });
    }).catch((err: Response) => {
        AppInsightsUtils.TrackEvent("Could not update me object.",err);
    });
}


export function RemoveCustomer(dispatch: (action: AuthenticatedKnownAction) => void, getState: () => IApplicationState, customerId: string) {
    const me = {...getState().authenticatedRoute.me};
    var customerIndex = me.myCustomers.findIndex(c => c.id === customerId);
    if (customerIndex !== -1) {
        me.myCustomers.splice(customerIndex,1);
    }

    dispatch({
        type: 'AUTHENTICATED_ROUTE_UPDATE_ME',
        me: me
    });
}