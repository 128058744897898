import * as React from "react";
import { Translation } from 'react-i18next';
import { IFileRequest, IFileAttachmentBase } from "../../../../Interface/Interfaces";
import { OverlayTrigger, Tooltip, Col, Row } from "react-bootstrap";
import { Table } from '@kpmgportals/genericcomponents';
import { FileRequestStatusEnum } from "../../../../Interface/Enum";
import AttachedFile from "../AttachedFile";
import FileRequestRowIcon from "./FileRequestRowIcon";
import i18n from '../../../translationcomponent/i18n';
import FileRequestTableRowToolTip from "./FileRequestTableRowToolTip";
import { UserVertical } from '@kpmgportals/genericcomponents';
import { USER_IMAGE_CDN } from '../../../../Utils/config';
import ContactInformationService from '../../../../apiServices/ContactInformationService';


interface FileRequestTableRowProps {
    request: IFileRequest;
    openFileRequestRowModal: (fileRequestRowId: string) => void;
    downloadTemplateFile: (file: IFileAttachmentBase) => void;
}

export default class FileRequestTableRow extends React.Component<FileRequestTableRowProps> {
    static displayName = 'FileRequestTableRow';

    get rowVariant() {
        switch (this.props.request.status) {
            case FileRequestStatusEnum.Matched:
                return "success";
            case FileRequestStatusEnum.Delivered:
                return "secondary";
            case FileRequestStatusEnum.Rejected:
                return "danger";
            default:
                if (this.props.request.isRecentlyAdded) {
                    return "primary";
                }
                return "default";
        }
    }

    get rowStatus() {
        if (this.props.request.status === FileRequestStatusEnum.Rejected) {
            return i18n.t('tickets:Filerequest.Status.Rejected')
        }
        if (this.props.request.status === FileRequestStatusEnum.Delivered) {
            return i18n.t('tickets:Filerequest.Status.Delivered')
        }
        if (this.props.request.status === FileRequestStatusEnum.Matched) {
            return i18n.t('tickets:Filerequest.Status.Matched')
        }

        return i18n.t('tickets:Filerequest.Status.NotMatched')
    }


    public fileStatusTooltip() {
        return (
            <Translation>
                {t => {
                    return (
                        <Row className="text-left">
                            <Col>
                                <Row className="mb-2">
                                    <Col>
                                        <Row>
                                            <Col>
                                                <strong>{t('tickets:Filerequest.RejectedFile')}</strong>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.props.request.rejectedFile}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <strong>{t('tickets:Filerequest.Comment')}</strong>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.props.request.comment}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                }}
            </Translation>
        )
    }

    public render() {
        return (
            <Translation>
                {t => {
                    return (
                        <Table.Row
                            key={`fiReqRow_${this.props.request.id}`}
                            onClick={() => { this.props.openFileRequestRowModal(this.props.request.id) }}
                            variant={this.rowVariant}
                        >
                            <Table.Col
                                xs="auto"
                                className="flex-grow-1"
                                truncate
                                separator
                            >
                                <FileRequestRowIcon request={this.props.request} />
                                <OverlayTrigger
                                    placement="auto-start"
                                    overlay={
                                        <Tooltip
                                            id={`request-${this.props.request.id}-description-popover`}
                                            hidden={(!this.props.request.description && !this.props.request.comment)}
                                        >
                                            {this.props.request.status === FileRequestStatusEnum.Rejected ? (
                                                this.fileStatusTooltip()
                                            ) : this.props.request.description}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-truncate">
                                        {this.props.request.request}
                                    </span>
                                </OverlayTrigger>
                            </Table.Col>
                            <Table.Col
                                xs="auto"
                                className="flex-grow-1"
                                truncate
                                separator
                            >
                                <OverlayTrigger
                                    placement="auto-start"
                                    overlay={
                                        <Tooltip
                                            id={`request-${this.props.request.id}-description-popover`}
                                            hidden={(!this.props.request.description && !this.props.request.comment)}
                                        >
                                            {this.props.request.status === FileRequestStatusEnum.Rejected ? (
                                                this.fileStatusTooltip()
                                            ) : this.props.request.description}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-truncate">
                                        {this.props.request.title}
                                    </span>
                                </OverlayTrigger>
                            </Table.Col>
                            <Table.Col
                                xs={3}
                                truncate
                                separator
                            >
                                <span className="text-truncate">
                                    {this.props.request.templateFile &&
                                        <AttachedFile
                                            attachFile={{ ...this.props.request.templateFile!, downloadedBy: [] }}
                                            groupId={this.props.request.id}
                                            downloadFiles={
                                                (originalFileName: string) =>
                                                    this.props.downloadTemplateFile(this.props.request.templateFile!)
                                            }
                                        />
                                    }
                                </span>
                            </Table.Col>
                            <Table.Col
                                xs={3}
                                truncate
                                separator
                            >
                                <FileRequestTableRowToolTip
                                    request={this.props.request}
                                >
                                    <span className="text-truncate">
                                        {this.props.request.uploadedFile?.originalFileName}
                                    </span>
                                </FileRequestTableRowToolTip>
                            </Table.Col>
                            <Table.Col
                                xs={3}
                                truncate
                            >
                                {this.props.request.matchedUser &&
                                    <UserVertical
                                        user={{
                                            email: this.props.request.matchedUser.email || "",
                                            firstName: this.props.request.matchedUser.firstName || "",
                                            lastName: this.props.request.matchedUser.lastName || "",
                                            name: "",
                                            id: this.props.request.matchedUser.userId || "",
                                            phone: "",
                                            pictureUrl: this.props.request.matchedUser.userPicture || "",
                                            title: "",
                                            userId: this.props.request.matchedUser.userId
                                        }}
                                        dateInfo={this.props.request.dateMatched}
                                        prefix={this.rowStatus}
                                        size="small"
                                        USER_IMAGE_CDN={USER_IMAGE_CDN}
                                        ContactInformationService={ContactInformationService}
                                    />
                                }
                                {!this.props.request.matchedUser &&
                                    <span className="text-truncate">
                                        {this.rowStatus}
                                    </span>
                                }
                            </Table.Col>
                        </Table.Row>
                    );
                }
                }
            </Translation>
        );
    }
}
