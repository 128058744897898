import { IToastMessage } from "../Interface/Interfaces";
import { Guid } from 'guid-typescript';

export class ToastMessage implements IToastMessage {
    public id: string;
    public subject: string;
    public bodyText: string;
    public status: string;
    public hideAfter?: number;
    public bodyPayLoad?: any;

    constructor(subject: string, bodyText: string, status: string, hideAfter?: number, bodyPayLoad?: any) {
        this.id = Guid.create().toString();
        this.subject = subject;
        this.bodyText = bodyText;
        this.status = status;
        this.hideAfter = hideAfter;
        this.bodyPayLoad = bodyPayLoad;
    }
}
