import * as React from 'react';
import { Translation } from 'react-i18next';
import NotificationsList from './NotificationsList';
import { Row, Col } from 'react-bootstrap';

interface INotificationsPageState {
    open: boolean;
}

export default class NotificationsPage extends React.Component<{}, INotificationsPageState> {
    static displayName = 'NotificationsPage';

    constructor(props: any) {
        super(props);
        this.state = { open: false };
    }

    public componentDidMount() {
        this.setState({ open: true });
    }

    public componentWillUnmount() {
        this.setState({ open: false });
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="mt-3">
                            <Col>
                                <Row>
                                    <Col>
                                        <h1 className="page-title">
                                            {t('common:NavBar.Alerts')}
                                        </h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <NotificationsList open={this.state.open} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                }
            }
            </Translation>
        );
    }
}


