import { IAppThunkAction } from '../';
import { TicketKnownAction } from '../Ticket';
import TicketService from '../../apiServices/TicketService';
import { ITicket, ITicketAttachments, IComment } from '../../Interface/Interfaces';
import AppInsightsUtils from '../../Utils/AppInsightsUtils';
import { OriginEnum, TicketStatus } from '../../Interface/Enum';
import { ToastFactory } from '../../Utils/ToastFactory';
import i18n from '../../components/translationcomponent/i18n';
import { getUserId } from '../../apiServices/UserService';
import { history } from '../configureStore';

export const TicketActions = {
    getATicket: (refNumber: string): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        TicketService.GetACustomerTicket(refNumber).then((ticket: ITicket) => {
            dispatch({
                type: 'GOT_A_TICKET',
                result: ticket
            });
            AppInsightsUtils.TrackEvent("Ticket was visited!", { AuthorOrganization: ticket.authorOrganizationText, AuthorOffice: ticket.authorOfficeText, RecipientOrganization: ticket.recipientOrganizationText, RecipientOffice: ticket.recipientOfficeText });
        }).catch((err: any) => {
            dispatch({
                type: "DENIED_A_TICKET"
            });
        });
    },
    createTicket: (): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        TicketService.CreateTicket().then((ticket: ITicket) => {
            dispatch({
                type: 'GOT_A_TICKET',
                result: ticket
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        }).catch((e: any) => {
            dispatch({
                type: 'LOADING',
                loading: false
            });
        });
    },
    removeNewCommentFile: (file: ITicketAttachments, customerId: string, ticketId: string): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        TicketService.RemoveNewCommentFile(file.id, customerId, ticketId);
    },
    commentAdded: (commentText: string, attachedFiles: ITicketAttachments[], fileCount: number): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        const ticket = getState().ticket.activeTicket;
        dispatch({
            type: 'LOADING',
            loading: true
        });
        const comment: IComment = {
            text: commentText,
            files: attachedFiles,
            authorId: "",
            authorPicture: "",
            authorText: "",
            commentId: "",
            created: "",
            origin: 0
        };
        TicketService.AddComment(ticket.id, comment, ticket.customer!.id).then((updatedTicket) => {
            const newComment = updatedTicket.comments[0];
            dispatch({
                type: 'GOT_A_TICKET',
                result: updatedTicket
            });
            dispatch({
                type: 'COMMENT_SAVED',
                isSaved: true
            });
            ToastFactory.createSuccessToast(i18n.t('ToastBar.AddComment.Success.Subject'), fileCount > 0 ? i18n.t('ToastBar.AddComment.Success.BodyText') : "", 5000);
            if (fileCount > 0) {
                AppInsightsUtils.TrackEvent("Comment was created with files", { Origin: OriginEnum[newComment.origin], Creator: newComment.authorText });
            } else {
                AppInsightsUtils.TrackEvent("Comment was created without files", { Origin: OriginEnum[newComment.origin], Creator: newComment.authorText });
                dispatch({
                    type: 'LOADING',
                    loading: false
                });
                dispatch({
                    type: 'COMMENT_SAVED',
                    isSaved: false
                });
            }
        }).catch((err: any) => {
            AppInsightsUtils.TrackEvent("Comment save failed", { Origin: OriginEnum.Customer, Ticket: ticket.refNumber, CommentAuthor: getUserId() });
        });
    },
    updateTicket: (ticketId: string, ticket: ITicket, customerId: string, fileCount: number): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        TicketService.UpdateTicket(ticketId, ticket, customerId).then((updatedTicket) => {
            dispatch({
                type: 'GOT_A_TICKET',
                result: updatedTicket
            });
            AppInsightsUtils.TrackEvent("Ticket was created", { AuthorOrg: updatedTicket.authorOrganizationText, AuthorOffice: updatedTicket.authorOfficeText, RecipientOrg: updatedTicket.recipientOrganizationText, RecipientOffice: updatedTicket.recipientOfficeText });
            if (fileCount === 0) {
                if (ticket.ticketStatus === TicketStatus.Draft) {
                    ToastFactory.createSuccessToast(i18n.t('tickets:NewTicket.Toast.Draft.Success.Subject'), "", 5000);
                } else {
                    ToastFactory.createSuccessToast(i18n.t('tickets:NewTicket.Toast.Success.Subject'), "", 5000);
                    history.push('/tickets/' + updatedTicket.refNumber);
                }
                dispatch({
                    type: 'LOADING',
                    loading: false
                });
            }
        }).catch((e: any) => {
            ToastFactory.createAlertToast(i18n.t('ToastBar.NewTicket.Failure.Subject'), e, 5000);
            dispatch({
                type: 'LOADING',
                loading: false
            });
        });
    },
    removeNewTicketFile: (fileId: string, customerId: string): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        TicketService.RemoveNewTicketFile(fileId, customerId);
    },
    filesWhereSaved: (success: boolean): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        const ticket = getState().ticket.activeTicket;
        dispatch({
            type: 'LOADING',
            loading: false
        });
        if (success) {
            ToastFactory.createSuccessToast(i18n.t('ToastBar.NewTicket.Success.Subject'), "", 5000);
            TicketService.GetACustomerTicket(ticket.refNumber!).then((updatedTicket: ITicket) => {
                dispatch({
                    type: 'GOT_A_TICKET',
                    result: updatedTicket
                });
                dispatch({
                    type: 'COMMENT_SAVED',
                    isSaved: false
                });
                history.push('/tickets/' + ticket.refNumber);
            }).catch(() => {
                dispatch({
                    type: 'DENIED_A_TICKET'
                });
            });
        } else {
            ToastFactory.createAlertToast(i18n.t('ToastBar.NewTicketFiles.Failure.BodyText'), "", 5000);
            AppInsightsUtils.TrackEvent("Comment file save failed", { Origin: OriginEnum.Customer, Ticket: ticket.refNumber, CommentAuthor: getUserId() });
        }
    },
    closeTicket: (): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        TicketService.closeTicket(getState().ticket.activeTicket).then((updatedTicket) => {
            dispatch({
                type: 'GOT_A_TICKET',
                result: updatedTicket
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        });
    },
    reOpenTicket: (): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        TicketService.ReOpenTicket(getState().ticket.activeTicket).then((updatedTicket) => {
            dispatch({
                type: 'GOT_A_TICKET',
                result: updatedTicket
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        }).catch((e) => {
            ToastFactory.createAlertToast(i18n.t(e), "", 5000);
        });
    },
    clearTicket: (): IAppThunkAction<TicketKnownAction> => (dispatch) => {
        dispatch({
            type: 'CLEAR_A_TICKET'
        });
    },
    setIsNewlyCreatedTicket: (isNewlyCreated: boolean): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SET_IS_NEWLY_CREATED',
            isNewlyCreated
        });
    },
    removeTicketDraft: (customerId: string, ticketId: string): IAppThunkAction<TicketKnownAction> => (dispatch, getState) => {
        TicketService.RemoveTicketDraft(customerId, ticketId).then(() => {
            dispatch({
                type: 'CLEAR_A_TICKET'
            });
            history.push('/tickets');
        });
    }
};
