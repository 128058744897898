import { ICustomerContact } from "../Interface/Interfaces";

export class CustomerContact implements ICustomerContact {
    public id: string = "";
    public name: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public title: string = "";
    public email: string = "";
    public phone: string = "";
    public userId?: string | undefined;
    public pictureUrl: string = "";
}
