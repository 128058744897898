export default class StorageService {
    private static sessionStorageName = 'KpmgData';

    public static GetStorageData() {
        return JSON.stringify(sessionStorage.getItem(this.sessionStorageName));
    }

    public static GetParsedStorageData() {
        return JSON.parse(sessionStorage.getItem(this.sessionStorageName)!);
    }

    public static SaveStorageData(data: any) {
        sessionStorage.setItem(this.sessionStorageName, JSON.stringify(data));
    }

    public static GetAccessToken() : string {
        const data = StorageService._GetParsedStorageData();
        return data?.accessToken.token ?? "";
    }

    public static GetRefreshToken() : string {
        const data = StorageService._GetParsedStorageData();
        return data?.accessToken.refreshToken ?? "";
    }

    public static GetRefreshSessionId() : string {
        const data = StorageService._GetParsedStorageData();
        return data?.accessToken.sessionId ?? "";
    }

    public static UpdateToken(token: any) {
        const sessionData = JSON.parse(sessionStorage.getItem(this.sessionStorageName)!);
        sessionData.accessToken = token;
        sessionStorage.setItem(this.sessionStorageName, JSON.stringify(sessionData));
    }

    public static ClearAll() {
        localStorage.removeItem("KPMGRefreshToken");
        sessionStorage.removeItem("KPMGToken");
        sessionStorage.removeItem("KpmgData");
        sessionStorage.removeItem('KPMGChats');
    }

    private static _GetParsedStorageData() : null | any {
        if (sessionStorage.getItem(this.sessionStorageName)) {
            return JSON.parse(sessionStorage.getItem(this.sessionStorageName)!);
        }

        return null;
    }
}
