import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Translation } from 'react-i18next';
import 'core-js';
import NotificationNavMobileButton from './smallComponents/NotificationNavMobileButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { Navbar, Nav, Col, Row, Button, Container } from 'react-bootstrap';
import NavMenuUpper from './smallComponents/NavMenuUpper';
import NavMainItems from './smallComponents/NavMainItems';
import SmallLogo from '../CommonComponents/Logos/SmallLogo';

interface INavMenuProps {
    toggleSideNav: () => void;
    getNotifications: () => {};
    ticketsNotifications: number;
    clearTicketNotifications: () => {};
}

class NavMenu extends React.Component<INavMenuProps, {}> {
    static displayName = 'NavMenu';

    constructor(props: any) {
        super(props);
        this.props.getNotifications();
    }

    public componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
        script.async = true;
        script.id = "onesignalScript";
        document.body.appendChild(script);
    }
    public componentDidUpdate(prevProps: INavMenuProps) {
        if (prevProps.ticketsNotifications !== this.props.ticketsNotifications) {
            if (window.location.pathname === "/tickets") {
                this.props.clearTicketNotifications();
            }
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Navbar expand="lg" variant="dark" bg="primary" sticky="top" className="nav-main navbar-top">
                            <Container fluid className="flex-wrap">
                                <Row className="w-100 nav-wrapper justify-content-between align-items-center">
                                    <Col xs="9" lg="auto" className="p-0">
                                        <Row>
                                            <Col xs="auto" className="d-lg-none d-flex align-items-center pr-0">
                                                <Button id="off-canvas-menu-trigger" className="menu-icon large" onClick={this.props.toggleSideNav}>
                                                    <FontAwesomeIcon icon={faBars} size="2x" />
                                                </Button>
                                            </Col>
                                            <Col xs="8" lg="auto" className="d-flex align-items-center">
                                                <Navbar.Brand as={NavLink} exact to="/" className="w-100 d-flex align-items-center">
                                                    <SmallLogo />
                                                </Navbar.Brand>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="auto" className="d-none d-lg-block">
                                        <NavMenuUpper />
                                    </Col>
                                    <Col xs="3" md="auto" className="d-lg-none d-flex justify-content-end">
                                        <NotificationNavMobileButton />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Navbar.Collapse>
                                            <Nav className="nav-desktop">
                                                <NavMainItems
                                                    ticketsNotifications={this.props.ticketsNotifications}
                                                    clearTicketNotifications={this.props.clearTicketNotifications}
                                                />
                                            </Nav>
                                        </Navbar.Collapse>
                                    </Col>
                                </Row>
                            </Container>
                        </Navbar>
                    )
                }
            }
            </Translation>
        );
    }
}
export default NavMenu;
