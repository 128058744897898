import { Reducer } from 'redux';
import { TicketsActions } from './actions/TicketsActions';
import { TicketsReducer } from './reducers/TicketsReducers';
import { ITicket, ICosmosGenericPagedResult } from '../Interface/Interfaces';

export interface ITicketsState {
    tickets: ICosmosGenericPagedResult<ITicket>;
    recentlyResolvedTickets: ITicket[];
    myTickets: any[];
    ticketHubOnline: boolean;
    connectionTickets: signalR.HubConnection | null;
    ticketsNotifications: number;
    currentTicket: ITicket | null;
}

interface IGetAllCustomersTickets { type: 'GOT_TICKETS'; results: ICosmosGenericPagedResult<ITicket>; }

interface IClearAllCustomersTickets { type: 'CLEAR_TICKETS'; }

export interface ITicketsConnectedTickets { type: 'TICKETS_CONNECTED'; connection: signalR.HubConnection; }

interface ITicketsDisconnectTickets { type: 'TICKETS_DISCONNECTED'; }

interface ITicketsNotificationsUpdates { type: 'TICKETS_NOTIFICATIONS_UPDATES'; }

export interface ITicketsUpdates { type: 'TICKETS_UPDATES'; newTicket: ITicket; tickets: ITicket[]; }

interface ITicketUpdateMy { type: 'TICKET_UPDATEMY'; newTicket: ITicket; }

interface ITicketsRead { type: 'TICKETS_READ'; }

interface IGetRecentlyResolvedCustomersTickets { type: 'GOT_RECENTLY_RESOLVED'; results: ITicket[]; }

export interface ITicketGetMyTicketsAction { type: 'TICKET_GETMY'; myTickets: any[]; }

interface ITicketOnCurrentTicket { type: 'TICKET_ON_CURRENT_TICKET'; currentTicket: ITicket; }

interface ITicketLeaveCurrentTicket { type: 'TICKET_LEAVE_CURRENT_TICKET'; }

export type TicketsKnownAction = IGetAllCustomersTickets | IClearAllCustomersTickets | IGetRecentlyResolvedCustomersTickets | ITicketGetMyTicketsAction
    | ITicketsDisconnectTickets | ITicketsConnectedTickets | ITicketsUpdates | ITicketsRead | ITicketOnCurrentTicket | ITicketLeaveCurrentTicket
    | ITicketUpdateMy | ITicketsNotificationsUpdates;

export const actionCreators = TicketsActions;

export const reducer: Reducer<ITicketsState> = (state: ITicketsState, action: TicketsKnownAction) => TicketsReducer(state, action);
