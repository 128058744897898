import { Reducer } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { AuthenticatedRouteActions } from './actions/AuthenticatedRouteActions';
import { AuthenticatedRouteReducer } from './reducers/AuthenticatedRouteReducers';
import { IMe } from '../Interface/Interfaces';

export interface IAuthenticatedRouteState {
    loggedIn: boolean;
    loggedOut: boolean;
    isLoading: boolean;
    refresh: boolean;
    me: IMe;
    isValidToken?: boolean;
    userPersonalIdentityNumberNotFound: boolean;
    refreshSessionId: string;
}

export interface IAuthenticatedRouteOwnProps {
    readonly component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    readonly path: string;
    readonly exact?: boolean;
}

interface IUpdateMeAuthenticatedRouteAction { type: 'AUTHENTICATED_ROUTE_UPDATE_ME'; me: IMe; }
interface ILoggedInAuthenticatedRouteAction { type: 'LOGIN_AUTHENTICATED'; me: IMe; }
interface ILoggedOutAuthenticatedRouteAction { type: 'LOGOUT_AUTHENTICATED'; }
interface IAlreadyLoggedInAuthenticatedRouteAction { type: 'ALREADYLOGGEDIN_AUTHENTICATED'; me: IMe; }
interface IProfilePicUpdatedAction { type: 'PROFILE_UPDATED'; }
interface IValidateTokenAuthenticatedRouteAction { type: "VALIDATE_TOKEN"; isValidToken: boolean; }
interface IUserNotFoundAuthenticatedRouteAction { type: "USER_NOT_FOUND"; userPersonalIdentityNumberNotFound: boolean; }

export type AuthenticatedKnownAction = ILoggedInAuthenticatedRouteAction |
ILoggedOutAuthenticatedRouteAction | IAlreadyLoggedInAuthenticatedRouteAction
| IProfilePicUpdatedAction | IValidateTokenAuthenticatedRouteAction
| IUserNotFoundAuthenticatedRouteAction | IUpdateMeAuthenticatedRouteAction;

export const actionCreators = AuthenticatedRouteActions;
export const reducer: Reducer<IAuthenticatedRouteState> = (state: IAuthenticatedRouteState, action: AuthenticatedKnownAction) => AuthenticatedRouteReducer(state, action);
