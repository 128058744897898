import * as React from 'react';
import { Translation } from 'react-i18next';
import TicketNotification from './TicketNotification';
import * as NotificationStore from '../../../store/Notification';
import * as CustomerStore from '../../../store/Customer';
import { IApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { INotification } from '../../../Interface/Interfaces';
import CustomerNotification from './CustomerNotification';
import { isJsonString } from '../../../Utils/CommonUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';

type INotificationsListProps = NotificationStore.INotificationOwnProps
    & typeof NotificationStore.actionCreators
    & NotificationStore.INotificationState
    & CustomerStore.ICustomerState;

interface INotificationsListState {
    gettingMore: boolean;
}

class NotificationsList extends React.Component<INotificationsListProps, INotificationsListState> {
    static displayName = 'NotificationsList';

    private MessageDiv: HTMLDivElement;
    constructor(props: INotificationsListProps) {
        super(props);
        this.state = { gettingMore: false };
    }

    public componentDidMount() {
        if (document.querySelector("#notification-list")) {
            this.MessageDiv = document.querySelector("#notification-list")! as HTMLDivElement;
            this.MessageDiv.addEventListener('scroll', (e: any) => this.handleScroll(e), true);
        }

        if (document.querySelector("#notification-list-mobile")) {
            this.MessageDiv = document.querySelector("#notification-list-mobile")! as HTMLDivElement;
            this.MessageDiv.addEventListener('scroll', (e: any) => this.handleScroll(e), true);
        }
    }

    public componentDidUpdate(prevProps: INotificationsListProps) {

        if (this.props.open !== prevProps.open) {
            if (this.props.open && this.props.unreadCount > 0) {
                this.props.clearNotifications();
            }
        }

        if (this.props.updateBell !== prevProps.updateBell && this.props.updateBell) {
            this.props.getNotifications(true);
        }
        if (this.props.allNotifications.length !== prevProps.allNotifications.length) {
            this.setState({ gettingMore: false });
        }
    }
    public handleScroll = (e: any) => {
        if (this.props.nextPageToken != null && this.MessageDiv.scrollHeight - 900 < this.MessageDiv.scrollTop && !this.state.gettingMore && !this.props.allNotificationsLoaded) {
            this.setState({ gettingMore: true }, () => this.props.getMore());
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Col id="notification-list" className="notification-list p-3">
                            {/*map notifications here*/}
                            {this.props.allNotifications.map((notification: INotification) => {
                                if (notification.eventText === "Ticket") {
                                    return (
                                        <TicketNotification
                                            eventByPicture={notification.eventByPicture}
                                            eventByText={notification.eventByText}
                                            eventText={notification.eventText}
                                            eventTime={notification.eventTime}
                                            isRead={notification.isRead}
                                            ticketId={notification.eventSource}
                                            eventAction={notification.eventAction}
                                            id={notification.id}
                                            url={"/tickets/" + notification.eventSource}
                                            key={notification.id}
                                        />
                                    )
                                }
                                if (notification.eventText === "Customer") {
                                    /*this validation needs to be inplace until we have only the new type of notifications on the users*/
                                    var isJson = isJsonString(notification.eventAction);
                                    let eventAction: any = null;
                                    if (isJson) {
                                        eventAction = JSON.parse(notification.eventAction);
                                    } else {
                                        eventAction = notification.eventAction;
                                    }
                                    return (
                                        <CustomerNotification
                                            eventByPicture={notification.eventByPicture}
                                            eventByText={notification.eventByText}
                                            eventText={notification.eventText}
                                            eventTime={notification.eventTime}
                                            isRead={notification.isRead}
                                            ticketId={notification.eventSource}
                                            eventAction={isJson ? eventAction.eventAction : eventAction}
                                            eventActionOn={isJson ? eventAction.eventActionOn : ""}
                                            id={notification.id}
                                            url={"/customers/" + notification.eventSource}
                                            eventActionNewMember={eventAction.eventActionNew}
                                        />
                                    )
                                }
                                else {
                                    return (null)
                                }
                            })}
                            {(this.state.gettingMore && !this.props.allNotificationsLoaded) &&
                                <Row>
                                    <Col className="text-center">
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    </Col>
                                </Row>
                            }
                        </Col>
                    )
                }
            }
            </Translation>
        );
    }
}
export default connect(
    (state: IApplicationState, _ownProps) => ({ ...state.notification, ...state.customer }), NotificationStore.actionCreators
)(NotificationsList);


