import * as React from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { Translation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { IFileAttachmentBase, IFilePondResponse } from '../../../Interface/Interfaces';
import StorageService from '../../../apiServices/StorageService';

export interface IGenericGenericFilePondProps {
    acceptedFileTypes?: string[];
    allowMultiple?: boolean
    customerId: string
    customerNumber?: string
    deliveryYear?: string
    disableFilePond: boolean
    fileAdded?: (file: IFileAttachmentBase) => void
    fileSizeLimit: number
    filesWhereSaved: (success: boolean) => void
    getFileCount: (fileCount: number) => void
    onErrorUpload?: (error: any) => void
    label?: any
    orderNumber?: string
    removeFiles?: boolean
    saved?: boolean
    serviceId?: string
    serviceName?: string
    ticketRef?: string
    uploadUrl: string
}

// Register the plugins
registerPlugin(FilePondPluginFileMetadata, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

export default class TicketFilePond extends React.Component<
    IGenericGenericFilePondProps,
    {}
    > {
    static displayName = 'TicketFilePond';

    private pond: any;
    public onFileUpload = (file: any) => {
        if (this.props.customerNumber) {
            file.setMetadata({
                "customerId": this.props.customerId,
                "orderNumber": this.props.orderNumber,
                "deliveryYear": this.props.deliveryYear,
                "serviceName": this.props.serviceName,
                "serviceId": this.props.serviceId
            });
        } else {
            file.setMetadata({
                "customerId": this.props.customerId,
                "customerNumber": this.props.customerNumber,
                "ticketRef": this.props.ticketRef
            });
        }
    }

    public onFileSuccessfullUpload = () => {
        this.pond.removeFiles()
    }

    public componentDidUpdate(prevProps: IGenericGenericFilePondProps) {
        if (prevProps.removeFiles !== this.props.removeFiles && this.props.removeFiles) {
            this.onFileSuccessfullUpload();
        }
        if (prevProps.saved !== this.props.saved && this.props.saved) {
            var files = this.pond.getFiles();
            if (files.length > 0) {
                this.pond!.processFiles().then((result: IFilePondResponse[]) => {
                    this.props.filesWhereSaved(true);
                    this.props.getFileCount(0);
                    this.pond!.removeFiles();
                }).catch((e: any) => {
                    this.props.filesWhereSaved(false);
                    this.props.getFileCount(0);
                })
            }
        }
    }

    private hasSie = () => {
        return this.props.acceptedFileTypes?.indexOf(".sie") !== -1;
    }

    private hasKtr = () => {
        return this.props.acceptedFileTypes?.indexOf(".ktr") !== -1;
    }

    private acceptedFileTypesWithoutExtension = () => {
        const extensions = ['.ktr', '.sie', '.si', '.se']
        return this.props.acceptedFileTypes?.filter(ext => extensions.indexOf(ext) === -1) ?? [];
    }

    private isExtensionPlainText = (filetype: string) => {
        const validExtensions = this.hasSie() ? ['si', 'se'] : [];
        filetype = filetype.toLowerCase();
        if (validExtensions.indexOf(filetype) === -1) {
            return false;
        }

        return true;
    }

    private isExtensionXmlText = (filetype: string) => {
        const validExtensions = this.hasSie() ? ['sie'] : [];
        filetype = filetype.toLowerCase();
        if (validExtensions.indexOf(filetype) === -1) {
            return false;
        }

        return true;
    }

    private isFilenameOctetStream = (fileName: string) => {
        const validFileNames = this.hasKtr() ? ['_1.ktr'] : [];
        fileName = fileName.toLowerCase();
        if (validFileNames.findIndex(name => fileName.endsWith(name)) === -1) {
            return false;
        }

        return true;
    }

    public validateCustomFiles = (source: any, type: any) => new Promise((resolve, reject) => {
        // Need to set filetype for sie files, filepond do not recognize sie files
        const nameParts = source.name.split('.');
        if (nameParts.length < 2) {
            resolve(type);
        }

        const fileType = nameParts.pop();
        if (!source.type && this.isExtensionPlainText(fileType)) {
            type = "text/plain";
        }

        if (!source.type && this.isExtensionXmlText(fileType)) {
            type = "text/xml";
        }

        if (!source.type && this.isFilenameOctetStream(source.name)) {
            type = "application/octet-stream";
        }

        resolve(type);
    })

    public fileAdded = (file: any, err: any) => {
        if (err === null) {
            var newlyAddedFile: IFileAttachmentBase = JSON.parse(file.serverId);
            this.pond!.removeFile(file);
            this.props.fileAdded!(newlyAddedFile);
            this.props.getFileCount(this.pond!.getFiles.length);
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="mb-3">
                            <Col>
                                <label>{this.props.label || t('common:FilePond.Label')}</label>
                                <FilePond
                                    ref={(ref: any) => this.pond = ref}
                                    allowMultiple={this.props.allowMultiple === false ? false : true}
                                    fileMetadataObject={{}}
                                    allowFileTypeValidation={this.acceptedFileTypesWithoutExtension()}
                                    maxFileSize={this.props.fileSizeLimit + "MB"}
                                    labelMaxFileSizeExceeded={t('common:FilePond.LargeFileWarning')}
                                    instantUpload
                                    disabled={this.props.disableFilePond}
                                    server={{
                                        process: {
                                            url: this.props.uploadUrl,
                                            headers: {
                                                'Authorization': 'Bearer ' + StorageService.GetAccessToken()
                                            },
                                        },
                                    }
                                    }
                                    onprocessfile={(error: any, file: any) => this.fileAdded(file, error)}
                                    allowDrop={!this.props.disableFilePond}
                                    allowBrowse={!this.props.disableFilePond}
                                    allowPaste={!this.props.disableFilePond}
                                    onaddfile={(error: any, file: any) => this.props.getFileCount(this.pond.getFiles().length)}
                                    labelIdle={`${t('common:FilePond.DragAndDrop')} <span class="filepond--label-action">${t('common:FilePond.Browse')}</span>`}
                                    dropOnPage
                                    acceptedFileTypes={this.props.acceptedFileTypes ? this.props.acceptedFileTypes : []}
                                    labelFileTypeNotAllowed={this.props.acceptedFileTypes ? t('common:FilePond.InvalidFiletype') : ''}
                                    fileValidateTypeLabelExpectedTypes=""
                                    fileValidateTypeDetectType={this.props.acceptedFileTypes ? this.validateCustomFiles : null}
                                    onerror={this.props.onErrorUpload}
                                />
                                <small className="text-secondary">{t('common:FilePond.FileSizeLimit', { fileSize: this.props.fileSizeLimit })}</small>
                            </Col>
                        </Row>
                    )
                }}
            </Translation>
        )
    }
}
