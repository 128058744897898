import * as React from 'react';
import { Translation } from 'react-i18next';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { handleKeyPress } from '../../../../Utils/CommonUtils';
import { Cardwrapper } from '@kpmgportals/genericcomponents';
import { createOrgSelectOptions, IAccountingCompany } from '@kpmgportals/reactselect';
import OrganizationsService from '../../../../apiServices/OrganizationsService';
import { debounce } from 'ts-debounce';

interface ICustomersFormProps{
    search:(searchText:string) => void;
}

export default class CustomersForm extends React.Component<ICustomersFormProps> {
    static displayName = 'CustomersForm';

    private keyWordsInputRef = React.createRef<any>();

    public componentWillUnmount() {
        this.clearFilter();
    }

    public searchPartnerAsync = async (searchText: string) => {
        const response = await OrganizationsService.SearchOrganizations(searchText);
        const results: IAccountingCompany[] = response.results;
        return createOrgSelectOptions(results);
    }

    private debouncedSearch = debounce(this.props.search, 300);

    private clearFilter = () => {
        this.keyWordsInputRef!.current!.value = "";
        this.props.search("");
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Cardwrapper>
                            <h2>{t('customers:Form.Title.SearchTitle')}</h2>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>{t('customers:Form.Label.Keywords')}</Form.Label>
                                        <Form.Control
                                            placeholder={t('customers:Form.Placeholder.Keywords')}
                                            onChange={(e: any) => {
                                                this.debouncedSearch(e.target.value);
                                            }}
                                            onKeyPress={(e: any) =>
                                                handleKeyPress(e, this.props.search, e.target.value)
                                            }
                                            ref={this.keyWordsInputRef}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-end">
                                    <Button variant="outline-primary" size="sm" onClick={() => this.clearFilter()}>
                                        {t('customers:Form.Action.ClearFilter')}
                                    </Button>
                                </Col>
                            </Row>
                        </Cardwrapper>
                    )
                }
            }
            </Translation>
        );
    }
}
