import HttpHelper from './HttpHelper';
import f from 'odata-filter-builder';
import { API_ENDPOINT } from '../Utils/config';

export default class OrganizationsService {
    public static SearchOrganizations(searchText: string) {
        const queryString = this.buildQueryString(searchText);
        const getTask = HttpHelper.Get(API_ENDPOINT + '/api/v2/organizations/external' + queryString);
        return getTask;
    }

    private static buildQueryString(searchText: string = ""): string {
        const searchTextUpperCase = searchText.toUpperCase();
        let params: string[] = [];
        const filter = f('or');

        if (searchText !== "") {
            filter
                .contains('normalizedName', searchTextUpperCase)
                .contains('organizationalNumber', searchTextUpperCase);
        }

        const filterstring = filter.toString();
        if (filterstring !== "") {
            params.push('$filter=' + encodeURIComponent(filterstring));
        }

        return params.length > 0 ? '?' + params.join('&') : "";
    }
}
