import * as React from 'react';
import { Translation } from 'react-i18next';
import { IExternalUser } from '../../../../Interface/Interfaces';
import { Row, Col } from 'react-bootstrap';
import CustomerMemberRow from './CustomerMemberRow';
import CustomerMemberNone from './CustomerMemberNone';
import { FixedSizeList } from 'react-window';
import { Table } from '@kpmgportals/genericcomponents';

interface ICustomerAccountingConsultantsProps {
    externalUsers: IExternalUser[];
}

export default class CustomerAccountingConsultants extends React.Component<ICustomerAccountingConsultantsProps, {}> {
    static displayName = 'CustomerAccountingConsultants';

    public renderRow = ({ ...props }) => (
        <CustomerMemberRow
            user={this.props.externalUsers[props.index]}
            style={props.style}
            index={props.index}
        />
    );

    public noRow = ({ ...props }) => (
        <CustomerMemberNone style={props.style} />
    );

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="mt-5">
                            <Col>
                                <Row>
                                    <Col>
                                        <Table>
                                            <Table.Body>
                                                <FixedSizeList
                                                    height={((this.props.externalUsers.length * 60) > 400) ? 400 : (this.props.externalUsers.length === 0 ? 60 : (this.props.externalUsers.length * 60))}
                                                    width="initial"
                                                    itemSize={55}
                                                    itemCount={this.props.externalUsers.length ? this.props.externalUsers.length : 1}
                                                    className="list-container"
                                                >
                                                    {this.props.externalUsers.length > 0 ? this.renderRow : this.noRow}
                                                </FixedSizeList>
                                            </Table.Body>
                                        </Table>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                }
            }
            </Translation>
        );
    }
}
