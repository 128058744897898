import * as React from 'react';
import { Translation } from 'react-i18next';
import { changePageTitle } from '../Utils/CommonUtils';
import { connect } from 'react-redux';
import { IApplicationState } from '../store';
import * as CustomerStore from '../store/Customer';
import CustomersTable from './CustomersComponents/SmallComponents/CustomersTable';
import { Cardwrapper } from '@kpmgportals/genericcomponents';
import ErrorBoundary from './HOC/ErrorBoundary';
import { Row, Col } from 'react-bootstrap';
import { CustomersTitle } from './CustomersComponents/SmallComponents/Customers/CustomersTitle';
import CustomersForm from './CustomersComponents/SmallComponents/Customers/CustomersForm';
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from 'redux';

type ICustomersProps = {
    states: {
        customer: CustomerStore.ICustomerState,
    }
    actions: {
        customer: typeof CustomerStore.actionCreators
    }
};

type ICustomersCombinedProps = ICustomersProps & RouteComponentProps<{}>;

class Customers extends React.Component<ICustomersCombinedProps, {}> {
    static displayName = 'Customers';

    private customerTable: HTMLDivElement;
    public componentDidMount() {
        this.props.actions.customer.getMyCustomers();
        window.addEventListener('scroll', this.handleScroll, true);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }

    public handleScroll = (e: any) => {
        if (this.props.states.customer.myCustomers.hasNextPage
            && !this.props.states.customer.isLoading
            && (window.innerHeight + document.documentElement!.scrollTop === document.documentElement!.offsetHeight)) {
            this.props.actions.customer.getMoreCustomers();
        }
    }

    private onRowClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const element = e.target as HTMLElement;
        var target = element.closest("[data-is-customer-row]");
        if (target) {
            const customerNumber = target.getAttribute("data-customer-number");
            this.props.history.push("/customers/" + customerNumber);
        }
    }

    public renderCustomersTable() {
        return (
            <CustomersTable
                getMoreCustomers={this.props.actions.customer.getMoreCustomers}
                onClick={this.onRowClick}
                customers={this.props.states.customer.myCustomers.results}
                hasNextPage={this.props.states.customer.myCustomers.hasNextPage}
                isLoading={this.props.states.customer.isLoading}
                sortCustomers={this.props.states.customer.sortCustomers}
                updateSortState={this.props.actions.customer.updateSortState}
            />
        );
    }

    public render() {
        return (
            <ErrorBoundary>
                <Translation>{
                    (t) => {
                        changePageTitle('PageTitle.Customers');
                        return (
                            <Row className="mt-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <CustomersTitle />
                                            <CustomersForm search={this.props.actions.customer.search} />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <Cardwrapper>
                                                {this.renderCustomersTable()}
                                            </Cardwrapper>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }
                }
                </Translation>
            </ErrorBoundary>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        states: {
            customer: state.customer,
        }
    }),
    (dispatch) => ({
        actions: {
            customer: bindActionCreators(CustomerStore.actionCreators, dispatch)
        }
    })
)(Customers);