import * as React from 'react';
import { Translation } from 'react-i18next';
import { Table } from '@kpmgportals/genericcomponents';

interface ICustomerMemberNoneProps {
    style: any;
}

export default class CustomerMemberNone extends React.Component<ICustomerMemberNoneProps, {}> {
    static displayName = 'CustomerMemberNone';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Table.Row
                            style={this.props.style}
                            className="mx-0"
                            noHover
                        >
                            <Table.Col
                                className="flex-grow-1 w-100 justify-content-center"
                                truncate
                            >
                                {t('customers:Customer.CustomerMembers.NoMembers')}
                            </Table.Col>
                        </Table.Row>
                    )
                }
            }
            </Translation>
        );
    }
}
