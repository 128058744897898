import AppInsightsUtils from './AppInsightsUtils';
import { SeverityLevel } from './../Interface/Enum';

export default class Logger {
    public static log(msg: any, props?: any): void {
        if ((window as any).KPMGDEBUG) {
            console.log(msg);
        }
    }

    public static info(msg: any, props?: any): void {
        this.log("Information: " + msg, props);
        AppInsightsUtils.TrackTrace(msg, props, SeverityLevel.Information);
    }

    public static error(msg: any, props?: any): void {
        this.log("Error: " + msg);
        AppInsightsUtils.TrackTrace(msg, props, SeverityLevel.Error);
    }
}
