import { IAppThunkAction } from '../';
import { KnownAction, IProfileState } from '../Profile';
import { GetUserProfil } from '../../Utils/ProfileHelpers';

export const ProfileActions = {
    connectCustomer: (connectionCustomer: signalR.HubConnection): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        connectionCustomer.on("updateprofile", () => {
            GetUserProfil().then((userProfile: IProfileState) => {
                dispatch({
                    type: 'GET_PROFILE',
                    profile: userProfile
                });
            });
        });
    },
    updateProfile: (userProfile: IProfileState): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_PROFILE',
            profile: userProfile
        });
    },
    getProfile: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        GetUserProfil().then((userProfile: IProfileState) => {
            dispatch({
                type: 'GET_PROFILE',
                profile: userProfile
            });
        });
    }
};
