import { IAssignee } from "../../src/Interface/Interfaces";

export class Assignee implements IAssignee {
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public pictureUrl: string = "";

    constructor(id: string = "", firstName: string = "", lastName: string = "", email: string = "", pictureUrl: string = "") {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.pictureUrl = pictureUrl;
    }
}
