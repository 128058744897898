import * as React from 'react';
import { Translation } from 'react-i18next';
import i18n from '../../translationcomponent/i18n';
import ReactImageFallback from "react-image-fallback";
import { USER_IMAGE_CDN } from '../../../Utils/config'
import * as defaultAvatar from '../../../assets/img/profile-large.png';
import { Redirect } from 'react-router';
import KPMGImageFallback from '../../CommonComponents/KPMGImageFallback';
import AppInsightsUtils from '../../../Utils/AppInsightsUtils';
import { Row, Col } from 'react-bootstrap';
import DateTimeHelper from '../../../Utils/DateTimeHelper';

export interface ITicketNotificationProps {
    eventAction: string;
    eventByText: string;
    eventText: string;
    eventTime: string;
    ticketId: string;
    eventByPicture: string;
    isRead: boolean;
    id: string;
    url: string;
}

export interface ITicketNotificationState {
    redirect: boolean;
}

export default class TicketNotification extends React.Component<ITicketNotificationProps, ITicketNotificationState> {
    static displayName = 'TicketNotification';

    constructor(props: ITicketNotificationProps) {
        super(props);
        this.state = { redirect: false };
    }

    private GetChange(change: string) {
        switch (change) {
            case 'WaitingForPartner':
                return i18n.t('tickets:History.StatusChange') + i18n.t('tickets:Status.WaitingForPartner');
            case 'updated':
                return i18n.t('tickets:Status.Updated');
            case 'commented':
                return i18n.t('tickets:Status.Commented');
            case 'WaitingForKPMG':
                return i18n.t('tickets:History.StatusChange') + i18n.t('tickets:Status.WaitingForKPMG');
            case 'created':
                return i18n.t('tickets:Status.Created');
            case 'edited':
                return i18n.t('tickets:Status.Edited');
            case 'Removed':
                return i18n.t('tickets:History.StatusChange') + i18n.t('tickets:Status.Removed');
            case 'Resolved':
                return i18n.t('tickets:History.StatusChange') + i18n.t('tickets:Status.Resolved');
            case 'change':
                return i18n.t('tickets:Status.Changed');
            case 'assigned':
                return i18n.t('tickets:Status.Assigned');
            case 'unassigned':
                return i18n.t('tickets:Status.Unassigned');
            case 'TicketAssignedToYou':
                return i18n.t('tickets:Status.TicketAssignedToYou');
            default:
                return '';
        }
    }

    private redirect = () => {
        this.setState({ redirect: true }, () => this.setState({ redirect: false }));
        AppInsightsUtils.TrackEvent("Update clicked", {});
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            <Row onClick={this.redirect} className="mx-0 py-3 mb-3 no-gutters notification">
                                <Col xs="2" className="px-2">
                                    {this.props.eventByPicture !== null && this.props.eventByPicture !== undefined ?
                                        <KPMGImageFallback
                                            sourceSet={USER_IMAGE_CDN + this.props.eventByPicture + "_s.jpg, " + USER_IMAGE_CDN + this.props.eventByPicture + "_l.jpg 2x"}
                                            src={USER_IMAGE_CDN + this.props.eventByPicture + "_s.jpg"}
                                            fallbackImage={defaultAvatar}
                                            initialImage={defaultAvatar}
                                            alt={this.props.eventByText}
                                            className="img-fluid rounded-circle border border-secondary bg-secondary"
                                        />
                                        :
                                        <ReactImageFallback
                                            src={defaultAvatar}
                                            fallbackImage={defaultAvatar}
                                            initialImage={defaultAvatar}
                                            alt={this.props.eventByText}
                                            className="img-fluid rounded-circle border border-secondary bg-secondary"
                                        />
                                    }
                                </Col>
                                <Col className="px-2">
                                    <Row className="mb-1">
                                        <Col>
                                            <strong>{this.props.eventText}: <span className="text-primary">{this.props.ticketId}</span></strong>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <small>{DateTimeHelper.FormatYearMonthDayWithTime(this.props.eventTime)}</small>
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col>
                                            {this.props.eventByText} <span className="text-secondary">{this.GetChange(this.props.eventAction)}</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {this.state.redirect &&
                                <Redirect exact to={this.props.url} />
                            }
                        </>
                    )
                }
            }
            </Translation>
        );
    }
}


