import {
    ICustomer,
    ICustomerContact,
    IAccountingCompany,
    IFiscalYear,
    IInternalUser,
    ICustomerEvent,
    ICustomerUser,
} from "../Interface/Interfaces";
import { AccoutingCompany } from "./AccountingCompany";
import { CustomerContact } from "./CustomerContact";
import { CustomerTypeEnum } from "../Interface/Enum";

export class Customer implements ICustomer {
    public id: string = "";
    public name: string = "";
    public imageUrl: string = "";
    public customerNumber: string = "";
    public organizationalNumber: string = "";
    public accountingCompany: IAccountingCompany = new AccoutingCompany();
    public customerResponsible: ICustomerContact = new CustomerContact();
    public responsibleAccountant: ICustomerContact = new CustomerContact();
    public mainCustomerContact: ICustomerContact = new CustomerContact();
    public fiscalMonthStart: number = 0;
    public fiscalMonthEnd: number = 0;
    public whiteList: string[] = [];
    public fiscalYears: IFiscalYear[] = [];
    public tickets: string[] = [];
    public externalUsers: ICustomerUser[] = [];
    public internalUsers: IInternalUser[] = [];
    public events: ICustomerEvent[] = [];
    public active: boolean = false;
    public isMember: boolean = true;
    public isRequested: boolean = false;
    public organizationForm: string = "";
    public industry: string = "";
    public customerType: CustomerTypeEnum = CustomerTypeEnum.NotSet;
    public city: string = "";

    constructor(accoutingCompany: IAccountingCompany = new AccoutingCompany()) {
        this.id = accoutingCompany.id;
        this.customerNumber = accoutingCompany.customerNumber;
        this.imageUrl = accoutingCompany.imageUrl!;
        this.name = accoutingCompany.name;
        this.organizationalNumber = accoutingCompany.organizationalNumber;
    }
}
