import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Translation } from 'react-i18next';
import i18n_ from '../../translationcomponent/i18n';
import 'core-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeAlt, faFileAlt, faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { Nav, Button, Badge, Dropdown, ButtonGroup } from 'react-bootstrap';

export interface INavItemsProps {
    isMobile?: boolean;
    ticketsNotifications: number;
    clearTicketNotifications: () => {};
    toggleSideNav?: () => void;
}

class NavMainItems extends React.Component<INavItemsProps> {
    static displayName = 'NavMainItems';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            <Nav.Link
                                as={NavLink}
                                exact
                                to="/"
                                activeClassName="active"
                                onClick={this.props.toggleSideNav}
                            >
                                <FontAwesomeIcon
                                    icon={faHomeAlt}
                                    size="lg"
                                    className="mr-1"
                                    fixedWidth
                                />
                                <span>{i18n_.t('common:NavBar.Home')}</span>
                            </Nav.Link>
                            {!this.props.isMobile ?
                                (
                                    <Dropdown as={ButtonGroup} className="nav-split-dropdown">
                                        <Button
                                            variant="link"
                                            as={NavLink}
                                            className="nav-link mr-0 position-relative"
                                            activeClassName="active"
                                            to="/tickets"
                                            onClick={() => {
                                                this.props.clearTicketNotifications();
                                                if (this.props.toggleSideNav) { this.props.toggleSideNav() };
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faFileAlt} size="lg" className="mr-1" fixedWidth />
                                            <span>{i18n_.t('common:NavBar.Tickets')}</span>
                                            {this.props.ticketsNotifications > 0 &&
                                                <Badge
                                                    pill
                                                    className={`${this.props.isMobile ? '' : 'menu-notification'}`}
                                                    variant="danger"
                                                >
                                                    {this.props.ticketsNotifications > 9 ? "9+" : this.props.ticketsNotifications}
                                                </Badge>
                                            }
                                        </Button>
                                        <Dropdown.Toggle split variant="link" id="header-ticket-dropdown" className="nav-link" />
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                as={NavLink}
                                                exact
                                                to="/tickets/new"
                                            >
                                                <FontAwesomeIcon icon={faFileAlt} size="lg" className="mr-1" fixedWidth />
                                                <span>{i18n_.t('common:NavBar.NewTicket')}</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) :
                                (
                                    <>
                                        <Nav.Link
                                            as={NavLink}
                                            exact
                                            to="/tickets/new"
                                            activeClassName="active"
                                            onClick={this.props.toggleSideNav}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFileAlt}
                                                size="lg"
                                                className="mr-1"
                                                fixedWidth
                                            />
                                            <span>{i18n_.t('common:NavBar.NewTicket')}</span>
                                        </Nav.Link>
                                        <Nav.Link
                                            as={NavLink}
                                            exact
                                            to="/tickets/"
                                            activeClassName="active"
                                            onClick={this.props.toggleSideNav}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFileAlt}
                                                size="lg"
                                                className="mr-1"
                                                fixedWidth
                                            />
                                            <span>{i18n_.t('common:NavBar.Tickets')}</span>
                                            {this.props.ticketsNotifications > 0 &&
                                                <Badge
                                                    pill
                                                    className={`${this.props.isMobile ? 'ml-3' : 'menu-notification'}`}
                                                    variant="danger"
                                                >
                                                    {this.props.ticketsNotifications > 9 ? "9+" : this.props.ticketsNotifications}
                                                </Badge>
                                            }
                                        </Nav.Link>
                                    </>
                                )
                            }
                            <Nav.Link
                                as={NavLink}
                                exact
                                to="/customers"
                                activeClassName="active"
                                onClick={this.props.toggleSideNav}
                            >
                                <FontAwesomeIcon
                                    icon={faBuilding}
                                    size="lg"
                                    className="mr-1"
                                    fixedWidth
                                />
                                <span>{i18n_.t('common:NavBar.Customers')}</span>
                            </Nav.Link>
                        </>
                    )
                }
            }
            </Translation>
        );
    }
}
export default NavMainItems;
