declare global {
    interface Number {
        thousandsSeperator(): string;
    }
    interface String {
        isEmptyOrWhiteSpace(): boolean;
        isNumber(): boolean;
    }
}

Number.prototype.thousandsSeperator = function(): string {
    return Number(this).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

String.prototype.isEmptyOrWhiteSpace = function(): boolean {
    if (this !== null) {
        const t = this.trim();
        if (t !== "") {
            return this === null;
        }
        return true;
    } else {
        return true;
    }
};
String.prototype.isNumber = function(): boolean {
    if (!Number.isNaN(Number(this))) {
        return true;
    } else {
        return false;
    }
};

export { };
