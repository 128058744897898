import * as React from 'react';
import { Translation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { changePageTitle } from '../../../../Utils/CommonUtils';

export class CustomersTitle extends React.Component<{}, {}> {
    static displayName = 'CustomersTitle';

    componentDidUpdate() {
        changePageTitle('PageTitle.Customers');
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="justify-content-between align-items-center mb-3">
                            <Col xs={12} lg="auto">
                                <h1 className="page-title">{t('customers:Title')}</h1>
                            </Col>
                        </Row>
                    )
                }
            }
            </Translation>
        );
    }
}
