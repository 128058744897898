import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileWord, faFilePdf, faFileExcel, faFilePowerpoint, faFileImage, faFileArchive, faFileCsv } from '@fortawesome/pro-solid-svg-icons';
import { iconFileSie } from '@kpmgportals/kpmgassets';

export default class FileUtils {

    public static GetFileImage(extension: string) {
        switch (extension.toLowerCase()) {
            case "docx":
                return <FontAwesomeIcon icon={faFileWord} />
            case "pdf":
                return <FontAwesomeIcon icon={faFilePdf} />
            case "xlsx":
                return <FontAwesomeIcon icon={faFileExcel} />
            case "pptx":
                return <FontAwesomeIcon icon={faFilePowerpoint} />
            case "sie":
            case "se":
                return <FontAwesomeIcon icon={iconFileSie} />
            case "png":
            case "jpg":
            case "jpeg":
            case "gif":
            case "svg":
                return <FontAwesomeIcon icon={faFileImage} />
            case "zip":
                return <FontAwesomeIcon icon={faFileArchive} />
            case "csv":
                return <FontAwesomeIcon icon={faFileCsv} />
            default:
                return <FontAwesomeIcon icon={faFile} />
        }
    }

    public static progress({ loaded, total, fileId }: any) {
        let elProgress = document.getElementById('progress-' + fileId);
        var progress = Math.round(loaded / total * 100);
        if (progress === 100) {
            elProgress!.innerHTML = '&nbsp';
        } else {
            elProgress!.innerHTML = progress + '%';
        }
    }
}
