export const USER_IMAGE_BLOB_URL = process.env.REACT_APP_USER_IMAGE_BLOB_URL;
export const USER_IMAGE_CDN = process.env.REACT_APP_USER_IMAGE_CDN_URL;
export const ORG_IMAGE_CDN = process.env.REACT_APP_ORG_IMAGE_CDN;
// ApplicationInsights key
export const APPINSIGHTS_INSTRUMENTATIONKEY = process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY;

// API Endpoint
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || "";

// Version Number of build
export const VERSION = process.env.REACT_APP_VERSION;

// I18n debug flag
export const I18N_DEBUG = process.env.REACT_APP_I18N_DEBUG || false;

// Support email
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;
export const PORTAL_NAME = process.env.REACT_APP_PORTAL_NAME;
