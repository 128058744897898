import { ITicketAttachments, ITicket, ICustomer, IComment, IHistory, IAssignee } from "../Interface/Interfaces";
import { TypeOfTicket, TicketStatus, OriginEnum } from "../Interface/Enum";
import { Customer } from "./Customer";
import { Assignee } from "./Assignee";

export class Ticket implements ITicket {
    public author: string = "";
    public authorText: string = "";
    public authorOrganizationText?: string | undefined = "";
    public authorOfficeText?: string | undefined = "";
    public authorEmail: string = "";
    public authorId: string = "";
    public authorPicture?: string | undefined = "";
    public created: string = "";
    public edited: string = "";
    public editedBy: string = "";
    public editedByText: string = "";
    public editedByPicture?: string | undefined = "";
    public id: string = "";
    public status: TicketStatus = TicketStatus.Created;
    public signalRPushed: boolean = false;
    public ticketStatus: number = 0;
    public recipientOrganizationText?: string | undefined = "";
    public recipientOfficeText?: string | undefined = "";
    public fiscalYear: number = 10;
    public comments: IComment[] = [];
    public client: string = "";
    public customer: ICustomer = new Customer();
    public history: IHistory[] = [];
    public recipientEmail?: string | undefined = "";
    public recipientText?: string | undefined = "";
    public recipientOrganizationId?: string | undefined = "";
    public recipientPicture?: string | undefined = "";
    public refNumber?: string | undefined = "";
    public typeOfTicket: TypeOfTicket = TypeOfTicket.PartnerTicket;
    public title: string = "";
    public description: string = "";
    public shortDescription: string = "";
    public recipientId: string = "";
    public confidential: boolean = false;
    public attachedFiles: ITicketAttachments[] = [];
    public origin: OriginEnum;
    public assignedTo?: IAssignee;

    public constructor(title: string = "", description: string = "", shortDescription: string = "", confidential: boolean = false, ticketStatus: number = 0, refNumber: string = "", attachedFiles: ITicketAttachments[] = [], assignedTo: IAssignee = new Assignee()) {
        this.title = title;
        this.description = description;
        this.shortDescription = shortDescription;
        this.confidential = confidential;
        this.ticketStatus = ticketStatus;
        this.refNumber = refNumber;
        this.attachedFiles = attachedFiles;
        if (assignedTo && assignedTo.id !== "") {
            this.assignedTo = assignedTo;
        }
    }
}
