import * as React from 'react';
import KPMGImageFallback from '@kpmgportals/kpmgimagefallback';
import { defaultAvatarOrg } from '@kpmgportals/kpmgassets';
import { ORG_IMAGE_CDN } from '../../../Utils/config';
import { ICustomer } from '../../../Interface/Interfaces';
import { Translation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

export interface ICustomerPictureProps {
    customer: ICustomer;
}

export default class CustomerPicture extends React.Component<ICustomerPictureProps, {}> {
    static displayName = 'CustomerPicture';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="justify-content-center align-items-center">
                            <Col lg={8} className="d-flex align-items-center flex-column">
                                <picture className="width-100 height-100" style={{ maxWidth: '150px' }}>
                                    <KPMGImageFallback
                                        sourceSet={ORG_IMAGE_CDN + this.props.customer.id + "_s.jpg" + (this.props.customer.imageUrl ? "?image=" + this.props.customer.imageUrl : "") + ", " + ORG_IMAGE_CDN + this.props.customer.id + "_l.jpg" + (this.props.customer.imageUrl ? "?image=" + this.props.customer.imageUrl : "") + " 2x"}
                                        src={this.props.customer.imageUrl === '' || this.props.customer.imageUrl == null
                                            ? "" : `${ORG_IMAGE_CDN}${this.props.customer.id}_s.jpg?image=${this.props.customer.imageUrl}`}
                                        fallbackImage={defaultAvatarOrg}
                                        initialImage={defaultAvatarOrg}
                                        alt="organization logo picture"
                                        className="profile-image-background rounded-circle w-100"
                                    />
                                </picture>
                            </Col>
                        </Row>
                    );
                }
            }
            </Translation>
        );
    }
}

