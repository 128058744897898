import * as React from 'react';
import { Translation } from 'react-i18next';
import { IInternalUser, IExternalUser, ICustomer } from '../../../../Interface/Interfaces';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import CustomerTeamMembers from './CustomerTeamMembers';
import CustomerAccountingConsultants from './CustomerAccountingConsultants';

interface ICustomerMembersProps {
    internalUsers: IInternalUser[];
    externalUsers: IExternalUser[];
    customer: ICustomer;
}

export default class CustomerMembers extends React.Component<ICustomerMembersProps, {}> {
    static displayName = 'CustomerMembers';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <h2>{t('customers:Customer.CustomerMembers.Members')}</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Tabs defaultActiveKey="teammembers" id="cutomerprofile-tabs">
                                            <Tab eventKey="teammembers" title={`KPMG (${this.props.internalUsers.length})`}>
                                                <CustomerTeamMembers internalUsers={this.props.internalUsers} />
                                            </Tab>
                                            <Tab eventKey="accountingconsultants" title={`${t('customers:Customer.CustomerMembers.AccountingConsultants')} (${this.props.externalUsers.length})`}>
                                                <CustomerAccountingConsultants externalUsers={this.props.externalUsers} />
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                }
            }
            </Translation>
        );
    }
}
