import { IProfileState, KnownAction } from "../Profile";

export function ProfileReducer(state: IProfileState, action: KnownAction) {
    switch (action.type) {
        case 'UPDATE_PROFILE':
            return {
                email: action.profile.email,
                FirstName: action.profile.FirstName,
                LastName: action.profile.LastName,
                organizationID: action.profile.organizationID,
                organizationOfficeID: action.profile.organizationOfficeID,
                pictureUrl: action.profile.pictureUrl,
                phoneNumber: action.profile.phoneNumber,
                whiteList: action.profile.whiteList,
                officeLocations: action.profile.officeLocations,
                userOrganization: action.profile.userOrganization
            };
        case 'GET_PROFILE':
            return {
                email: action.profile.email,
                FirstName: action.profile.FirstName,
                LastName: action.profile.LastName,
                organizationID: action.profile.organizationID,
                organizationOfficeID: action.profile.organizationOfficeID,
                pictureUrl: action.profile.pictureUrl,
                phoneNumber: action.profile.phoneNumber,
                whiteList: action.profile.whiteList,
                officeLocations: action.profile.officeLocations,
                userOrganization: action.profile.userOrganization
            };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return state || { email: "", FirstName: "", LastName: "", organizationID: "", organizationOfficeID: "", pictureUrl: "", phoneNumber: "", whiteList: [], userOrganization: {id: "", imageUrl: "", name: "", organizationalNumber: ""} };
}
