import * as React from "react";
import { Translation } from "react-i18next";
import * as NotificationStore from '../../../store/Notification';
import * as TicketsStore from '../../../store/Tickets';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../store';
import { withRouter, Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { Button, Badge } from 'react-bootstrap';

type INotificationsListProps = NotificationStore.INotificationState
    & TicketsStore.ITicketsState
    & typeof NotificationStore.actionCreators;

interface INotificationNavMobileButtonState {
    redirect: boolean;
}

class NotificationNavMobileButton extends React.Component<INotificationsListProps, INotificationNavMobileButtonState> {
    static displayName = 'NotificationNavMobileButton';

    constructor(props: any) {
        super(props);
        this.state = {
            redirect: false
        };
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            {(this.state.redirect) &&
                                <Redirect to="/notifications" />
                            }
                            <Button
                                className="position-relative"
                                onClick={() => this.setState({ redirect: true }, () => this.setState({ redirect: false }, () => { this.props.clearNotifications() }))}
                            >
                                <FontAwesomeIcon icon={faBell} size="2x" />
                                {this.props.unreadCount > 0 &&
                                    <Badge
                                        pill
                                        className="menu-notification"
                                        variant="danger"
                                    >
                                        {this.props.unreadCount > 9 ? "9+" : this.props.unreadCount}
                                    </Badge>
                                }
                            </Button>
                        </>
                    );
                }
            }
            </Translation>
        );
    };
} export default withRouter(connect(
    (state: IApplicationState) => state.notification, NotificationStore.actionCreators
)(NotificationNavMobileButton) as any);
