import { IAuthenticatedRouteState, AuthenticatedKnownAction } from '../AuthenticatedRoute';
import { Me } from '../../classes/Me';
import { Guid } from 'guid-typescript';

export function AuthenticatedRouteReducer(state: IAuthenticatedRouteState, action: AuthenticatedKnownAction) {
    switch (action.type) {
        case 'LOGIN_AUTHENTICATED':
            return { ...state, loggedIn: true, me: action.me };
        case 'LOGOUT_AUTHENTICATED':
            return { ...state, loggedOut: true, isLoading: false, me: new Me() };
        case 'ALREADYLOGGEDIN_AUTHENTICATED':
            return { ...state, loggedIn: true, me: action.me };
        case 'PROFILE_UPDATED':
            return { ...state, loggedIn: state.loggedIn, loggedOut: state.loggedOut, isLoading: state.loggedOut, refresh: !state.refresh };
        case 'VALIDATE_TOKEN':
            return { ...state, isValidToken:  action.isValidToken};
        case 'USER_NOT_FOUND':
            return { ...state, userPersonalIdentityNumberNotFound: action.userPersonalIdentityNumberNotFound};
        case 'AUTHENTICATED_ROUTE_UPDATE_ME':
            return { ...state, me: action.me };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }

    return state || {
        loggedIn: false,
        loggedOut: false,
        isLoading: true,
        refresh: false,
        me: new Me(),
        userPersonalIdentityNumberNotFound: false,
        refreshSessionId: Guid.raw()
    };
}
