import * as React from "react";
import { Translation } from "react-i18next";
import { Row, Col, Form, Button } from 'react-bootstrap';
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

interface ActivateELoginState {
    termReadChecked: boolean;
    termApproveChecked: boolean;
}
interface ActivateELoginProps {
    onActivateAccount: (loginMethod: string) => void;
    showCancelButton: boolean;
    onCancel?: () => void;
    isNewUser?: boolean;
}
class ActivateELogin extends React.Component<ActivateELoginProps, ActivateELoginState> {
    static displayName = 'ActivateELogin';

    constructor(props: ActivateELoginProps) {
        super(props);
        this.state = {
            termReadChecked: false,
            termApproveChecked: false
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row>
                            <Col>
                                {this.props.isNewUser &&
                                    <Row className="mb-3">
                                        <Col>
                                            <p>{t('common:ActivateAccount.InfoText.Se.PartOne')}</p>
                                            <p>{t('common:ActivateAccount.InfoText.Se.PartTwo')}</p>
                                            <p>
                                                {t('common:ActivateAccount.InfoText.Se.PartThree')} <a style={{ textDecoration: "underline" }} href="https://www.KPMG.se/gdpr" target="_blank" rel="noopener noreferrer">KPMG.se/gdpr</a>
                                                {t('common:ActivateAccount.InfoText.Se.PartThreeAfterAtag')}
                                            </p>

                                            <p style={{ fontStyle: 'italic' }}>{t('common:ActivateAccount.InfoText.En.PartOne')}</p>
                                            <p style={{ fontStyle: 'italic' }}>{t('common:ActivateAccount.InfoText.En.PartTwo')}</p>
                                            <p style={{ fontStyle: 'italic' }}>
                                                {t('common:ActivateAccount.InfoText.En.PartThree')} <a style={{ textDecoration: "underline" }} href="https://www.KPMG.se/gdpr" target="_blank" rel="noopener noreferrer">KPMG.se/gdpr</a>
                                                {t('common:ActivateAccount.InfoText.En.PartThreeAfterAtag')}
                                            </p>
                                        </Col>
                                    </Row>
                                }
                                {!this.props.isNewUser &&
                                    <Row className="mb-3">
                                        <Col>
                                            <p>{t('common:ActivateAccount.InfoTextExistingUser.PartOne')}</p>
                                            <p>{t('common:ActivateAccount.InfoTextExistingUser.PartTwo')}</p>
                                            <p>{t('common:ActivateAccount.InfoTextExistingUser.PartThree')} <a style={{ textDecoration: "underline" }} href="https://www.KPMG.se/gdpr" target="_blank" rel="noopener noreferrer">KPMG.se/gdpr</a></p>
                                        </Col>
                                    </Row>
                                }
                                <Row className="mb-3">
                                    <Col>
                                        <Row className="mb-2">
                                            <Col>
                                                <Form.Check
                                                    custom
                                                    type="checkbox"
                                                    id="activate-termRead"
                                                    label={t('common:ActivateAccount.TermRead')}
                                                    onClick={() => this.setState({ termReadChecked: !this.state.termReadChecked })}
                                                    defaultChecked={this.state.termReadChecked}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    custom
                                                    type="checkbox"
                                                    id="activate-termApproved"
                                                    label={t('common:ActivateAccount.TermApproval')}
                                                    onClick={() => this.setState({ termApproveChecked: !this.state.termApproveChecked })}
                                                    defaultChecked={this.state.termApproveChecked}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="padding-top-1 padding-bottom-1">
                                    <Col>
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => this.props.onActivateAccount("BankId")}
                                            style={{ width: '50%', padding: '10px 20px', marginBottom: '1rem' }}
                                            className="button white radius small "
                                            disabled={!this.state.termApproveChecked || !this.state.termReadChecked}
                                        >
                                            <Row>
                                                <Col xs={2}>
                                                    <img src="/images/flag-round-sweden.png" alt="sweden" style={{ height: '40px', marginRight: '40px' }} />
                                                </Col>
                                                <Col xs={8} style={{ margin: 'auto' }}>
                                                    <span style={{ fontSize: '15px' }}>Activate account with BankID</span>
                                                </Col>
                                                <Tippy
                                                    content={
                                                        <div style={{ textAlign: 'initial', padding: '15px' }}>
                                                            <h2 style={{ color: 'black', fontSize: '22px' }}>Vad är BankID?</h2>
                                                            <p>BankID är en elektronisk ID-handling som är jämförbar med pass, körkort och andra fysiska legitimationer. För BankID krävs ett svenskt personnr eller samordningsnummer</p>
                                                            <h2 style={{ color: 'black', fontSize: '22px' }}>Hur skaffar jag BankID?</h2>
                                                            <p>Du beställer BankID hos din bank. Läs mer om hur det går till hos din bank eller på <a href="https://www.bankid.com" style={{ color: 'blue' }}>www.bankid.com</a>.</p>
                                                        </div>
                                                    }
                                                    allowHTML
                                                    trigger="mouseover mouseenter"
                                                    arrow
                                                    theme='light'
                                                    interactive
                                                    placement="bottom"
                                                >
                                                    <Col xs={2} style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <FontAwesomeIcon size="2x" style={{ color: '#c7c7c7' }} icon={faInfoCircle} />
                                                    </Col>
                                                </Tippy>
                                            </Row>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="padding-top-1 padding-bottom-1">
                                    <Col>
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => this.props.onActivateAccount("FrejaEidPlus")}
                                            style={{ width: '50%', padding: '10px 20px', marginBottom: '1rem' }}
                                            className="button white radius small freja-plus-information"
                                            disabled={!this.state.termApproveChecked || !this.state.termReadChecked}
                                        >
                                            <Row>
                                                <Col xs={2}>
                                                    <img src="/images/flag-round-sweden.png" alt="scandinavia" style={{ height: '40px', marginRight: '40px' }} />
                                                </Col>
                                                <Col xs={8} style={{ margin: 'auto' }}>
                                                    <span style={{ fontSize: '15px' }}>Activate account with Freja eID Plus</span>
                                                </Col>
                                                <Tippy
                                                    content={
                                                        <div style={{ textAlign: 'initial', padding: '15px' }}>
                                                            <h2 style={{ color: 'black', fontSize: '22px' }}>Vad är Freja eID Plus?</h2>
                                                            <p>Freja eID är en kostnadsfri statligt godkänd mobil e-legitimation för personer som är mantalsskrivna i Sverige. Med Freja e-id plus behöver du ingen internetbank.</p>
                                                            <h2 style={{ color: 'black', fontSize: '22px' }}>Hur skaffar jag Freja eID Plus?</h2>
                                                            <p>Du måste gå till ett godkänt Freja eID-ombud och ta med dig din mobil samt den ID-handling du registrerade. På Frejas e-id:s webbplats <a href="https://frejaeid.com/registrera-kom-igang/" style={{ color: 'blue' }}>Registrera - Kom igång - Freja eID</a> kan du läsa hur du skaffar Freja e-id plus och vilka eID ombud som finns.</p>
                                                        </div>
                                                    }
                                                    allowHTML
                                                    trigger="mouseover mouseenter"
                                                    arrow
                                                    theme='light'
                                                    interactive
                                                    placement="bottom"
                                                >
                                                    <Col xs={2} style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <FontAwesomeIcon className="" size="2x" style={{ color: '#c7c7c7' }} icon={faInfoCircle} />
                                                    </Col>
                                                </Tippy>
                                            </Row>
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="padding-top-1 padding-bottom-1">
                                    <Col>
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => this.props.onActivateAccount("FrejaMail")}
                                            style={{ width: '50%', padding: '10px 20px', marginBottom: '1rem' }}
                                            className="button white radius small"
                                            disabled={!this.state.termApproveChecked || !this.state.termReadChecked}
                                        >
                                            <Row>
                                                <Col xs={2}>
                                                    <img src="/images/flag-round-scandinavia.png" alt="sweden" style={{ height: '40px', marginRight: '40px' }} />
                                                </Col>
                                                <Col xs={8} style={{ margin: 'auto' }}>
                                                    <span style={{ fontSize: '15px' }}>Activate account with Freja eID</span>
                                                </Col>
                                                <Tippy
                                                    content={
                                                        <div style={{ textAlign: 'initial', padding: '15px' }}>
                                                            <h2 style={{ color: 'black', fontSize: '22px' }}>What is Freja eID?</h2>
                                                            <p>Freja eID is a mobile app that represents your identity in digital form as an electronic identity (eID). For identification you need a Danish, Finnish, Norwegian or British passport.</p>
                                                            <h2 style={{ color: 'black', fontSize: '22px' }}>How do I get Freja eID?</h2>
                                                            <p>Follow this link to <a href="https://frejaeid.com/en/get-freja-eid/" style={{ color: 'blue' }}>Get Freja eID - Freja eID </a> and download the app.</p>
                                                            <p>For information about the registration process, visit <a href="https://frejaeid.com/en/registering-get-started/" style={{ color: 'blue' }}>Registering - Get Started - Freja eID</a>.</p>
                                                        </div>
                                                    }
                                                    allowHTML
                                                    trigger="mouseover mouseenter"
                                                    arrow
                                                    theme='light'
                                                    interactive
                                                    placement="bottom"
                                                >
                                                    <Col xs={2} style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <FontAwesomeIcon size="2x" style={{ color: '#c7c7c7' }} icon={faInfoCircle} />
                                                    </Col>
                                                </Tippy>
                                            </Row>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                }
            }
            </Translation>
        );
    }
}
export default ActivateELogin;
