import { ApplicationInsights, Exception } from '@microsoft/applicationinsights-web';
import { getAppInsights } from '../services/TelemetryService';
import Logger from './Logger';
import { getUserId } from '../apiServices/UserService';
import { VERSION } from './config';

export default class AppInsightsUtils {
    public static TrackEvent(eventName: string, props: any): void {
        try {
            if (this.Initzialized()) {
                this.appInsights.trackEvent({ name: `KPMG Link ${eventName}` }, props);
            }
        } catch (e) {
            Logger.log(`AppInsightsUtils TrackEvent error: ${e}`);
        }
    }
    public static TrackTrace(message: string, props?: any, severityLevel?: AI.SeverityLevel): void {
        try {
            if (this.Initzialized()) {
                this.appInsights.trackTrace({ message: `KPMG Link ${message}`, severityLevel: severityLevel }, props!);
            }
        } catch (e) {
            Logger.log(`AppInsightsUtils TrackTrace error: ${e}`);
        }
    }
    public static TrackEventDuration(name: string, operationValue: string, props: any): void {
        try {
            if (operationValue === "start") {
                this.appInsights.startTrackEvent(`KPMG Link ${name}`);
            } else {
                this.appInsights.stopTrackEvent(`KPMG Link ${name}`, props);
            }
        } catch (e) {
            Logger.log(`AppInsightsUtils TrackEventDuration error: ${e}`);
        }
    }
    public static TrackException(exeption: Exception): void {
        try {
            if (this.Initzialized()) {
                this.appInsights.trackException(exeption);
            }
        } catch (e) {
            Logger.log(`AppInsightsUtils TrackException error: ${e}`);
        }
    }
    private static appInsights: ApplicationInsights;

    private static Initzialized(): boolean {
        if (this.appInsights === null || this.appInsights === undefined) {
            this.appInsights = getAppInsights();
        }
        if (this.appInsights !== null && this.appInsights.context !== null && this.appInsights.context.user !== null && this.appInsights.context.user.id !== getUserId()) {
            let userId = getUserId();
            this.appInsights.context.user.id = userId
            this.appInsights.setAuthenticatedUserContext(userId);
            this.appInsights.context.application.ver = VERSION ?? "localhost";

            this.appInsights.addTelemetryInitializer(envelope => {
                var item = envelope.baseData;
                if (item) {
                    item.properties = item.properties || {};
                    item.properties["PortalName"] = window.location.host;
                }
            });

            this.appInsights.context.sessionManager.update();
        }
        return true;
    }
}
