import * as React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import Cardwrapper from '../components/CommonComponents/Containers/Cardwrapper';
import { KpmgLogo as Logo } from '@kpmgportals/kpmgassets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

class Unauthorized extends React.Component<{}, {}> {
    static displayName = 'Unauthorized';

    public render() {
        return (
            <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Col xs={12} md={8} lg={6}>
                    <Cardwrapper>
                        <Row className="justify-content-center mb-3">
                            <Col xs={8} md={6}>
                                <Logo className="text-primary" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Alert variant="danger">
                                    <Alert.Heading as="h2">
                                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="mr-1" />
                                        Access denied
                                    </Alert.Heading>
                                    <p>You do not have access to this page. Please check that you use browser Chrome, Firefox or Edge. If it still does not work, please contact your contact person at KPMG to get access.</p>
                                </Alert>
                            </Col>
                        </Row>
                    </Cardwrapper>
                </Col>
            </Row>
        );
    }
}

export default Unauthorized;
