import * as React from "react";
import { Translation } from 'react-i18next';
import { Table, TableSortableHeader } from '@kpmgportals/genericcomponents';
import { KPMGContextConsumer } from '@kpmgportals/genericcomponents';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

interface ISortHeaders extends Record<string, "" | "asc" | "desc"> { }

interface ICustomersTableHeadState {
    headers: ISortHeaders;
}

interface ISieFilesTableHeadProps {
    changeSort: (name: string, sortOrder: "asc" | "desc" | "") => void;
    blacklist?: string[]
    customersLength: number;
}

export default class CustomersTableHead extends React.Component<ISieFilesTableHeadProps, ICustomersTableHeadState> {
    static displayName = 'CustomersTableHead';

    constructor(props: any) {
        super(props)
        this.state = ({
            headers: this.headers,
        });
    }

    public get headers(): ISortHeaders {
        return {
            "name": "",
            "organizationalNumber": "",
            "customerNumber": "",
            "customerResponsible/name": "",
            "fiscalMonthEnd": ""
        };
    }

    public changeSort = (name: string, sortOrder: "asc" | "desc" | "") => {
        var headers = this.sortTableHeader(name, this.state.headers);
        this.props.changeSort(name, headers[name]);
        this.setState({ headers: headers });
    }

    private sortTableHeader(name: string, sortableHeaders: ISortHeaders): ISortHeaders {
        var sortableHeadersClone = Object.assign({}, sortableHeaders);
        for (const key in sortableHeadersClone) {
            if (sortableHeadersClone.hasOwnProperty(key)) {
                const element = sortableHeadersClone[key];
                if (name !== key) {
                    sortableHeadersClone[key] = "";
                    continue;
                }

                if (element === "" || element === "desc") {
                    sortableHeadersClone[key] = "asc";
                    continue;
                }

                sortableHeadersClone[key] = "desc";
            }
        }

        return sortableHeadersClone;
    }

    inBlacklist(name: string) {
        if (!this.props.blacklist) {
            return false;
        }

        return this.props.blacklist.indexOf(name) !== -1;
    }

    public render() {
        return (
            <Translation>
                {t => {
                    return (
                        <KPMGContextConsumer>
                            {KPMGContext => (
                                <Table.Head
                                    style={
                                        { paddingRight: `${this.props.customersLength > 7 ? `${KPMGContext?.scrollbarSize}px` : 0}` }
                                    }
                                >
                                    {!this.inBlacklist("name") &&
                                        <Table.Col
                                            th
                                            className="flex-grow-1"
                                            separator
                                            truncate
                                            xs={3}
                                        >
                                            <span className="text-truncate w-100">
                                                <TableSortableHeader
                                                    displayName={t('customers:TableHeaders.Name')}
                                                    sortOrder={this.state.headers["name"]}
                                                    name="name"
                                                    changeSort={this.changeSort}
                                                />
                                            </span>
                                        </Table.Col>
                                    }
                                    <Table.Col
                                        th
                                        className="flex-grow-2"
                                        separator
                                        truncate
                                    >
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="customertableheader_orgnr">
                                                    <span>{t('customers:TableHeaders.OrgNr')}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <span className="text-truncate w-100">
                                                <TableSortableHeader
                                                    displayName={t('customers:TableHeaders.OrgNr')}
                                                    sortOrder={this.state.headers["organizationalNumber"]}
                                                    name="organizationalNumber"
                                                    changeSort={this.changeSort}
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    </Table.Col>
                                    <Table.Col
                                        th
                                        className="flex-grow-1"
                                        separator
                                        truncate
                                    >
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="customertableheader_custnr">
                                                    <span>{t('customers:TableHeaders.CustomerNumber')}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <span className="text-truncate w-100">
                                                <TableSortableHeader
                                                    displayName={t('customers:TableHeaders.CustomerNumber')}
                                                    sortOrder={this.state.headers["customerNumber"]}
                                                    name="customerNumber"
                                                    changeSort={this.changeSort}
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    </Table.Col>
                                    <Table.Col
                                        th
                                        className="flex-grow-1"
                                        separator
                                        truncate
                                    >
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="customertableheader_custman">
                                                    <span>{t('customers:TableHeaders.CustomerManager')}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <span className="text-truncate w-100">
                                                <TableSortableHeader
                                                    displayName={t('customers:TableHeaders.CustomerManager')}
                                                    sortOrder={this.state.headers["customerResponsible/name"]}
                                                    name="customerResponsible/name"
                                                    changeSort={this.changeSort}
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    </Table.Col>
                                    <Table.Col
                                        th
                                        className="flex-grow-1"
                                        separator
                                        truncate
                                    >
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="customertableheader_fyend">
                                                    <span>{t('customers:TableHeaders.FiscalEnd')}</span>
                                                </Tooltip>
                                            }
                                        >
                                            <span className="text-truncate w-100">
                                                <TableSortableHeader
                                                    displayName={t('customers:TableHeaders.FiscalEnd')}
                                                    sortOrder={this.state.headers["fiscalMonthEnd"]}
                                                    name="fiscalMonthEnd"
                                                    changeSort={this.changeSort}
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    </Table.Col>
                                </Table.Head>
                            )
                            }
                        </KPMGContextConsumer>
                    );
                }
                }
            </Translation>
        );
    }
}
