import * as React from 'react';
import i18n_ from '../translationcomponent/i18n';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import * as AuthenticatedRouteStore from '../../store/AuthenticatedRoute';
import * as ProfileStore from '../../store/Profile';
import { IApplicationState } from '../../store/index';
import { updateLanguage, GetMeFromStorage } from '../../apiServices/UserService';
import { NavLink } from 'react-router-dom';
import { USER_IMAGE_CDN } from '../../Utils/config'
import * as defaultAvatar from './../../assets/img/profile-large.png'
import KPMGImageFallback from '../CommonComponents/KPMGImageFallback';
import AppInsightsUtils from '../../Utils/AppInsightsUtils';
import { IMe } from '../../Interface/Interfaces';
import { Dropdown } from 'react-bootstrap';
import StorageService from '../../apiServices/StorageService';
import * as CustomerStore from '../../store/Customer';

interface NavMenuState {
    settingOpen?: boolean;
    langOpen?: boolean;
    langChanged?: boolean;
    name?: string;
    userPicture: string;
}

type UserSettingsProps =
    AuthenticatedRouteStore.IAuthenticatedRouteState
    & typeof AuthenticatedRouteStore.actionCreators
    & ProfileStore.IProfileState
    & typeof ProfileStore.actionCreators
    & CustomerStore.ICustomerState;

class UserSettings extends React.Component<UserSettingsProps, NavMenuState> {
    static displayName = 'UserSettings';

    private lang: string;
    constructor(props: any) {
        super(props);
        this.state = {
            settingOpen: false,
            userPicture: defaultAvatar
        };
        this.openLang = this.openLang.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.signOut = this.signOut.bind(this);
        this.changeLang = this.changeLang.bind(this);
        this.lang = localStorage!.getItem('i18nextLng')!;
    }
    public componentDidMount() {
        const me: IMe = GetMeFromStorage();
        this.setState({ name: me.firstName.charAt(0).toUpperCase() + " " + me.lastName.charAt(0).toUpperCase() })
        this.checkUserPicture();
        this.props.getProfile();
    }

    public componentDidUpdate(prevProps: UserSettingsProps) {
        if (prevProps.refresh !== this.props.refresh) {
            const user = StorageService.GetParsedStorageData();
            this.setState({ userPicture: USER_IMAGE_CDN + user.userPicture });
        }
        if (this.props.emailChanged && !prevProps.emailChanged) {
            this.props.logOut();
            AppInsightsUtils.TrackEvent("User was signed out due to email changes", {});
        }
        if (this.props.userIsRemoved && !prevProps.userIsRemoved) {
            this.props.logOut();
            AppInsightsUtils.TrackEvent("User was signed out due to user was removed", {});
        }
    }

    public checkUserPicture = () => {
        const user = StorageService.GetParsedStorageData();
        if (user.userPicture != null && user.userPicture !== "" && user.userPicture !== undefined) {
            this.setState({ userPicture: USER_IMAGE_CDN + user.userPicture });
        }
    }

    private signOut() {
        this.props.logOut();
        AppInsightsUtils.TrackEvent("User signed out", {});
    }

    public changeLang(e: any) {
        if (e.target.dataset.value === "sv-SE") {
            i18n_.changeLanguage('sv-SE');
        } else {
            i18n_.changeLanguage('en-US');
        }
        this.lang = e.target.dataset.value;
        this.setState({ langChanged: !this.state.langChanged });
        updateLanguage(this.lang);
    }
    public openLang() {
        this.setState({ langOpen: !this.state.langOpen })
    }

    public openMenu(e: any) {
        if (e.target.dataset.value !== "language") {
            this.setState({ settingOpen: !this.state.settingOpen })
        }
        if (this.state.settingOpen) {
            AppInsightsUtils.TrackEvent("Navbar profilespicture opened", {});
        } else {
            AppInsightsUtils.TrackEvent("Navbar profilespicture closed", {});
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Dropdown alignRight>
                            <Dropdown.Toggle id="profile-dropdown">
                                <picture>
                                    <KPMGImageFallback
                                        sourceSet={this.state.userPicture + "_s.jpg, " + this.state.userPicture + "_l.jpg 2x"}
                                        src={this.state.userPicture + "_s.jpg"}
                                        fallbackImage={defaultAvatar}
                                        initialImage={defaultAvatar}
                                        alt={`
                                                ${this.props.FirstName !== "" ? this.props.FirstName + ' ' + this.props.LastName : this.props.FirstName === "" ? this.props.me.firstName + ' ' + this.props.me.lastName : ''
                                            }
                                            `}
                                        className="profile-image rounded-circle bordered mr-1"
                                        style={{ width: '1.3333333333em', height: '1.3333333333em', verticalAlign: '-0.225em' }}
                                    />
                                </picture>
                                {this.props.FirstName !== "" &&
                                    this.props.FirstName.charAt(0).toUpperCase() + " " + this.props.LastName.charAt(0).toUpperCase()
                                }
                                {this.props.FirstName === "" &&
                                    this.state.name
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    as={NavLink}
                                    exact
                                    to="/user/myprofile/"
                                    activeClassName="active"
                                    onClick={() => AppInsightsUtils.TrackEvent("My profile clicked", {})}
                                >
                                    {t('common:NavBar.User.MyProfile')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    as={NavLink}
                                    exact
                                    to="/user/emailsettings"
                                    activeClassName="active"
                                >
                                    {t('common:NavBar.User.EmailSettings')}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    className={this.lang! === 'en-US' ? 'active' : ''}
                                    onClick={this.changeLang}
                                    data-value="en-US"
                                >
                                    {i18n_.t('profile:User.EnglishLanguage')}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className={this.lang! === 'sv-SE' ? 'active' : ''}
                                    onClick={this.changeLang}
                                    data-value="sv-SE"
                                >
                                    {i18n_.t('profile:User.SwedishLanguage')}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    onClick={this.signOut}
                                >
                                    {t('common:NavBar.User.Logout')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }
            }
            </Translation>
        );
    }
}
export default connect(
    (state: IApplicationState) => ({ ...state.profile, ...state.authenticatedRoute, ...state.customer }), ({
        ...ProfileStore.actionCreators,
        ...AuthenticatedRouteStore.actionCreators
    })
)(UserSettings);
