import * as React from "react";
import { Translation } from 'react-i18next';
import { IFileRequests, IFileRequest, IFileAttachmentBase } from "../../../../Interface/Interfaces";
import { Row, Col } from "react-bootstrap";
import Table from '../../../CommonComponents/Tables/Table';
import FileRequestTableRow from './FileRequestTableRow';
import FileRequestTableHead from './FileRequestTableHead';
// import FileRequestFilter from "./FileRequestFilter"; //TODO

interface FileRequestTableProps {
    fileRequest: IFileRequests;
    openFileRequestRowModal: (fileRequestRowId: string) => void;
    downloadTemplateFile: (file: IFileAttachmentBase) => void;
    uniqueFileRequestCategories: string[];
}

export default class FileRequestTable extends React.Component<FileRequestTableProps> {
    static displayName = 'FileRequestTable';

    public render() {
        return (
            <Translation>
                {t => {
                    return (
                        <Row>
                            <Col>
                                {this.props.uniqueFileRequestCategories.map((category: string) => {
                                    return (
                                        <React.Fragment key={category}>
                                            {(this.props.fileRequest.fileRequests.find((x: IFileRequest) => x.category === category)) &&
                                                <Row>
                                                    <Col>
                                                        <h3 className="mt-3">{category}</h3>
                                                        <Table minWidth="lg">
                                                            <Table.Body>
                                                                <FileRequestTableHead />
                                                                {this.props.fileRequest.fileRequests.filter((x: IFileRequest) => x.category === category).map(
                                                                    (request: IFileRequest, index) => {
                                                                        return (
                                                                            <FileRequestTableRow
                                                                                key={`frRow_${request.id}`}
                                                                                request={request}
                                                                                openFileRequestRowModal={this.props.openFileRequestRowModal}
                                                                                downloadTemplateFile={this.props.downloadTemplateFile}
                                                                            />
                                                                        )
                                                                    }
                                                                )}
                                                            </Table.Body>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </Col>
                        </Row>
                    );
                }}
            </Translation>
        );
    }
}
