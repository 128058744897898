import React from 'react';
import { Translation } from 'react-i18next';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
    ControlComponent,
    ValueContainerComponent,
    IndicatorsContainerComponent,
    DropdownIndicatorComponent,
    ClearIndicatorComponent,
    resetStyles,
    setFilterOption
} from '../../../Utils/ReactSelectHelper';

export interface IUserOptionOrgReactSelectProps {
    getSelectedValue?: any;
    type?: 'user' | 'organization';
    callBacks?: any[];
    options?: any[];
    template?: {};
    isMulti?: boolean;
    singleValue?: any;
    option?: any;
    customerNumber?: string;
    id?: string;
    value?: any;
    defaultValue?: any;
    noOptionsMessage?: any;
    isClearable?: boolean;
    placeholder?: any;
    isDisabled?: boolean;
    size?: 'sm' | 'lg';
    filterOption?: any | 'default';
}

export default class UserOptionOrgReactSelect extends React.Component<IUserOptionOrgReactSelectProps, {}> {
    static displayName = 'UserOptionOrgReactSelect';

    public CallCallBack = (selectInput: any) =>
        new Promise(resolve => {
            if (this.props.type === 'user') {
                let callBacks = this.props.callBacks!.map((cb: (searchText: string, companyId: string) => Promise<any>) => cb(selectInput, this.props.customerNumber!));
                Promise.all(callBacks).then((results: any) => {
                    var flatResult = results.flatMap((x: any) => x.results);
                    var kpmgUsers = flatResult
                        .filter((x: any) => x.organization.name === 'KPMG')
                        .map((user: any) => { return { label: `${user.firstName} ${user.lastName}`, value: user.id, pictureUrl: user.pictureUrl, email: user.email, organization: user.organization } });
                    var otherUsers = flatResult
                        .filter((x: any) => x.organization.name !== 'KPMG')
                        .map((user: any) => { return { label: `${user.firstName} ${user.lastName}`, value: user.id, pictureUrl: user.pictureUrl, email: user.email, organization: user.organization } });

                    var groupedResult = [
                        {
                            label: 'KPMG',
                            options: kpmgUsers
                        },
                        {
                            label: otherUsers.length > 0 ? otherUsers[0].organization.name : "Others",
                            options: otherUsers
                        }
                    ]
                    resolve(groupedResult);
                });
            }
        });

    public render() {
        if (this.props.callBacks) {
            return (
                <Translation>{
                    (t) => {
                        return (
                            <AsyncSelect
                                cacheOptions
                                isClearable
                                searchable
                                loadOptions={this.CallCallBack}
                                isMulti={this.props.isMulti}
                                onChange={this.props.getSelectedValue}
                                inputId={this.props.id}
                                noOptionsMessage={() => this.props.noOptionsMessage}
                                components={{
                                    SingleValue: this.props.singleValue,
                                    Option: this.props.option,
                                    Control: ControlComponent,
                                    ValueContainer: ValueContainerComponent,
                                    IndicatorsContainer: IndicatorsContainerComponent,
                                    DropdownIndicator: DropdownIndicatorComponent,
                                    ClearIndicator: ClearIndicatorComponent,
                                }}
                                styles={resetStyles}
                                placeholder={this.props.placeholder}
                                isDisabled={this.props.isDisabled}
                                size={this.props.size}
                                filterOption={setFilterOption(this.props.filterOption)}
                            />
                        );
                    }
                }
                </Translation>
            );
        }
        else {
            return (
                <Translation>{
                    (t) => {
                        return (
                            <Select
                                options={this.props.options}
                                isMulti={this.props.isMulti}
                                onChange={this.props.getSelectedValue}
                                inputId={this.props.id}
                                value={this.props.value}
                                defaultValue={this.props.defaultValue}
                                noOptionsMessage={() => this.props.noOptionsMessage}
                                components={{
                                    SingleValue: this.props.singleValue,
                                    Option: this.props.option,
                                    Control: ControlComponent,
                                    ValueContainer: ValueContainerComponent,
                                    IndicatorsContainer: IndicatorsContainerComponent,
                                    DropdownIndicator: DropdownIndicatorComponent,
                                    ClearIndicator: ClearIndicatorComponent,
                                }}
                                styles={resetStyles}
                                isClearable={this.props.isClearable}
                                placeholder={this.props.placeholder}
                                isDisabled={this.props.isDisabled}
                                size={this.props.size}
                                filterOption={setFilterOption(this.props.filterOption)}
                            />
                        );
                    }
                }
                </Translation>
            );
        }
    }
}
