import { IAppThunkAction } from '..';
import { KnownAction } from '../FileRequest';
import FileRequestService from '../../apiServices/FileRequestService';
import { ITicket,
    IFileRequests, IFileAttachmentBase , IFileRequestMatchCollecton, IFileRequest } from '../../Interface/Interfaces';
import {FileRequestStateEnum } from '../../Interface/Enum';
import { ToastFactory } from '../../Utils/ToastFactory';
import i18n from '../../components/translationcomponent/i18n';
import { FileRequests } from 'src/classes/FileRequests';
import { FileRequest } from 'src/classes/FileRequest';

export const FileRequestActions = {
    getTempFileRequestRow: (ticketId: string, fileRequestRowId: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        FileRequestService.GetTempFileRequestRow(ticketId, fileRequestRowId).then((fileRequestRow: IFileRequest) => {
            dispatch({
                type: 'GOT_A_FILEREQUEST_ROW',
                result: fileRequestRow
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        }).catch((err: any) => {
            dispatch({
                type: 'GOT_A_FILEREQUEST_ROW',
                result: new FileRequest()
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        });
    },
    unmatchTempFileRequestRow: (ticketId: string, fileRequestRowId: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        FileRequestService.UnmatchTempFileRequestRow(ticketId, fileRequestRowId).then((fileRequest: IFileRequests) => {
            dispatch({
                type: 'GOT_A_TEMP_FILEREQUEST',
                result: fileRequest
            });
            dispatch({
                type: 'GOT_A_MATCHING_FILEREQUEST',
                result: fileRequest
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        }).catch((err: any) => {
            dispatch({
                type: 'LOADING',
                loading: false
            });
        });
    },
    getAFileRequest: (ticketId: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        FileRequestService.GetTicketFileRequest(ticketId).then((fileRequest: IFileRequests) => {
            dispatch({
                type: 'GOT_A_FILEREQUEST',
                result: fileRequest
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        }).catch(() => {
            dispatch({
                type: 'GOT_A_FILEREQUEST',
                result: new FileRequests()
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        });
    },
     createTempFileRequest: (ticket: ITicket): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        FileRequestService.CreateTempFileRequest(ticket.id).then((fileRequest: IFileRequests) => {
            dispatch({
                type: 'GOT_A_TEMP_FILEREQUEST',
                result: JSON.parse(JSON.stringify(fileRequest))
            });
            dispatch({
                type: 'GOT_A_MATCHING_FILEREQUEST',
                result: JSON.parse(JSON.stringify(fileRequest))
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        }).catch(() => {
            dispatch({
                type: 'LOADING',
                loading: false
            });
        });

    },
    updateFileRequestMatches: (ticketId: string, matchCollection: IFileRequestMatchCollecton): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        FileRequestService.UpdateFileRequestMatches(ticketId, matchCollection).then((fileRequest: IFileRequests)=> {
            dispatch({
                type: 'GOT_A_MATCHING_FILEREQUEST',
                result: JSON.parse(JSON.stringify(fileRequest))
            });
            dispatch({
                type: 'GOT_A_TEMP_FILEREQUEST',
                result: JSON.parse(JSON.stringify(fileRequest))
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        }).catch(() => {
            dispatch({
                type: 'LOADING',
                loading: false
            });
        });
    },
    updateFileRequestRowMatch: (ticketId: string, fileRequestRowId:string, unmatchedFileId:string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        FileRequestService.UpdateFileRequestRowMatch(ticketId, fileRequestRowId,unmatchedFileId).then((fileRequest: IFileRequests)=> {
            dispatch({
                type: 'GOT_A_MATCHING_FILEREQUEST',
                result: JSON.parse(JSON.stringify(fileRequest))
            });
            dispatch({
                type: 'GOT_A_TEMP_FILEREQUEST',
                result: JSON.parse(JSON.stringify(fileRequest))
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });
        }).catch(() => {
            dispatch({
                type: 'LOADING',
                loading: false
            });
        });
    },
    updateFileRequestState: (ticketId: string, state: FileRequestStateEnum): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'LOADING',
            loading: true
        });
        FileRequestService.UpdateFileRequestState(ticketId, state).then((fileRequest: IFileRequests) => {
            dispatch({
                type: 'GOT_A_FILEREQUEST',
                result: JSON.parse(JSON.stringify(fileRequest))
            });

            switch (state) {
                case FileRequestStateEnum.Draft:
                    ToastFactory.createSuccessToast(i18n.t('ToastBar.UpdateFileRequest.Draft.Success.Subject'), "", 5000);
                    break;
                case FileRequestStateEnum.FileRequest:
                    ToastFactory.createSuccessToast(i18n.t('ToastBar.UpdateFileRequest.FileRequest.Success.Subject'), "", 5000);
                    break;
                default:
                    break;
            }
        }).catch((err) => {
            dispatch({
                type: 'GOT_A_FILEREQUEST',
                result: new FileRequests()
            });
            dispatch({
                type: 'LOADING',
                loading: false
            });

            ToastFactory.createAlertToast(i18n.t('ToastBar.UpdateFileRequest.Failure.Subject'), err, 5000);
        });
    },
    removeFileRequest: (ticketId: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        FileRequestService.RemoveUnusedFileRequestFiles(ticketId).then(()=> {
        });
    },
    setMatchingFileRequest: (fileRequest: IFileRequests): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'GOT_A_MATCHING_FILEREQUEST',
            result: fileRequest
        });
    },
    updateTempFileRequest: (fileRequest: IFileRequests): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'GOT_A_TEMP_FILEREQUEST',
            result: fileRequest
        });
    },
    matchSelectedFileRequest: (fileId: string, file: IFileAttachmentBase): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        if (file) {
            const fileRequestId = fileId;

            const matchingFileRequest: IFileRequests = JSON.parse(JSON.stringify(getState().fileRequest.matchingFileRequest));

            if (fileRequestId === 'match') {
                if (!matchingFileRequest!.unmatchedFiles.find(x => x.id === file.id)) {
                    matchingFileRequest!.unmatchedFiles.push(file);
                }
                if (matchingFileRequest!.fileRequests.find(x => x.uploadedFile?.id === file.id)) {
                    matchingFileRequest!.fileRequests.find(x => x.uploadedFile?.id === file.id)!.uploadedFile = undefined;
                }
            } else {
                if (matchingFileRequest!.fileRequests.find(x => x.uploadedFile?.id === file.id)) {
                    matchingFileRequest!.fileRequests.find(x => x.uploadedFile?.id === file.id)!.uploadedFile = undefined;
                }
                matchingFileRequest!.fileRequests.find(x => x.id === fileRequestId)!.uploadedFile = file
                matchingFileRequest!.unmatchedFiles = matchingFileRequest!.unmatchedFiles.filter(x => x.id !== file.id);
            }

            dispatch({
                type: 'GOT_A_MATCHING_FILEREQUEST',
                result: matchingFileRequest
            });
        }
    },
    cancelCurrentMatches: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        const tempFileRequest: IFileRequests = JSON.parse(JSON.stringify(getState().fileRequest.tempFileRequest));
        dispatch({
            type: 'GOT_A_MATCHING_FILEREQUEST',
            result: tempFileRequest
        });
    }
};
