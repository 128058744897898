import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket } from '../../../../Interface/Interfaces';
import { TypeOfTicket, TicketStatus, FiscalYearEnum, DeliveredStatusEnum } from '../../../../Interface/Enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { Row, Col, Button, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap';
import Cardwrapper from '../../../CommonComponents/Containers/Cardwrapper';
import { history } from '../../../../store/configureStore';
import { TicketVariant } from '../../../../Utils/TicketsHelper';
import { UserVertical } from '@kpmgportals/genericcomponents';
import { USER_IMAGE_CDN } from '../../../../Utils/config';
import ContactInformationService from '../../../../apiServices/ContactInformationService';

export interface ITicketProps {
    ticket: ITicket;
}

export default class TicketCard extends React.Component<ITicketProps, {}> {
    static displayName = 'TicketCard';

    public openTicket = () => {
        if (this.props.ticket.status === TicketStatus.Draft) {
            history.push({ pathname: '/tickets/new', state: { "ticketDraftId": this.props.ticket.id } });
        } else {
            history.push(`/tickets/${this.props.ticket.refNumber}`);
        }
    }

    public render() {

        const creatorPicture = this.props.ticket.authorPicture || '';

        return (
            <Translation>{
                (t) => {
                    return (
                        <Col lg="6" className="grid-margin-bottom">
                            <Cardwrapper variant={TicketVariant(this.props.ticket.status)} className="h-100 flex-column d-flex">
                                <Row className="flex-grow-1 flex-shrink-1">
                                    <Col className="d-flex flex-column">
                                        <Row className="flex-grow-1 flex-shrink-1">
                                            <Col className="d-flex justify-content-between flex-column">
                                                <Row>
                                                    <Col>
                                                        <Row className="align-items-center justify-content-between">
                                                            <Col xs={12} lg={8}>
                                                                <h2>
                                                                    {this.props.ticket.customer != null &&
                                                                        this.props.ticket.customer.name
                                                                    }
                                                                    {this.props.ticket.confidential &&
                                                                        <OverlayTrigger
                                                                            overlay={
                                                                                <Tooltip id={`${this.props.ticket.refNumber}-tooltip`}>
                                                                                    {t('tickets:Status.Confidential')}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon icon={faEyeSlash} size="xs" className="ml-3" />
                                                                        </OverlayTrigger>
                                                                    }
                                                                </h2>
                                                            </Col>
                                                            <Col xs="auto">
                                                                {this.props.ticket.signalRPushed && this.props.ticket.historyCount !== undefined && this.props.ticket.historyCount === 1 &&
                                                                    <Badge variant="primary">{t('tickets:NewTicket.Title')}</Badge>
                                                                }
                                                                {this.props.ticket.signalRPushed && this.props.ticket.historyCount !== undefined && this.props.ticket.historyCount > 1 &&
                                                                    <Badge variant="warning">{t('tickets:Status.Updated')}</Badge>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Row>
                                                                    <Col>
                                                                        <h3>{this.props.ticket.title}</h3>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <p className="mb-0">
                                                                            {this.props.ticket.shortDescription.length > 150 ? this.props.ticket.shortDescription.slice(0, 250).concat(' ...') : this.props.ticket.shortDescription}
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <hr className="w-100" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={12} className="mb-3 mb-md-0">
                                                {this.props.ticket.customer != null &&
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <small>{t('tickets:Ticket.Customer')}</small>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {`${this.props.ticket.customer.name || ''} ${this.props.ticket.customer.organizationalNumber || ''}, ${this.props.ticket.customer.customerNumber || ''}`}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={6} xl={4} className="text-truncate">
                                                <Row>
                                                    <Col>
                                                        <small>{t('tickets:Ticket.TicketID')}</small>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.props.ticket.refNumber}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={6} xl={4} className="text-truncate">
                                                <Row>
                                                    <Col>
                                                        <small>{t('tickets:Ticket.Tickettype')}</small>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {t('tickets:TicketTypes.' + TypeOfTicket[this.props.ticket.typeOfTicket])}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={6} xl={4}>
                                                <Row>
                                                    <Col>
                                                        <small>{t('tickets:NewTicket.FiscalYear')}</small>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {t('tickets:Ticket.' + FiscalYearEnum[this.props.ticket.fiscalYear])}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={6} xl={4} className="mb-3 mb-xl-0">
                                                <Row>
                                                    <Col>
                                                        <small>{t('tickets:Ticket.Status')}</small>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {((this.props.ticket.typeOfTicket === TypeOfTicket.PreparedByClient || this.props.ticket.typeOfTicket === TypeOfTicket.PreparedByPartner) &&
                                                            this.props.ticket.fileRequestDeliveredStatus !== DeliveredStatusEnum.NotDelivered) ? (
                                                                <>
                                                                    <Badge variant="warning">
                                                                        <span>{t('tickets:Status.' + TicketStatus[this.props.ticket.status])}</span>
                                                                    </Badge>
                                                                    <Badge className="ml-1" variant={this.props.ticket.fileRequestDeliveredStatus === DeliveredStatusEnum.FullyDelivered ? 'success' : 'warning'}>
                                                                        <span>{t('tickets:Filerequest.DeliverStatus.' + DeliveredStatusEnum[this.props.ticket.fileRequestDeliveredStatus!])}</span>
                                                                    </Badge>
                                                                </>
                                                            ) : (
                                                                (
                                                                    TicketStatus[this.props.ticket.status] === 'Resolved' ? (
                                                                        t('tickets:Status.' + TicketStatus[this.props.ticket.status])
                                                                    ) :
                                                                        (
                                                                            <Badge variant="warning">
                                                                                {t('tickets:Status.' + TicketStatus[this.props.ticket.status])}
                                                                            </Badge>
                                                                        )
                                                                )
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={6} xl={4} className="mb-3 mb-xl-0">
                                                <Row>
                                                    <Col>
                                                        <small>{t('tickets:Files.Attached')}</small>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {this.props.ticket.attachedFiles?.length ? t('common:Statement.Yes') : t('common:Statement.No')}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={12} md={6} xl={4} className="mb-3 mb-xl-0">
                                                <Row>
                                                    <Col>
                                                        <small>{t('tickets:Ticket.Created')}</small>
                                                    </Col>
                                                </Row>
                                                <Row noGutters className="align-items-center">
                                                    <UserVertical
                                                        user={{
                                                            pictureUrl: creatorPicture,
                                                            name: this.props.ticket.authorText,
                                                            firstName: "",
                                                            lastName: "",
                                                            email: "",
                                                            id: this.props.ticket.author,
                                                            phone: "",
                                                            title: ""
                                                        }}
                                                        dateInfo={this.props.ticket.created}
                                                        USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                        ContactInformationService={ContactInformationService}
                                                    />
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={6} xl={4} className="mb-3 mb-xl-0">
                                                <Row>
                                                    <Col>
                                                        <small>{t('tickets:Ticket.Changed')}</small>
                                                    </Col>
                                                </Row>
                                                <Row noGutters className="align-items-center">
                                                    {this.props.ticket.historyCount && this.props.ticket.historyCount > 1 &&
                                                        <UserVertical
                                                            user={{
                                                                pictureUrl: this.props.ticket.editedByPicture || '',
                                                                name: this.props.ticket.editedByText,
                                                                firstName: "",
                                                                lastName: "",
                                                                email: "",
                                                                id: this.props.ticket.editedBy,
                                                                phone: "",
                                                                title: ""
                                                            }}
                                                            dateInfo={this.props.ticket.edited}
                                                            noImage
                                                            USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                            ContactInformationService={ContactInformationService}
                                                        />
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr className="w-100" />
                                    </Col>
                                </Row>
                                <Row className="justify-content-end">
                                    <Col xs="auto">
                                        <Button
                                            onClick={this.openTicket}
                                        >
                                            {t('tickets:Ticket.Open')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Cardwrapper>
                        </Col>
                    );
                }
            }
            </Translation>
        );
    }
}
