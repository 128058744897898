import * as React from 'react';
import { Translation } from 'react-i18next';
import GenericFilePond from '../../CommonComponents/GenericFilePond';
import { ITicket, ITicketAttachments, IFileAttachmentBase } from '../../../Interface/Interfaces';
import { TicketStatus } from '../../../Interface/Enum';
import AttachedFiles from './AttachedFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { Col, Row, Button, Spinner } from 'react-bootstrap';
import ButtonCancel from '../../CommonComponents/Buttons/ButtonCancel';
import { API_ENDPOINT } from '../../../../src/Utils/config';
import { PortalsTextEditor } from '@kpmgportals/portalstexteditor';
import i18n from '../../translationcomponent/i18n';
import { ToastFactory } from '../../../Utils/ToastFactory';

interface IAddCommentProps {
    postComment: (value: string) => void;
    isCommentSaved: boolean;
    loading: boolean;
    comments: any[];
    customerId: string;
    ticketId: string;
    getFileCount: (fileCount: number) => void;
    filesWhereSaved: (success: boolean) => void;
    ticket: ITicket;
    attachedFiles: ITicketAttachments[];
    attachedFileAdded: (file: IFileAttachmentBase) => void;
    removeNewCommentFile?: (file: ITicketAttachments) => void;
    downloadFiles: (e: any, comment: any, originalFileName: string) => void;
    fileCount: number;
    addCommentIsDirty: (isAddCommentDirty: boolean) => void;
    cancelComment: () => void;
}

interface IAddCommentState {
    tinyMCEText: string;
    clearFiles: boolean;
}

export default class AddComment extends React.Component<IAddCommentProps, IAddCommentState> {
    static displayName = 'AddComment';

    constructor(props: IAddCommentProps) {
        super(props);
        this.state = { tinyMCEText: "", clearFiles: false };
    }


    public componentDidUpdate(prevProps: IAddCommentProps, prevState: IAddCommentState) {
        if (this.props.isCommentSaved && (prevProps.isCommentSaved !== this.props.isCommentSaved)) {
            this.setState({ tinyMCEText: "" });
        }

        if (prevState.tinyMCEText === "" && this.state.tinyMCEText !== "") {
            this.props.addCommentIsDirty(true);
        }

        if (prevState.tinyMCEText !== "" && this.state.tinyMCEText === "") {
            this.props.addCommentIsDirty(false);
        }
    }

    private uploadFileError = async (error: any) => {
        try {
            if (error.includes('ASCII'))
                error = i18n.t('tickets:Files.AsciiFileErrorMsg');
            ToastFactory.createAlertToast(i18n.t('tickets:Files.FileUploadError'), error);
        } catch (e) { }
    }

    public render() {
        return (
            <Translation>
                {
                    (t) => {
                        return (
                            this.props.ticket.ticketStatus !== TicketStatus.Resolved &&
                            <Row className="position-relative" id="add-comment-button-section">
                                <Col>
                                    {this.props.loading &&
                                        <Row className="justify-content-center loading-cover cover-white justify-content-center align-items-center">
                                            <Col xs="auto">
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col>
                                            <Row className="mb-3">
                                                <Col>
                                                    <label>{t('tickets:Ticket.AddCommentPlaceHolder')}<sup className="required">*</sup></label>
                                                    <div>
                                                        <PortalsTextEditor
                                                            value={this.state.tinyMCEText}
                                                            id="CommentJodit"
                                                            onChange={(newContent: string) => {
                                                                this.setState({ tinyMCEText: newContent })
                                                            }}
                                                            height="auto"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {this.props.attachedFiles.length > 0 &&
                                                        <AttachedFiles
                                                            removeNewTicketFile={this.props.removeNewCommentFile}
                                                            attachedFiles={this.props.attachedFiles}
                                                            newTicket
                                                            downloadFiles={this.props.downloadFiles}
                                                            comment="description"
                                                            downloadAllId="add-comment"
                                                        />
                                                    }
                                                    <GenericFilePond
                                                        uploadUrl={`${API_ENDPOINT}/api/v2/customers/${this.props.customerId}/tickets/${this.props.ticketId}/comments/files`}
                                                        customerId="1"
                                                        removeFiles={this.state.clearFiles}
                                                        filesWhereSaved={this.props.filesWhereSaved}
                                                        getFileCount={this.props.getFileCount}
                                                        saved={this.props.isCommentSaved}
                                                        fileSizeLimit={50}
                                                        disableFilePond={this.props.ticket.ticketStatus === TicketStatus.Resolved ? true : false}
                                                        fileAdded={this.props.attachedFileAdded}
                                                        onError={this.uploadFileError}
                                                    />
                                                    <Row className="justify-content-end">
                                                        <Col xs="auto">
                                                            <ButtonCancel
                                                                onClick={() => {
                                                                    this.setState({ clearFiles: true, tinyMCEText: '' }, () => this.setState({ clearFiles: false }));
                                                                    this.props.cancelComment();
                                                                }}
                                                            >
                                                                {t('common:Action.Cancel')}
                                                            </ButtonCancel>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Button
                                                                onClick={() => this.props.postComment(this.state.tinyMCEText)}
                                                                disabled={this.props.loading || this.state.tinyMCEText.isEmptyOrWhiteSpace() || this.props.fileCount! > 0}
                                                            >
                                                                {t('tickets:Ticket.AddComment')} <FontAwesomeIcon icon={faPaperPlane} className="ml-3" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        );
                    }
                }
            </Translation>
        );
    }
}
