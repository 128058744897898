import { IFileRequest, IFileAttachmentBase } from "../Interface/Interfaces";
import { FileRequestStatusEnum } from "src/Interface/Enum";


export class FileRequest implements IFileRequest {
    public id: string = "";
    public title: string = "";
    public request: string = "";
    public description: string = "";
    public category: string = "";
    public dateAdded: string = "";
    public dateMatched: string = "";
    public templateFile: IFileAttachmentBase;
    public uploadedFile: IFileAttachmentBase;
    public status: FileRequestStatusEnum;
    public isRecentlyAdded: boolean = false;
    public comment: string = "";
    public rejectedFile: string = "";
}