import { TicketKnownAction, ITicketState } from '../Ticket';
import { Ticket } from '../../classes/Ticket';

export function TicketReducer(state: ITicketState, action: TicketKnownAction) {
    switch (action.type) {
        case 'GOT_A_TICKET':
            return { ...state, activeTicket: action.result };
        case 'CLEAR_A_TICKET':
            return { ...state, activeTicket: new Ticket() };
        case 'GOT_A_TEAM_MEMBERS':
            return { ...state, teamMembers: action.teamMembers };
        case 'DENIED_A_TICKET':
            return { ...state, activeTicket: new Ticket(), accessDenied: true };
        case 'LOADING':
            return { ...state, isLoading: action.loading };
        case 'COMMENT_SAVED':
            return { ...state, isCommentSaved: action.isSaved };
        case 'SET_IS_NEWLY_CREATED':
            return { ...state, isNewlyCreated: action.isNewlyCreated };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return state || { activeTicket: new Ticket(), teamMembers: [], accessDenied: false, isLoading: false, isCommentSaved: false, isNewlyCreated: false };
}
