import { ITicket, ICustomerUser } from "../Interface/Interfaces";

export default class TicketHelper {
    public static SetRecipient(ticket: ITicket, recipient: ICustomerUser) {
        ticket.recipientEmail = !!recipient ? recipient!.email : "";
        ticket.recipientId = !!recipient ? recipient!.id : "";
        ticket.recipientPicture = !!recipient ? recipient!.pictureUrl : "";
        ticket.recipientText = !!recipient ? recipient!.firstName + " " + recipient!.lastName : "";
        return ticket;
    }
}
