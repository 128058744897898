import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket } from '../../../../Interface/Interfaces';
import { Row, Col, Spinner } from 'react-bootstrap';
import TicketCard from "./TicketCard";

export interface ITicketCardsProps {
    tickets: ITicket[];
    isLoading: boolean;
}

export default class TicketCards extends React.Component<ITicketCardsProps, {}> {
    static displayName = 'TicketCards';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            <Row>
                                {
                                    this.props.tickets.map((ticket: ITicket) => {
                                        return <TicketCard ticket={ticket} key={ticket.id} />
                                    })
                                }
                            </Row>
                            {this.props.isLoading &&
                                <Row className="justify-content-center">
                                    <Col xs="auto">
                                        <Spinner animation="border" role="status" />
                                    </Col>
                                </Row>
                            }
                        </>
                    );
                }
            }
            </Translation>
        );
    }
}
