import * as React from 'react';
import { Row, RowProps } from 'react-bootstrap';
import classNames from 'classnames';

export interface ITableRowProps extends RowProps {
    className?: string;
    onClick?: () => void;
    thead?: boolean;
    noHover?: boolean;
    variant?: 'default' | 'development' | 'success' | 'warning' | 'danger' | 'secondary';
}

export default class TableRow extends React.Component<ITableRowProps> {
    static displayName = 'TableRow';

    public static defaultProps = {
        variant: "default"
    };
    public classes(className?: string, thead?: boolean, noHover?: boolean) {
        const classes = classNames(
            className,
            thead ? 'thead' : 'tr',
            `state-${this.props.variant}-block`,
            noHover ? '' : 'has-hover',
        );
        return classes;
    }
    public render() {
        const {
            className,
            thead,
            noHover,
            onClick,
            variant,
            ...props
        } = this.props;
        return (
            <Row
                onClick={onClick}
                className={this.classes(className, thead, noHover)}
                {...props}
            >
                {this.props.children}
            </Row>
        );
    }
}
