import { Reducer } from 'redux';
import { IFileRequests, IFileRequest } from '../Interface/Interfaces';
import { FileRequestReducer } from './reducers/FileRequestReducer';
import { FileRequestActions } from './actions/FileRequestActions';

export interface IFileRequestState {
    fileRequest: IFileRequests;
    tempFileRequest: IFileRequests;
    matchingFileRequest: IFileRequests;
    loading: ILoading;
    selectedFileRequestRow: IFileRequest;
}

interface ILoading { type: 'LOADING'; loading: boolean; }
interface IGetAFileRequest { type: 'GOT_A_FILEREQUEST'; result: IFileRequests; }
interface IGetATempFileRequest { type: 'GOT_A_TEMP_FILEREQUEST'; result: IFileRequests; }
interface IGetAMatchingFileRequest { type: 'GOT_A_MATCHING_FILEREQUEST'; result: IFileRequests; }
interface IGetAFileRequestRow { type: 'GOT_A_FILEREQUEST_ROW'; result: IFileRequest; }

export type KnownAction = ILoading | IGetAFileRequest
    | IGetATempFileRequest | IGetAMatchingFileRequest
    | IGetAFileRequestRow;

export const actionCreators = FileRequestActions;

export const reducer: Reducer<IFileRequestState> = (state: IFileRequestState, action: KnownAction) => FileRequestReducer(state, action);
