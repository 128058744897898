import { ITicket } from "../Interface/Interfaces";
import { TicketStatus } from "../Interface/Enum";

export function sortMyTickets(myTickets: ITicket[], newTicket: ITicket) {
    let index = 4;
    let updatedMyTickets: any[] = myTickets.map((ticket: ITicket, idx) => {
        if (ticket.id === newTicket.id) {
            index = idx;
        }
        return ticket;
    });
    newTicket.signalRPushed = true;
    if (index === 4 && updatedMyTickets.length === 3) {
        updatedMyTickets.pop();
        updatedMyTickets.unshift(newTicket);
    } else {
        updatedMyTickets[index] = newTicket;
        updatedMyTickets = move(updatedMyTickets, index, 0);
    }
    return updatedMyTickets;
}

function move(arr: any[], oldIndex: number, newIndex: number) {
    while (oldIndex < 0) {
        oldIndex += arr.length;
    }
    while (newIndex < 0) {
        newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
}

export function ticketsUpdateCheck(newTicket: ITicket, tickets: ITicket[]) {
    const ticketFilter = tickets.filter((ticket) => ticket.id === newTicket.id);
    if (ticketFilter.length > 0) {
        // ticket exists change it
        const splicedTickets = tickets.filter((ticket) => ticket.id !== ticketFilter[0].id);
        splicedTickets.splice(0, 0, newTicket);
        return splicedTickets;
    } else {
        // ticket does not exist add it
        tickets.splice(0, 0, newTicket);
        return tickets;
    }
}

export function TicketVariant(ticketState: TicketStatus) {
    if (TicketStatus[ticketState] === 'Resolved') {
        return 'success';
    }
    if (TicketStatus[ticketState] === 'Draft') {
        return 'warning';
    }
    return 'default'
}

