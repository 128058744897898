import { Reducer } from 'redux';
import { TicketActions } from './actions/TicketActions';
import { TicketReducer } from './reducers/TicketReducers';
import { ITicket, ICustomerUser } from '../Interface/Interfaces';

export interface ITicketState {
    activeTicket: ITicket;
    teamMembers: ICustomerUser[];
    accessDenied: boolean;
    isLoading: boolean;
    isCommentSaved: boolean;
    isNewlyCreated: boolean;
}

interface IClearATicket { type: 'CLEAR_A_TICKET'; }
interface IGetATicketDenied { type: 'DENIED_A_TICKET'; }
interface ILoading { type: 'LOADING'; loading: boolean; }
interface IGetATicket { type: 'GOT_A_TICKET'; result: ITicket; }
interface ICommentSaved { type: 'COMMENT_SAVED'; isSaved: boolean; }
interface IGetTeamMembers { type: 'GOT_A_TEAM_MEMBERS'; teamMembers: ICustomerUser[]; }
interface ISetIsNewlyCreatedT { type: 'SET_IS_NEWLY_CREATED'; isNewlyCreated: boolean; }

export type TicketKnownAction = IGetATicket | IClearATicket | IGetTeamMembers | IGetATicketDenied | ILoading | ICommentSaved | ISetIsNewlyCreatedT;

export const actionCreators = TicketActions;

export const reducer: Reducer<ITicketState> = (state: ITicketState, action: TicketKnownAction) => TicketReducer(state, action);
