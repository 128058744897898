import * as React from 'react';

export interface IGenericSaveCancelProps{
    divClassName: string;
    cancelClassName: string;
    saveClassName: string;
    saveCallback: () => void;
    cancelCallBack: () => void;
    saveButtonText: string;
    cancelButtonText: string;
    disabled: boolean;
}

export default class GenericSaveCancel extends React.Component<IGenericSaveCancelProps, {}> {
    static displayName = 'GenericSaveCancel';

    public render() {
        return (
            <div className={this.props.divClassName}>
                <button disabled={this.props.disabled} className={this.props.cancelClassName} onClick={this.props.cancelCallBack}>{this.props.cancelButtonText}</button>
                <button disabled={this.props.disabled} className={this.props.saveClassName} onClick={this.props.saveCallback}>{this.props.saveButtonText}</button>
            </div>
        );
    }
}
