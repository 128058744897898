import { format, addDays, isValid, subMonths, startOfMonth, endOfMonth, isAfter, subYears, differenceInDays, setMonth, addYears } from "date-fns";
import { MonthCount, TimeRulesConstants } from "../constants/constants";

export default class DateTimeHelper {
    public static FormatYearMonthDay(date: Date | string = new Date()) {
        const realDate = new Date(date);
        const formatedDate = format(realDate, 'yyyy-MM-dd');
        return formatedDate;
    }

    public static FormatYearMonthDayWithTime(date: Date | string = new Date()) {
        const realDate = new Date(date);
        const formatedDate = format(realDate, 'yyyy-MM-dd HH:mm');
        return formatedDate;
    }

    public static FormatYearMonthDayNoSeperator(date: Date | string = new Date()) {
        const realDate = new Date(date);
        const formatedDate = format(realDate, 'yyyyMMdd');
        return formatedDate;
    }

    public static FormatYearMonthDayWithTimeForFileNames(date: Date | string = new Date()) {
        const realDate = new Date(date);
        const formatedDate = format(realDate, 'yyyyMMdd-HH:mm');
        return formatedDate;
    }

    public static FormatYear(date: Date | string = new Date()) {
        const realDate = new Date(date);
        const formatedDate = format(realDate, 'yyyy');
        return formatedDate;
    }

    public static FormatMonthNumber(date: Date | string) {
        const realDate = new Date(date);
        const month = format(realDate, 'M');
        return month;
    }

    public static FormatFinancialYearEnd(date: Date | string) {
        const realDate = new Date(date);
        const formatedDate = format(realDate, 'M') === MonthCount.February
            ? `28 ${format(realDate, 'MMM')}`
            : format(realDate, 'dd MMM');
        return formatedDate;
    }

    public static FormatFinancialYearFromMonth(month: number) {
        const now = new Date();
        const lastMonth = setMonth(now, month - 1);
        const startOfLastMonth = format(startOfMonth(lastMonth), 'd MMMM');
        return startOfLastMonth;
    }

    public static FormatFinancialYearToMonth(month: number) {
        const now = new Date();
        const lastMonth = setMonth(now, month - 1);
        const startOfLastMonth = format(endOfMonth(lastMonth), 'd MMMM');
        return startOfLastMonth;
    }

    public static FormatFullMonthText(date: Date | string) {
        const realDate = new Date(date);
        const monthName = format(realDate, 'MMMM');
        return monthName;
    }

    public static AddDays(date: Date, dayCount: number) {
        const realDate = new Date(date);
        const newDate = addDays(realDate, dayCount);
        return newDate;
    }

    public static AddYears(date: Date | string, yearCount: number) {
        const realDate = new Date(date);
        const newDate = addYears(realDate, yearCount);
        return newDate;
    }

    public static SubTractMonths(date: Date | string, monthCount: number) {
        const realDate = new Date(date);
        const newDate = subMonths(realDate, monthCount);
        return newDate;
    }

    public static SubtractYears(date: Date | string, yearCount: number) {
        const realDate = new Date(date);
        const newDate = subYears(realDate, yearCount);
        return newDate;
    }

    public static isValid(date: Date | string) {
        if (date === null || date === '' || date === undefined) {
            return false;
        }
        const realDate = new Date(date);
        const valid = isValid(realDate);
        return valid;
    }

    public static isAfter(date: Date | string, dateToCompare: Date | string) {
        date = new Date(date);
        dateToCompare = new Date(dateToCompare);
        const isItAfter = isAfter(date, dateToCompare);
        return isItAfter;
    }

    public static HowManyDaysLeftThirtyDayCountdown(date: Date | string) {
        const realDate = new Date(date);
        const now = new Date();
        const diffrenceInDays = differenceInDays(now, realDate);
        const daysleft = TimeRulesConstants.FileDeletedInDays - diffrenceInDays;
        return daysleft;
    }

    public static FormatFinancialYearPeriodLabel(date: Date | string) {
        const realDate = new Date(date);
        const startDateString = format(startOfMonth(realDate), 'dd MMM');
        const endDateString = format(endOfMonth(DateTimeHelper.SubTractMonths(realDate, 1)), 'M') === MonthCount.February
            ? `28 ${format(endOfMonth(DateTimeHelper.SubTractMonths(realDate, 1)), 'MMM')}`
            : format(endOfMonth(DateTimeHelper.SubTractMonths(realDate, 1)), 'dd MMM');
        return `${startDateString} - ${endDateString}`;
    }

    public static DiffrenceInDays(theLaterDate: Date | string, theEarlierDate: Date | string) {
        const dateLeft = new Date(theLaterDate);
        const dateRight = new Date(theEarlierDate);
        const dayCount = differenceInDays(dateLeft, dateRight);
        return dayCount;
    }
}
