import { IAppThunkAction } from '../';
import { TicketsKnownAction } from '../Tickets';
import TicketsService from '../../apiServices/TicketsService';
import { TicketStatus } from '../../Interface/Enum';
import { getToken } from '../../apiServices/UserService';
import * as signalR from '@microsoft/signalr';
import Logger from '../../Utils/Logger';
import { ITicket } from '../../Interface/Interfaces';

export const TicketsActions = {
    connectTicket: (): IAppThunkAction<TicketsKnownAction> => (dispatch, getState) => {
        const connectionTickets = new signalR.HubConnectionBuilder()
            .withUrl("/ticket", { accessTokenFactory: () => getToken() })
            .configureLogging((window as any).KPMGDEBUG ? signalR.LogLevel.Trace : signalR.LogLevel.None)
            .build();
        connectionTickets.start()
            .catch((err) => {
                Logger.log("error connecting to ticketSignalR");
                Logger.error(err);
            }).then(() => {
                dispatch({
                    type: 'TICKETS_CONNECTED',
                    connection: connectionTickets
                });
                TicketsService.GetMyTickets().then((tickets: any) => {
                    dispatch({
                        type: 'TICKET_GETMY',
                        myTickets: tickets.results
                    });
                });
            });
        connectionTickets.onclose(() => dispatch({
            type: 'TICKETS_DISCONNECTED'
        }));
        connectionTickets.on("sendticket", (userId: string, ticketId: string) => {
            Logger.log('TicketId: ' + ticketId + " is updated");
            const tickets = getState().tickets.tickets.results;
            const myDropDownCustomer = getState().customer.myDropDownCustomer;
            // if (state.currentTicket == null || state.currentTicket.id !== ticketId) {
            dispatch({
                type: 'TICKETS_NOTIFICATIONS_UPDATES'
            });
            // }
            TicketsService.GetMyTicket(ticketId).then((ticket: any) => {
                if (ticket.results.length > 0) {
                    ticket = ticket.results[0];
                }
                // Updating my tickets on Home page
                dispatch({
                    type: 'TICKET_UPDATEMY',
                    newTicket: ticket
                });
                if (ticket.customer.id === myDropDownCustomer.id || myDropDownCustomer.id === "") {
                    dispatch({
                        type: 'TICKETS_UPDATES',
                        newTicket: ticket,
                        tickets: tickets
                    });
                }
                // Updating Tickets on Tickets page if incoming ticket is of same customer as filter.
            });
        });
        connectionTickets.on("sendticketcustomeruser", (ticketId: string) => {
            // Connection for group
            Logger.log('TicketId: ' + ticketId + " is updated");
            const tickets = getState().tickets.tickets.results;
            const myDropDownCustomer = getState().customer.myDropDownCustomer;
            // if (state.currentTicket == null || state.currentTicket.id !== ticketId) {
            dispatch({
                type: 'TICKETS_NOTIFICATIONS_UPDATES'
            });
            // }
            TicketsService.GetMyTicket(ticketId).then((ticket: any) => {
                if (ticket.results.length > 0) {
                    ticket = ticket.results[0];
                }
                // Updating my tickets on Home page
                dispatch({
                    type: 'TICKET_UPDATEMY',
                    newTicket: ticket
                });
                if (ticket.customer.id === myDropDownCustomer.id || myDropDownCustomer.id === "") {
                    dispatch({
                        type: 'TICKETS_UPDATES',
                        newTicket: ticket,
                        tickets: tickets
                    });
                }
                // Updating Tickets on Tickets page if incoming ticket is of same customer as filter.
            });
        });
        connectionTickets.on("sendticketconfidentialcustomeruser", (ticketId: string) => {
            // Connection for single user
            Logger.log('TicketId: ' + ticketId + " is updated");
            const tickets = getState().tickets.tickets.results;
            const myDropDownCustomer = getState().customer.myDropDownCustomer;
            // if (state.currentTicket == null || state.currentTicket.id !== ticketId) {
            dispatch({
                type: 'TICKETS_NOTIFICATIONS_UPDATES'
            });
            // }
            TicketsService.GetMyTicket(ticketId).then((ticket: any) => {
                if (ticket.results.length > 0) {
                    ticket = ticket.results[0];
                }
                dispatch({
                    type: 'TICKET_UPDATEMY',
                    newTicket: ticket
                });
                if (ticket.customer.id === myDropDownCustomer.id || myDropDownCustomer.id === "") {
                    dispatch({
                        type: 'TICKETS_UPDATES',
                        newTicket: ticket,
                        tickets: tickets
                    });
                }
            });
        });
    },
    getAllCustomersTickets: (searchText?: string, ticketStatus?: number, resolvedTickets: boolean = false, selectedCustomerId: string = "", confidential: boolean = false, gettingMore: boolean = false, ticketType?: number): IAppThunkAction<TicketsKnownAction> => (dispatch, getState) => {
        const state = getState().tickets;
        const loadingTickets = state.tickets;
        if (!gettingMore) {
            loadingTickets.results = [];
        }
        loadingTickets.loading = true;
        dispatch({
            type: 'GOT_TICKETS',
            results: loadingTickets
        });
        if (selectedCustomerId) {
            TicketsService.GetACustomerTickets(gettingMore ? state.tickets.nextPageToken : null, selectedCustomerId, searchText, resolvedTickets ? TicketStatus.Resolved : ticketStatus, 0, confidential, ticketType).then((tickets) => {
                tickets.loading = false;
                tickets.results = [...loadingTickets.results, ...tickets.results];
                dispatch({
                    type: 'GOT_TICKETS',
                    results: tickets
                });
            });
        } else {
            TicketsService.GetAllCustomersTickets(gettingMore ? state.tickets.nextPageToken : null, searchText, resolvedTickets ? TicketStatus.Resolved : ticketStatus, selectedCustomerId, 0, confidential, ticketType).then((tickets) => {
                tickets.loading = false;
                tickets.results = [...loadingTickets.results, ...tickets.results];
                dispatch({
                    type: 'GOT_TICKETS',
                    results: tickets
                });
            });
        }
    },
    getAllCustomersResolvedTickets: (selectedCustomerId: string = ""): IAppThunkAction<TicketsKnownAction> => (dispatch, getState) => {
        // Add so we can get both all resolved and we can get a single customers resolved tickets. take 6 and make sure to use the same endpoint as the other get request
        dispatch({
            type: 'GOT_RECENTLY_RESOLVED',
            results: []
        });
        if (selectedCustomerId) {
            TicketsService.GetACustomerTickets("", selectedCustomerId ? selectedCustomerId : "", "", TicketStatus.Resolved, 6).then((results) => {
                dispatch({
                    type: 'GOT_RECENTLY_RESOLVED',
                    results: results.results
                });
            });
        } else {
            TicketsService.GetAllCustomersTickets("", "", TicketStatus.Resolved, selectedCustomerId ? selectedCustomerId : "", 6).then((results) => {
                dispatch({
                    type: 'GOT_RECENTLY_RESOLVED',
                    results: results.results
                });
            });
        }
    },
    leftComponentTickets: (): IAppThunkAction<TicketsKnownAction> => (dispatch) => {
        dispatch({
            type: 'GOT_RECENTLY_RESOLVED',
            results: []
        });
        dispatch({
            type: 'GOT_TICKETS',
            results: { hasNextPage: false, loading: true, nextPageToken: "", results: [] }
        });
    },
    getMyTickets: (customerId?: string): IAppThunkAction<TicketsKnownAction> => (dispatch) => {
        TicketsService.GetMyTickets(customerId).then((tickets: any) => {
            dispatch({
                type: 'TICKET_GETMY',
                myTickets: tickets.results
            });
        });
    },
    onCurrentTicket: (currentTicket: ITicket): IAppThunkAction<TicketsKnownAction> => (dispatch) => {
        dispatch({
            type: 'TICKET_ON_CURRENT_TICKET',
            currentTicket: currentTicket
        });
    },
    leaveCurrentTicket: (): IAppThunkAction<TicketsKnownAction> => (dispatch) => {
        dispatch({
            type: 'TICKET_LEAVE_CURRENT_TICKET'
        });
    },
    clearTicketNotifications: (): IAppThunkAction<TicketsKnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'TICKETS_READ'
        });
    }
};
