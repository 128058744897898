import * as React from 'react';
import { Translation } from 'react-i18next';
import { RouteComponentProps, NavLink, Prompt } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import * as TicketStore from '../../store/Ticket';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import TicketActionHeader from './CommonComponents/TicketActionHeader';
import TicketHeader from './CommonComponents/TicketHeader';
import TicketDescription from './CommonComponents/TicketDescription';
import TicketService from '../../apiServices/TicketService';
import Cardwrapper from '../CommonComponents/Containers/Cardwrapper';
import Comments from './CommonComponents/Comments';
import History from './SmallComponents/History';
import { Col, Row, Tabs, Tab, Button, Alert } from 'react-bootstrap';
import { ITicketAttachments, IFileAttachmentBase, IFileRequestMatchCollecton } from '../../Interface/Interfaces';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorBoundary from '../HOC/ErrorBoundary';
import * as FileRequestStore from '../../store/FileRequest';
import TicketFileRequest from './CommonComponents/TicketFileRequest';
import { TicketStatus, TypeOfTicket, FileRequestStateEnum, DeliveredStatusEnum, FileRequestStatusEnum } from '../../Interface/Enum';
import { bindActionCreators } from 'redux';
import { history } from '../../store/configureStore';
import ConfirmModal from "../CommonComponents/Modals/ConfirmModal";
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

type ITicketOpenProps = {
    states: {
        ticket: TicketStore.ITicketState,
        fileRequest: FileRequestStore.IFileRequestState,
    }
    actions: {
        ticket: typeof TicketStore.actionCreators,
        fileRequest: typeof FileRequestStore.actionCreators
    }
};

type ITicketOpenCombinedProps = ITicketOpenProps & RouteComponentProps<{}>;

interface ITicketViewState {
    fileCount: number;
    newAttachedFiles: ITicketAttachments[];
    canSaveFileRequest: boolean;
    canSendFileRequest?: boolean;
    matchFilesModalOpen: boolean;
    refreshTicket: boolean;
    unsavedChanges: boolean,
    uniqueFileRequestCategories: string[];
    ticketIsCancelled: boolean;
    isAddCommentDirty: boolean;
    ticketIsClosed: boolean;
    activeTab: string;
}


class TicketView extends React.Component<ITicketOpenCombinedProps, ITicketViewState> {
    static displayName = 'TicketView';

    constructor(props: any) {
        super(props);
        this.state = {
            fileCount: 0,
            newAttachedFiles: [],
            canSaveFileRequest: false,
            canSendFileRequest: false,
            matchFilesModalOpen: false,
            refreshTicket: false,
            unsavedChanges: false,
            uniqueFileRequestCategories: [],
            ticketIsCancelled: false,
            isAddCommentDirty: false,
            ticketIsClosed: false,
            activeTab: "comments"
        };

    }

    public componentDidMount() {
        this.props.actions.ticket.getATicket(this.props.match.params!["refNumber"]);
    }

    public componentDidUpdate(prevProps: ITicketOpenCombinedProps, prevState: ITicketViewState) {

        if (this.props.states.ticket.activeTicket.id !== "" && this.props.states.ticket.activeTicket.refNumber !== this.props.match.params!["refNumber"]) {
            this.props.actions.ticket.clearTicket();
            for (const file of this.state.newAttachedFiles) {
                this.props.actions.ticket.removeNewCommentFile(file, this.props.states.ticket.activeTicket.customer!.id, this.props.states.ticket.activeTicket.refNumber!);
            }

            this.setState({ newAttachedFiles: [] });
            this.props.actions.ticket.getATicket(this.props.match.params!["refNumber"]);
        }

        //We are loading a file request here if the ticket type is correct
        this.loadFileRequest(prevProps);

        if (this.state.refreshTicket) {
            if (this.props.states.fileRequest.fileRequest && this.props.states.fileRequest.fileRequest.eTag !== prevProps.states.fileRequest.fileRequest.eTag) {
                this.setState({ refreshTicket: false });
                const refNum = this.props.states.ticket.activeTicket.refNumber;
                if (refNum) {
                    this.props.actions.ticket.getATicket(refNum);
                }
            }
        }

        if (this.props.states.fileRequest.fileRequest !== prevProps.states.fileRequest.fileRequest) {
            this.setState({ uniqueFileRequestCategories: [...new Set(this.props.states.fileRequest.fileRequest.fileRequests.map(item => item.category))] });
            this.validateTicketFileRequest();
        }

        if (this.props.states.fileRequest.tempFileRequest !== prevProps.states.fileRequest.tempFileRequest) {
            this.validateTicketFileRequest();
        }

        if (this.props.states.ticket.activeTicket.id && this.props.states.ticket.activeTicket.id !== prevProps.states.ticket.activeTicket.id &&
            (this.props.states.ticket.activeTicket.typeOfTicket === TypeOfTicket.PreparedByPartner)
            && !this.props.states.fileRequest.loading) {
            const { typeOfTicket } = this.props.states.ticket.activeTicket;
            this.setState({ activeTab: (typeOfTicket === TypeOfTicket.PreparedByPartner) ? "filerequests" : "comments" });
        }
    }

    private loadFileRequest(prevProps: ITicketOpenCombinedProps) {
        //We are loading a file request here if the ticket type is correct
        if (this.props.states.ticket.activeTicket && this.props.states.ticket.activeTicket.id !== prevProps.states.ticket.activeTicket.id &&
            (this.props.states.ticket.activeTicket.typeOfTicket === TypeOfTicket.PreparedByClient ||
                this.props.states.ticket.activeTicket.typeOfTicket === TypeOfTicket.PreparedByPartner)) {

            this.props.actions.fileRequest.getAFileRequest(this.props.states.ticket.activeTicket.id);
        }

        if (this.props.states.fileRequest.fileRequest && this.props.states.fileRequest.fileRequest !== prevProps.states.fileRequest.fileRequest) {
            this.validateTicketFileRequest();
        }

        if (this.props.states.fileRequest.fileRequest !== prevProps.states.fileRequest.fileRequest) {
            this.props.actions.fileRequest.createTempFileRequest(this.props.states.ticket.activeTicket);
        }
    }

    public componentWillUnmount() {
        this.props.actions.ticket.setIsNewlyCreatedTicket(false);
        this.props.actions.ticket.clearTicket();
        for (const file of this.state.newAttachedFiles) {
            this.props.actions.ticket.removeNewCommentFile(file, this.props.states.ticket.activeTicket.customer!.id, this.props.states.ticket.activeTicket.refNumber!);
        }
    }

    public validateTicketFileRequest = () => {
        const hasFileRequests = this.props.states.fileRequest.fileRequest !== this.props.states.fileRequest.tempFileRequest
            || this.props.states.fileRequest.tempFileRequest.unmatchedFiles.length;

        if (hasFileRequests
            && this.props.states.ticket.activeTicket.ticketStatus !== TicketStatus.WaitingForCustomer
            && this.props.states.ticket.activeTicket.ticketStatus !== TicketStatus.Resolved) {
            const fileRequestHasUploads = this.props.states.fileRequest.tempFileRequest.fileRequests.find(x => x.uploadedFile !== null && x.status === FileRequestStatusEnum.Matched);

            this.setState({ canSaveFileRequest: this.state.unsavedChanges, canSendFileRequest: !!fileRequestHasUploads ? true : false });
        } else {
            this.setState({ canSaveFileRequest: false, canSendFileRequest: false });
        }
    }

    public getFileCount = (fileCount: number) => {
        this.setState({ fileCount: fileCount });
    }

    public downloadFiles = (e: any, comment: any, originalFileName: string) => {
        if (comment !== "description") {
            let url = `api/v2/customers/${this.props.states.ticket.activeTicket.customer!.id}/tickets/${this.props.states.ticket.activeTicket.refNumber!}/comments/${comment.id}/files?fileId=${e.currentTarget.dataset.value}&downloadAll=${e.currentTarget.dataset.value.includes("all") ? true : false}`;
            TicketService.DownloadFiles(url, this.props.states.ticket.activeTicket.refNumber!, e.currentTarget.dataset.value.includes("all") ? true : false, originalFileName, true, e.currentTarget.dataset.value);
        } else {
            let url = `api/v2/customers/${this.props.states.ticket.activeTicket.customer!.id}/tickets/${this.props.states.ticket.activeTicket.refNumber!}/files?fileId=${e.currentTarget.dataset.value}&downloadAll=${e.currentTarget.dataset.value.includes("all") ? true : false}`;
            TicketService.DownloadFiles(url, this.props.states.ticket.activeTicket.refNumber!, e.currentTarget.dataset.value.includes("all") ? true : false, originalFileName, false, e.currentTarget.dataset.value);
        }
    }

    private openModal = (e: any) => { }

    public saveComment = (value: string) => {
        this.setState({ isAddCommentDirty: false });
        var description = value;
        this.props.actions.ticket.commentAdded(description, this.state.newAttachedFiles, this.state.fileCount);
        this.setState({ newAttachedFiles: [] });
    }

    public addCommentIsDirty = (isAddCommentDirty: boolean) => {
        this.setState({ isAddCommentDirty })
    };

    public commentAttachedFileAdded = (file: IFileAttachmentBase) => {
        const attachedFiles = this.state.newAttachedFiles;
        attachedFiles.push({ ...file, downloadedBy: [] });
        this.setState({ newAttachedFiles: attachedFiles, isAddCommentDirty: true });
    }
    public removeNewCommentFile = (file: ITicketAttachments) => {
        const attachedFiles = this.state.newAttachedFiles;
        var fileToRemoveIdx = attachedFiles.findIndex(x => x.id === file.id);
        attachedFiles.splice(fileToRemoveIdx, 1);
        this.props.actions.ticket.removeNewCommentFile(file, this.props.states.ticket.activeTicket.customer!.id, this.props.states.ticket.activeTicket.refNumber!);
        this.setState({ newAttachedFiles: attachedFiles, isAddCommentDirty: false });
    }

    public matchFiles = (removedIds: string[]) => {
        const fileRequestIds = this.props.states.fileRequest.matchingFileRequest.fileRequests.map(x => ({ fileRequestId: x.id, uploadId: x.uploadedFile?.id }));
        const unmatchedIds = this.props.states.fileRequest.matchingFileRequest.unmatchedFiles.map(x => x.id);
        const postData: IFileRequestMatchCollecton = {
            unmatchedIds: unmatchedIds,
            fileRequestIds: fileRequestIds,
            removedIds
        }

        this.props.actions.fileRequest.updateFileRequestMatches(this.props.states.ticket.activeTicket.id, postData);
        this.setState({ matchFilesModalOpen: false });
        this.validateTicketFileRequest();
        this.setState({ unsavedChanges: true });
    }

    public fileRequestFileAdded = (file: IFileAttachmentBase) => {
        const tempFileRequest = { ...this.props.states.fileRequest.tempFileRequest };
        if (!tempFileRequest.unmatchedFiles) {
            tempFileRequest.unmatchedFiles = [];
        }

        tempFileRequest.unmatchedFiles.push(file);

        const matchingFileRequest = { ...this.props.states.fileRequest.matchingFileRequest }
        if (!matchingFileRequest.unmatchedFiles) {
            matchingFileRequest.unmatchedFiles = [];
        }

        matchingFileRequest.unmatchedFiles.push(file);
        this.props.actions.fileRequest.setMatchingFileRequest(matchingFileRequest);
        this.props.actions.fileRequest.updateTempFileRequest(tempFileRequest);

        this.setState({ unsavedChanges: true });
        this.validateTicketFileRequest();
    }

    public cancelMatch = () => {
        this.setState({ matchFilesModalOpen: false });
        this.props.actions.fileRequest.cancelCurrentMatches();
        this.validateTicketFileRequest();
    }

    public unmatchTempFileRequestRow = (ticketId: string, fileRequestRowId: string) => {
        this.props.actions.fileRequest.unmatchTempFileRequestRow(ticketId, fileRequestRowId);
        this.setState({ unsavedChanges: true });
    }

    public updateFileRequestRowMatch = (ticketId: string, fileRequestRowId: string, unmatchedFileId: string) => {
        this.props.actions.fileRequest.updateFileRequestRowMatch(ticketId, fileRequestRowId, unmatchedFileId);
        this.setState({ unsavedChanges: true });
    }

    public openMatchFilesModal = () => {
        this.setState({ matchFilesModalOpen: true });
    }

    public saveTicket = (isDraft: boolean) => {
        if (isDraft) {
            this.props.actions.fileRequest.updateFileRequestState(this.props.states.ticket.activeTicket.id, FileRequestStateEnum.Draft);
        } else {
            this.setState({ refreshTicket: true });
            this.props.actions.fileRequest.updateFileRequestState(this.props.states.ticket.activeTicket.id, FileRequestStateEnum.FileRequest);
        }

        this.setState({ unsavedChanges: false });
    }

    public cancel = () => {
        if (this.state.isAddCommentDirty) {
            this.checkCommentsTab('ticketIsCancelled');
        }
        else {
            history.push("/tickets");
        }
    }

    public closeConfirmTicket = () => {
        if (this.state.isAddCommentDirty) {
            this.checkCommentsTab('ticketIsClosed');
        }
        else {
            this.props.actions.ticket.closeTicket()
        }
    }

    public cancelComment = () => {
        this.setState({ newAttachedFiles: [] });
    }

    private smoothScrollToComment = (actionKey: 'ticketIsClosed' | 'ticketIsCancelled') => {
        var element = window.document.querySelector('#ticket-description-section');
        setTimeout(() => element?.scrollIntoView({ behavior: 'smooth' }), 0);
        this.setState({ ...this.state, [actionKey]: true });
    }

    private checkCommentsTab(value: 'ticketIsClosed' | 'ticketIsCancelled') {
        if (this.state.activeTab !== "comments") {
            this.setState({ activeTab: "comments" }, () => this.smoothScrollToComment(value));
        } else {
            this.smoothScrollToComment(value);
        }
    }

    public cancelConfirmModal(actionKey: 'ticketIsClosed' | 'ticketIsCancelled') {
        this.setState({ ...this.state, [actionKey]: false });
    }

    public render() {
        return (
            <ErrorBoundary>
                <Translation>{
                    (t) => {
                        if (this.props.states.ticket.accessDenied) {
                            return (
                                <Row>
                                    <Col xs="12" className="mt-5">
                                        <Alert variant="danger">
                                            <Alert.Heading as="h2">
                                                <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="mr-1" />
                                                {t('tickets:Ticket.Denied.Title')}
                                            </Alert.Heading>
                                            <Row>
                                                <Col>
                                                    <p>{t('tickets:Ticket.Denied.Body')}</p>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col xs="auto">
                                                    <Button
                                                        as={NavLink}
                                                        to="/"
                                                        exact
                                                        variant="secondary"
                                                    >
                                                        {t('tickets:Ticket.Denied.Start')}
                                                    </Button>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button
                                                        as={NavLink}
                                                        to="/tickets"
                                                        exact
                                                        variant="primary"
                                                    >
                                                        {t('tickets:Ticket.Denied.All')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Alert>
                                    </Col>
                                </Row>
                            )
                        }
                        else if (this.props.states.ticket.activeTicket.id !== '') {
                            return (
                                <>
                                    <TicketActionHeader
                                        newTicket={false}
                                        ticket={this.props.states.ticket.activeTicket}
                                        closeAction={this.closeConfirmTicket}
                                        reOpenAction={this.props.actions.ticket.reOpenTicket}
                                        canSaveFileRequest={this.state.canSaveFileRequest}
                                        canSendFileRequest={this.state.canSendFileRequest}
                                        postAction={this.saveTicket}
                                        loading={this.props.states.ticket.isLoading}
                                        setIsNewlyCreated={this.props.actions.ticket.setIsNewlyCreatedTicket}
                                        isNewlyCreatedTicket={this.props.states.ticket.isNewlyCreated}
                                        cancel={this.cancel}
                                    />

                                    <TicketHeader
                                        newTicket={false}
                                        ticket={this.props.states.ticket.activeTicket}
                                        fileRequestDeliveredStatus={this.props.states.fileRequest.fileRequest.deliveredStatus !== DeliveredStatusEnum.NotDelivered ? this.props.states.fileRequest.fileRequest.deliveredStatus : undefined}
                                    />
                                    <TicketDescription
                                        ticket={this.props.states.ticket.activeTicket}
                                        newTicket={false}
                                        isTicketCreated={false}
                                        downloadFiles={this.downloadFiles}
                                    />
                                    <Row className="grid-margin-bottom">
                                        <Col>
                                            <Cardwrapper>
                                                <Row>
                                                    <Col>
                                                        <Tabs onSelect={(activeTab: string) => this.setState({ activeTab })} activeKey={this.state.activeTab} defaultActiveKey={this.props.states.ticket.activeTicket.typeOfTicket === TypeOfTicket.PreparedByPartner ? "filerequests" : "comments"} id="ticket-tabs">
                                                            {(this.props.states.ticket.activeTicket.typeOfTicket === TypeOfTicket.PreparedByClient || this.props.states.ticket.activeTicket.typeOfTicket === TypeOfTicket.PreparedByPartner) &&
                                                                <Tab eventKey="filerequests" title={t('tickets:Ticket.TabPanel.RequestedFiles')}>
                                                                    <Row className="my-3">
                                                                        <Col>
                                                                            <TicketFileRequest
                                                                                ticket={this.props.states.ticket.activeTicket}
                                                                                tempFileRequest={this.props.states.fileRequest.tempFileRequest}
                                                                                fileCount={(fileRequestCount: number) => { }}
                                                                                matchFiles={this.matchFiles}
                                                                                fileRequestFileAdded={this.fileRequestFileAdded}
                                                                                cancelMatch={this.cancelMatch}
                                                                                matchFilesModalOpen={this.state.matchFilesModalOpen}
                                                                                openMatchFilesModal={this.openMatchFilesModal}
                                                                                matchSelected={this.props.actions.fileRequest.matchSelectedFileRequest}
                                                                                matchingFileRequest={this.props.states.fileRequest.matchingFileRequest}
                                                                                selectedFileRequestRow={this.props.states.fileRequest.selectedFileRequestRow}
                                                                                getFileRequestRow={this.props.actions.fileRequest.getTempFileRequestRow}
                                                                                unmatchTempFileRequestRow={this.unmatchTempFileRequestRow}
                                                                                matchTempFileRequestRow={this.updateFileRequestRowMatch}
                                                                                uniqueFileRequestCategories={this.state.uniqueFileRequestCategories}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Tab>
                                                            }
                                                            <Tab eventKey="comments" title={t('tickets:Ticket.TabPanel.Comments')}>
                                                                <Row className="my-3">
                                                                    <Col>
                                                                        <Comments
                                                                            fileCount={this.state.fileCount}
                                                                            removeNewCommentFile={this.removeNewCommentFile}
                                                                            attachedFileAdded={this.commentAttachedFileAdded}
                                                                            attachedFiles={this.state.newAttachedFiles}
                                                                            comments={this.props.states.ticket.activeTicket.comments}
                                                                            downloadFiles={this.downloadFiles}
                                                                            saveComment={this.saveComment}
                                                                            isCommentSaved={this.props.states.ticket.isCommentSaved}
                                                                            loading={this.props.states.ticket.isLoading}
                                                                            ticket={this.props.states.ticket.activeTicket}
                                                                            getFileCount={this.getFileCount}
                                                                            filesWhereSaved={this.props.actions.ticket.filesWhereSaved}
                                                                            addCommentIsDirty={this.addCommentIsDirty}
                                                                            cancelComment={this.cancelComment}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Tab>
                                                            <Tab eventKey="history" title={t('tickets:Ticket.TabPanel.History')}>
                                                                <Row className="my-3">
                                                                    <Col>
                                                                        {this.props.states.ticket.activeTicket?.history?.map((x, index) => (
                                                                            <History
                                                                                ChangedBy={x.eventCreatorText}
                                                                                ChangedById={x.eventCreatorId}
                                                                                ChangedTime={x.eventOccurred}
                                                                                PictureGuid={x.authorPicture}
                                                                                Status={x.eventStatusText}
                                                                                key={index}
                                                                            />
                                                                        ))}
                                                                    </Col>
                                                                </Row>
                                                            </Tab>
                                                        </Tabs>
                                                    </Col>
                                                </Row>
                                            </Cardwrapper>
                                        </Col>
                                    </Row>
                                    <Prompt
                                        when={!this.state.ticketIsCancelled && !this.state.ticketIsClosed && this.state.unsavedChanges}
                                        message={t('tickets:Ticket.UnsavedChangesMessage')}
                                    />
                                    <Prompt
                                        when={!this.state.ticketIsCancelled && !this.state.ticketIsClosed && this.state.isAddCommentDirty}
                                        message={t('tickets:CancelTicket.Body')}
                                    />
                                    {this.state.ticketIsCancelled &&
                                        <ConfirmModal
                                            isOpen={this.state.ticketIsCancelled}
                                            title={t('tickets:CancelTicket.Title')}
                                            closeModal={() => this.cancelConfirmModal('ticketIsCancelled')}
                                            confirm={() => {
                                                history.push("/tickets")
                                            }}
                                            body={t('tickets:CancelTicket.Body')}
                                            confirmationButtonText={t('common:Action.DiscardChanges')}
                                            buttonVariant="primary"
                                            icon={faCheckCircle}
                                        />
                                    }
                                    {this.state.ticketIsClosed &&
                                        <ConfirmModal
                                            isOpen={this.state.ticketIsClosed}
                                            title={t('tickets:CancelTicket.Title')}
                                            closeModal={() => this.cancelConfirmModal('ticketIsClosed')}
                                            confirm={() => {
                                                this.cancelConfirmModal('ticketIsClosed')
                                                this.props.actions.ticket.closeTicket()
                                            }}
                                            body={t('tickets:CancelTicket.Body')}
                                            confirmationButtonText={t('tickets:Ticket.ResolveTicket')}
                                            buttonVariant="primary"
                                            icon={faCheckCircle}
                                        />
                                    }
                                </>
                            )
                        }
                        return null;
                    }
                }
                </Translation>
            </ErrorBoundary>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        states: {
            ticket: state.ticket,
            fileRequest: state.fileRequest,
        }
    }),
    (dispatch) => ({
        actions: {
            ticket: bindActionCreators(TicketStore.actionCreators, dispatch),
            fileRequest: bindActionCreators(FileRequestStore.actionCreators, dispatch),
        }
    })
)(TicketView);
