import React, { useEffect } from 'react';
import { createContext } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../store';
import * as CustomerStore from '../store/Customer';
import * as ProfileStore from '../store/Profile';
import * as CustomerHubStore from '../store/CustomerHub';
import * as AuthenticatedRouteStore from '../store/AuthenticatedRoute';
import { bindActionCreators } from 'redux';

type CustomerHubContextType = {
    connection: signalR.HubConnection | null;
};

export const CustomerHubContext = createContext<CustomerHubContextType>({ connection: null });

type ICustomerHubContextProps = {
    children: React.ReactNode,
    stores: {
        hub: CustomerHubStore.ICustomerHubState,
        authenticated: AuthenticatedRouteStore.IAuthenticatedRouteState
    },
    actions: {
        customer: typeof CustomerStore.actionCreators,
        hub: typeof CustomerHubStore.actionCreators,
        authenticatedRoute: typeof AuthenticatedRouteStore.actionCreators
        profile: typeof ProfileStore.actionCreators
    }
};

const CustomerHubContextProvider = (props: ICustomerHubContextProps): JSX.Element => {
    useEffect(() => {
        if (props.stores.hub.connectionCustomer === null && props.stores.authenticated.loggedIn) {
            props.actions.hub.connectCustomer();
            return;
        }
    }, [props.actions.hub, props.stores.authenticated.loggedIn, props.stores.hub.connectionCustomer]);

    useEffect(() => {
        if (props.stores.hub.connectionCustomer !== null) {
            props.actions.customer.connectCustomer(props.stores.hub.connectionCustomer);
            props.actions.authenticatedRoute.connectCustomer(props.stores.hub.connectionCustomer);
            props.actions.profile.connectCustomer(props.stores.hub.connectionCustomer);
        }

    }, [props.actions.authenticatedRoute, props.actions.customer, props.actions.profile, props.stores.hub.connectionCustomer]);

    return <CustomerHubContext.Provider value={{ connection: props.stores.hub.connectionCustomer }}>{props.children}</CustomerHubContext.Provider>;
};

export default connect(
    (state: IApplicationState) => ({
        stores: {
            hub: state.customerHub,
            authenticated: state.authenticatedRoute
        }
    }),
    (dispatch) => ({
        actions: {
            customer: bindActionCreators(CustomerStore.actionCreators, dispatch),
            hub: bindActionCreators(CustomerHubStore.actionCreators, dispatch),
            authenticatedRoute: bindActionCreators(AuthenticatedRouteStore.actionCreators, dispatch),
            profile: bindActionCreators(ProfileStore.actionCreators, dispatch)
        }
    })
)(CustomerHubContextProvider);

export const useCustomerHubContext = (): CustomerHubContextType => React.useContext(CustomerHubContext);
