import * as React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from '../../services/TelemetryService';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { APPINSIGHTS_INSTRUMENTATIONKEY } from 'src/Utils/config';
import ErrorBoundary from './ErrorBoundary';

interface IMyTelemetryProviderProps {
    after: () => void;
}

type ITelemetryProviderProps = RouteComponentProps<{}>
    & IMyTelemetryProviderProps;

interface ITelemetryProviderState {
    initialized: boolean;
}

class TelemetryProvider extends React.Component<ITelemetryProviderProps, ITelemetryProviderState> {
    static displayName = 'TelemetryProvider';

    constructor(props: ITelemetryProviderProps) {
        super(props);
        this.state = {
            initialized: false
        };
    }

    componentDidMount() {
        const { history } = this.props;
        const { initialized } = this.state;
        const AppInsightsInstrumentationKey = APPINSIGHTS_INSTRUMENTATIONKEY!;
        if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
            ai.initialize(AppInsightsInstrumentationKey, history);
            this.setState({initialized: true});
        }

        this.props.after();
    }

    render() {
        const { children } = this.props;
        return (
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        );
    }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
