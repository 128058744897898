import HttpHelper from './HttpHelper';
import { ISort, ITicketDTO } from './../Interface/Interfaces';
import f from 'odata-filter-builder';
import { API_ENDPOINT } from '../Utils/config';

export default class CustomerService {

    public static GetMyCustomers(nextPageToken: string | null, searchText: string = "", changedSortState: ISort | null = { sortOn: "name", sortOrder: "asc" }) {
        const queryString = this.CreateQueryStringFromSearchTextAndStatusAndPageToken(searchText, nextPageToken, changedSortState);
        const getTask = HttpHelper.Get(API_ENDPOINT + '/api/v2/customers/my' + queryString);
        return getTask;
    }

    public static GetACustomer(customerId: string) {
        return HttpHelper.Get(API_ENDPOINT + '/api/v2/customers/' + customerId);
    }

    public static GetACustomersTickets(customerId: string, nextPageToken: string = "") {
        const queryString = this.CreateQueryStringCustomerTickets(null);

        const getTask = HttpHelper.Get(API_ENDPOINT + '/api/v2/customers/' + customerId + '/tickets' + queryString);
        return getTask;
    }

    public static CreateCustomerTicket(ticketDto: ITicketDTO, customerId: string) {
        const postTask = HttpHelper.Post(API_ENDPOINT + '/api/v2/customers/' + customerId + '/tickets', JSON.stringify(ticketDto));
        return postTask;
    }

    public static CreateQueryStringCustomerTickets(continuationToken: string | null): any {
        let queryString = "";
        let params: string[] = [];
        params.push('$orderby=' + encodeURIComponent("edited") + ' ' + encodeURIComponent("asc"));
        if (continuationToken !== null) {
            params.push('continuationToken=' + encodeURIComponent(continuationToken));
        }
        queryString = params.join('&');

        if (queryString.length > 0) {
            queryString = '?' + queryString;
        }
        return queryString;
    }

    private static CompileFilter(searchText: string = ""): string {
        const searchTextUpperCase = searchText.toUpperCase();

        let filter = f('or');

        if (searchText !== undefined && searchText !== "") {
            filter = filter
                .contains('normalizedName', searchTextUpperCase)
                .contains('customerNumber', searchTextUpperCase)
                .contains('organizationalNumber', searchTextUpperCase)
                .contains('customerResponsible/normalizedName', searchTextUpperCase)
                .eq('customerResponsible/normalizedEmail', searchTextUpperCase)
                .contains('responsibleAccountant/normalizedName', searchTextUpperCase)
                .eq('responsibleAccountant/normalizedEmail', searchTextUpperCase);
        }
        return filter.toString();
    }

    private static CreateQueryStringFromSearchTextAndStatusAndPageToken(searchText: string, continuationToken: string | null, changedSortState: ISort | null) {
        const filter = this.CompileFilter(searchText);

        let queryString = "";
        let params: string[] = [];

        if (filter !== "") {
            params.push('$filter=' + encodeURIComponent(filter));
        }

        if (changedSortState) {
            params.push('$orderby=' + encodeURIComponent(changedSortState.sortOn) + ' ' + encodeURIComponent(changedSortState.sortOrder));
        }

        if (continuationToken !== null) {
            params.push('continuationToken=' + encodeURIComponent(continuationToken));
        }

        queryString = params.join('&');

        if (queryString.length > 0) {
            queryString = '?' + queryString;
        }

        return queryString;
    }
}
