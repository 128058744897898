import { IAppThunkAction } from '../';
import { AuthenticatedKnownAction } from '../AuthenticatedRoute';
import { LogOut, UserService } from '../../apiServices/UserService';
import HttpHelper from '../../apiServices/HttpHelper';
import StorageService from '../../apiServices/StorageService';
import Logger from '../../Utils/Logger';
import { IMe } from '../../Interface/Interfaces';
import { API_ENDPOINT } from '../../Utils/config';
import { AddOrUpdateCustomer, RemoveCustomer } from 'src/Utils/AuthenticatedRouteHelper';

export const AuthenticatedRouteActions = {
    connectCustomer: (connectionCustomer: signalR.HubConnection): IAppThunkAction<AuthenticatedKnownAction> => (dispatch, getState) => {
        connectionCustomer.on("updatecustomer", (customerId: string) => {
            AddOrUpdateCustomer(dispatch, getState, customerId);
        });

        connectionCustomer.on("addedtocustomer", (customerId: string) => {
            AddOrUpdateCustomer(dispatch, getState, customerId);
        });

        connectionCustomer.on("customerengagementremoved", (customerId: string) => {
            RemoveCustomer(dispatch,getState,customerId);
            connectionCustomer.invoke('LeaveGroup', customerId);
        });
    },
    authorize: (): IAppThunkAction<AuthenticatedKnownAction> => (dispatch, getState) => {
        const sessionId = getState().authenticatedRoute.refreshSessionId;
        HttpHelper.GetThatNeedsMigration(`${API_ENDPOINT}/api/user/externallogin?deviceId=${HttpHelper.getDeviceId()}&sessionId=${sessionId}`)
        .then((response: any) => {
            if (response.status === 200) {
                StorageService.ClearAll();

                dispatch({
                    type: 'USER_NOT_FOUND',
                    userPersonalIdentityNumberNotFound: false
                });
                response.json().then((data: any) => {
                    StorageService.SaveStorageData(data);
                    UserService.GetMe().then((me: IMe) => {
                        dispatch({
                            type: 'LOGIN_AUTHENTICATED',
                            me: me
                        });
                    });
                });
            }
            else if (response.status === 404) {
                dispatch({
                    type: 'USER_NOT_FOUND',
                    userPersonalIdentityNumberNotFound: true
                });
            }
            else {
                dispatch({
                    type: 'USER_NOT_FOUND',
                    userPersonalIdentityNumberNotFound: false
                });
            }
        });
    },
    signIn: (loginMethod: string, redirectUri: string = '/'): IAppThunkAction<AuthenticatedKnownAction> => (dispatch, getState) => {
        window.location.href = `${API_ENDPOINT}/api/user/signin?loginMethod=${loginMethod}&redirectUri=${redirectUri}`;
    },
    identify: (): IAppThunkAction<AuthenticatedKnownAction> => (dispatch, getState) => {
        window.location.href = `${API_ENDPOINT}/api/user/identify`;
    },
    requestLogIn: (): IAppThunkAction<AuthenticatedKnownAction> => (dispatch, getState) => {
        HttpHelper.getValidAccessToken().then((token) => {
            Logger.log(token);
            if (token === "expired") {
                dispatch({
                    type: 'LOGOUT_AUTHENTICATED'
                });
                StorageService.ClearAll();
            } else {
                UserService.GetMe()
                    .then((me: IMe) => {
                        dispatch({
                            type: 'LOGIN_AUTHENTICATED',
                            me: me
                        });
                    });
            }
        });
    },
    alreadyLoggedIn: (): IAppThunkAction<AuthenticatedKnownAction> => (dispatch) => {
        UserService.GetMe()
            .then((me: IMe) => {
                dispatch({
                    type: 'ALREADYLOGGEDIN_AUTHENTICATED',
                    me: me
                });
            });
    },
    logOut: (): IAppThunkAction<AuthenticatedKnownAction> => (dispatch) => {
        LogOut()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch({
                        type: 'LOGOUT_AUTHENTICATED'
                    });
                    StorageService.ClearAll();
                    window.location.href = "/";
                }
            });
    },
    updateUserPic: (newPicture: string): IAppThunkAction<AuthenticatedKnownAction> => (dispatch, getState) => {
        const user = StorageService.GetParsedStorageData();
        user.userPicture = newPicture;
        StorageService.SaveStorageData(user);
        dispatch({
            type: 'PROFILE_UPDATED'
        });
        UserService.GetMe()
            .then((me: IMe) => {
                dispatch({
                    type: 'ALREADYLOGGEDIN_AUTHENTICATED',
                    me: me
                });
            });
    },
};
