import { IAppThunkAction } from '../';
import { KnownAction } from '../Toasts';
import { IToastMessage } from '../../Interface/Interfaces';

export const ToastsActions = {
    addToastMessage: (toastMessage: IToastMessage): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'ADD_TOAST',
            payload: toastMessage
        });
        if (toastMessage.hideAfter) {
            setTimeout(() => dispatch({
                type: 'REMOVE_TOAST',
                id: toastMessage.id
            }), toastMessage.hideAfter);
        }
    },
    removeToastMessage: (id: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'REMOVE_TOAST',
            id: id
        });
    }
};
