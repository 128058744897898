import * as React from "react";
import filterInvalidDOMProps from "filter-invalid-dom-props";

interface KPMGImageFallbackProps {
    src: string;
    sourceSet: string;
    fallbackImage: string | React.ReactElement<any> | string[] | Array<React.ReactElement<any>>;
    initialImage?: string | React.ReactElement<any>;
    onLoad?: (event: React.SyntheticEvent<HTMLImageElement> | string) => void;
    onError?: (event: React.SyntheticEvent<HTMLImageElement> | string) => void;
    initialTimeout?: number;
}

export default class KPMGImageFallback extends React.Component<
    KPMGImageFallbackProps &
    React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    >,
    any> {
    static displayName = 'KPMGImageFallback';

    private isLoaded: boolean;
    private displayImage: any;

    constructor(props: any) {
        super(props);
        this.state = {
            imageSource: null
        };
        this.setDisplayImage = this.setDisplayImage.bind(this);
        this.handleInitialTimeout = this.handleInitialTimeout.bind(this);
        this.isLoaded = false;
    }

    private handleInitialTimeout() {
        if (this.props.initialTimeout && this.props.initialTimeout > 0) {
            setTimeout(() => {
                if (!this.isLoaded) {
                    this.setState({
                        imageSource: this.props.initialImage
                    })
                }
            }, this.props.initialTimeout);
        }
        else {
            this.setState({
                imageSource: this.props.initialImage
            });
        }
    }

    public componentDidMount() {
        this.handleInitialTimeout();
        this.displayImage = new (window as any).Image();
        this.setDisplayImage({ image: this.props.src, fallbacks: this.props.fallbackImage });
    }

    public componentDidUpdate(prevProps: KPMGImageFallbackProps) {
        if (prevProps.src !== this.props.src) {
            this.isLoaded = false;
            if (this.props.initialImage) {
                this.handleInitialTimeout();
            }
            this.setDisplayImage({ image: this.props.src, fallbacks: this.props.fallbackImage });
        }
    }

    public componentWillUnmount() {
        if (this.displayImage) {
            this.displayImage.onerror = null;
            this.displayImage.onload = null;
            this.displayImage = null;
        }
    }

    private setDisplayImage({ image, fallbacks }: any) {
        const imagesArray = [image].concat(fallbacks).filter(fallback => !!fallback)
        this.displayImage.onerror = () => {
            if (imagesArray.length > 2 && typeof imagesArray[1] === "string") {
                const updatedFallbacks = imagesArray.slice(2);
                this.setDisplayImage({ image: imagesArray[1], fallbacks: updatedFallbacks });
                return;
            }
            this.isLoaded = true;
            this.setState({
                imageSource: imagesArray[1] || null
            }, () => {
                if (this.props.onError) {
                    this.props.onError(this.props.src);
                }
            });
        };
        this.displayImage.onload = () => {
            this.isLoaded = true;
            this.setState({
                imageSource: imagesArray[0]
            }, () => {
                if (this.props.onLoad) {
                    this.props.onLoad(imagesArray[0]);
                }
            });
        };
        if (typeof imagesArray[0] === "string") {
            this.displayImage.src = imagesArray[0];
        }
        else {
            this.setState({
                imageSource: imagesArray[0]
            }, () => {
                if (this.props.onLoad) {
                    this.props.onLoad(imagesArray[0]);
                }
            });
        }
    }

    private isBase64Image(str: any): boolean {
        try {
            const base64string = str.split("data:image/png;base64,")[1];
            return btoa(atob(base64string)) === base64string;
        } catch (err) {
            return false;
        }
    }

    render() {
        const srcSet = this.state.imageSource !== this.props.initialImage && !this.isBase64Image(this.state.imageSource) ? { srcSet: this.props.sourceSet } : {};
        return (
            typeof this.state.imageSource === "string" ?
                <img {...filterInvalidDOMProps(this.props)} src={this.state.imageSource} {...srcSet} alt="Fallback" />
                :
                this.state.imageSource
        );
    }
}
