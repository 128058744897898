import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket } from '../../../../Interface/Interfaces';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Cardwrapper, KPMGContextConsumer, Table } from '@kpmgportals/genericcomponents';
import TicketTableHeader from "./TicketTableHeader";
import TicketRow from "./TicketRow";

export interface ITicketListProps {
    tickets: ITicket[];
    isLoading: boolean;
}

export default class TicketList extends React.Component<ITicketListProps, {}> {
    static displayName = 'TicketList';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <KPMGContextConsumer>
                            {KPMGContext => (
                                <>
                                    {this.props.tickets.length > 0 &&
                                        <Cardwrapper>
                                            <Row>
                                                <Col>
                                                    <Table minWidth="xl" className="ticket-listing">
                                                        <TicketTableHeader ticketsLength={this.props.tickets.length} />
                                                        <Table.Body>
                                                            {
                                                                this.props.tickets.map((ticket: ITicket) => {
                                                                    return (
                                                                        <TicketRow
                                                                            ticket={ticket}
                                                                            key={ticket.id}
                                                                            style={{ paddingRight: `${this.props.tickets.length > 7 ? `${KPMGContext?.scrollbarSize}px` : 0}` }}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </Table.Body>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </Cardwrapper>
                                    }
                                    {this.props.isLoading &&
                                        <Row className="justify-content-center">
                                            <Col xs="auto">
                                                <Spinner animation="border" role="status" />
                                            </Col>
                                        </Row>
                                    }
                                </>
                            )}
                        </KPMGContextConsumer>
                    );
                }
            }
            </Translation>
        );
    }
}
