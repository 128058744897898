import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';

type LoginProps = RouteComponentProps<{}>;

class SetPassword extends React.Component<LoginProps, {}> {
    static displayName = 'SetPassword';

    private guid: any;

    constructor(props: any) {
        super(props)
        this.guid = this.props.match.params!["guid"];
    }

    public render() {
        return (
            (this.guid &&
                <Redirect to={`/activate/${this.guid}`} />
            )
        )
    }
}

export default SetPassword;
