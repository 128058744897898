import * as React from 'react';
import { Translation } from 'react-i18next';
import EmailSettingDropDown from './EmailSettingDropDown';

export interface IEmailSettingProps {
    labelId: string;
    type: string;
    changeCallback: (e: any) => void;
    stateKey: string;
    selectedValue: number;
    loading: boolean;
}

export default class EmailSetting extends React.Component<IEmailSettingProps, {}> {
    static displayName = 'EmailSetting';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <div className="cell">
                            <div className="grid-x grid-padding-x margin-bottom-1">
                                <div className="cell large-4 text-right flex-container align-middle">
                                    <label htmlFor={this.props.labelId} className="text-normal width-100 svg-vertical-align-text-top">{this.props.type}</label>
                                </div>
                                <div className="cell large-8">
                                    <EmailSettingDropDown
                                        labelId={this.props.labelId}
                                        changeCallback={this.props.changeCallback}
                                        stateKey={this.props.stateKey}
                                        selectedValue={this.props.selectedValue}
                                        loading={this.props.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                }
            }
            </Translation>
        );
    }
}

