import * as React from "react";
import { Translation } from 'react-i18next';
import { Table } from '@kpmgportals/genericcomponents';

export default class FileRequestTableHead extends React.Component {
    static displayName = 'FileRequestTableHead';

    public render() {
        return (
            <Translation>
                {t => {
                    return (
                        <Table.Head>
                            <Table.Col
                                th
                                className="flex-grow-1"
                                separator
                            >
                                {t('tickets:Filerequest.Request')}
                            </Table.Col>
                            <Table.Col
                                th
                                className="flex-grow-1"
                                separator
                            >
                                {t('tickets:Filerequest.Title')}
                            </Table.Col>
                            <Table.Col
                                th
                                xs={3}
                                separator
                            >
                                {t('tickets:Filerequest.Template')}
                            </Table.Col>
                            <Table.Col
                                th
                                xs={3}
                                separator
                            >
                                {t('tickets:Filerequest.UploadedFile')}
                            </Table.Col>
                            <Table.Col
                                th
                                xs={3}
                            >
                                {t('tickets:Filerequest.FileStatus')}
                            </Table.Col>
                        </Table.Head>
                    );
                }
                }
            </Translation>
        );
    }
}
