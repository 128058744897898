import * as React from "react";
import { Translation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";

interface IWelcomePopup {
    showModal: boolean;
    disableWelcomePopup: () => void;
}

function WelcomePopup(props: IWelcomePopup) {
    return (
        <Translation>
            {
                (t) => (
                    <Modal style={{ zIndex: 10000 }} show={props.showModal} onHide={props.disableWelcomePopup}>
                        <Modal.Header>
                            <h2>{t('common:StartPage.WelcomePopup.Title')}</h2>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ whiteSpace: "pre-line" }}>{t('common:StartPage.WelcomePopup.Body')}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                onClick={props.disableWelcomePopup}
                                size="sm"
                            >
                                {t('common:Close')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }
        </Translation>
    );
}

WelcomePopup.displayName = 'WelcomePopup';
export default WelcomePopup;
