import * as React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router";
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import * as AuthenticatedRouteStore from '../../store/AuthenticatedRoute';
import HttpHelper, { TokenState } from "../../apiServices/HttpHelper";
import Logger from "../../Utils/Logger";
import { Modal, Button } from 'react-bootstrap';

type AuthenticatedRouteProps =
    AuthenticatedRouteStore.IAuthenticatedRouteState
    & typeof AuthenticatedRouteStore.actionCreators
    & AuthenticatedRouteStore.IAuthenticatedRouteOwnProps
    & RouteComponentProps<{}>;

export interface AuthenticatedRouteState {
    tokenState: TokenState;
    activatedAccountModalOpen?: boolean;
}

class AuthenticatedRoute extends React.Component<AuthenticatedRouteProps, AuthenticatedRouteState> {
    static displayName = 'AuthenticatedRoute';

    constructor(props: any) {
        super(props);
        this.state = { tokenState: TokenState.Initalizing, activatedAccountModalOpen: undefined };
    }

    public componentDidMount() {
        this.ValidateAccessToken();
    }

    public componentDidUpdate(prevProps: AuthenticatedRouteProps, prevState: AuthenticatedRouteState) {
        if (this.state.tokenState !== TokenState.Refreshing && this.state.tokenState !== TokenState.Expired && this.state.tokenState !== TokenState.Good) {
            if (HttpHelper.isOldAccessToken()) {
                Logger.log("Refreshing");
                this.setState({ tokenState: TokenState.Refreshing }, () => { this.ValidateAccessToken(); });
            } else {
                this.setState({ tokenState: TokenState.Good });
            }
        }

        if ((this.props.location.state !== undefined && this.props.location.state !== null) && (this.props.location.state! as any).didActivateAccount && this.state.activatedAccountModalOpen === undefined) {
            this.setState({ activatedAccountModalOpen: true });
        }
    }

    private ValidateAccessToken() {
        HttpHelper.getValidAccessToken().then(t => {
            if (t === "expired") {
                Logger.log("Expired");
                this.setState({ tokenState: TokenState.Expired });
            }
            else {
                this.setState({ tokenState: TokenState.Good });
                Logger.log("Good");
            }
        });
    }

    public onActivateELogin = () => {
        this.props.identify();
    }

    render() {
        switch (this.state.tokenState) {
            case TokenState.Initalizing:
                return null;
            case TokenState.Refreshing:
                return null;
            case TokenState.Expired:
                return <Redirect to={{ pathname: "/login", search: this.props.location?.search, state: this.props.location.state }} />;
            case TokenState.Good:
                if (!this.props.loggedIn) {
                    this.props.alreadyLoggedIn();
                }
                return (
                    <>
                        {this.props.loggedIn ? <Route path={this.props.path} exact={this.props.exact} component={this.props.component} /> : (null)}
                        {this.state.activatedAccountModalOpen &&
                            <Modal show>
                                <Modal.Header>
                                    <Modal.Title>
                                        Account Activated
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Your account has been activated</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        onClick={() => this.setState({ activatedAccountModalOpen: false })}
                                    >
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        }
                    </>
                )
            default:
                return null;
        }
    }
}
export default connect(
    (state: IApplicationState, ownProps) => state.authenticatedRoute, AuthenticatedRouteStore.actionCreators
)(AuthenticatedRoute);
