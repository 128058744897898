import { Reducer } from 'redux';
import { ICustomer, ICosmosGenericPagedResult, ITicket, IAccountingCompany, ISort } from '../Interface/Interfaces';
import { CustomersActions } from './actions/CustomersActions';
import { CustomersReducer } from './reducers/CustomersReducers';

export interface ICustomerState {
    myCustomers: ICosmosGenericPagedResult<ICustomer>;
    myDropDownCustomers: IAccountingCompany[];
    myDropDownCustomer: ICustomer;
    searchText: string;
    isLoading: boolean;
    connectionCustomer: signalR.HubConnection | null;
    updateBell: boolean;
    selectedCustomer: ICustomer;
    selectedCustomerTickets: ITicket[];
    selectedCustomerTicketPages: number;
    selectedCustomerActiveTicketPage: number;
    onlineCustomerHub: boolean;
    reconnectUser: boolean;
    ticketsLoading: boolean;
    sortCustomers: ISort;
    userIsRemoved: boolean;
    emailChanged: boolean;
}

export interface ICustomerGetMyCustomersAction { type: 'GET_MY_DROPDOWN_CUSTOMERS'; myDropDownCustomers: IAccountingCompany[]; }

export interface ICustomerClearedMyCustomersAction { type: 'CLEAR_MY_DROPDOWN_CUSTOMERS'; myDropDownCustomers: IAccountingCompany[]; }

export interface ICustomerGetMyCustomerAction { type: 'GET_MY_DROPDOWN_CUSTOMER'; myDropDownCustomer: ICustomer; }

interface ICustomerGetACustomerAction { type: 'GET_A_CUSTOMER'; selectedCustomer: ICustomer; }

interface ICustomerGetACustomerTicketsAction { type: 'GET_A_CUSTOMER_TICKETS'; tickets: ITicket[]; selectedCustomerTicketPages: number; }

interface ICustomerChangeCustomerTicketsPageAction { type: 'CHANGE_A_CUSTOMER_TICKETS_PAGE'; selectedCustomerTicketPage: number; }

interface ICustomerGetACustomerAccessDeniedAction { type: 'GET_A_CUSTOMER_ACCESSDENIED'; selectedCustomer: ICustomer; }

interface ICustomerLeftACustomerAction { type: 'LEFT_A_CUSTOMER'; }

export interface ICustomerGetMyAction { type: 'GET_MY_CUSTOMERS'; myCustomers: ICosmosGenericPagedResult<ICustomer>; }

export interface ICustomerGetMoreCustomersAction { type: 'GET_MORE_CUSTOMERS'; moreMyCustomers: ICosmosGenericPagedResult<ICustomer>; }

interface ICustomerGettingMoreAction { type: 'GETTING_MORE_CUSTOMERS'; }

export interface ICustomerSearchTextUpdatedAction { type: 'SEARCH_TEXT_UPDATED'; searchText: string; }

export interface ICustomerConnectHub { type: 'CONNECT_CUSTOMERHUB'; connectionCustomer: signalR.HubConnection; }

export interface ICustomerDisconnectedHub { type: 'DISCONNECTED_CUSTOMERHUB'; }

export interface ICustomerReconnectHub { type: 'RECONNECT_USER'; reconnectUser: boolean; }

interface ICustomerUpdateBellAction { type: 'UPDATE_BELL'; update: boolean; }

export interface ICustomerUpdateSortState { type: 'UPDATE_SORT_STATE'; sortCustomers: ISort; myCustomers: ICosmosGenericPagedResult<ICustomer>; }

interface IUserRemoved { type: 'CUSTOMER_USER_REMOVED'; userIsRemoved: boolean; }

export interface IEmailChanged { type: 'EMAIL_CHANGED'; isChanged: boolean; }

export type KnownAction = ICustomerGetMyAction | ICustomerGetMoreCustomersAction | ICustomerGettingMoreAction | ICustomerSearchTextUpdatedAction
    | ICustomerGetACustomerAccessDeniedAction | ICustomerLeftACustomerAction | ICustomerGetACustomerTicketsAction
    | ICustomerDisconnectedHub | ICustomerReconnectHub | ICustomerGetACustomerTicketsAction | ICustomerChangeCustomerTicketsPageAction | ICustomerGetACustomerAction
    | ICustomerUpdateBellAction | ICustomerConnectHub | ICustomerGetMyCustomersAction | ICustomerClearedMyCustomersAction | ICustomerGetMyCustomerAction | ICustomerUpdateSortState
    | IUserRemoved | IEmailChanged;

export const actionCreators = CustomersActions;

export const reducer: Reducer<ICustomerState> = (state: ICustomerState, action: KnownAction) => CustomersReducer(state, action);
