import React from 'react';
import { components } from 'react-select';
import KPMGImageFallback from '../components/CommonComponents/KPMGImageFallback';
import * as userDefaultAvatar from '../assets/img/profile-large.png';
import * as orgDefaultAvatar from '../assets/img/org-profile-small.png';
import { Row, Col } from 'react-bootstrap';
import { ORG_IMAGE_CDN, USER_IMAGE_CDN } from './config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IAccountingCompany } from '../Interface/Interfaces';
import { createFilter } from 'react-select';

const getLeftSize = (isClearable: boolean, selectSize: false | 'sm' | 'lg') => {
    if (isClearable && !selectSize) {
        return 'calc(2.25rem - 3px)';
    }
    if (isClearable && selectSize === 'sm') {
        return 'calc(1.75rem - 3px)';
    }
    return 0;
}

export const resetStyles = {
    control: () => ({}),
    valueContainer: () => ({}),
    indicatorsContainer: () => ({}),
    dropdownIndicator: () => ({ cursor: 'pointer' }),
    indicatorSeparator: (provided: any, state: any) => ({
        alignSelf: 'stretch',
        margin: 'auto',
        height: '54%',
        position: 'absolute',
        background: '#9e9e9e',
        top: 0,
        bottom: 0,
        left: getLeftSize(state.selectProps.isClearable, state.selectProps.size),
        width: 1,
    } as React.CSSProperties),
    clearIndicator: (provided: any) => ({
        ...provided,
        display: 'block',
        cursor: 'pointer',
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        color: 'inherit',
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        'z-index': 9,
    }),
};

export const ValueContainerComponent = ({ children, ...props }: any) => (
    <components.ValueContainer {...props} className="d-flex flex-grow-1 flex-shrink-1 align-items-center p-0 overflow-hidden position-relative flex-wrap">
        {children}
    </components.ValueContainer>
);

export const ControlComponent = (props: any) => (
    <components.Control
        className={`custom-select react-select form-control d-flex align-items-center position-relative${props.isDisabled ? ' disabled' : ''}${props.selectProps.size ? ` custom-select-${props.selectProps.size}` : ''}`}
        {...props}
    />
);

export const IndicatorsContainerComponent = (props: any) => (
    <components.IndicatorsContainer
        className={`position-absolute d-flex align-items-center position-right position-top position-bottom m-auto flex-shrink-0 indicator-container${props.selectProps.isClearable ? ' is-clearable' : ''}`}
        {...props}
    />
);

export const DropdownIndicatorComponent = (props: any) => (
    <components.DropdownIndicator className="position-absolute dropdown-indicator" {...props}>
        <FontAwesomeIcon icon={faChevronDown} size="sm" className={`dropdown-indicator-icon ${props.selectProps.menuIsOpen ? 'open' : 'closed'}`} />
    </components.DropdownIndicator>
);

export const ClearIndicatorComponent = (props: any) => {
    const {
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles('clearIndicator', props)}
        >
            <FontAwesomeIcon icon={faTimes} size="sm" />
        </div>
    );
};

export const UserSingleValue = ({ children, ...props }: any) => (
    <components.SingleValue {...props}>
        <Row noGutters className="align-items-center flex-nowrap">
            <Col xs="auto" className="mr-2">
                <picture>
                    <KPMGImageFallback
                        sourceSet={USER_IMAGE_CDN + props.data.pictureUrl + "_s.jpg, " + USER_IMAGE_CDN + props.data.pictureUrl + "_l.jpg 2x"}
                        src={USER_IMAGE_CDN + props.data.pictureUrl + "_s.jpg"}
                        fallbackImage={userDefaultAvatar}
                        initialImage={userDefaultAvatar}
                        alt="Profile photo"
                        className={`d-inline-block rounded-circle dropdown-image single-value${props.selectProps.size ? ` single-value-${props.selectProps.size}` : ''}`}
                    />
                </picture>
            </Col>
            <Col className="text-truncate">
                {props.data.label} <small>({props.data.email})</small>
            </Col>
        </Row>
    </components.SingleValue>
);

export const UserOption = ({ children, ...props }: any) => (
    <components.Option {...props}>
        <Row noGutters className="align-items-center user-dropdown-option px-2 flex-nowrap">
            <Col xs="auto" className="mr-2">
                <picture>
                    <KPMGImageFallback
                        sourceSet={USER_IMAGE_CDN + props.data!.pictureUrl + "_s.jpg, " + USER_IMAGE_CDN + props.data!.pictureUrl + "_l.jpg 2x"}
                        src={USER_IMAGE_CDN + props.data!.pictureUrl + "_s.jpg"}
                        fallbackImage={userDefaultAvatar}
                        initialImage={userDefaultAvatar}
                        alt={props.data!.label}
                        className={`d-inline-block rounded-circle dropdown-image option${props.selectProps.size ? ` option-${props.selectProps.size}` : ''}`}
                    />
                </picture>
            </Col>
            <Col className="info text-truncate">
                <Row noGutters>
                    <Col className="text-truncate">
                        {props.data!.label}
                    </Col>
                </Row>
                <Row noGutters>
                    <Col className="text-truncate">
                        <small>
                            {props.data!.email}
                        </small>
                    </Col>
                </Row>
            </Col>
        </Row>
    </components.Option>
);

export const OrgSingleValue = ({ children, ...props }: any) => (
    <components.SingleValue {...props}>
        <Row noGutters className="align-items-center flex-nowrap">
            <Col xs="auto" className="mr-2">
                <picture>
                    <KPMGImageFallback
                        sourceSet={`${ORG_IMAGE_CDN}${props.data!.value.id}_s.jpg?image=${props.data!.value.imageUrl}`}
                        src={props.data!.value.imageUrl === '' || props.data!.value.imageUrl == null
                            ? "" : `${ORG_IMAGE_CDN}${props.data!.value.id}_s.jpg?image=${props.data!.value.imageUrl}`}
                        fallbackImage={orgDefaultAvatar}
                        initialImage={orgDefaultAvatar}
                        alt="Profile photo"
                        className={`d-inline-block rounded-circle dropdown-image single-value${props.selectProps.size ? ` single-value-${props.selectProps.size}` : ''}`}
                    />
                </picture>
            </Col>
            <Col className="text-truncate">
                {props.data!.value === 'All' ? (
                    props.data!.label
                ) :
                    (
                        <>
                            {props.data!.value.name}{' '}<small>({props.data!.value.organizationalNumber}, {props.data!.value.customerNumber})</small>
                        </>
                    )
                }
            </Col>
        </Row>
    </components.SingleValue>
);

export const OrgOption = ({ children, ...props }: any) => (
    <components.Option {...props}>
        <Row noGutters className="align-items-center org-dropdown-option px-2 flex-nowrap">
            <Col xs="auto" className="mr-2">
                <picture>
                    <KPMGImageFallback
                        sourceSet={`${ORG_IMAGE_CDN}${props.data!.value.id}_s.jpg?image=${props.data!.value.imageUrl}`}
                        src={props.data!.value.imageUrl === '' || props.data!.value.imageUrl == null
                            ? "" : `${ORG_IMAGE_CDN}${props.data!.value.id}_s.jpg?image=${props.data!.value.imageUrl}`}
                        fallbackImage={orgDefaultAvatar}
                        initialImage={orgDefaultAvatar}
                        alt={props.data!.value.name}
                        className={`d-inline-block rounded-circle dropdown-image option${props.selectProps.size ? ` option-${props.selectProps.size}` : ''}`}
                    />
                </picture>
            </Col>
            <Col className="info text-truncate">
                <Row noGutters>
                    <Col className="text-truncate">
                        {props.data!.value === 'All' ? (
                            props.data!.label

                        ) : (
                            props.data!.value.name
                        )
                        }
                    </Col>
                </Row>
                {props.data!.value !== 'All' &&
                    <Row noGutters>
                        <Col className="text-truncate">
                            <small>
                                {props.data!.value.organizationalNumber}, {props.data!.value.customerNumber}
                            </small>
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    </components.Option>
);

export const FileRequestOption = ({ children, ...props }: any) => (
    <components.Option {...props}>
        <span className={props.isDisabled ? '' : "text-secondary"}>{props.data!.request}</span> - {props.data!.label}
    </components.Option>
);

export const createOrgSelectOptions = (customers: IAccountingCompany[], allOptionLabel?: any) => {
    let customerOptions: any = [];
    if (allOptionLabel) {
        customerOptions.push(
            { value: 'All', label: allOptionLabel }
        )
    }
    customers.forEach((customer) => {
        /**
         * if we don't set explicit values the styling of the dropdown
         * will fail, displaying all options as the "selected" option
         * all the time while open.
         *
         * The dropdown will work, the selection will be displayed
         * in the input field. But when the dropdown is opened all
         * available options will be "selected" in their styling.
         */
        customerOptions.push({
            value: customer,
            label: customer.name,
            organizationalNumber: customer.organizationalNumber,
            customerNumber: customer.customerNumber
        })
    })
    return customerOptions;
};

export const createUserSelectOptions = (users: any, allOptionLabel?: any, nooneOptionLabel?: any) => {
    let userOptions: any = [];
    if (allOptionLabel) {
        userOptions.push(
            { value: 'All', label: allOptionLabel }
        )
    }
    if (nooneOptionLabel) {
        userOptions.push(
            { value: 'noone', label: nooneOptionLabel }
        )
    }
    users.forEach((user: any) => {
        /**
         * if we don't set explicit values the styling of the dropdown
         * will fail, displaying all options as the "selected" option
         * all the time while open.
         *
         * The dropdown will work, the selection will be displayed
         * in the input field. But when the dropdown is opened all
         * available options will be "selected" in their styling.
         */
        userOptions.push({
            value: user.id,
            label: user.name ? user.name : `${user.firstName} ${user.lastName}`,
            email: user.email,
            ...user
        })
    })
    return userOptions;
};

const stringifyData = (data: any) => {
    let forbiddenKeys = [
        'label'.toLowerCase(), // already included
        'value'.toLowerCase(), // already included
        'personalIdentityNumber'.toLowerCase(), // users should not be able to guess/surmise parts of personal identity numbers in dropdowns
        'id'.toLowerCase(), // exlude internal id strings
        'userId'.toLowerCase(), // exlude internal id strings
    ]
    let dataString = '';
    Object.keys(data).forEach(key => {
        if (!forbiddenKeys.includes(key.toLowerCase())) {
            dataString = [dataString, data[key]].join(' ');
        }
    })
    return dataString;
}

export const setFilterOption = (filterOption: any) => {
    if (filterOption && filterOption === 'default') {
        return;
    }
    if (!filterOption) {
        return createFilter({
            stringify: option => `${option.label} ${option.value} ${stringifyData(option.data)}`,
        })
    }
    return filterOption;
}
