import * as React from 'react';
import { Translation } from 'react-i18next';
import { Table, UserVertical } from '@kpmgportals/genericcomponents';
import { USER_IMAGE_CDN } from '../../../../Utils/config';
import ContactInformationService from '../../../../apiServices/ContactInformationService';
import { IInternalUser, IExternalUser } from '../../../../Interface/Interfaces';
import { TeamMemberString } from '../../../../Utils/CustomerHelper';

interface ICustomerMemberRowProps {
    user: IInternalUser | IExternalUser;
    isInternalUser?: boolean;
    style?: any;
    index: number;
}

export default class CustomerMemberRow extends React.Component<ICustomerMemberRowProps, {}> {
    static displayName = 'CustomerMemberRow';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Table.Row
                            key={this.props.index}
                            style={this.props.style}
                            variant={(this.props.isInternalUser && !this.props.user.isApproved) ? "warning" : "default"}
                            className="mx-0"
                        >
                            <Table.Col
                                xs="auto"
                                className="flex-grow-1"
                                truncate
                                separator
                            >
                                <span className="position-absolute">
                                    <UserVertical
                                        user={{
                                            firstName: this.props.user.firstName,
                                            lastName: this.props.user.lastName,
                                            email: "",
                                            name: this.props.user.firstName + " " + this.props.user.lastName,
                                            id: this.props.user.userId,
                                            phone: "",
                                            pictureUrl: this.props.user.pictureUrl,
                                            title: (this.props.user as IInternalUser)?.role ?? TeamMemberString,
                                            userId: this.props.user.userId
                                        }}
                                        displayRole
                                        USER_IMAGE_CDN={USER_IMAGE_CDN}
                                        ContactInformationService={ContactInformationService}
                                        hoverCardAppendTo={document.body}
                                    />
                                </span>
                            </Table.Col>
                        </Table.Row>
                    )
                }
            }
            </Translation>
        );
    }
}
