import * as React from 'react';
import { Translation } from 'react-i18next';
import { IInternalUser } from '../../../../Interface/Interfaces';
import { FixedSizeList } from 'react-window';
import CustomerMemberRow from './CustomerMemberRow';
import { Row, Col } from 'react-bootstrap';
import { Table } from '@kpmgportals/genericcomponents';
import CustomerMemberNone from './CustomerMemberNone';

interface ICustomerTeamMembersProps {
    internalUsers: IInternalUser[];
}

export default class CustomerTeamMembers extends React.Component<ICustomerTeamMembersProps, {}> {
    static displayName = 'CustomerTeamMembers';

    public renderRow = ({ ...props }) => (
        <CustomerMemberRow
            isInternalUser
            user={this.props.internalUsers[props.index]}
            style={props.style}
            index={props.index}
        />
    );

    public noRow = ({ ...props }) => (
        <CustomerMemberNone style={props.style} />
    );

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="mt-5">
                            <Col>
                                <Table>
                                    <Table.Body>
                                        <FixedSizeList
                                            height={((this.props.internalUsers.length * 60) > 400) ? 400 : (this.props.internalUsers.length * 60)}
                                            width="initial"
                                            itemSize={55}
                                            itemCount={this.props.internalUsers.length ? this.props.internalUsers.length : 1}
                                            itemData={{ ...this.props }}
                                            className="list-container"
                                        >
                                            {this.props.internalUsers.length > 0 ? this.renderRow : this.noRow}
                                        </FixedSizeList>
                                    </Table.Body>
                                </Table>
                            </Col>
                        </Row>
                    )
                }
            }
            </Translation>
        );
    }
}
