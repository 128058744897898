import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Translation } from 'react-i18next';
import UserSettings from '../UserSettings';
import 'core-js';
import Notifications from '../Notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { SUPPORT_EMAIL, PORTAL_NAME } from '../../../Utils/config';

class NavMenuUpper extends React.Component {
    static displayName = 'NavMenuUpper';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Navbar.Collapse className="align-items-center">
                            <Nav>
                                <Button
                                    href={`mailto:${SUPPORT_EMAIL}?subject=${PORTAL_NAME}`}
                                >
                                    <FontAwesomeIcon icon={faEnvelope} size="lg" className="mr-1" />
                                    <span> {t('common:NavBar.Support')}</span>
                                </Button>
                                <Button
                                    as={NavLink}
                                    to="/help"
                                    activeClassName="active"
                                >
                                    <FontAwesomeIcon icon={faQuestionCircle} size="lg" className="mr-1" />
                                    <span>{t('common:NavBar.Help')}</span>
                                </Button>
                                <Notifications />
                                <UserSettings />
                            </Nav>
                        </Navbar.Collapse>
                    )
                }
            }
            </Translation>
        );
    }
}
export default NavMenuUpper;
