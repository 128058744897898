import * as React from 'react';
import { Translation } from 'react-i18next';
import { ICustomer } from '../../../Interface/Interfaces';
import { Row, Col } from 'react-bootstrap';
import CustomerProfile from './CustomerInfo/CustomerProfile/CustomerProfile';

export interface ICustomerInfoProps {
    customer: ICustomer;
    getCustomer: (customerNumber: string) => void;
}

export default class CustomerInfo extends React.Component<ICustomerInfoProps> {
    static displayName = 'CustomerInfo';

    public showMore: boolean = false;

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row>
                            <Col>
                                <Row>
                                    <Col>                                                                                  
                                        <CustomerProfile
                                            customer={this.props.customer}                                         
                                            getCustomer={this.props.getCustomer}
                                        />                                         
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                }
            }
            </Translation>
        );
    }
}
