import * as React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import i18n_ from './translationcomponent/i18n';
import MyTickets from './HomeLeftComponents/MyTickets';
import Events from './HomeLeftComponents/Events';
import * as TicketsStore from '../store/Tickets';
import { IApplicationState } from '../store';
import TwitterFeed from './TwitterComponent/Twitter';
import { Row, Col } from 'react-bootstrap';
import Cardwrapper from './CommonComponents/Containers/Cardwrapper';
import { VERSION } from '../Utils/config';
import WelcomePopup from './smallComponents/WelcomePopup';
import ErrorBoundary from './HOC/ErrorBoundary';
import { bindActionCreators } from 'redux';
import ReleaseModal from "./smallComponents/ReleaseModal";
import ReleaseModalsService from "../apiServices/ReleaseModalsService";
import { IReleaseModal } from "../Interface/Interfaces/ReleaseModals/IReleaseModal";

type IHomeProps = {
    states: {
        tickets: TicketsStore.ITicketsState,
    }
    actions: {
        tickets: typeof TicketsStore.actionCreators
    }
};

export interface IHomeState {
    showWelcomePopup: boolean;
    showChangeLogPopup: boolean;
    releaseModal: IReleaseModal;
}

class Home extends React.Component<IHomeProps, IHomeState> {
    static displayName = 'Home';

    constructor(props: any) {
        super(props);
        this.state = {
            showWelcomePopup: false,
            showChangeLogPopup: false,
            releaseModal: {
                releaseDate: null,
                textEng: '',
                textSv: ''
            }
        }
    }

    public componentDidMount() {
        ReleaseModalsService.GetReleaseModalText()
            .then((result: IReleaseModal) => {
                this.setState({
                    releaseModal: result,
                    showChangeLogPopup: (result.textEng !== '' || result.textSv !== '') && (result.releaseDate != null)
                });
            })
    }

    public changeTitle = () => {
        document.title = i18n_.t('common:KPMGTitle');
    }

    public disablePopup = () => {
        this.setState({ showChangeLogPopup: false });
        localStorage.setItem('changeLogPopupVersion', VERSION!);
    }

    public disableWelcomePopup = () => {
        this.setState({ showWelcomePopup: false });
        localStorage.setItem('disableWelcomePopup', 'true');
        localStorage.setItem('changeLogPopupVersion', VERSION!);
    }

    public render() {
        return (
            <ErrorBoundary>
                <Translation>{
                    (t) => {
                        this.changeTitle();
                        return (
                            <>
                                <Row className="mt-3">
                                    <Col lg="8">
                                        <Row className="grid-margin-bottom">
                                            <Col>
                                                <Cardwrapper>
                                                    <Row>
                                                        <Col>
                                                            <h1>{t('common:StartPage.Title')}</h1>
                                                            <p style={{ whiteSpace: "pre-line" }}>{t('common:StartPage.Body')}</p>
                                                        </Col>
                                                    </Row>
                                                </Cardwrapper>
                                            </Col>
                                        </Row>
                                        <Row className="grid-margin-bottom">
                                            <Col>
                                                <Cardwrapper>
                                                    <Row>
                                                        <Col className="old">
                                                            <MyTickets
                                                                myTickets={this.props.states.tickets.myTickets}
                                                                loading={this.props.states.tickets.tickets.loading}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Cardwrapper>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Cardwrapper variant="development">
                                                    <Row>
                                                        <Events />
                                                    </Row>
                                                </Cardwrapper>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4">
                                        <Cardwrapper>
                                            <TwitterFeed />
                                        </Cardwrapper>
                                    </Col>
                                </Row>
                                <WelcomePopup showModal={this.state.showWelcomePopup} disableWelcomePopup={this.disableWelcomePopup} />
                                {this.state.showChangeLogPopup && (this.state.releaseModal.textEng || this.state.releaseModal.textSv) &&
                                    <ReleaseModal
                                        showModal={this.state.showChangeLogPopup}
                                        disablePopup={this.disablePopup}
                                        releaseModal={this.state.releaseModal}
                                    />
                                }
                            </>
                        )
                    }
                }
                </Translation>
            </ErrorBoundary>
        );

    }
}

export default connect(
    (state: IApplicationState) => ({
        states: {
            tickets: state.tickets,
        }
    }),
    (dispatch) => ({
        actions: {
            tickets: bindActionCreators(TicketsStore.actionCreators, dispatch),
        }
    })
)(Home);
