import * as React from "react";
import { Translation } from "react-i18next";
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { PortalsTextViewer } from "@kpmgportals/portalstexteditor";
import { IReleaseModal } from "../../Interface/Interfaces/ReleaseModals/IReleaseModal";
import DateTimeHelper from "../../Utils/DateTimeHelper";

interface IReleaseModalProps {
    showModal: boolean;
    disablePopup: () => void;
    releaseModal: IReleaseModal | null;
}

interface IReleaseModalState {
    userLanguage: string | null;
}

export default class ReleaseModal extends React.Component<IReleaseModalProps, IReleaseModalState> {
    static displayName = 'ReleaseModal';

    constructor(props: IReleaseModalProps) {
        super(props);
        this.state = {
            userLanguage: localStorage.getItem("i18nextLng")
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Modal style={{ zIndex: 10000 }} show={this.props.showModal} onHide={this.props.disablePopup}>
                            <Modal.Header>
                                <h2>{t('common:StartPage.ReleaseModal.Title')}</h2>
                            </Modal.Header>
                            <Modal.Body>
                                {this.props.releaseModal &&
                                    <Row>
                                        <Col>
                                            <Row className="mb-3">
                                                <Col>
                                                    {this.props.releaseModal.releaseDate ? DateTimeHelper.FormatYearMonthDay(new Date(this.props.releaseModal.releaseDate)) : ''}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <PortalsTextViewer
                                                        value={this.state.userLanguage === 'sv-SE' && this.props.releaseModal?.textSv !== '' ? this.props.releaseModal!.textSv : this.props.releaseModal!.textEng}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    onClick={this.props.disablePopup}
                                    size="sm"
                                >
                                    {t('common:Close')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )
                }
            }
            </Translation>
        )
    }
}
