import * as React from 'react';
import { Translation } from 'react-i18next';
import 'core-js';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import Menu from 'react-burger-menu/lib/menus/slide'
import { Navbar, Button } from 'react-bootstrap';
import NavMainItems from './smallComponents/NavMainItems';
import NavMobileSecondaryItems from './smallComponents/NavMobileSecondaryItems';

export interface ISideNavMenuProps {
    toggleSideNav: () => void;
    isOpen: boolean;
    firstName: string;
    lastName: string;
    FirstName: string;
    LastName: string;
    logOut: () => void;

    getNotifications: () => {};
    ticketsNotifications: number;
    clearTicketNotifications: () => {};
}

interface ISideNavMenuState {
    redirect?: boolean;
}

export default class SideNavMenu extends React.Component<ISideNavMenuProps, ISideNavMenuState> {
    static displayName = 'SideNavMenu';

    constructor(props: any) {
        super(props)
        this.state = {
            redirect: false
        };
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            {this.state.redirect &&
                                <Redirect
                                    to='/logout'
                                />
                            }
                            <Navbar
                                as={Menu}
                                className={`nav-mobile mobile-menu bg-primary flex-column nav-main${this.props.isOpen ? ' nav-mobile-shadow' : ''}`}
                                menuClassName="position-relative pt-5"
                                isOpen={this.props.isOpen}
                                customBurgerIcon={false}
                                disableOverlayClick={() => this.props.toggleSideNav()}
                                customCrossIcon={false}
                                disableCloseOnEsc
                            >
                                <Button
                                    variant="link"
                                    className="text-light nav-mobile-button-close position-absolute"
                                    onClick={() => this.props.toggleSideNav()}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                <NavMainItems
                                    ticketsNotifications={this.props.ticketsNotifications}
                                    clearTicketNotifications={this.props.clearTicketNotifications}
                                    isMobile
                                    toggleSideNav={this.props.toggleSideNav}
                                />
                                <NavMobileSecondaryItems
                                    firstName={this.props!.firstName!}
                                    lastName={this.props!.lastName!}
                                    FirstName={this.props!.FirstName}
                                    LastName={this.props!.LastName}
                                    toggleSideNav={this.props.toggleSideNav}
                                    logOut={this.props.logOut}
                                />
                            </Navbar>
                        </>
                    )
                }
            }
            </Translation>
        );
    }
}
