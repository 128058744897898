import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import * as AuthenticatedRoute from './AuthenticatedRoute';
import * as Profile from './Profile';
import * as Ticket from './Ticket';
import * as Tickets from './Tickets';
import * as Notification from './Notification';
import * as Toasts from './Toasts';
import * as Customer from './Customer';
import * as CustomerHub from './CustomerHub';
import { insightsMonitor } from 'redux-appinsights';
import { createBrowserHistory } from 'history';
import * as FileRequest from './FileRequest';

export const history = createBrowserHistory({ basename: '/' });

export default function configureStore(initialState : any) {
    const reducers = {
        authenticatedRoute: AuthenticatedRoute.reducer,
        profile: Profile.reducer,
        ticket: Ticket.reducer,
        tickets: Tickets.reducer,
        notification: Notification.reducer,
        toasts: Toasts.reducer,
        customer: Customer.reducer,
        customerHub: CustomerHub.reducer,
        fileRequest: FileRequest.reducer
  };

    const middleware =
        [
            thunk,
            routerMiddleware(history),
            insightsMonitor({
                globals: {
                    env: process.env.NODE_ENV
                }
            })
        ];

  // In development, use the browser's Redux dev tools extension if installed
    const enhancers =
        [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
