import * as React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { IApplicationState } from '../store';
import * as AuthenticatedRouteStore from '../store/AuthenticatedRoute';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import DesktopLogo from './CommonComponents/Logos/DesktopLogo';
import Logger from '../Utils/Logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import Tippy from '@tippyjs/react';

type LoginProps =
    AuthenticatedRouteStore.IAuthenticatedRouteState
    & typeof AuthenticatedRouteStore.actionCreators
    & RouteComponentProps<{}>;

class Login extends React.Component<LoginProps, {}> {
    static displayName = 'Login';

    constructor(props: LoginProps) {
        super(props);
        this.props.authorize();
    }

    public componentDidMount() {
        Logger.log("loginpage login");
        this.props.requestLogIn();
        if (!document.body.classList.contains("inverted")) {
            this.attachClassNames();
        }
    }

    public componentWillUnmount() {
        document.body.classList.remove('index');
        document.body.classList.remove('login');
        document.body.classList.remove('inverted');
        document.body.classList.remove('old');
    }

    private attachClassNames = () => {
        document.body.classList.add('index');
        document.body.classList.add('login');
        document.body.classList.add('inverted');
        document.body.classList.add('old');
    }

    public signIn = (loginMethod: string) => {
        this.props.signIn(loginMethod);
    }

    public render() {
        const loggedIn = this.props.location.search.includes("?loggedIn=true");
        return (
            <Translation>{
                (t) => {
                    return (
                        <>
                            {!this.props.loggedIn && this.props.userPersonalIdentityNumberNotFound === false &&
                                <>
                                    {!loggedIn &&
                                        <div className="index login inverted old width-100">
                                            <div className="grid-y medium-grid-frame">
                                                <div className="cell auto cell-block-container cell-block-y width-100">
                                                    <div className="grid-container">
                                                        <div className="grid-x grid-padding-x padding-bottom-3 padding-top-1">
                                                            <div className="cell small-12 large-6 large-offset-3">
                                                                <div className="grid-x grid-padding-x">
                                                                    <div className="cell padding-top-3" style={{ width: "45%" }}>
                                                                        <DesktopLogo className="logo" />
                                                                    </div>
                                                                    <div className="cell">
                                                                        <div>Welcome to Client Access.</div>
                                                                    </div>
                                                                    <div className="small-12 cell padding-top-1 padding-bottom-1">
                                                                        <span className="">
                                                                            <button onClick={() => this.signIn("BankId")} style={{ width: '65%', padding: '10px 20px' }} className="button white radius small ">
                                                                                <Row>
                                                                                    <Col xs={2}>
                                                                                        <img src="/images/flag-round-sweden.png" alt="sweden" style={{ height: '40px', marginRight: '40px' }} />
                                                                                    </Col>
                                                                                    <Col xs={8} style={{ margin: 'auto' }}>
                                                                                        <span style={{ fontSize: '15px' }}>Login with BankID</span>
                                                                                    </Col>
                                                                                    <Tippy
                                                                                        content={
                                                                                            <div style={{ textAlign: 'initial', padding: '15px' }}>
                                                                                                <h2 style={{ color: 'black', fontSize: '22px' }}>Vad är BankID?</h2>
                                                                                                <p>BankID är en elektronisk ID-handling som är jämförbar med pass, körkort och andra fysiska legitimationer. För BankID krävs ett svenskt personnr eller samordningsnummer</p>
                                                                                                <h2 style={{ color: 'black', fontSize: '22px' }}>Hur skaffar jag BankID?</h2>
                                                                                                <p>Du beställer BankID hos din bank. Läs mer om hur det går till hos din bank eller på <a href="https://www.bankid.com" style={{ color: 'blue' }}>www.bankid.com</a>.</p>
                                                                                            </div>
                                                                                        }
                                                                                        allowHTML
                                                                                        trigger="mouseover mouseenter"
                                                                                        arrow
                                                                                        theme='light'
                                                                                        interactive
                                                                                        placement="bottom"
                                                                                    >
                                                                                        <Col xs={2} style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                                                                            <FontAwesomeIcon size="2x" style={{ color: '#c7c7c7' }} icon={faInfoCircle} />
                                                                                        </Col>
                                                                                    </Tippy>
                                                                                </Row>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                    <div className="small-12 cell padding-top-1 padding-bottom-1">
                                                                        <span className="">
                                                                            <button onClick={() => this.signIn("FrejaEidPlus")} style={{ width: '65%', padding: '10px 20px' }} className="button white radius small freja-plus-information">
                                                                                <Row>
                                                                                    <Col xs={2}>
                                                                                        <img src="/images/flag-round-sweden.png" alt="scandinavia" style={{ height: '40px', marginRight: '40px' }} />
                                                                                    </Col>
                                                                                    <Col xs={8} style={{ margin: 'auto' }}>
                                                                                        <span style={{ fontSize: '15px' }}>Login with Freja eID Plus</span>
                                                                                    </Col>
                                                                                    <Tippy
                                                                                        content={
                                                                                            <div style={{ textAlign: 'initial', padding: '15px' }}>
                                                                                                <h2 style={{ color: 'black', fontSize: '22px' }}>Vad är Freja eID Plus?</h2>
                                                                                                <p>Freja eID är en kostnadsfri statligt godkänd mobil e-legitimation för personer som är mantalsskrivna i Sverige. Med Freja e-id plus behöver du ingen internetbank.</p>
                                                                                                <h2 style={{ color: 'black', fontSize: '22px' }}>Hur skaffar jag Freja eID Plus?</h2>
                                                                                                <p>Du måste gå till ett godkänt Freja eID-ombud och ta med dig din mobil samt den ID-handling du registrerade. På Frejas e-id:s webbplats <a href="https://frejaeid.com/registrera-kom-igang/" style={{ color: 'blue' }}>Registrera - Kom igång - Freja eID</a> kan du läsa hur du skaffar Freja e-id plus och vilka eID ombud som finns.</p>
                                                                                            </div>
                                                                                        }
                                                                                        allowHTML
                                                                                        trigger="mouseover mouseenter"
                                                                                        arrow
                                                                                        theme='light'
                                                                                        interactive
                                                                                        placement="bottom"
                                                                                    >
                                                                                        <Col xs={2} style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                                                                            <FontAwesomeIcon className="" size="2x" style={{ color: '#c7c7c7' }} icon={faInfoCircle} />
                                                                                        </Col>
                                                                                    </Tippy>
                                                                                </Row>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                    <div className="small-12 cell padding-top-1 padding-bottom-1">
                                                                        <span className="">
                                                                            <button onClick={() => this.signIn("FrejaMail")} style={{ width: '65%', padding: '10px 20px' }} className="button white radius small">
                                                                                <Row>
                                                                                    <Col xs={2}>
                                                                                        <img src="/images/flag-round-scandinavia.png" alt="sweden" style={{ height: '40px', marginRight: '40px' }} />
                                                                                    </Col>
                                                                                    <Col xs={8} style={{ margin: 'auto' }}>
                                                                                        <span style={{ fontSize: '15px' }}>Login with Freja eID</span>
                                                                                    </Col>
                                                                                    <Tippy
                                                                                        content={
                                                                                            <div style={{ textAlign: 'initial', padding: '15px' }}>
                                                                                                <h2 style={{ color: 'black', fontSize: '22px' }}>What is Freja eID?</h2>
                                                                                                <p>Freja eID is a mobile app that represents your identity in digital form as an electronic identity (eID). For identification you need a Danish, Finnish, Norwegian or British passport.</p>
                                                                                                <h2 style={{ color: 'black', fontSize: '22px' }}>How do I get Freja eID?</h2>
                                                                                                <p>Follow this link to <a href="https://frejaeid.com/en/get-freja-eid/" style={{ color: 'blue' }}>Get Freja eID - Freja eID </a> and download the app.</p>
                                                                                                <p>For information about the registration process, visit <a href="https://frejaeid.com/en/registering-get-started/" style={{ color: 'blue' }}>Registering - Get Started - Freja eID</a>.</p>
                                                                                            </div>
                                                                                        }
                                                                                        allowHTML
                                                                                        trigger="mouseover mouseenter"
                                                                                        arrow
                                                                                        theme='light'
                                                                                        interactive
                                                                                        placement="bottom"
                                                                                    >
                                                                                        <Col xs={2} style={{ margin: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                                                                                            <FontAwesomeIcon size="2x" style={{ color: '#c7c7c7' }} icon={faInfoCircle} />
                                                                                        </Col>
                                                                                    </Tippy>
                                                                                </Row>
                                                                            </button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {loggedIn &&
                                        <div className="signed-out login width-100">
                                            <div className="grid-y medium-grid-frame">
                                                <div className="cell auto cell-block-container cell-block-y width-100">
                                                    <div className="grid-container">
                                                        <div className="grid-x grid-padding-x padding-bottom-3 padding-top-1">
                                                            <div className="cell small-12 large-8 large-offset-2">
                                                                <div className="grid-x grid-padding-x" style={{ marginTop: '15rem' }}>
                                                                    <div className="cell text-center padding-top-2">
                                                                        <FontAwesomeIcon icon={faSpinner} size="5x" spin />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {!this.props.loggedIn && this.props.userPersonalIdentityNumberNotFound === true &&
                                <Redirect to="/Unauthorized" />
                            }
                            {this.props.loggedIn &&
                                <Redirect exact to={{ pathname: "/", state: this.props.location.state }} />
                            }
                        </>
                    )
                }
            }
            </Translation>
        );
    }
}
export default connect(
    (state: IApplicationState) => state.authenticatedRoute,
    AuthenticatedRouteStore.actionCreators,
)(Login);
