import HttpHelper from './HttpHelper';
import { IUserOrganization } from '../../src/Interface/Interfaces';
import { API_ENDPOINT } from '../Utils/config';

export interface IUserProfile {
    email: string;
    firstName: string;
    lastName: string;
    organizationID: string;
    organizationOfficeID: string;
    pictureUrl?: any;
    phoneNumber?: any;
    userOrganization: IUserOrganization;
}

interface IOfficeLocation {
    id: string;
    name: string;
    adress: string;
}

interface IProfilePageInfo {
    profile: IUserProfile;
    officeLocations: IOfficeLocation[];
    whiteListValues: string[];
}

export default class UserProfileService {
    public static GetCurrentUserProfile(): Promise<IUserProfile> {
        return HttpHelper.Get(API_ENDPOINT + '/api/UserProfile/GetCurrentUserProfile');
    }

    public static GetWhiteListForCurrentUser(): Promise<string[]> {
        return HttpHelper.Get(API_ENDPOINT + '/api/UserProfile/GetWhiteListForCurrentUser');
    }

    public static GetOfficesForCurrentUser(): Promise<IOfficeLocation[]> {
        return HttpHelper.Get(API_ENDPOINT + '/api/UserProfile/GetOfficesForCurrentUser');
    }

    public static GetCurrentUserProfileInfoForPage(): Promise<IProfilePageInfo> {
        return new Promise((resolve, reject) => {
            Promise.all([
                UserProfileService.GetCurrentUserProfile(),
                UserProfileService.GetWhiteListForCurrentUser(),
                UserProfileService.GetOfficesForCurrentUser()
            ]).then((values) => {
                resolve({
                    profile : values[0], // user profile
                    whiteListValues : values[1], // whitelist
                    officeLocations : values[2] // offices
                });
            });
        });
    }

    public static UpdateCurrentUserProfile(fieldName: string, value: string): Promise<any> {
        const propertyToUpdate = { fieldName: fieldName, fieldValue: value };
        return HttpHelper.Post(API_ENDPOINT + '/api/userprofile/updatecurrentuserprofile', JSON.stringify(propertyToUpdate));
    }

    public static GetUserProfileByEmail(email: string): Promise<any> {
        return HttpHelper.Post(API_ENDPOINT + '/api/UserProfile/GetUserByEmail', JSON.stringify(email));
    }

    public static UploadProfileImage(image64: string): Promise<any> {
        return HttpHelper.Post(API_ENDPOINT + '/api/userprofile/addimagefile', JSON.stringify(image64));
    }
}
