import * as React from 'react';
import KPMGImageFallback from '@kpmgportals/kpmgimagefallback';
import { ORG_IMAGE_CDN } from '../../../Utils/config';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { defaultAvatarOrg } from '@kpmgportals/kpmgassets';

export interface IInlineOrganizationProps {
    className: string;
    label: string;
    pictureUrl?: string;
    customerId?: string;
    size?: 'large' | 'normal' | 'small';
}

export default class InlineOrganization extends React.Component<IInlineOrganizationProps, {}> {
    static displayName = 'InlineOrganization';

    public static defaultProps = {
        size: "normal"
    }

    public getImageSize() {
        if (this.props.size === 'large') {
            return 'xxlarge';
        }
        if (this.props.size === 'normal') {
            return 'xlarge';
        }
        if (this.props.size === 'small') {
            return 'large';
        }
        return 'xlarge';
    }

    public classes() {
        let imageSize = this.getImageSize();
        const classes = classNames(
            'profile-image-background',
            'rounded-circle',
            `profile-image-${imageSize}`,
        );

        return classes;
    }

    public render() {
        return (
            <Row noGutters className="align-items-center flex-nowrap text-truncate">
                <Col xs="auto" className="mr-1">
                    <picture>
                        <KPMGImageFallback
                            sourceSet={ORG_IMAGE_CDN + this.props.customerId! + "_s.jpg?image=" + this.props.pictureUrl + ", " + ORG_IMAGE_CDN + this.props.customerId + "_l.jpg?image=" + this.props.pictureUrl + " 2x"}
                            src={this.props.pictureUrl === '' || this.props.pictureUrl == null
                                ? "" : `${ORG_IMAGE_CDN}${this.props.customerId}_s.jpg?image=${this.props.pictureUrl}`}
                            fallbackImage={defaultAvatarOrg}
                            initialImage={defaultAvatarOrg}
                            alt={this.props.label}
                            className={this.classes()}
                        />
                    </picture>
                </Col>
                <Col
                    className="text-line-height-12 text-truncate"
                >
                    <strong>{this.props.label}</strong>
                </Col>
            </Row>
        );
    }
}
