import { ToastMessage } from '../classes/ToastMessage';
import { store } from '../';

export class ToastFactory {
    public static createSuccessToast(subject: string, bodyText: string, hideAfter?: number | undefined, subjectPayLoad?: any | undefined) {
        let payload: any;
        if (subjectPayLoad !== undefined) {
            payload = hideAfter !== undefined ?
                new ToastMessage(subject, bodyText, "success", hideAfter, subjectPayLoad)
                : new ToastMessage(subject, bodyText, "success", hideAfter = undefined, subjectPayLoad);
        } else {
            payload = hideAfter !== undefined ?
                new ToastMessage(subject, bodyText, "success", hideAfter)
                : new ToastMessage(subject, bodyText, "success");
        }
        store.dispatch({
            type: 'ADD_TOAST',
            payload: payload
        });
        if (payload.hideAfter) {
            setTimeout(() => store.dispatch({
                type: 'REMOVE_TOAST',
                id: payload.id
            }), payload.hideAfter);
        }
    }
    public static createAlertToast(subject: string, bodyText: string, hideAfter?: number | undefined, subjectPayLoad?: any | undefined) {
        let payload: any;
        if (subjectPayLoad !== undefined) {
            payload = hideAfter !== undefined ?
                new ToastMessage(subject, bodyText, "alert", hideAfter, subjectPayLoad)
                : new ToastMessage(subject, bodyText, "alert", hideAfter = undefined, subjectPayLoad);
        } else {
            payload = hideAfter !== undefined ?
                new ToastMessage(subject, bodyText, "alert", hideAfter)
                : new ToastMessage(subject, bodyText, "alert");
        }
        store.dispatch({
            type: 'ADD_TOAST',
            payload: payload
        });
        if (payload.hideAfter) {
            setTimeout(() => store.dispatch({
                type: 'REMOVE_TOAST',
                id: payload.id
            }), payload.hideAfter);
        }
    }
    public static createDisconnectedToast() {
        store.dispatch({
            type: 'ADD_TOAST',
            payload: new ToastMessage("disconnectedSubject", "disconnectedBody", "disconnected")
        });
    }
}
