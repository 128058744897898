import * as React from 'react';
import { Translation } from 'react-i18next';
import i18n from '../../translationcomponent/i18n';
import { Col, Row } from 'react-bootstrap';
import { USER_IMAGE_CDN } from '../../../Utils/config';
import { UserInline } from '@kpmgportals/genericcomponents';
import ContactInformationService from '../../../apiServices/ContactInformationService';
import DateTimeHelper from '../../../Utils/DateTimeHelper';

export interface IHistoryProps {
    PictureGuid: string;
    ChangedBy: string;
    Status: string;
    ChangedTime: string;
    ChangedById: string;
}

export interface IHistoryState {
    StatusChange: boolean;
}

export default class History extends React.Component<IHistoryProps, IHistoryState> {
    static displayName = 'History';

    constructor(props: IHistoryProps) {
        super(props);
        this.state = { StatusChange: false };
    }
    private GetChange(change: string) {
        switch (change) {
            case 'WaitingForPartner':
                return i18n.t('tickets:Status.WaitingForPartner');
            case 'updated':
                return i18n.t('tickets:Status.Updated');
            case 'commented':
                return i18n.t('tickets:Status.Commented');
            case 'WaitingForKPMG':
                return i18n.t('tickets:Status.WaitingForKPMG');
            case 'created':
                return i18n.t('tickets:Status.Created');
            case 'edited':
                return i18n.t('tickets:Status.Edited');
            case 'Removed':
                return i18n.t('tickets:Status.Removed');
            case 'Resolved':
                return i18n.t('tickets:Status.Closed');
            case 'change':
                return i18n.t('tickets:Status.Changed');
            case 'assigned':
                return i18n.t('tickets:Status.Assigned');
            case 'unassigned':
                return i18n.t('tickets:Status.Unassigned');
            case 'FinancialYear':
                return i18n.t('tickets:Status.FinancialYear');
            case 'Reopen':
                return i18n.t('tickets:Status.Reopened');
            case 'rejected':
                return i18n.t('tickets:Filerequest.Status.RejectFileRequest');
            case 'FileRequestPartDelivery':
                return i18n.t('tickets:Filerequest.Status.PartDelivery');
            case 'FileRequestFullDelivery':
                return i18n.t('tickets:Filerequest.Status.FullDelivery');
            default:
                return '';
        }
    }

    public render() {
        return (
            <Translation>
                {
                    (t) => {
                        return (
                            <Row>
                                <Col xs="auto">
                                    <UserInline
                                        user={{
                                            email: "",
                                            firstName: "",
                                            lastName: "",
                                            id: this.props.ChangedById,
                                            name: this.props.ChangedBy,
                                            phone: "",
                                            pictureUrl: this.props.PictureGuid,
                                            title: "",
                                            userId: this.props.ChangedById
                                        }}
                                        USER_IMAGE_CDN={USER_IMAGE_CDN}
                                        ContactInformationService={ContactInformationService}
                                    />
                                    {this.props.Status === "WaitingForCustomer" || this.props.Status === "WaitingForKPMG" ? t('tickets:Ticket.History.StatusChange') : ""}
                                    {this.state.StatusChange ? t('tickets:Ticket.History.Status') : ""} <strong> {this.GetChange(this.props.Status)}</strong> {" "}
                                    {t('common:History.On')} <span className="text-color-black">{DateTimeHelper.FormatYearMonthDayWithTime(this.props.ChangedTime).toString()}</span>
                                </Col>
                            </Row>
                        );
                    }
                }
            </Translation>
        );
    }
}
