import * as React from 'react';
import { Translation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFirefox, faEdge, faChrome, faInternetExplorer } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Modal, Row, Col, Button, Container } from 'react-bootstrap';

export interface BrowserModalState {
    showBrowserModal: boolean;
}

export default class BrowserModal extends React.Component<{}, BrowserModalState> {
    static displayName = 'BrowserModal';

    constructor(props: any) {
        super(props);
        this.state = {
            showBrowserModal: false
        }
    }

    public componentDidMount() {
        this.wasModalAlreadyShown();
    }

    public wasModalAlreadyShown = () => {
        const modal = JSON.parse(sessionStorage.getItem("KPMGShowBrowserModal")!);
        if (modal == null || modal === undefined) {
            this.checkForIE();
        }
    }

    public checkForIE = () => {

        var sAgent = window.navigator.userAgent;
        var Idx = sAgent.indexOf("MSIE");

        if (Idx > 0 || !!navigator.userAgent.match(/Trident\/7\./)) {
            // Is IE 8, 9, 10 or 11.
            this.setState({ showBrowserModal: true });
            let modalWasShown = { wasShown: true };
            sessionStorage.setItem('KPMGShowBrowserModal', JSON.stringify(modalWasShown));
        } else {
            // It is IE
            this.setState({ showBrowserModal: false });
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Modal
                            show={this.state.showBrowserModal}
                            onHide={() => this.setState({ showBrowserModal: false })}
                            dialogClassName="modal-90vw"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>{t('common:BrowserModal.IEStatement')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <Row className="mb-3">
                                                <Col className="text-center">
                                                    <FontAwesomeIcon
                                                        icon={faInternetExplorer}
                                                        size="4x"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>{t('common:BrowserModal.IEStatement')}</p>
                                                    <p>{t('common:BrowserModal.ModernBrowserSuggestion')}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row className="mb-3">
                                                <Col className="text-center">
                                                    <FontAwesomeIcon
                                                        icon={faArrowRight}
                                                        size="4x"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-center">
                                                    <p>{t('common:BrowserModal.ConsiderationStatement')}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row className="mb-3">
                                                <Col className="text-center">
                                                    <FontAwesomeIcon
                                                        icon={faChrome}
                                                        size="4x"
                                                        className="mx-3"
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faFirefox}
                                                        size="4x"
                                                        className="mx-3"
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faEdge}
                                                        size="4x"
                                                        className="mx-3"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>{t('common:BrowserModal.Recommendation')}</p>
                                                    <ul>
                                                        <li>Google Chrome</li>
                                                        <li>Mozilla Firefox</li>
                                                        <li>Microsoft Edge</li>
                                                    </ul>
                                                    <p>{t('common:BrowserModal.ContactIT')}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    onClick={() => this.setState({ showBrowserModal: false })}
                                >
                                    {t('common:BrowserModal.Continue')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )
                }
            }
            </Translation>
        );
    }
}

