import * as React from 'react';
import { Translation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { Table } from '@kpmgportals/genericcomponents';
import { KPMGContextConsumer } from '@kpmgportals/genericcomponents';

interface ITicketListTableHeaderProps {
    ticketsLength: number;
}
export default class TicketListTableHeader extends React.Component<ITicketListTableHeaderProps> {
    static displayName = 'TicketListTableHeader';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <KPMGContextConsumer>
                            {KPMGContext => (
                                <Table.Head
                                    style={
                                        { paddingRight: `${this.props.ticketsLength > 7 ? `${KPMGContext?.scrollbarSize}px` : 0}` }
                                    }
                                    className="mx-0"
                                >
                                    <Table.Col
                                        th
                                        separator
                                        className="ticket-id flex-grow-1"
                                    >
                                        {t('tickets:MyTicket.TicketID')}
                                    </Table.Col>
                                    <Table.Col
                                        th
                                        separator
                                        className="ticket-title flex-grow-1"
                                    >
                                        {t('common:Information.Title')}
                                    </Table.Col>
                                    <Table.Col
                                        th
                                        separator
                                        className="ticket-customer flex-grow-1"
                                    >
                                        {t('tickets:Ticket.Customer')}
                                    </Table.Col>
                                    <Table.Col
                                        th
                                        separator
                                        className="ticket-attachments flex-grow-1"
                                    >
                                        <FontAwesomeIcon icon={faPaperclip} />
                                    </Table.Col>
                                    <Table.Col
                                        th
                                        separator
                                        className="ticket-created flex-grow-1"
                                    >
                                        {t('tickets:Ticket.Created')}
                                    </Table.Col>
                                    <Table.Col
                                        th
                                        separator
                                        className="ticket-changed flex-grow-1"
                                    >
                                        {t('tickets:Ticket.Changed')}
                                    </Table.Col>
                                    <Table.Col
                                        th
                                        className="ticket-status flex-grow-1"
                                    >
                                        {t('tickets:Ticket.Status')}
                                    </Table.Col>
                                </Table.Head>
                            )}
                        </KPMGContextConsumer>
                    );
                }
            }
            </Translation>
        );
    }
}
