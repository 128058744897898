import { ISort, ICustomer } from "../Interface/Interfaces";
import CustomerService from "../../src/apiServices/CustomerService";
import { Customer } from "../../src/classes/Customer";
import { ICustomerState } from "src/store/Customer";

export const TeamMemberString = "TeamMember";

export function CreatedInitialCustomerSort(): ISort {
    return {
        sortOn: "name",
        sortOrder: "asc"
    };
}

export function ChangeStateSort(sortOn: string, storeSort: ISort): ISort {
     if (storeSort.sortOn !== sortOn) {
         return { sortOn: sortOn, sortOrder: "asc" };
     } else if (storeSort.sortOrder === "asc") {
         return { sortOn: sortOn, sortOrder: "desc" };
     } else {
         return { sortOn: sortOn, sortOrder: "asc" };
     }
}

export function UpdateCustomer(dispatch: any, getState: any, customerId: string) {
    customerId = customerId.split("-external")[0];
    const state:ICustomerState = getState().customer;
    if (state.selectedCustomer?.id === customerId) {
        CustomerService.GetACustomer(customerId).then((result) => {
            const customer: ICustomer = result;
            customer.isMember = true;
            dispatch({
                type: 'GET_A_CUSTOMER',
                selectedCustomer: customer
            });
        }).catch((err: Response) => {
            // We dont have access to this Customer
            const customer = new Customer();
            customer.isMember = false;
            customer.customerNumber = state.selectedCustomer.customerNumber;
            if (err.status === 304) {
                customer.isRequested = true;
            }
            dispatch({
                type: 'GET_A_CUSTOMER_ACCESSDENIED',
                selectedCustomer: customer
            });
        });
    }
}
