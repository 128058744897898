import React from 'react';
import { Col, ColProps } from 'react-bootstrap';
import classNames from 'classnames';

type verticalAlignsTypes = 'top' | 'bottom' | 'center' | 'baseline' | 'stretch' | 'none' | 'end' | 'start';

export interface ITableColProps extends ColProps {
    className?: string;
    onClick?: () => void;
    th?: boolean;
    verticalAlign?: verticalAlignsTypes;
    truncate?: boolean; // Preps for truncating (ellipsis) text in column. Still requires that the child is wrapped in an element with 'text-truncate' as class.
    separator?: boolean;
}

export default class TableCol extends React.Component<ITableColProps> {
    static displayName = 'TableCol';

    public contentVerticalAlign(align: verticalAlignsTypes) {
        if (align === 'none') {
            return '';
        }
        return `d-flex align-items-${align}`;
    }

    public classes(
        className?: string,
        th?: boolean,
        truncate?: boolean,
        separator?: boolean,
        verticalAlign?: verticalAlignsTypes,
    ) {
        let alignment = verticalAlign ? verticalAlign : 'center';
        if (verticalAlign === 'bottom') {
            alignment = 'end';
        }
        if (verticalAlign === 'top') {
            alignment = 'start';
        }
        const classes = classNames(
            className,
            th ? 'th' : 'td',
            truncate ? 'table-col-truncate-width' : '',
            separator ? 'line-after' : '',
            verticalAlign ? this.contentVerticalAlign(alignment) : this.contentVerticalAlign('center'),
        );
        return classes;
    }

    public render() {
        const {
            className,
            th,
            verticalAlign = 'center',
            truncate,
            onClick,
            separator,
            ...props
        } = this.props;
        return (
            <Col
                onClick={onClick}
                className={this.classes(className, th, truncate, separator, verticalAlign)}
                {...props}
            >
                {this.props.children}
            </Col>
        );
    }
}
