import { Reducer } from 'redux';
import { INotification } from './../Interface/Interfaces';
import { NotificationActions } from './actions/NotificationActions';
import { NotificationReducer } from './reducers/NotificationReducers';

export interface INotificationState {
    allNotifications: any[];
    unreadCount: number;
    nextPageToken: string;
    hasNextPage: boolean;
    allNotificationsLoaded: boolean;
}
export interface INotificationOwnProps {
    open: boolean;
    close?: () => void;
}

interface INotificationUnreadAction { type: 'GE_MY_NOTIFICATIONS'; allNotifications: any[]; nextPageToken: string; hasNextPage: boolean; }

interface INotificationReadAction { type: 'CLEAR_NOTIFICATIONS'; }

interface INotificationNewAction { type: 'NEW_NOTIFICATION'; notfication: INotification; }

interface INotificationGetUnreadCountAction { type: 'UNREAD_NOTIFICATION_COUNT'; unreadCount: number; }

interface INotificationGetMoreAction { type: 'GET_MORE_NOTIFICATIONS'; moreNotifications: any[]; nextPageToken: string; hasNextPage: boolean; }

export type KnownAction = INotificationUnreadAction | INotificationReadAction | INotificationGetUnreadCountAction | INotificationNewAction | INotificationGetMoreAction;

export const actionCreators = NotificationActions;

export const reducer: Reducer<INotificationState> = (state: INotificationState, action: KnownAction) => NotificationReducer(state, action);
