import * as React from 'react';
import { Translation } from 'react-i18next';
import i18n from '../../translationcomponent/i18n';
import { ITicket } from '../../../Interface/Interfaces';
import { TypeOfTicket, TicketStatus, FiscalYearEnum, OriginEnum, DeliveredStatusEnum } from '../../../Interface/Enum';
import { Col, Row, Form, FormCheck, Badge } from 'react-bootstrap';
import Cardwrapper from '../../CommonComponents/Containers/Cardwrapper';
import UserOptionOrgReactSelect from '../../CommonComponents/Inputs/UserOptionOrgReactSelect';
import { OrgSingleValue, OrgOption, UserSingleValue, UserOption, createOrgSelectOptions, createUserSelectOptions } from '../../../Utils/ReactSelectHelper';
import { UserInline } from '@kpmgportals/genericcomponents';
import { USER_IMAGE_CDN } from '../../../Utils/config';
import ContactInformationService from '../../../apiServices/ContactInformationService';
import DateTimeHelper from '../../../Utils/DateTimeHelper';

interface TicketHeaderProps {
    newTicket: boolean;
    ticket: ITicket;
    updateTicket?: (e: any) => void;
    validateFiscalYear?: (e: any) => void;
    selectedFiscalYearIsClosed?: boolean;
    organizations?: any;
    setAccountingCompany?: (e: any) => void;
    users?: any;
    setRecipient?: (e: any) => void;
    setAssignee?: (e: any) => void;
    fileRequestDeliveredStatus?: DeliveredStatusEnum;
}

export default class TicketHeader extends React.Component<TicketHeaderProps, {}> {
    static displayName = 'TicketHeader';

    get ticketRefnumber() {
        return "PT-";
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="grid-margin-bottom">
                            <Col>
                                <Cardwrapper>
                                    <Row className="mb-3">
                                        <Col xs={{ span: 6, order: 1 }} lg={{ span: "auto", order: 1 }} className="text-truncate ticket-header-col mb-3 mb-lg-0">
                                            <Row>
                                                <Col>
                                                    <label>{t('tickets:MyTicket.TicketID')}</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-truncate">
                                                    {(!this.props.newTicket || (this.props.newTicket && this.props.ticket.refNumber !== "")) &&
                                                        <span>{this.props.ticket.refNumber}</span>
                                                    }
                                                    {(this.props.newTicket && this.props.ticket.refNumber === "") &&
                                                        <span>{this.ticketRefnumber}</span>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ span: 6, order: 2 }} lg={{ span: "auto", order: 2 }} className="text-truncate ticket-header-col mb-3 mb-lg-0">
                                            <Row>
                                                <Col>
                                                    <label>{t('tickets:Search.Status')}</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-truncate">
                                                    {(this.props.newTicket && (this.props.ticket.ticketStatus === TicketStatus.Created || this.props.ticket.ticketStatus === TicketStatus.UnfinishedDraft)) &&
                                                        <span>{t('common:Information.New')}</span>
                                                    }
                                                    {(!this.props.newTicket || (this.props.newTicket && this.props.ticket.ticketStatus !== TicketStatus.Created)) &&
                                                        (this.props.ticket.typeOfTicket === TypeOfTicket.PreparedByPartner && this.props.fileRequestDeliveredStatus) ?
                                                        (
                                                            <>
                                                                <Badge variant="warning">
                                                                    <span>{t('tickets:Status.' + TicketStatus[this.props.ticket.ticketStatus])}</span>
                                                                </Badge>
                                                                <Badge className="ml-1" variant={this.props.fileRequestDeliveredStatus === DeliveredStatusEnum.FullyDelivered ? 'success' : 'warning'}>
                                                                    <span>{t('tickets:Filerequest.DeliverStatus.' + DeliveredStatusEnum[this.props.fileRequestDeliveredStatus!])}</span>
                                                                </Badge>
                                                            </>
                                                        ) : (
                                                            (this.props.ticket.ticketStatus !== TicketStatus.UnfinishedDraft &&
                                                                <Badge variant="warning">
                                                                    <span>{t('tickets:Status.' + TicketStatus[this.props.ticket.ticketStatus])}</span>
                                                                </Badge>
                                                            )
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ span: 6, order: 3 }} lg={{ span: "auto", order: 3 }} className="text-truncate ticket-header-col mb-3 mb-lg-0">
                                            <Row>
                                                <Col>
                                                    <label>{t('tickets:Status.Created')}</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-truncate">
                                                    {(this.props.newTicket && (this.props.ticket.created === "" || this.props.ticket.created === null)) &&
                                                        <span />
                                                    }
                                                    {(!this.props.newTicket || (this.props.newTicket && (this.props.ticket.created !== "" && this.props.ticket.created !== null))) &&
                                                        <span>{DateTimeHelper.FormatYearMonthDayWithTime(this.props.ticket.created)}</span>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ span: 6, order: 5 }} lg={{ span: "auto", order: 4 }} className="ticket-header-col">
                                            <Row>
                                                <Col>
                                                    <label>{t('common:Information.CreatedBy')}</label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <UserInline
                                                        user={{
                                                            email: this.props.ticket.authorEmail,
                                                            firstName: "",
                                                            lastName: "",
                                                            name: this.props.ticket.authorText?.length > 16 ? this.props.ticket.authorText?.substring(0, 13) + "..." : this.props.ticket.authorText,
                                                            id: this.props.ticket.authorId,
                                                            phone: "",
                                                            pictureUrl: this.props.ticket.authorPicture!,
                                                            title: "",
                                                            userId: this.props.ticket.authorId
                                                        }}
                                                        USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                        ContactInformationService={ContactInformationService}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ span: 6, order: 4 }} lg={{ span: "auto", order: 5 }} className="text-truncate ticket-header-col mb-3 mb-lg-0">
                                            <Row>
                                                <Col>
                                                    <Form.Label htmlFor="ticket-checkbox-confidential">
                                                        {t('help:NewTicket.Confidential.Title')}
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-truncate">
                                                    <FormCheck
                                                        custom
                                                        type="switch"
                                                    >
                                                        <FormCheck.Input
                                                            type="checkbox"
                                                            id="ticket-checkbox-confidential"
                                                            onChange={this.props.updateTicket ? (e: any) => this.props.updateTicket!(e) : () => false}
                                                            checked={this.props.ticket.confidential}
                                                            data-value="confidential"
                                                            disabled={!this.props.newTicket}
                                                        />
                                                        <FormCheck.Label htmlFor="ticket-checkbox-confidential">
                                                            {this.props.ticket.confidential ? (
                                                                <span>{t('common:Statement.Yes')}</span>
                                                            ) :
                                                                (
                                                                    <span>{t('common:Statement.No')}</span>
                                                                )
                                                            }
                                                        </FormCheck.Label>
                                                    </FormCheck>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }} lg={{ span: "auto", order: 1 }} className="text-truncate ticket-header-col">
                                            <Row>
                                                <Col>
                                                    <label htmlFor="ticketType">
                                                        {t('tickets:Ticket.Tickettype')}
                                                    </label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {this.props.ticket.typeOfTicket === TypeOfTicket.PreparedByPartner ? (
                                                        <span>
                                                            {t('tickets:TicketTypes.PreparedByPartner')}
                                                        </span>
                                                    ) :
                                                        (
                                                            <span>
                                                                {t('tickets:TicketTypes.PartnerTicket')}
                                                            </span>
                                                        )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }} lg={{ span: "auto", order: 2 }} className="ticket-header-col">
                                            <Row>
                                                <Col>
                                                    <label htmlFor="ticket-search-organization-dropdown">
                                                        {t('tickets:NewTicket.Organization')}<sup className="required">*</sup>
                                                    </label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="ticket-search-organization-dropdown">
                                                        <UserOptionOrgReactSelect
                                                            id="ticket-search-organization-dropdown"
                                                            isMulti={false}
                                                            singleValue={OrgSingleValue}
                                                            option={OrgOption}
                                                            getSelectedValue={this.props.setAccountingCompany}
                                                            options={this.props.newTicket ? createOrgSelectOptions(this.props.organizations) : [{ value: this.props.ticket.customer, label: this.props.ticket.customer!.name }]}
                                                            placeholder={t('tickets:NewTicket.SetOrganization')}
                                                            isDisabled={!this.props.newTicket}
                                                            size="sm"
                                                            defaultValue={this.props.newTicket ? '' : { value: this.props.ticket.customer, label: this.props.ticket.customer?.name }}
                                                            value={this.props.ticket.customer?.name ? { value: this.props.ticket.customer, label: this.props.ticket.customer?.name } : ''}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ span: 12, order: 3 }} md={{ span: 6, order: 3 }} lg={{ span: "auto", order: 3 }} className="ticket-header-col">
                                            <Row>
                                                <Col>
                                                    <label htmlFor="fiscalYear">
                                                        {t('tickets:Ticket.FiscalYear')}
                                                    </label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="fiscalYear">
                                                        <Form.Control
                                                            as="select"
                                                            value={this.props.ticket.fiscalYear.toString()}
                                                            disabled={this.props.ticket.ticketStatus === TicketStatus.Created || this.props.ticket.ticketStatus === TicketStatus.UnfinishedDraft || this.props.ticket.ticketStatus === TicketStatus.Draft ? false : true}
                                                            onChange={this.props.validateFiscalYear ? (e: any) => this.props.validateFiscalYear!(e.currentTarget.value) : () => false}
                                                            size="sm"
                                                        >
                                                            <option value={this.props.ticket.fiscalYear === FiscalYearEnum.NoShow ? FiscalYearEnum.NoShow : FiscalYearEnum.NotSet}>{i18n.t('tickets:Ticket.SelectFinancialYear')}</option>
                                                            {(this.props.ticket.customer?.fiscalYears && this.props.ticket.customer?.fiscalYears?.length > 0) &&
                                                                <>
                                                                    <option value={FiscalYearEnum.CurrentYear}>{this.props.ticket.customer?.fiscalYears[0]?.year}</option>
                                                                    {this.props.ticket.customer?.fiscalYears.length > 1 && <option value={FiscalYearEnum.PreviousYear}>{this.props.ticket.customer?.fiscalYears[1].year}</option>}
                                                                </>
                                                            }
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ span: 12, order: 4 }} md={{ span: 6, order: 4 }} lg={{ span: "auto", order: 4 }} className="ticket-header-col">
                                            <Row>
                                                <Col>
                                                    <label htmlFor="ticket-search-recipient-dropdown">
                                                        {t('tickets:NewTicket.Recipient')}{this.props.ticket.confidential ? <sup className="required">*</sup> : ""}
                                                    </label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {(!this.props.newTicket && this.props.ticket.recipientId) &&
                                                        <UserInline
                                                            isGreyedOut={(this.props.ticket.origin !== OriginEnum.Internal && this.props.ticket.assignedTo !== undefined)}
                                                            user={{
                                                                email: this.props.ticket.recipientEmail!,
                                                                firstName: "",
                                                                lastName: "",
                                                                name: this.props.ticket.recipientText!,
                                                                id: this.props.ticket.recipientId!,
                                                                phone: "",
                                                                pictureUrl: this.props.ticket.recipientPicture!,
                                                                title: "",
                                                                userId: this.props.ticket.recipientId
                                                            }}
                                                            USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                            ContactInformationService={ContactInformationService}
                                                        />
                                                    }
                                                    {this.props.newTicket &&
                                                        <Form.Group controlId="ticket-search-recipient-dropdown">
                                                            <UserOptionOrgReactSelect
                                                                id="ticket-search-recipient-dropdown"
                                                                isMulti={false}
                                                                singleValue={UserSingleValue}
                                                                option={UserOption}
                                                                getSelectedValue={this.props.setRecipient}
                                                                options={this.props.newTicket ? createUserSelectOptions(this.props.users, false, t('tickets:NewTicket.NoRecipient')) : [{
                                                                    value: this.props.ticket.recipientId,
                                                                    label: this.props.ticket.recipientText,
                                                                    id: this.props.ticket.recipientId!,
                                                                    name: this.props.ticket.recipientText!,
                                                                    email: this.props.ticket.recipientEmail!,
                                                                    pictureUrl: this.props.ticket.recipientPicture!,
                                                                    userId: this.props.ticket.recipientId
                                                                }]}
                                                                placeholder={t('profile:User.SelectUser')}
                                                                isDisabled={(!this.props.ticket.customer?.name || !this.props.newTicket) ? true : false}
                                                                size="sm"
                                                                defaultValue={this.props.newTicket ? '' : {
                                                                    value: this.props.ticket.recipientId,
                                                                    label: this.props.ticket.recipientText,
                                                                    id: this.props.ticket.recipientId!,
                                                                    name: this.props.ticket.recipientText!,
                                                                    email: this.props.ticket.recipientEmail!,
                                                                    pictureUrl: this.props.ticket.recipientPicture!,
                                                                    userId: this.props.ticket.recipientId
                                                                }}
                                                                value={this.props.ticket.recipientId ? {
                                                                    value: this.props.ticket.recipientId,
                                                                    label: this.props.ticket.recipientText,
                                                                    id: this.props.ticket.recipientId!,
                                                                    name: this.props.ticket.recipientText!,
                                                                    email: this.props.ticket.recipientEmail!,
                                                                    pictureUrl: this.props.ticket.recipientPicture!,
                                                                    userId: this.props.ticket.recipientId
                                                                } : ''}
                                                            />
                                                        </Form.Group>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ span: 12, order: 5 }} md={{ span: 6, order: 5 }} lg={{ span: "auto", order: 5 }} className="ticket-header-col">
                                            {(this.props.ticket.assignedTo) &&
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <label>
                                                                {t('tickets:Ticket.Assignee')}
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <UserInline
                                                                user={{
                                                                    email: this.props.ticket.assignedTo?.email,
                                                                    firstName: this.props.ticket.assignedTo?.firstName,
                                                                    lastName: this.props.ticket.assignedTo?.lastName,
                                                                    id: this.props.ticket.assignedTo?.id,
                                                                    name: "",
                                                                    phone: "",
                                                                    pictureUrl: this.props.ticket.assignedTo?.pictureUrl,
                                                                    title: "",
                                                                    userId: this.props.ticket.assignedTo?.id
                                                                }}
                                                                USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                                ContactInformationService={ContactInformationService}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </Cardwrapper>
                            </Col>
                        </Row>
                    );
                }
            }
            </Translation>
        );
    }
}

