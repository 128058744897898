import { IAppThunkAction } from '../';
import { ICustomerState, KnownAction } from '../Customer';
import { TicketsKnownAction } from '../Tickets';
import { TicketKnownAction } from '../Ticket';
import { AuthenticatedKnownAction } from '../AuthenticatedRoute';
import AppInsightsUtils from '../../Utils/AppInsightsUtils';
import CustomerService from '../../apiServices/CustomerService';
import { UserService } from '../../apiServices/UserService';
import * as signalR from '@microsoft/signalr';
import { ICustomer, ITicket, IMe } from '../../Interface/Interfaces';
import { Customer } from '../../classes/Customer';
import { UpdateCustomer, ChangeStateSort } from '../../Utils/CustomerHelper';
import { AccoutingCompany } from '../../classes/AccountingCompany';
import MyHistory from '../../history';
import TicketsService from '../../apiServices/TicketsService';
import { TicketStatus } from '../../Interface/Enum';
import TicketService from '../../apiServices/TicketService';

export const CustomersActions = {
    connectCustomer: (connectionCustomer:signalR.HubConnection): IAppThunkAction<KnownAction> & IAppThunkAction<TicketsKnownAction> & IAppThunkAction<TicketKnownAction> & IAppThunkAction<AuthenticatedKnownAction> => (dispatch: any, getState: any) => {
        dispatch({
            type: 'RECONNECT_USER',
            reconnectUser: false
        });

        dispatch({
            type: 'CONNECT_CUSTOMERHUB',
            connectionCustomer: connectionCustomer
        });

        connectionCustomer.on("updatecustomer", (customerId: string) => {
            UpdateCustomer(dispatch, getState, customerId);
        });

        connectionCustomer.on("addedtocustomer", (customerId: string) => {
            connectionCustomer.invoke('JoinGroup', customerId);
        });

        connectionCustomer.on("customerengagementremoved", (customerId: string) => {
            const customerState:ICustomerState = getState().customer;
            if (customerState.selectedCustomer?.id === customerId) {
                var customer = {...customerState.selectedCustomer};
                customer.isMember = false;
                dispatch({
                    type: 'CUSTOMER_GET_A_CUSTOMER_ACCESSDENIED',
                    selectedCustomer: customer
                });
            }
        });

        connectionCustomer.on("reconnect", (customerId: string) => {
            connectionCustomer.stop().then(() => {
                dispatch({
                    type: 'RECONNECT_USER',
                    reconnectUser: true
                });
            }).then(() => {
                UpdateCustomer(dispatch, getState, customerId);
                if (getState().customer.searchText === "") {
                    CustomerService.GetMyCustomers(null).then((result) => {
                        dispatch({
                            type: 'GET_MY_CUSTOMERS',
                            myCustomers: result
                        });
                    });
                }
                if (MyHistory.location.pathname === "/tickets") {
                    const ticketsState = getState().tickets;
                    ticketsState.tickets.loading = true;
                    dispatch({
                        type: 'GOT_TICKETS',
                        results: ticketsState.tickets
                    });
                    TicketsService.GetAllCustomersTickets(null, ticketsState.searchText, ticketsState.resolvedTickets ? TicketStatus.Resolved : ticketsState.ticketStatus, ticketsState.selectedCustomerId, 0, ticketsState.confidential).then((tickets) => {
                        dispatch({
                            type: 'GOT_TICKETS',
                            results: tickets
                        });
                    });
                }
                if (MyHistory.location.pathname.includes('/tickets/PT-')) {
                    const ticketState = getState().ticket;
                    const realCustomerNumber = customerId.split("-");
                    if (ticketState.ticket.customer.customerNumber === realCustomerNumber[0]) {
                        TicketService.GetACustomerTicket(ticketState.ticket.refNumber).then((ticket: ITicket) => {
                            dispatch({
                                type: 'GOT_A_TICKET',
                                result: ticket
                            });
                        }).catch(() => {
                            dispatch({
                                type: 'DENIED_A_TICKET'
                            });
                        });
                    }
                }
                UserService.GetMe().then((me: IMe) => {
                    dispatch({
                        type: 'ALREADYLOGGEDIN_AUTHENTICATED',
                        me: me
                    });
                });
            });
        });
        connectionCustomer.on("refreshupdates", () => {
            dispatch({
                type: 'UPDATE_BELL',
                update: true
            });
        });
        connectionCustomer.onclose(() => {
            dispatch({
                type: 'DISCONNECTED_CUSTOMERHUB'
            });
        });
        connectionCustomer.on("userremoved", () => {
            dispatch({
                type: 'CUSTOMER_USER_REMOVED',
                userIsRemoved: true
            });
        });
        connectionCustomer.on("mailChanged", () => {
            dispatch({
                type: 'EMAIL_CHANGED',
                isChanged: true
            });
        });
    },
    changeUpdateBellState: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_BELL',
            update: false
        });
    },
    getMyDropDownCustomers: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        const customerArray = getState().authenticatedRoute.me.myCustomers.map((customer: ICustomer) => new AccoutingCompany(customer));
        dispatch({
            type: 'GET_MY_DROPDOWN_CUSTOMERS',
            myDropDownCustomers: customerArray
        });
    },
    getMyCustomers: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        CustomerService.GetMyCustomers(null).then((result) => {
            dispatch({
                type: 'GET_MY_CUSTOMERS',
                myCustomers: result
            });
        });
    },
    getMyDropDownCustomer: (customerNumber: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        CustomerService.GetACustomer(customerNumber).then((customer: ICustomer) => {
            dispatch({
                type: 'GET_MY_DROPDOWN_CUSTOMER',
                myDropDownCustomer: customer
            });
        });
    },
    clearMyDropDownCustomer: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'GET_MY_DROPDOWN_CUSTOMER',
            myDropDownCustomer: new Customer()
        });
    },
    searchTextUpdated: (e: any): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: 'SEARCH_TEXT_UPDATED',
            searchText: e.target.value
        });
        if (e.target.value === "") {
            CustomerService.GetMyCustomers(null).then((result) => {
                dispatch({
                    type: 'GET_MY_CUSTOMERS',
                    myCustomers: result
                });
            });
        }
    },
    search: (searchText: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        AppInsightsUtils.TrackEvent("Audithub | CustomerSearch", { SearchText: searchText });
        CustomerService.GetMyCustomers(null, searchText).then((result) => {
            dispatch({
                type: 'GET_MY_CUSTOMERS',
                myCustomers: result
            });
        });
    },
    updateSortState: (sortOn: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        const store = getState();
        const changedSortState = ChangeStateSort(sortOn, store.customer.sortCustomers);
        CustomerService.GetMyCustomers(getState().customer.myCustomers.nextPageToken, getState().customer.searchText, changedSortState).then((result) => {
            dispatch({
                type: 'UPDATE_SORT_STATE',
                sortCustomers: changedSortState,
                myCustomers: result
            });
        });
    },
    getMoreCustomers: (): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: "GETTING_MORE_CUSTOMERS"
        });
        const state = getState().customer;
        CustomerService.GetMyCustomers(state.myCustomers.nextPageToken, state.searchText, null).then((result) => {
            dispatch({
                type: 'GET_MORE_CUSTOMERS',
                moreMyCustomers: result,
            });
        });
    },
    setMyDropDownCustomer: (customerNumber: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState().customer;
        const selectedCustomer = state.myDropDownCustomers.find((x) => x.customerNumber === customerNumber);
        dispatch({
            type: 'GET_MY_DROPDOWN_CUSTOMER',
            myDropDownCustomer: new Customer(selectedCustomer!)
        });
    },
    getACustomer: (customerNumber: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        CustomerService.GetACustomer(customerNumber).then((result) => {
            const customer: ICustomer = result;
            customer.isMember = true;
            dispatch({
                type: 'GET_A_CUSTOMER',
                selectedCustomer: customer
            });
        }).catch((err: Response) => {
            // We dont have access to this Customer
            const customer = new Customer();
            customer.isMember = false;
            customer.customerNumber = customerNumber;
            dispatch({
                type: 'GET_A_CUSTOMER_ACCESSDENIED',
                selectedCustomer: customer
            });
        });
    },
    leaveACustomer: (): IAppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: 'LEFT_A_CUSTOMER',
        });
    },
    getACustomerTickets: (customerNumber: string): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        CustomerService.GetACustomersTickets(customerNumber).then((ticketsResult) => {
            const relevantTickets = ticketsResult.results.filter((ticket: ITicket) => {
                return (ticket.status > 0 && ticket.status < 5) || (ticket.status > 7 && ticket.status < 10);
            });
            dispatch({
                type: "GET_A_CUSTOMER_TICKETS",
                tickets: relevantTickets,
                selectedCustomerTicketPages: Math.ceil(relevantTickets.length / 5)
            });
        });
    },
    changeTicketPage: (ticketPage: any): IAppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: "CHANGE_A_CUSTOMER_TICKETS_PAGE",
            selectedCustomerTicketPage: ticketPage.selected + 1
        });
    },
    favoritesChecked: (isChecked: boolean): IAppThunkAction<KnownAction> => (dispatch) => {
        AppInsightsUtils.TrackEvent("Audithub | CustomerFavorites", { Checked: isChecked });
    }
};
