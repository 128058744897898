import { TicketsKnownAction, ITicketsState, ITicketsConnectedTickets } from '../Tickets';
import { sortMyTickets, ticketsUpdateCheck } from '../../Utils/TicketsHelper';
import { ITicket } from '../../Interface/Interfaces';

export function TicketsReducer(state: ITicketsState, action: TicketsKnownAction) {
    switch (action.type) {
        case 'GOT_TICKETS':
            return { ...state, tickets: action.results };
        case 'CLEAR_TICKETS':
            return {
                ...state,
                tickets: { hasNextPage: false, nextPageToken: null, results: [], loading: true }
            };
        case 'GOT_RECENTLY_RESOLVED':
            return { ...state, recentlyResolvedTickets: action.results };
        case 'TICKET_GETMY':
            return { ...state, myTickets: action.myTickets };
        case 'TICKETS_DISCONNECTED':
            return TicketsDisconnect(state);
        case 'TICKETS_CONNECTED':
            return TicketsConnected(state, action);
        case 'TICKETS_NOTIFICATIONS_UPDATES':
            return { ...state, ticketsNotifications: state.ticketsNotifications! + 1 };
        case 'TICKETS_UPDATES':
            const updatedTickets = ticketsUpdateCheck(action.newTicket, action.tickets);
            return UpdateTickets(state, updatedTickets);
        case 'TICKETS_READ':
            return { ...state, ticketsNotifications: 0 };
        case 'TICKET_ON_CURRENT_TICKET':
            return { ...state, currentTicket: action.currentTicket };
        case 'TICKET_LEAVE_CURRENT_TICKET':
            return { ...state, currentTicket: null };
        case 'TICKET_UPDATEMY':
            const updatedMyTickets = sortMyTickets(state.myTickets!, action.newTicket);
            return { ...state , myTickets: updatedMyTickets };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return state || {
        myTickets: [], tickets: { hasNextPage: false, nextPageToken: null, results: [], loading: true },
        recentlyResolvedTickets: [],
        ticketHubOnline: false,
        connectionTickets: null,
        ticketsNotifications: 0,
        currentTicket: null
    };
}

function TicketsDisconnect(state: ITicketsState) {
    const q = {
        ticketHubOnline: false,
        tickets: {
            hasNextPage: state.tickets.hasNextPage,
            nextPageToken: state.tickets.nextPageToken,
            results: state.tickets.results,
            loading: false
        }
    };
    return { ...state, q };
}

function TicketsConnected(state: ITicketsState, action: ITicketsConnectedTickets) {
    const q = {
        ticketHubOnline: true,
        tickets: {
            hasNextPage: state.tickets.hasNextPage,
            nextPageToken: state.tickets.nextPageToken,
            results: state.tickets.results,
            loading: false
        },
        connectionTickets: action.connection
    };
    return { ...state, q };
}

function UpdateTickets(state: ITicketsState, updatedTickets: ITicket[]) {
    const tickets = {
        hasNextPage: state.tickets.hasNextPage,
        nextPageToken: state.tickets.nextPageToken,
        results: updatedTickets,
        loading: false
    };

    return { ...state, tickets };
}
