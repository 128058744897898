import * as React from 'react';
import { NavLink } from 'react-router-dom';
import history from '../history';
import i18n from 'i18next';

class NotFound extends React.Component<{}, {}> {
    static displayName = 'NotFound';

    public render() {
        return (
            <div className="signed-out login width-100">
                <div className="grid-y medium-grid-frame">
                    <div className="cell text-center">
                        <h1 className="svg-vertical-align-text-top svg-margin-right-half humongous padding-top-3">
                            <svg aria-hidden="true" data-prefix="fas" data-icon="exclamation-circle" className="svg-icon svg-inline--fa fa-exclamation-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014 11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328 5.745-11.985 12.599z" /></svg>
                            {i18n.t('common:NotFound.PageNotFound')}
                        </h1>
                        <p>{i18n.t('common:NotFound.NotExist')}</p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <p><a onClick={() => history.goBack()}>{i18n.t('common:NotFound.GoBack')}</a> {i18n.t('common:NotFound.WhereYouCameFrom')} <NavLink exact to="/">{i18n.t('common:NotFound.StartPage')}</NavLink>.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
