import { ICustomerHubState, KnownAction, ICustomerConnectHub } from '../CustomerHub';

export function CustomerHubReducer(state: ICustomerHubState, action: KnownAction) {
    switch (action.type) {
        case 'CUSTOMERHUB_CONNECT_HUB':
            return ConnectCustomerHub(state, action);
    }

    return state || { connectionCustomer: null, connected: false };
}

function ConnectCustomerHub(state: ICustomerHubState, action: ICustomerConnectHub) {
    return { ...state, connectionCustomer: action.connectionCustomer, connected: true };
}