import * as React from 'react';
import { Col } from 'react-bootstrap';
import classNames from 'classnames';
import TableHead from './TableHead';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableCol from './TableCol';

type minWdiths = 'xl' | 'lg' | 'md' | ' sm';

export interface ITableProps {
    className?: string;
    minWidth?: minWdiths;
}

class Table extends React.Component<ITableProps> {
    static displayName = 'Table';

    static Head: typeof TableHead;
    static Body: typeof TableBody;
    static Row: typeof TableRow;
    static Col: typeof TableCol;
    public tableClasses(className?: string, minWidth?: minWdiths) {
        const tableClasses = classNames(
            'table',
            className,
            `min-${minWidth}`
        );
        return tableClasses;
    }
    public render() {
        const {
            className,
            minWidth,
            ...props
        } = this.props;
        return (
            minWidth ? (
                <div className="table-wrapper">
                    <div {...props} className={this.tableClasses(className, minWidth)}>
                        <Col>
                            {this.props.children}
                        </Col>
                    </div>
                </div>
            ) :
                (
                    <div {...props} className={this.tableClasses(className, minWidth)}>
                        <Col>
                            {this.props.children}
                        </Col>
                    </div>
                )
        );
    }
}

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Col = TableCol;
export default Table;
