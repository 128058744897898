import * as React from 'react';
import { Translation } from 'react-i18next';
import { RouteComponentProps, Prompt } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import * as TicketStore from '../../store/Ticket';
import * as CustomersStore from '../../store/Customer';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import * as AuthenticatedRouteStore from '../../store/AuthenticatedRoute';
import TicketHeader from './CommonComponents/TicketHeader';
import TicketActionHeader from './CommonComponents/TicketActionHeader';
import { ITicket, ICustomer, IFileAttachmentBase, ITicketAttachments } from '../../Interface/Interfaces';
import { Ticket } from '../../classes/Ticket';
import TicketDescription from './CommonComponents/TicketDescription';
import GenericModal from '../CommonComponents/GenericModal';
import i18n from '../translationcomponent/i18n';
import { Customer } from '../../classes/Customer';
import TicketHelper from '../../Utils/TicketHelper';
import { TypeOfTicket, FiscalYearEnum } from '../../Interface/Enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import ErrorBoundary from '../HOC/ErrorBoundary';
import { PageTransition } from '@kpmgportals/genericcomponents'
import { bindActionCreators } from 'redux';
import { history } from '../../store/configureStore';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import ConfirmModal from "src/components/CommonComponents/Modals/ConfirmModal";
import { TicketStatus } from "../../Interface/Enum";
import TicketService from "../../apiServices/TicketService";

type INewTicketProps = {
    states: {
        ticket: TicketStore.ITicketState,
        customer: CustomersStore.ICustomerState,
        authenticatedRoute: AuthenticatedRouteStore.IAuthenticatedRouteState
    }
    actions: {
        ticket: typeof TicketStore.actionCreators,
        customer: typeof CustomersStore.actionCreators,
    }
};

type INewTicketCombinedProps = INewTicketProps & RouteComponentProps<{}>;

interface INewTicketViewState {
    newTicket: ITicket;
    modalBody: any;
    modalOpen: boolean;
    newCustomer: ICustomer;
    newRecipientId: string;
    modalName: string;
    isCreated: boolean;
    isCompleteNewTicket: boolean;
    fileCount: number;
    newTicketType: TypeOfTicket;
    fiscalYear: FiscalYearEnum;
    fiscalYearClosed: object;
    selectedFiscalYearIsClosed: boolean;
    isDirty: boolean;
    ticketIsCancelled: boolean;
    isExistingTicketDraft: boolean;
    canSaveTicketDraft: boolean;
    filesNotSaved: IFileAttachmentBase[];
    deleteFileNeedsConfirmation: boolean;
    fileToRemove?: ITicketAttachments;
}

class NewTicketView extends React.Component<INewTicketCombinedProps, INewTicketViewState> {
    static displayName = 'NewTicketView';

    public ticketCreated: boolean = false;
    public onloadEvent: any;
    public hasDeletedTicket: boolean = false;
    public draftRemoved: boolean = false;
    constructor(props: INewTicketCombinedProps) {
        super(props);
        this.state = {
            newTicket: new Ticket(),
            modalBody: "",
            modalOpen: false,
            newCustomer: new Customer(),
            modalName: "",
            newRecipientId: "",
            isCreated: false,
            isCompleteNewTicket: false,
            fileCount: 0,
            newTicketType: TypeOfTicket.PartnerTicket,
            fiscalYear: FiscalYearEnum.NotSet,
            fiscalYearClosed: { currentYear: false, previousYear: false },
            selectedFiscalYearIsClosed: false,
            isDirty: false,
            ticketIsCancelled: false,
            isExistingTicketDraft: false,
            canSaveTicketDraft: false,
            filesNotSaved: [],
            deleteFileNeedsConfirmation: false,
            fileToRemove: undefined
        };

        this.onloadEvent = window.addEventListener('beforeunload', (e) => {
            this.removeTicketWhenNotSaved();
        });
    }

    public componentDidMount() {
        this.props.actions.customer.getMyDropDownCustomers();

        const ticketDraftId = (this.props.location.state !== undefined && this.props.location.state !== null) ? (this.props.location.state! as any).ticketDraftId : null;

        if (ticketDraftId) {
            this.props.actions.ticket.getATicket(ticketDraftId);
            this.setState({ isExistingTicketDraft: true });
        } else {
            this.props.actions.ticket.createTicket();
            this.setState({ isExistingTicketDraft: false });
        }
    }

    public componentDidUpdate(prevProps: INewTicketCombinedProps) {
        if (this.props.states.authenticatedRoute.me !== prevProps.states.authenticatedRoute.me) {
            let ticket = this.updateMe(this.state.newTicket);
            this.setState({ ...this.state, newTicket: ticket });
            this.props.actions.customer.getMyDropDownCustomers();
        }

        if (prevProps.states.ticket.activeTicket.attachedFiles?.length !== this.props.states.ticket.activeTicket.attachedFiles?.length) {
            let ticket = this.state.newTicket;
            ticket.attachedFiles = this.props.states.ticket.activeTicket.attachedFiles;
            this.setState({ newTicket: ticket });
        }
        const customer = (this.props.location.state !== undefined && this.props.location.state !== null) ? (this.props.location.state! as any).customer : null;
        if ((customer !== null && customer !== undefined) && (customer.id !== this.state.newCustomer.id)) {
            const { location, history } = this.props;

            let state = { ...history.location.state as any };
            delete state.customer;
            history.push(location.pathname, state);
            this.setState({ newCustomer: customer }, () => {
                this.updateOrg();
                this.props.actions.customer.getMyDropDownCustomer(this.state.newCustomer.id);
            })
        }
        if (!this.props.location.state && this.props.states.customer.myDropDownCustomers!.length === 1 && !this.state.newCustomer.id) {
            this.setState({ newCustomer: new Customer(this.props.states.customer.myDropDownCustomers[0]) }, () => {
                this.updateOrg();
                this.props.actions.customer.getMyDropDownCustomer(this.state.newCustomer.id);
            });
        }

        if (this.props.states.customer.myDropDownCustomer.id !== prevProps.states.customer.myDropDownCustomer.id) {
            this.setState({ newTicket: { ...this.state.newTicket, customer: { ...this.state.newTicket.customer!, fiscalYears: this.props.states.customer.myDropDownCustomer.fiscalYears } } })
        }

        if (this.props.states.ticket.activeTicket?.id && this.props.states.ticket.activeTicket !== prevProps.states.ticket.activeTicket && !this.ticketCreated) {
            this.setState({ newTicket: this.props.states.ticket.activeTicket });
        }

        const ticketDraftId = (this.props.location.state !== undefined && this.props.location.state !== null) ? (this.props.location.state! as any).ticketDraftId : null;
        if (this.props.states.ticket.activeTicket && this.props.states.ticket.activeTicket !== prevProps.states.ticket.activeTicket && ticketDraftId) {
            if (this.props.states.ticket.activeTicket.customer?.id) {
                this.props.actions.customer.getMyDropDownCustomer(this.props.states.ticket.activeTicket.customer!.id);
            }
            this.setState({ newTicket: this.props.states.ticket.activeTicket }, () => {
                this.validateNewTicket();
            });
        }
    }

    public componentWillUnmount() {
        this.removeTicketWhenNotSaved();
        window.removeEventListener("beforeunload", this.onloadEvent);
    }

    public removeTicketWhenNotSaved = () => {
        if (!this.hasDeletedTicket && this.state.newTicket.ticketStatus === 11) {
            this.props.actions.customer.clearMyDropDownCustomer();
            TicketService.RemoveTicketUnfinishedDraft(this.state.newTicket.id);
            this.hasDeletedTicket = true;
        }

        if (this.state.filesNotSaved.length > 0) {
            for (var file of this.state.filesNotSaved) {
                this.props.actions.ticket.removeNewTicketFile(file.id, this.state.newTicket.customer!.id);
            }
        }

        this.props.actions.ticket.clearTicket();
    }

    public validateFiscalYear = (value: any) => {
        const selectedFiscalYear = this.props.states.customer.myDropDownCustomer.fiscalYears[value];
        this.setState({ selectedFiscalYearIsClosed: selectedFiscalYear.isClosed })
        this.setState({ fiscalYear: Number(value) }, () => {
            this.updateFiscalYear();
            this.validateNewTicket();
        });
    }

    public setAccountingCompany = (e: any) => {
        this.setState({ newCustomer: e.value, isDirty: true }, () => {
            this.updateOrg();
            this.props.actions.customer.getMyDropDownCustomer(this.state.newCustomer.id);
            this.validateNewTicket();
        })
    }

    public updateTicket = (e: any) => {
        var ticket = this.state.newTicket;
        ticket[e.target.dataset.value] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({ newTicket: ticket, isDirty: true }, () => {
            this.validateNewTicket();
        });
    }

    public validateNewTicket = () => {
        if (
            this.state.newTicket.customer.id &&
            this.state.newTicket.title &&
            this.state.newTicket.description
        ) {
            if (!this.state.newTicket.confidential) {
                this.setState({ isCompleteNewTicket: true });
            } else {
                if (this.state.newTicket.recipientId !== "") {
                    this.setState({ isCompleteNewTicket: true });
                } else {
                    this.setState({ isCompleteNewTicket: false });
                }
            }
        } else {
            this.setState({ isCompleteNewTicket: false });
        }

        if (this.state.newTicket.customer?.id) {
            this.setState({ canSaveTicketDraft: true });
        } else {
            this.setState({ canSaveTicketDraft: false });
        }
    }

    public updateOrg = () => {
        var ticket = this.state.newTicket;
        if (this.state.newCustomer.id !== ticket.customer?.id) {
            ticket = this.clearRecipient(ticket);
        }
        ticket.customer = this.state.newCustomer;
        this.setState({ ...this.state, newTicket: ticket, isDirty: true });
    }

    public updateRecipient = () => this.setState({ ...this.state, newTicket: TicketHelper.SetRecipient(this.state.newTicket, this.props.states.customer.myDropDownCustomer.internalUsers.find(x => x.id === this.state.newRecipientId)!), isDirty: true });

    public updateDescription = (newContent: string) => {
        var ticket = { ...this.state.newTicket };
        ticket.description = newContent;
        this.setState({ newTicket: ticket, isDirty: true }, () => {
            this.validateNewTicket();
        });
    }

    public updateFiscalYear = () => {
        var ticket = this.state.newTicket;
        ticket.fiscalYear = this.state.fiscalYear;
        this.setState({ ...this.state, newTicket: ticket, isDirty: true });
    }

    public saveTicket = (isDraft: boolean) => {
        const ticket = { ...this.state.newTicket };
        ticket.ticketStatus = isDraft ? TicketStatus.Draft : TicketStatus.Created;
        this.props.actions.ticket.updateTicket(ticket.id, ticket, this.state.newTicket.customer.id, this.state.fileCount);
        this.ticketCreated = true;
        this.setState({ filesNotSaved: [], fileToRemove: undefined, deleteFileNeedsConfirmation: false, isDirty: false });
    }

    private updateMe = (ticket: ITicket) => {
        var me = this.props.states.authenticatedRoute.me;
        ticket.author = me.id;
        ticket.authorEmail = me.email;
        ticket.authorId = me.id;
        ticket.authorText = me.firstName + " " + me.lastName;
        return ticket;
    }

    private clearRecipient = (ticket: ITicket): ITicket => {
        ticket.recipientEmail = "";
        ticket.recipientId = "";
        ticket.recipientPicture = "";
        ticket.recipientText = "";
        return ticket;
    }

    private handleModalChanges = () => {
        this.setState({ ...this.state, modalOpen: false }, () => {
            if (this.state.modalName === "org") {
                this.updateOrg();
                this.props.actions.customer.getMyDropDownCustomer(this.state.newCustomer.id);
                this.validateNewTicket();
            }
            else if (this.state.modalName === "recipient") {
                this.updateRecipient();
                this.validateNewTicket();
            }
            else if (this.state.modalName === "financialyear") {
                this.updateFiscalYear();
                this.validateNewTicket();
            }
        })
    }

    public setRecipient = (e: any) => {
        this.setState({ newRecipientId: e.value, isDirty: true }, () => {
            this.updateRecipient();
            this.validateNewTicket();
        })
    }

    public fileAdded = (file: IFileAttachmentBase) => {
        const ticket = this.state.newTicket;
        ticket.attachedFiles!.push({ ...file, downloadedBy: [] });
        this.setState({ newTicket: ticket, isDirty: true, filesNotSaved: [...this.state.filesNotSaved, file] });
    }

    public requestFileRemoval = (file: ITicketAttachments) => {
        const uploadedFilesToRemoveIdx = this.state.filesNotSaved.findIndex(x => x.id === file.id);
        if (uploadedFilesToRemoveIdx === -1) {
            this.setState({ fileToRemove: file, deleteFileNeedsConfirmation: true });
        } else {
            this.removeNewTicketFile(file);
        }
    }

    public removeNewTicketFile = (file: ITicketAttachments) => {
        const ticket = this.state.newTicket;
        var fileToRemoveIdx = ticket.attachedFiles!.findIndex(x => x.id === file.id);
        ticket.attachedFiles!.splice(fileToRemoveIdx, 1);
        this.props.actions.ticket.removeNewTicketFile(file.id, this.state.newTicket.customer!.id);

        let newUploadedFiles = [...this.state.filesNotSaved];
        let uploadedFilesToRemoveIdx = newUploadedFiles.findIndex(x => x.id === file.id);
        newUploadedFiles.splice(uploadedFilesToRemoveIdx, 1);

        this.setState({ newTicket: ticket, filesNotSaved: newUploadedFiles });
    }

    public cancel = () => {
        if (this.state.isDirty) {
            this.setState({ ticketIsCancelled: true });
        }
        else {
            history.push("/tickets");
        }
    }

    public removeTicketDraft = () => {
        this.props.actions.customer.clearMyDropDownCustomer();
        this.draftRemoved = true;
        this.props.actions.ticket.removeTicketDraft(this.props.states.ticket.activeTicket.customer.id, this.props.states.ticket.activeTicket.id);
    }

    public render() {
        return (
            <ErrorBoundary>
                <PageTransition>
                    <Translation>{
                        (t) => {
                            return (
                                <>
                                    <TicketActionHeader
                                        newTicket
                                        ticket={this.state.newTicket}
                                        postAction={this.saveTicket}
                                        loading={this.props.states.ticket.isLoading}
                                        isCompleteNewTicket={this.state.isCompleteNewTicket}
                                        isTicketCreated={this.state.isCreated}
                                        fileCount={this.state.fileCount}
                                        setIsNewlyCreated={this.props.actions.ticket.setIsNewlyCreatedTicket}
                                        isNewlyCreatedTicket={this.props.states.ticket.isNewlyCreated}
                                        cancel={this.cancel}
                                        canSaveDraft={this.state.canSaveTicketDraft}
                                        removeTicketDraft={this.removeTicketDraft}
                                    />
                                    <TicketHeader
                                        newTicket
                                        ticket={this.state.newTicket}
                                        updateTicket={this.updateTicket}
                                        validateFiscalYear={this.validateFiscalYear}
                                        selectedFiscalYearIsClosed={this.state.selectedFiscalYearIsClosed}
                                        organizations={this.props.states.customer.myDropDownCustomers}
                                        setAccountingCompany={this.setAccountingCompany}
                                        users={this.props.states.customer.myDropDownCustomer.internalUsers}
                                        setRecipient={this.setRecipient}
                                    />

                                    {this.state.newTicket.customer?.id &&
                                        <TicketDescription
                                            newTicket
                                            ticket={this.state.newTicket}
                                            isTicketCreated={this.state.isCreated}
                                            filesWhereSaved={this.props.actions.ticket.filesWhereSaved}
                                            loading={this.props.states.ticket.isLoading}
                                            updateTicket={this.updateTicket}
                                            setIsCompleteTicket={this.validateNewTicket}
                                            getFileCount={(filecount: number) => this.setState({ fileCount: filecount })}
                                            fileAdded={this.fileAdded}
                                            removeNewTicketFile={this.requestFileRemoval}
                                            updateDescription={this.updateDescription}
                                        />
                                    }
                                    {this.state.modalOpen &&
                                        <GenericModal
                                            onClose={() => this.setState({ ...this.state, modalOpen: false, selectedFiscalYearIsClosed: false })}
                                            showModal={this.state.modalOpen}
                                            onSuccess={this.handleModalChanges}
                                            deleteButtonClass="button radius text-uppercase service-order service-order-redovisningsmall"
                                            successText={i18n.t('common:Action.Save')}
                                            saveIcon={<FontAwesomeIcon icon={faSave} />}
                                            showCancelButton={false}
                                            large={false}
                                            onSuccessDisabled={this.state.selectedFiscalYearIsClosed}
                                        >
                                            {this.state.modalBody}
                                            {this.state.selectedFiscalYearIsClosed === true &&
                                                <p className="help-text margin-0" style={{ color: 'red' }}>{i18n.t('tickets:NewTicket.FiscalYearClosedToolTip')}</p>
                                            }
                                        </GenericModal>
                                    }
                                    <Prompt
                                        when={this.state.isDirty && !this.state.ticketIsCancelled && !this.ticketCreated && !this.draftRemoved}
                                        message={t('tickets:CancelTicket.Body')}
                                    />
                                    {this.state.deleteFileNeedsConfirmation &&
                                        <ConfirmModal
                                            isOpen={this.state.deleteFileNeedsConfirmation}
                                            title={t('tickets:DeleteFile.Title')}
                                            closeModal={() => this.setState({ fileToRemove: undefined, deleteFileNeedsConfirmation: false })}
                                            confirm={() => {
                                                this.removeNewTicketFile(this.state.fileToRemove!);
                                                this.saveTicket(this.state.newTicket.ticketStatus === TicketStatus.Draft);
                                            }}
                                            body={t('tickets:DeleteFile.Body')}
                                            confirmationButtonText={t('common:Action.SaveChanges')}
                                            buttonVariant="primary"
                                            icon={faCheckCircle}
                                        />
                                    }
                                    {this.state.ticketIsCancelled &&
                                        <ConfirmModal
                                            isOpen={this.state.ticketIsCancelled}
                                            title={t('tickets:CancelTicket.Title')}
                                            closeModal={() => this.setState({ ticketIsCancelled: false })}
                                            confirm={() => {
                                                history.push("/tickets")
                                            }}
                                            body={t('tickets:CancelTicket.Body')}
                                            confirmationButtonText={t('common:Action.DiscardChanges')}
                                            buttonVariant="primary"
                                            icon={faCheckCircle}
                                        />
                                    }
                                </>
                            );
                        }
                    }
                    </Translation>
                </PageTransition>
            </ErrorBoundary>
        );
    }
}

export default connect(
    (state: IApplicationState) => ({
        states: {
            ticket: state.ticket,
            customer: state.customer,
            authenticatedRoute: state.authenticatedRoute,
        }
    }),
    (dispatch) => ({
        actions: {
            ticket: bindActionCreators(TicketStore.actionCreators, dispatch),
            customer: bindActionCreators(CustomersStore.actionCreators, dispatch)
        }
    })
)(NewTicketView);
