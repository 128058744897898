import * as React from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { Translation } from 'react-i18next';
import { ButtonCancel, ModalHeader } from '@kpmgportals/genericcomponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

interface IConfirmModalModalProps {
    closeModal: () => void;
    confirm: () => void;
    isOpen: boolean;
    title: string;
    body: any;
    confirmationButtonText: string;
    icon?: any;
    buttonVariant?: "primary" | "secondary" | "success" | "warning" | "danger" | "light" | "link";
    messageAboveTitle?: string;
}

export default class ConfirmModal extends React.Component<IConfirmModalModalProps> {
    static displayName = 'ConfirmModal';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Modal
                            size="lg"
                            show={this.props.isOpen}
                            onHide={this.props.closeModal}
                            backdropClassName="modal-secondary-above-backdrop"
                            className="modal-secondary-above"
                            centered
                        >
                            <ModalHeader
                                modalSmallTitle={this.props.messageAboveTitle}
                                modalTitle={this.props.title}
                            />
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <p>
                                            {this.props.body}
                                        </p>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-between">
                                <Col xs="auto">
                                    <ButtonCancel
                                        onClick={this.props.closeModal}
                                    >
                                        {t('common:Action.Cancel')}
                                    </ButtonCancel>
                                </Col>
                                <Col xs="auto">
                                    <Button
                                        onClick={this.props.confirm}
                                        variant={this.props.buttonVariant ? this.props.buttonVariant : "primary"}
                                    >
                                        {this.props.confirmationButtonText}
                                        <FontAwesomeIcon
                                            icon={this.props.icon ? this.props.icon : faCheckCircle}
                                            className="ml-3"
                                        />
                                    </Button>
                                </Col>
                            </Modal.Footer>
                        </Modal>
                    )
                }
            }
            </Translation>
        )
    }
}

