import { KnownAction, IToastsState } from '../Toasts';

export function ToastsReducer(state: IToastsState, action: KnownAction) {
    switch (action.type) {
        case 'ADD_TOAST':
            return { ...state, toastMessages: [action.payload, ...state.toastMessages] };
        case 'REMOVE_TOAST':
            const toastMessages = state.toastMessages.filter((toast) => toast.id !== action.id);
            return { ...state, toastMessages: toastMessages };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return state || {
        toastMessages: []
    };
}
