import UserProfileService from "./../apiServices/UserProfileService";
import { IProfileState } from "./../Interface/Interfaces";

export function GetUserProfil(): Promise<IProfileState> {
    return new Promise<IProfileState>((resolve, reject) => {
    UserProfileService.GetCurrentUserProfileInfoForPage().then((result: any) => {
        const profile: IProfileState = {
                email : result.profile.email,
                FirstName : result.profile.firstName,
                LastName : result.profile.lastName,
                organizationID : result.profile.organizationID,
                organizationOfficeID : result.profile.organizationOfficeID,
                phoneNumber : result.profile.phoneNumber,
                pictureUrl : result.profile.pictureUrl,
                userOrganization: result.profile.userOrganization,
                whiteList: result.whiteListValues,
                officeLocations: result.officeLocations
            };
        resolve(profile);
        });
    });
}
