import * as React from 'react';
import { Translation } from 'react-i18next';
import { changePageTitle } from '../Utils/CommonUtils';

export default class Help extends React.Component<{}, {}> {
    static displayName = 'Help';

    public render() {
        return (
            <Translation>
                {
                    (t) => {
                        changePageTitle('translations:PageTitle.Help');
                        return (
                            <div className="grid-container">
                                <div className="grid-x grid-padding-x padding-top-1">
                                    <div className="cell">
                                        <h1>{t('help:Title')}</h1>
                                        <p>{t('help:TitleSubtext')}</p>
                                        <p>{t('help:TitleSubtext2')}</p>
                                        <img src={`/images/helpImages/1-${localStorage.i18nextLng}.png`} alt="helpimage-1" />
                                        <h2>{t('help:GetStartedTitle')}</h2>
                                        <p>{t('help:GetStartedSubtext')}</p>
                                        <h3>{t('help:PushNotificationsTitle')}</h3>
                                        <p>{t('help:PushNotificationsSubtext')}</p>
                                        <img src={`/images/helpImages/2-${localStorage.i18nextLng}.png`} alt="helpimage-2" />
                                        <p>{t('help:PushNotificationsSubtext2')}</p>
                                        <h2 style={{ marginTop: '1rem' }}>{t('help:AddAppTitle')}</h2>
                                        <h3>{t('help:IphoneTitel')}</h3>
                                        <p>{t('help:IphoneSubtitle')}</p>
                                        <img src={`/images/helpImages/3-${localStorage.i18nextLng}.png`} alt="helpimage-3" />
                                        <img src={`/images/helpImages/4-${localStorage.i18nextLng}.png`} alt="helpimage-4" />
                                        <h3>{t('help:AndroidTitel')}</h3>
                                        <p>{t('help:AndroidSubTitle')}</p>
                                        <img src={`/images/helpImages/5-${localStorage.i18nextLng}.png`} alt="helpimage-5" />
                                        <h2>{t('help:PersonalSettingsTitle')}</h2>
                                        <img src={`/images/helpImages/6-${localStorage.i18nextLng}.png`} alt="helpimage-6" />
                                        <ul style={{ marginTop: '1rem' }}>
                                            <li>{t('help:PersonalSettingsLi1')}</li>
                                            <li>{t('help:PersonalSettingsLi2')}</li>
                                            <li>{t('help:PersonalSettingsLi3')}</li>
                                        </ul>
                                        <h2>{t('help:UpdatesTitle')}</h2>
                                        <img src={`/images/helpImages/7-${localStorage.i18nextLng}.png`} alt="helpimage-7" />
                                        <p>{t('help:UpdatesSubtext')}</p>
                                        <h2>{t('help:CustomersTitle')}</h2>
                                        <p>{t('help:CustomersSubtext')}</p>
                                        <img src={`/images/helpImages/8-${localStorage.i18nextLng}.png`} alt="helpimage-8" />
                                        <h2 style={{ marginTop: '1rem' }}>{t('help:HandlingTicketsTitle')}</h2>
                                        <p>{t('help:HandlingTicketsSubtext')}</p>
                                        <p>{t('help:HandlingTicketsSubtext2')}</p>
                                        <ul style={{ marginTop: '1rem' }}>
                                            <li>{t('help:HandlingTicketsLi1')}</li>
                                            <li>{t('help:HandlingTicketsLi2')}</li>
                                        </ul>
                                        {/* <p>{t('help:HandlingTicketsSubtext3')}</p> */}
                                        <h3>{t('help:TicketsTitle')}</h3>
                                        <p>{t('help:TicketsSubtext')}</p>
                                        <img src={`/images/helpImages/9-${localStorage.i18nextLng}.png`} alt="helpimage-9" />
                                        <h3 style={{ marginTop: '1rem' }}>{t('help:NewTicketTitle')}</h3>
                                        <p>{t('help:NewTicketSubtext')}</p>
                                        <img src={`/images/helpImages/10-${localStorage.i18nextLng}.png`} alt="helpimage-10" />
                                        <p>{t('help:NewTicketSubtext2')}</p>
                                        <img src={`/images/helpImages/11-${localStorage.i18nextLng}.png`} alt="helpimage-11" />
                                        <h4 style={{ marginTop: '1rem' }}>{t('help:OrganizationTitle')}</h4>
                                        <p>{t('help:OrganizationSubtext')}</p>
                                        <h4>{t('help:FinancialYearTitle')}</h4>
                                        <p>{t('help:FinancialYearSubtext')}</p>
                                        <h4>{t('help:RecipientTitle')}</h4>
                                        <p>{t('help:RecipientSubtext')}</p>
                                        <h4>{t('help:ConfidentialTitle')}</h4>
                                        <p>{t('help:ConfidentialSubtitle')}</p>
                                        <h4>{t('help:AttachmentTitle')}</h4>
                                        <p>{t('help:AttachmentSubtext')}</p>
                                        <h4>{t('help:NewTicketSaveDraftTitle')}</h4>
                                        <p>{t('help:NewTicketSaveDraftBody')}</p>
                                        <h4>{t('help:SendTicketTitle')}</h4>
                                        <p>{t('help:SendTicketSubtext')}</p>
                                        <h4>{t('help:SendTicketAdditionTitle')}</h4>
                                        <p>{t('help:SendTicketAdditionSubtext')}</p>
                                        <img src={`/images/helpImages/12-${localStorage.i18nextLng}.png`} alt="helpimage-12" />
                                        <h3 style={{ marginTop: '1rem' }}>{t('help:OngoingTicketsTitle')}</h3>
                                        <p>{t('help:OngoingTicketsSubtext')}</p>

                                        <h4>{t('help:TicketChangesTitle')}</h4>
                                        <p>{t('help:TicketChangesSubtext')}</p>
                                        <ul>
                                            <li><strong>{t('help:Email')}</strong>{t('help:TicketChangesLi1')}</li>
                                            <li><strong>{t('help:Push')}</strong>{t('help:TicketChangesLi2')}</li>
                                            <li><strong>{t('help:Updates')}</strong>{t('help:TicketChangesLi3')}</li>
                                        </ul>
                                        <img src={`/images/helpImages/13-${localStorage.i18nextLng}.png`} alt="helpimage-13" />
                                        <h4 style={{ marginTop: '1rem' }}>{t('help:AnswerTicketTitle')}</h4>
                                        <p>{t('help:AnswerTicketSubtext')}</p>
                                        <h4 style={{ marginTop: '1rem' }}>{t('help:HistoryTitle')}</h4>
                                        <p>{t('help:HistorySubtext')}</p>
                                        <img src={`/images/helpImages/14-${localStorage.i18nextLng}.png`} alt="helpimage-14" />
                                        <h4 style={{ marginTop: '1rem' }}>{t('help:DownloadFilesTitle')}</h4>
                                        <p>{t('help:DownloadFilesSubTextPart1')}</p>
                                        <h4>{t('help:CloseTicketTitle')}</h4>
                                        <p>{t('help:CloseTicketSubtext')}</p>

                                        <h3 style={{ marginTop: '1rem' }}>{t('help:PbpTicketsTitle')}</h3>
                                        <h4>{t('help:PbpOverviewTitle')}</h4>
                                        <p>{t('help:PbpOverviewSubtext1')}</p>
                                        <p>{t('help:PbpOverviewSubtext2')}</p>
                                        <ol style={{ marginTop: '1rem' }}>
                                            <li>{t('help:PbpOverviewli1')}</li>
                                            <li>{t('help:PbpOverviewli2')}</li>
                                            <li>{t('help:PbpOverviewli3')}</li>
                                            <ul>
                                                <li><strong>{t('help:Filerequests')}</strong>{t('help:PbpOverviewli3Subl1')}</li>
                                                <li><strong>{t('help:Comments')}</strong>{t('help:PbpOverviewli3Subl2')}</li>
                                                <li><strong>{t('help:History')}</strong>{t('help:PbpOverviewli3Subl3')}</li>
                                            </ul>
                                            <li>{t('help:PbpOverviewli4')}</li>
                                        </ol>
                                        <p>{t('help:PbpOverviewSubtext3')}</p>
                                        <img src={`/images/helpImages/15-${localStorage.i18nextLng}.png`} alt="helpimage-15" />
                                        <p>{t('help:PbpOverviewSubtext4')}</p>

                                        <h3 style={{ marginTop: '1rem' }}>{t('help:DeliverFilesTitle')}</h3>
                                        <h4 style={{ marginTop: '1rem' }}>{t('help:DeliverFilesOverviewTitle')}</h4>
                                        <p>{t('help:DeliverFilesSubtext')}</p>

                                        <ol>
                                            <li>{t('help:DeliverFilesLi1')}</li>
                                            <li>{t('help:DeliverFilesLi2')}</li>
                                            <li>{t('help:DeliverFilesLi3')}</li>
                                            <li>{t('help:DeliverFilesLi4')}</li>
                                        </ol>
                                        <img src={`/images/helpImages/16-${localStorage.i18nextLng}.png`} alt="helpimage-16" />

                                        <h3 style={{ marginTop: '1rem' }}>{t('help:MatchFileTitle')}</h3>
                                        <p style={{ marginTop: '1rem' }}>{t('help:MatchFileSubtext1')}</p>

                                        <ol type="a">
                                            <li>{t('help:MatchFileLi1')}</li>
                                            <li>{t('help:MatchFileLi2')}</li>
                                        </ol>
                                        <p style={{ marginTop: '1rem' }}><strong>{t('help:MatchMultipleFileSubtitle')}</strong></p>
                                        <p style={{ marginTop: '1rem' }}>{t('help:MatchMultipleFileSubtext1')}</p>
                                        <img src={`/images/helpImages/17-${localStorage.i18nextLng}.png`} alt="helpimage-17" />
                                        <p style={{ marginTop: '1rem' }}>{t('help:MatchMultipleFileSubtext2')}</p>
                                        <img src={`/images/helpImages/18-${localStorage.i18nextLng}.png`} alt="helpimage-18" />
                                        <p style={{ marginTop: '1rem' }}>{t('help:MatchMultipleFileSubtext3')}</p>
                                        <p style={{ marginTop: '1rem' }}>{t('help:MatchMultipleFileSubtext4')}</p>
                                        <img src={`/images/helpImages/19-${localStorage.i18nextLng}.png`} alt="helpimage-19" />
                                        <p style={{ marginTop: '1rem' }}>{t('help:MatchMultipleFileSubtext5')}</p>
                                        <img src={`/images/helpImages/20-${localStorage.i18nextLng}.png`} alt="helpimage-20" />
                                        <p style={{ marginTop: '1rem' }}>{t('help:MatchMultipleFileSubtext6')}</p>
                                        <img src={`/images/helpImages/21-${localStorage.i18nextLng}.png`} alt="helpimage-21" />
                                        <p style={{ marginTop: '1rem' }}>{t('help:MatchMultipleFileSubtext7')}</p>
                                        <img src={`/images/helpImages/22-${localStorage.i18nextLng}.png`} alt="helpimage-22" />


                                        <p style={{ marginTop: '1rem' }}><strong>{t('help:MatchSingleFileSubtitle')}</strong></p>
                                        <p style={{ marginTop: '1rem' }}>{t('help:MatchSingleFileSubtext')}</p>
                                        <img src={`/images/helpImages/23-${localStorage.i18nextLng}.png`} alt="helpimage-23" />

                                        <h3 style={{ marginTop: '1rem' }}>{t('help:SendFilesTitle')}</h3>
                                        <p style={{ marginTop: '1rem' }}>{t('help:SendFilesSubtext1')}</p>
                                        <img src={`/images/helpImages/24-${localStorage.i18nextLng}.png`} alt="helpimage-24" />
                                        <p style={{ marginTop: '1rem' }}>{t('help:SendFilesSubtext2')}</p>
                                        <p style={{ marginTop: '1rem' }}>{t('help:SendFilesSubtext3')}</p>
                                        <p style={{ marginTop: '1rem' }}>{t('help:SendFilesSubtext4')}</p>
                                        <img src={`/images/helpImages/25-${localStorage.i18nextLng}.png`} alt="helpimage-25" />

                                        <h3 style={{ marginTop: '1rem' }}>{t('help:CommentAndHistoryTitle')}</h3>
                                        <p style={{ marginTop: '1rem' }}>{t('help:CommentAndHistorySubtext1')}</p>
                                        <img src={`/images/helpImages/26-${localStorage.i18nextLng}.png`} alt="helpimage-26" />
                                        <p style={{ marginTop: '1rem' }}>{t('help:CommentAndHistorySubtext2')}</p>
                                        <img src={`/images/helpImages/27-${localStorage.i18nextLng}.png`} alt="helpimage-27" />

                                        <h3 style={{ marginTop: '1rem' }}>{t('help:ChangesInRequestTitle')}</h3>
                                        <p style={{ marginTop: '1rem' }}>{t('help:ChangesInRequestSubtext1')}</p>
                                        <p style={{ marginTop: '1rem' }}>{t('help:ChangesInRequestSubtext2')}</p>
                                        <img src={`/images/helpImages/28-${localStorage.i18nextLng}.png`} alt="helpimage-28" />

                                    </div>
                                    <footer className="cell margin-top-3 padding-top-3 padding-bottom-3 padding-bottom-medium-down-8" />
                                </div>
                            </div>
                        );
                    }
                }
            </Translation>
        );

    }
}
