import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket } from '../../../Interface/Interfaces';
import { Row, Col } from 'react-bootstrap';
import { ButtonCancel } from '@kpmgportals/genericcomponents';
import FileRequestActionHeader from './ActionHeaders/FileRequestActionHeader';
import DefaultTicketActionHeader from './ActionHeaders/DefaultTicketActionHeader';
import { TypeOfTicket } from '../../../Interface/Enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
interface TicketActionHeaderProps {
    newTicket: boolean;
    ticket: ITicket;
    postAction?: (isDraft: boolean) => void;
    loading?: boolean;
    isCompleteNewTicket?: boolean;
    closeAction?: () => void;
    isTicketCreated?: boolean;
    reOpenAction?: () => void;
    fileCount?: number;
    selectedFiscalYearIsClosed?: boolean;
    cancelAction?: () => void;
    canCloseFileRequest?: boolean;
    canSaveFileRequest?: boolean;
    canSendFileRequest?: boolean;
    setIsNewlyCreated: (isNew: boolean) => void;
    isNewlyCreatedTicket: boolean;
    cancel: () => void;
    canSaveDraft?: boolean;
    removeTicketDraft?: () => void;
}

export default class TicketActionHeader extends React.Component<TicketActionHeaderProps, {}> {
    static displayName = 'TicketActionHeader';

    public postAction = () => {
        if (this.props.postAction) {
            this.props.postAction!(false);
        }
    }

    public postDraftAction = () => {
        if (this.props.postAction) {
            this.props.postAction!(true);
        }
    }

    get ticketRefnumber() {
        return "PT-";
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="bg-white header-card grid-margin-bottom align-items-center py-3 justify-content-between position-sticky ticket-header-actions">
                            <Col xs="auto">
                                <h1 className="font-family-kpmg mb-0">
                                    {t('tickets:MyTicket.TicketID')}:{' '}
                                    {(!this.props.newTicket || (this.props.newTicket && this.props.ticket.refNumber !== "")) &&
                                        <span>{this.props.ticket.refNumber}</span>
                                    }
                                    {(this.props.newTicket && this.props.ticket.refNumber === "") &&
                                        <span>{this.ticketRefnumber}</span>
                                    }
                                </h1>
                            </Col>
                            <Col xs={12} lg="auto" className="mt-3 mt-lg-0 justify-content-between d-flex">
                                {this.props.ticket.typeOfTicket !== TypeOfTicket.PreparedByPartner &&
                                    <DefaultTicketActionHeader {...this.props} />
                                }
                                <FileRequestActionHeader {...this.props} />
                                <ButtonCancel
                                    onClick={this.props.cancel}
                                    size="sm"
                                    className="ml-3 px-3"
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                    />
                                </ButtonCancel>

                            </Col>
                        </Row>
                    );
                }
            }
            </Translation>
        );
    }
}
