import HttpHelper from './HttpHelper';
import { API_ENDPOINT } from '../Utils/config';

export default class NotificationService {
    public static GetMyNotifications(nextPageToken: string | null): Promise<any> {
        const body = { nextPageToken: nextPageToken };
        return HttpHelper.Post(API_ENDPOINT + '/api/notification/getnotifications', JSON.stringify(body));
    }

    public static ClearMyNotificationsCount() {
        return HttpHelper.Get(API_ENDPOINT + '/api/notification/clearunreadnotifications');
    }

    public static GetLatestNotifications() {
        return HttpHelper.Get(API_ENDPOINT + '/api/notification/getlatestnotification');
    }
}
