import * as React from 'react';
import TableRow from './TableRow';

export default class TableHead extends React.Component {
    static displayName = 'TableHead';

    public render() {
        return (
            <TableRow thead {...this.props} noHover>
                {this.props.children}
            </TableRow>
        );
    }
}
