import * as React from 'react';
import { Translation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ITicket } from '../../Interface/Interfaces';
import TicketCard from './TicketCard';
import AppInsightsUtils from '../../Utils/AppInsightsUtils';

interface IMyTicketState {
    singalR: boolean;
}

interface IMyTicketProps {
    myTickets: any[];
    loading?: boolean;
}

class MyTickets extends React.Component<IMyTicketProps, IMyTicketState> {
    static displayName = 'MyTickets';

    constructor(props: IMyTicketProps) {
        super(props);
        this.state = { singalR: false };
    }

    public componentDidUpdate(prevProps: IMyTicketProps) {
        if (this.props.myTickets !== prevProps.myTickets) {
            this.props.myTickets.forEach((ticket: ITicket) => {
                if (ticket.signalRPushed === true) {
                    this.setState({ singalR: true });
                }
            })
        }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <div className="cell medium-6 position-relative">
                            <NavLink to="/tickets" onClick={() => { AppInsightsUtils.TrackEvent("Homepage show all tickets clicked", { message: "Show all tickets clicked" }) }}>
                                <button className="text-smallest clear button show-all text-uppercase padding-0 padding-right-1">{t('common:Action.ShowAll')}</button>
                            </NavLink>
                            <h2>{t('tickets:MyTickets')}</h2>
                            {(!this.props.loading && this.props.myTickets.length === 0) &&
                                <div className="grid-x grid-padding-x padding-top-1 padding-bottom-1 position-relative text-center">
                                    <div className="cell">
                                        <h3 className="h6 text-color-black text-body-font">
                                            No tickets assigned
                                        </h3>
                                    </div>
                                </div>
                            }
                            {this.props.myTickets.map((ticket: ITicket, idx) => {
                                return (
                                    <TicketCard
                                        Confidential={ticket.confidential}
                                        Customer={ticket.customer}
                                        LastEventTime={ticket.edited}
                                        SignalRPushed={ticket.signalRPushed}
                                        Status={ticket.status}
                                        Title={ticket.title}
                                        TicketType="Partner"
                                        Id={ticket.id}
                                        RefNumber={ticket.refNumber!}
                                        Pop={(idx === 0 && this.state.singalR) ? true : false}
                                        key={ticket.id}
                                    />
                                )
                            })}
                        </div>
                    )
                }
            }
            </Translation>
        );
    }
}

export default MyTickets;
