import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket } from '../../../../Interface/Interfaces';
import { TypeOfTicket } from '../../../../Interface/Enum';
import { Button } from 'react-bootstrap';

interface FileRequestActionHeaderProps {
    newTicket: boolean;
    ticket: ITicket;
    postAction?: (isDraft: boolean) => void;
    loading?: boolean;
    isCompleteNewTicket?: boolean;
    closeAction?: () => void;
    isTicketCreated?: boolean;
    reOpenAction?: () => void;
    fileCount?: number;
    selectedFiscalYearIsClosed?: boolean;
    canSaveFileRequest?: boolean;
    canSendFileRequest?: boolean;
}

export default class FileRequestActionHeader extends React.Component<FileRequestActionHeaderProps, {}> {
    static displayName = 'FileRequestActionHeader';

    public postAction = () => {
        if (this.props.postAction) {
            this.props.postAction!(false);
        }
    }

    public postDraftAction = () => {
        if (this.props.postAction) {
            this.props.postAction!(true);
        }
    }

    public render() {
        if (
            this.props.newTicket ||
            this.props.ticket.typeOfTicket === TypeOfTicket.CustomerTicket ||
            this.props.ticket.typeOfTicket === TypeOfTicket.PartnerTicket) {
            return "";
        }

        return (
            <Translation>
                {
                    (t) => {
                        return (
                            <>
                                <Button
                                    onClick={this.postDraftAction}
                                    disabled={!this.props.canSaveFileRequest! || this.props.loading!}
                                    className="btn-sm-lg"
                                >
                                    {t('tickets:Filerequest.Save')}
                                </Button>
                                <Button
                                    onClick={this.postAction}
                                    disabled={!this.props.canSendFileRequest! || this.props.loading!}
                                    className="btn-sm-lg ml-3"
                                >
                                    {t('tickets:Filerequest.Send')}
                                </Button>
                            </>
                        );
                    }
                }
            </Translation>
        );
    }
}
