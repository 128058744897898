import * as React from 'react';
import { IApplicationState } from '../../store';
import * as ToastsStore from '../../store/Toasts';
import * as TicketsStore from '../../store/Tickets';
import * as CustomerStore from '../../store/Customer';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

type IToastBarProps =
    typeof ToastsStore.actionCreators
    & typeof TicketsStore.actionCreators
    & typeof CustomerStore.actionCreators
    & ToastsStore.IToastsState;

class ToastBar extends React.Component<IToastBarProps, {}> {
    static displayName = 'ToastBar';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <div className="toastbar">
                            {this.props.toastMessages.map(toast => {
                                const { id, status } = toast;
                                switch (status) {
                                    default:
                                        return (
                                            <Toast
                                                key={id}
                                                onClose={() => this.props.removeToastMessage(id)}
                                                className={toast.status}
                                            >
                                                <Toast.Header>
                                                    {toast.status === 'success' &&
                                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-1" size="lg" fixedWidth />
                                                    }
                                                    {toast.status === 'alert' &&
                                                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" size="lg" fixedWidth />
                                                    }
                                                    {toast.status === 'warning' &&
                                                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" size="lg" fixedWidth />
                                                    }
                                                    <strong className="mr-auto">
                                                        {t(toast.subject)}
                                                    </strong>
                                                </Toast.Header>
                                                {(toast.bodyText) &&
                                                    <Toast.Body>
                                                        {t(toast.bodyText)}
                                                    </Toast.Body>
                                                }
                                            </Toast>
                                        );
                                }
                            })}
                        </div>
                    );
                }
            }
            </Translation>
        );
    }
}
export default connect(
    (state: IApplicationState) => state.toasts, ({ ...ToastsStore.actionCreators, ...TicketsStore.actionCreators, ...CustomerStore.actionCreators })
)(ToastBar);
