import React from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

function ButtonCancel (props: any){
	const { className, size, prefix = 'btn', variant = 'cancel', ...attr } = props;
	const classes = classNames(
		className,
		size && `${prefix}-${size}`,
		`${prefix}-${variant}`,
	);

	return <Button variant="link" className={classes} {...attr}>{props.children}</Button>;
};

ButtonCancel.displayName = 'ButtonCancel';
export default ButtonCancel;
