import * as React from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import StorageService from '../../apiServices/StorageService';
import { Translation } from 'react-i18next';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import i18n_ from '../translationcomponent/i18n';
import { IFilePondResponse, IFileAttachmentBase } from '../../Interface/Interfaces';

export interface IGenericGenericFilePondProps {
    uploadUrl: string;
    customerId: string;
    orderNumber?: string;
    deliveryYear?: string;
    serviceName?: string;
    serviceId?: string;
    removeFiles?: boolean;
    disableFilePond: boolean;
    customerNumber?: string;
    ticketRef?: string;
    saved?: boolean;
    filesWhereSaved: (success: boolean) => void;
    getFileCount: (fileCount: number) => void;
    fileSizeLimit: number;
    fileAdded?: (file: IFileAttachmentBase) => void;
    onError?: (error: any) => void
}

// Register the plugins
registerPlugin(FilePondPluginFileMetadata, FilePondPluginFileValidateSize);

export default class GenericFilePond extends React.Component<IGenericGenericFilePondProps, {}> {
    static displayName = 'GenericFilePond';

    private pond: any;
    public onFileUpload = (file: any) => {
        if (this.props.customerNumber) {
            file.setMetadata({ "customerId": this.props.customerId, "orderNumber": this.props.orderNumber, "deliveryYear": this.props.deliveryYear, "serviceName": this.props.serviceName, "serviceId": this.props.serviceId });
        } else {
            file.setMetadata({ "customerId": this.props.customerId, "customerNumber": this.props.customerNumber, "ticketRef": this.props.ticketRef });
        }
    }

    public onFileSuccessfullUpload = () => {
        this.pond.removeFiles()
    }

    public componentDidUpdate(prevProps: IGenericGenericFilePondProps) {
        if (prevProps.removeFiles !== this.props.removeFiles && this.props.removeFiles) {
            this.onFileSuccessfullUpload();
        }
        if (prevProps.saved !== this.props.saved && this.props.saved) {
            var files = this.pond.getFiles();
            if (files.length > 0) {
                this.pond!.processFiles().then((result: IFilePondResponse[]) => {
                    this.props.filesWhereSaved(true);
                    this.pond!.removeFiles();
                    this.props.getFileCount(0);
                }).catch((e: any) => {
                    this.props.filesWhereSaved(false);
                    this.props.getFileCount(0);
                })
            }
        }
    }

    public fileAdded = (file: any, err: any) => {
        if (err == null) {
            var newlyAddedFile: IFileAttachmentBase = JSON.parse(file.serverId);
            this.pond!.removeFile(file);
            this.props.fileAdded!(newlyAddedFile);
            this.props.getFileCount(this.pond!.getFiles.length);
        }
    }

    public error = (error: any) => {
        this.props.onError ?
            this.props.onError(error) :
            console.log(error);
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <div className="grid-x grid-padding-x padding-bottom-1">
                            {/*File Pond*/}
                            <div className="cell">
                                <label htmlFor="answer-files">{t('tickets:Ticket.AttachFiles')}</label>
                                <FilePond
                                    ref={(ref: any) => this.pond = ref}
                                    allowMultiple
                                    fileMetadataObject={{}}
                                    allowFileTypeValidation
                                    maxFileSize={this.props.fileSizeLimit + "MB"}
                                    labelMaxFileSizeExceeded={i18n_.t("Order.LargeFileWarning")}
                                    instantUpload
                                    disabled={this.props.disableFilePond}
                                    server={{
                                        process: {
                                            url: this.props.uploadUrl,
                                            headers: {
                                                'Authorization': 'Bearer ' + StorageService.GetAccessToken()
                                            },
                                            onerror: (error: any) => {
                                                this.error(error);
                                            }
                                        },
                                    }
                                    }
                                    onprocessfile={(error: any, file: any) => this.fileAdded(file, error)}
                                    allowDrop={!this.props.disableFilePond}
                                    allowBrowse={!this.props.disableFilePond}
                                    allowPaste={!this.props.disableFilePond}
                                    onaddfile={(error: any, file: any) => this.props.getFileCount(this.pond.getFiles().length)}
                                    labelIdle={t('tickets:Ticket.DragAndDrop') + '<span class="filepond--label-action">' + t('tickets:Ticket.Browse') + '</span>'}
                                    dropOnPage
                                />
                                <small className="text-color-dark-gray">{t('common:Information.FileSizeLimit', { fileSize: this.props.fileSizeLimit })}</small>
                            </div>
                        </div>
                    );
                }
            }
            </Translation>
        );
    }
}
