import * as React from 'react';
import { KpmgLogo } from '@kpmgportals/kpmgassets';

interface DesktopLogoProps {
    className?: string;
    svgClassName?: string;
}

export default class DesktopLogo extends React.Component<DesktopLogoProps, {}> {    
    public render () {
        const {className, svgClassName} = this.props;

        return (
            <div className={`logo-text ${className}`}>
                <KpmgLogo className={svgClassName ? svgClassName : ""} />
                <h1>Client Access</h1>
            </div>
        )
    }
};
