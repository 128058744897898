import * as React from 'react';
import { Translation } from 'react-i18next';
import AttachedFiles from './AttachedFiles';
import { OriginEnum } from '../../../Interface/Enum';
import { Col, Row } from 'react-bootstrap';
import { UserInline } from '@kpmgportals/genericcomponents';
import { USER_IMAGE_CDN } from '../../../Utils/config';
import ContactInformationService from '../../../apiServices/ContactInformationService';
import { PortalsTextViewer } from '@kpmgportals/portalstexteditor';
import DateTimeHelper from '../../../Utils/DateTimeHelper';

export interface ICommentProps {
    comment: any;
    downloadFiles: (e: any, comment: any, originalFileName: string) => void;
    idx: number;
}

export default class Comment extends React.Component<ICommentProps, {}> {
    static displayName = 'Comment';

    public render() {
        return (
            <Translation>
                {
                    (t) => {
                        return (
                            <Row className="mb-3">
                                <Col xs={6} className={`${this.props.comment.origin === OriginEnum.Internal ? "" : "offset-6"}`}>
                                    <div className={`ticket-comment rounded p-5 ${this.props.comment.origin === OriginEnum.Internal ? "is-mykpmg" : "is-customer"}`}>
                                        <Row className="mb-3">
                                            <Col className="d-flex align-items-center">
                                                <UserInline
                                                    user={{
                                                        email: "",
                                                        firstName: "",
                                                        lastName: "",
                                                        id: this.props.comment.authorId,
                                                        name: this.props.comment.authorText,
                                                        phone: "",
                                                        pictureUrl: this.props.comment.authorPicture,
                                                        title: "", userId: this.props.comment.authorId
                                                    }}
                                                    imageSize="large"
                                                    boldName
                                                    USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                    ContactInformationService={ContactInformationService}
                                                />
                                                <span className="ml-1">{DateTimeHelper.FormatYearMonthDayWithTime(this.props.comment.created)}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <PortalsTextViewer
                                                            id={`comment-${this.props.idx}-MCE`}
                                                            value={this.props.comment.text}
                                                            dataValue="Description"
                                                        />
                                                    </Col>
                                                </Row>
                                                {!!this.props.comment.files && !!this.props.comment.files.length &&
                                                    <Row>
                                                        <Col>
                                                            <AttachedFiles
                                                                attachedFiles={this.props.comment.files}
                                                                newTicket={false}
                                                                downloadFiles={this.props.downloadFiles}
                                                                comment={this.props.comment}
                                                                showExpiredDate
                                                                downloadAllId={`comment-${this.props.idx}`}
                                                            />
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        );
                    }
                }
            </Translation>
        );
    }
}
