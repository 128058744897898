import * as React from 'react';
import { Translation } from 'react-i18next';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import i18n_ from './translationcomponent/i18n';
import Choices from 'choices.js';
import UserProfileService, { IUserProfile } from './../apiServices/UserProfileService';
import { USER_IMAGE_CDN } from '../Utils/config'
import * as defaultAvatar from './../assets/img/profile-large.png'
import { connect } from 'react-redux';
import * as AuthenticatedRouteStore from '../store/AuthenticatedRoute';
import { IApplicationState } from '../store/index';
import * as ProfileStore from '../store/Profile';
import { IProfileState } from "./../Interface/Interfaces"
import KPMGImageFallback from './CommonComponents/KPMGImageFallback';
import { changePageTitle } from '../Utils/CommonUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faUpload } from '@fortawesome/pro-solid-svg-icons';
import ErrorBoundary from './HOC/ErrorBoundary';

type MyProfileProps =
    AuthenticatedRouteStore.IAuthenticatedRouteState
    & typeof AuthenticatedRouteStore.actionCreators
    & ProfileStore.IProfileState
    & typeof ProfileStore.actionCreators;

interface IMyProfileState {
    editFirstName: boolean;
    editLastName: boolean;
    editEmail: boolean;
    editPhoneNumber: boolean;
    editOfficeLocation: boolean;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    userPicture: string;
    officeLocation: string;
    user: IUserProfile;
    officeLocations: any[];
    whiteListEmails: any[];
    isUserLoaded: boolean;
    isModalOpen: boolean;
    image?: any;
    zoomLevel: number;
    imageRotation: number;
    imageToBig: boolean;
    imageToSmall: boolean;
    uploading: boolean;
    showWarningLastName: boolean;
    showWarningEmail: boolean;
    emailWarningMessage: string;

}

const warningStyle = {
    color: 'red'
};

class MyProfile extends React.Component<MyProfileProps, IMyProfileState> {
    static displayName = 'MyProfile';

    editor: AvatarEditor;
    private dropzoneRef = React.createRef<Dropzone>();
    private officeLocationChoiceDropdown: any;
    constructor(props: any) {
        super(props);
        this.state = {
            editFirstName: false,
            editLastName: false,
            editEmail: false,
            editOfficeLocation: false,
            editPhoneNumber: false,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            userPicture: defaultAvatar,
            officeLocation: "",
            uploading: false,
            isModalOpen: false,
            image: "",
            zoomLevel: 1,
            imageRotation: 0,
            imageToBig: false,
            imageToSmall: false,
            user: {
                email: this.props.email,
                firstName: this.props.FirstName,
                lastName: this.props.LastName,
                organizationID: this.props.organizationID,
                organizationOfficeID: this.props.organizationOfficeID,
                phoneNumber: this.props.phoneNumber,
                pictureUrl: this.props.pictureUrl,
                userOrganization: this.props.userOrganization
            },
            officeLocations: this.props.officeLocations!,
            whiteListEmails: [],
            isUserLoaded: false,
            showWarningLastName: false,
            showWarningEmail: false,
            emailWarningMessage: ""
        }
    }

    public componentDidMount() {
        this.GetCurrentUserProfileInfoForPage();
    }

    public changeFirstNameField = (e: any) => {
        var lettersOnly = /^[a-zA-ZäöåÄÖÅ]+$/
        if (e.target.value.match(lettersOnly) || e.target.value === "") {
            var updatedUser = this.state.user;
            updatedUser.firstName = e.target.value;
            this.setState({ user: updatedUser })
        }
    }
    public changeLastNameField = (e: any) => {
        var updatedUser = this.state.user;
        updatedUser.lastName = e.target.value;
        this.setState({ user: updatedUser })
    }
    public changeEmailField = (e: any) => {
        var inputToLowerCase = e.target.value.toLowerCase();
        var updatedUser = this.state.user;
        updatedUser.email = inputToLowerCase;
        this.setState({ user: updatedUser })
    }
    public changePhoneField = (e: any) => {
        var NumAndDashRegEx = /^[+]?[0-9- ]*$/
        if (e.target.value.match(NumAndDashRegEx) || e.target.value === "") {
            var updatedUser = this.state.user;
            updatedUser.phoneNumber = e.target.value;
            this.setState({ user: updatedUser })
        }
    }
    public changeOfficeLocationField = (e: any) => {
        var updatedUser = this.state.user;
        updatedUser.organizationOfficeID = e.target.value;
        this.setState({ user: updatedUser })
    }

    public GetCurrentUserProfileInfoForPage = () => {
        UserProfileService.GetCurrentUserProfileInfoForPage().then((result: any) => {
            //Instantiating Choices.js dropdown
            this.officeLocationChoiceDropdown = new Choices('#profile-office-location', {
                classNames: {
                    containerOuter: 'choices choices-profile flex-child-grow',
                },
                fuseOptions: {
                    include: 'score',
                    threshold: 0.1,
                },
            });

            //Filling user and setting email state so we can revert to old email if user types invalid email
            this.setState({ user: result.profile }, () => this.initialRevertState());
            this.setState({ whiteListEmails: result.whiteListValues });

            //Filling array that will be used in dropdown, needs to be in object format with label and value keys
            this.setState({ officeLocations: result.officeLocations }, () => this.LableOfficeLocation());
        }).then(() => {
            this.setState({ isUserLoaded: true })
        });
    }
    //Checks if userPicture is null
    public checkUserPicture = () => {
        if (this.state.user.pictureUrl != null) {
            this.setState({ userPicture: USER_IMAGE_CDN + this.state.user.pictureUrl + "_l.jpg" });
        }
    }

    //Setting initial reset state, in case user cancels changes.
    public initialRevertState = () => {
        this.checkUserPicture();
        this.setState({ firstName: this.state.user.firstName });
        this.setState({ lastName: this.state.user.lastName });
        this.setState({ officeLocation: this.state.user.organizationOfficeID });
        this.setState({ phoneNumber: this.state.user.phoneNumber });
        this.setState({ email: this.state.user.email });
    }

    public LableOfficeLocation = () => {
        var officeLocationsLabeled: any[] = [];
        if (this.state.officeLocations !== [])
            this.state.officeLocations.forEach((office: any) => {
                if (office) {
                    if (this.state.user.organizationOfficeID === office.id) {
                        officeLocationsLabeled.push({ value: office.id, label: office.name, selected: true });
                    }
                    else {
                        officeLocationsLabeled.push({ value: office.id, label: office.name });
                    }
                }
            });
        // Filling Choices.js dropdown with array
        if (officeLocationsLabeled.length > 0) {
            this.officeLocationChoiceDropdown.setChoices(officeLocationsLabeled, 'value', 'label', false);
            this.officeLocationChoiceDropdown.disable();
        }
    }

    //Opeing and closing dropdown logic
    public OpenDropDown = () => {
        if (this.state.isUserLoaded) {
            this.setState({ editOfficeLocation: true });
            this.officeLocationChoiceDropdown.enable();
        }
    }
    public CloseDropDown = (action: string) => {
        if (this.state.isUserLoaded) {
            // If action is Save, then save to database
            if (action === "save") {
                this.setState({ editOfficeLocation: false }, () => this.SaveChoice("organizationOfficeID"));
                this.officeLocationChoiceDropdown.disable();
            }
            else {
                this.setState({ editOfficeLocation: false }, () => this.ResetChoice("organizationOfficeID"));
                this.officeLocationChoiceDropdown.disable();
            }
        }
    }

    // Saving to user object DataBase


    public UpdateUserStore = () => {
        let user: IProfileState = {
            FirstName: this.state.user.firstName,
            LastName: this.state.user.lastName,
            email: this.state.user.email,
            organizationID: this.state.user.organizationID,
            organizationOfficeID: this.state.user.organizationOfficeID,
            pictureUrl: this.state.user.pictureUrl,
            phoneNumber: this.state.user.phoneNumber,
            whiteList: this.state.whiteListEmails,
            officeLocations: this.state.officeLocations,
            userOrganization: this.state.user.userOrganization
        }
        this.props.updateProfile(user);
    }
    public SaveUpdatedUserToDB = (fieldName: string, value: string) => {
        UserProfileService.UpdateCurrentUserProfile(fieldName, value).then((response: string) => {
            this.UpdateUserStore();
            if (fieldName === "email") {
                // Email passed backend test, now we can set proper revert state.
                this.ResetRevertState(fieldName);
            }
        }).catch((error: any) => {
            this.setState({ emailWarningMessage: "profile:MyProfile.WarningMessage.EmailExists" }, () => this.setState({ showWarningEmail: true }));
            //Forcing email from DB back into input
            var updatedUser = this.state.user;
            updatedUser.email = this.state.email;
            this.setState({ user: updatedUser })
        });

    }

    public ResetChoice = (fieldName: string) => {
        var updatedUser = this.state.user;
        switch (fieldName) {
            case 'firstName':
                updatedUser.firstName = this.state.firstName;
                this.setState({ user: updatedUser })
                break;
            case 'lastName':
                updatedUser.lastName = this.state.lastName;
                this.setState({ user: updatedUser })
                break;
            case 'email':
                updatedUser.email = this.state.email;
                this.setState({ user: updatedUser })
                break;
            case 'phoneNumber':
                updatedUser.phoneNumber = this.state.phoneNumber;
                this.setState({ user: updatedUser })
                break;
            case 'organizationOfficeID':
                updatedUser.organizationOfficeID = this.state.officeLocation;
                this.setState({ user: updatedUser })
                //Update Choice.js selected field
                if (this.state.officeLocations !== [])
                    var officeLocationsLabeled: any[];
                officeLocationsLabeled = [];
                this.officeLocationChoiceDropdown.clearStore();
                this.state.officeLocations.forEach((office: any) => {
                    if (this.state.user.organizationOfficeID === office.id) {
                        officeLocationsLabeled.push({ value: office.id, label: office.name, selected: true });
                    }
                    else {
                        officeLocationsLabeled.push({ value: office.id, label: office.name });
                    }
                });
                this.officeLocationChoiceDropdown.setChoices(officeLocationsLabeled, 'value', 'label', false);
                break;
        }
    }

    // After successful save, update revert state that is called from cancel
    public ResetRevertState = (fieldName: string) => {
        switch (fieldName) {
            case 'firstName':
                this.setState({ firstName: this.state.user.firstName });
                break;
            case 'lastName':
                this.setState({ lastName: this.state.user.lastName });
                break;
            case 'email':
                this.setState({ email: this.state.user.email });
                break;
            case 'phoneNumber':
                this.setState({ phoneNumber: this.state.user.phoneNumber });
                break;
            case 'organizationOfficeID':
                this.setState({ officeLocation: this.state.user.organizationOfficeID });
                break;
        }
    }

    //Is Email in the whitelist
    public SaveChoice = (fieldName: string) => {
        // Loop throught whitelist array and create RegEx
        var regEx = this.createRegEx();
        //var emailRegEx = new RegExp(/(?:[a-z0-9!#$%&' * +/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
        /**
         * RegExp check for emails:
         * - Allows one letter TLD's according to spec
         * - Sets allowed characters
         * - Correctly limits the lenght of the different parts of an email address (except local part)
         * - Allows TLD's with numbers, unless they begin with a number (not allowed in spec)
         * - Limits each set after @ to 64 characters according to spec
         * - Tested against examples here: https://stackoverflow.com/a/38787343/6477949
         * - Allows for local@[IPv4-address]
         * - Disallows beginning with .
         * - Handles a lot more rules...
         *
         * Current limitations:
         * - Does not handle maximum number of chars before @
         * - Does not handle local@[IPv6-address]
         * - Does not handle if part before @ ends with .
         */
        var emailRegEx = new RegExp(/^((?!\.)(?!.*\.\.)(([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~'-]+(\.[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~'-]+)*)|(".+")))@(((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]{0,61}\.)+([a-zA-Z][a-zA-Z0-9]{0,61}))))$/);
        var lettersOnly = /^[A-Za-zäöåÄÖÅ]+(?: [A-Za-zäöåÄÖÅ]+)*$/;
        switch (fieldName) {
            case "firstName":
                if (this.state.user.firstName.match(lettersOnly) && this.state.user.firstName !== "") {
                    //Email domain to be saved is whitelisted
                    this.SaveUpdatedUserToDB(fieldName, this.state.user.firstName);
                    this.ResetRevertState(fieldName);
                }

                break;
            case "lastName":
                if (this.state.user.lastName.match(lettersOnly) && this.state.user.lastName !== "") {
                    this.SaveUpdatedUserToDB(fieldName, this.state.user.lastName);
                    this.ResetRevertState(fieldName);
                }
                else {
                    this.setState({ showWarningLastName: true });
                    var updatedUser = this.state.user;
                    updatedUser.lastName = this.state.lastName;
                    this.setState({ user: updatedUser })
                }
                break;
            case "email":
                if (this.state.user.email.match(regEx) && this.state.user.email.match(emailRegEx)) {
                    var regexWhitespaces = /\s/;
                    var testWhiteSpaces = regexWhitespaces.test(this.state.user.email);
                    if (!testWhiteSpaces) {
                        //Email domain to be saved is whitelisted
                        this.SaveUpdatedUserToDB(fieldName, this.state.user.email);
                    }
                    else {
                        //Email contains spaces
                        //Changing input back to email that was fetched from DB
                        this.setState({ emailWarningMessage: "profile:MyProfile.WarningMessage.EmailSpaces" }, () => this.setState({ showWarningEmail: true }));
                        //Forcing email from DB back into input
                        let updatedUser = this.state.user;
                        updatedUser.email = this.state.email;
                        this.setState({ user: updatedUser })
                    }

                }
                else {
                    //Email domain to be is not whitelisted
                    //Changing input back to email that was fetched from DB
                    this.setState({ emailWarningMessage: "profile:MyProfile.WarningMessage.Email" }, () => this.setState({ showWarningEmail: true }));
                    //Forcing email from DB back into input
                    let updatedUser = this.state.user;
                    updatedUser.email = this.state.email;
                    this.setState({ user: updatedUser })
                }
                break;
            case "phoneNumber":
                var NumAndDashRegEx = /^[+]?[0-9- ]*$/
                if (this.state.user.phoneNumber.match(NumAndDashRegEx) || this.state.user.phoneNumber === "") {
                    this.SaveUpdatedUserToDB(fieldName, this.state.user.phoneNumber);
                    this.ResetRevertState(fieldName);
                }
                break;
            case "organizationOfficeID":
                this.SaveUpdatedUserToDB(fieldName, this.state.user.organizationOfficeID);
                this.ResetRevertState(fieldName);
                break;
            default:
                break;
        }

    }

    public createRegEx = () => {
        var regexFromMyArray = new RegExp('.*' + this.state.whiteListEmails.join("$|.*") + '$', 'gm');
        return regexFromMyArray;
    }
    public handleDrop = (dropped: File[]) => {
        var size: any = dropped[0].size;
        var file = dropped[0];
        var img = new Image();
        var _URL = window.URL;

        img.onload = () => {
            let width = img.width;
            let height = img.height;

            if (size > 2000000) {
                this.setState({ imageToBig: true, imageToSmall: false, image: "" });
            }
            else if (size < 2000000 && width < 600 && height < 600) {
                this.setState({ imageToBig: false, imageToSmall: true, image: "" });
            }
            else {
                this.setState({ image: dropped[0], imageToSmall: false, imageToBig: false });
            }
        }
        img.src = _URL.createObjectURL(file);
    }
    public openModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    public setEditorRef = (editor: AvatarEditor) => {
        this.editor = editor;
    }

    public saveImage = () => {
        var base64Img = this.editor.getImage().toDataURL();
        this.setState({ uploading: true });
        UserProfileService.UploadProfileImage(base64Img).then((result: any) => {
            this.setState({ isModalOpen: !this.state.isModalOpen, image: "", imageRotation: 0, zoomLevel: 1, uploading: false, userPicture: base64Img });
            this.props.updateUserPic(result);
        });
    }

    public zoom = (e: any) => {
        this.setState({ zoomLevel: e.target.value / 10 });
    }

    public rotate = (e: any) => {
        var direction = e!.target!.dataset!.direction;
        this.setState({ imageRotation: direction === "left" ? this.state.imageRotation + 90 : this.state.imageRotation - 90 });
    }

    public cancelProfilePic = () => {
        this.setState({ image: "", isModalOpen: false });
    }
    public render() {
        return (
            <ErrorBoundary>
                <Translation>{
                    (t) => {
                        changePageTitle('profile:MyProfile.Title');
                        return (
                            <div className="cell auto cell-block-container cell-block-y width-100">
                                <div className="grid-container">
                                    <div style={{ marginBottom: 20 + 'rem' }} className="grid-x grid-padding-x padding-bottom-3 padding-bottom-medium-down-8 padding-top-1">
                                        <div className="cell">
                                            <div className="cell">
                                                <h1>{i18n_.t('profile:MyProfile.Title')}</h1>
                                            </div>
                                        </div>
                                        <div className="cell">
                                            <div className="grid-x grid-padding-x padding-left-1">
                                                <div className="cell large-8 small-order-2 large-order-1">

                                                    <div className="grid-x grid-padding-x">
                                                        <div className="cell large-10 login-input">
                                                            <label htmlFor="name-first">{i18n_.t('profile:MyProfile.FirstName')} <span id="name-first-edit"><svg onClick={() => { if (this.state.isUserLoaded) { this.setState({ editFirstName: true }) } }} aria-hidden="true" data-prefix="fas" data-icon="edit" className="svg-icon svg-inline--fa fa-edit fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" /></svg></span></label>
                                                            <div className="input-group login display-block-medium-down">
                                                                <input name="name-first" id="name-first" className="input__login input-group-field" type="text" required placeholder={i18n_.t('profile:MyProfile.FirstName')} value={this.state.user.firstName} onChange={this.changeFirstNameField} disabled={!this.state.editFirstName} />
                                                                <span id="name-first-edit-buttons" className={this.state.editFirstName ? "" : "invisible"}>
                                                                    <button id="name-first-save" className="clear button primary margin-0 login-input-button-after" onClick={() => { if (this.state.user.firstName !== "") { this.setState({ editFirstName: false }, () => this.SaveChoice("firstName")) } }}>{t('common:Action.Save')}</button>
                                                                    <button id="name-first-cancel" className="clear button secondary margin-0 login-input-button-after" onClick={() => this.setState({ editFirstName: false }, () => this.ResetChoice("firstName"))}>{t('common:Action.Cancel')}</button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="grid-x grid-padding-x">
                                                        <div className="cell large-10 login-input">
                                                            <label htmlFor="name-last">{i18n_.t('profile:MyProfile.LastName')} <span id="name-last-edit"><svg onClick={() => { if (this.state.isUserLoaded) { this.setState({ editLastName: true, showWarningLastName: false }) } }} aria-hidden="true" data-prefix="fas" data-icon="edit" className="svg-icon svg-inline--fa fa-edit fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" /></svg></span></label>
                                                            <div className="input-group login display-block-medium-down">
                                                                <input name="name-last" id="name-last" className="input__login input-group-field" type="text" required placeholder={i18n_.t('profile:MyProfile.LastName')} value={this.state.user.lastName} onChange={this.changeLastNameField} disabled={!this.state.editLastName} />
                                                                <span id="name-last-edit-buttons" className={this.state.editLastName ? "" : "invisible"}>
                                                                    <button id="name-last-save" className="clear button primary margin-0 login-input-button-after" onClick={() => { if (this.state.user.lastName !== "") { this.setState({ editLastName: false }, () => this.SaveChoice("lastName")) } }}>{t('common:Action.Save')}</button>
                                                                    <button id="name-last-cancel" className="clear button secondary margin-0 login-input-button-after" onClick={() => this.setState({ editLastName: false }, () => this.ResetChoice("lastName"))}>{t('common:Action.Cancel')}</button>
                                                                </span>
                                                            </div>
                                                            {this.state.showWarningLastName && <p id="email-helper" className="text-smaller padding-left-half text-color-dark-red" style={warningStyle}>{i18n_.t('profile:MyProfile.WarningMessage.LastName')}</p>}
                                                        </div>
                                                    </div>

                                                    <div className="grid-x grid-padding-x">
                                                        <div className="cell large-10 login-input">

                                                            <label htmlFor="email">{i18n_.t('profile:MyProfile.Email')} </label> {/*Remove the closing tag of the label when applying edit again*/}
                                                            {/* <span id="email-edit"><svg onClick={() => { if (this.state.isUserLoaded) { this.setState({ editEmail: true, showWarningEmail: false }) } }} aria-hidden="true" data-prefix="fas" data-icon="edit" className="svg-icon svg-inline--fa fa-edit fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg></span></label>*/}
                                                            <div className="input-group login margin-0 display-block-medium-down">
                                                                <input name="email" id="email" className="input__login input-group-field" type="email" required placeholder={i18n_.t('profile:MyProfile.Email')} value={this.state.user.email} onChange={this.changeEmailField} disabled={!this.state.editEmail} />
                                                                <span id="email-edit-buttons" className={this.state.editEmail ? "" : "invisible"}>
                                                                    <button id="email-save" className="clear button primary margin-0 login-input-button-after" onClick={() => { if (this.state.user.email !== "") { this.setState({ editEmail: false }, () => this.SaveChoice("email")) } }}>{t('common:Action.Save')}</button>
                                                                    <button id="email-cancel" className="clear button secondary margin-0 login-input-button-after" onClick={() => this.setState({ editEmail: false }, () => this.ResetChoice("email"))}>{t('common:Action.Cancel')}</button>
                                                                </span>
                                                            </div>
                                                            {this.state.showWarningEmail && <p id="email-helper" className="text-smaller padding-left-half text-color-dark-red" style={warningStyle}>{i18n_.t(this.state.emailWarningMessage)}</p>}
                                                        </div>
                                                    </div>

                                                    <div className="grid-x grid-padding-x">
                                                        <div className="cell large-10 login-input">
                                                            <label htmlFor="phone">{i18n_.t('profile:MyProfile.Phone')}
                                                                <span id="phone-edit">
                                                                    <svg onClick={() => { if (this.state.isUserLoaded) { this.setState({ editPhoneNumber: true }) } }} aria-hidden="true" data-prefix="fas" data-icon="edit" className="svg-icon svg-inline--fa fa-edit fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" /></svg>
                                                                </span>
                                                            </label>
                                                            <div className="input-group login display-block-medium-down">
                                                                <input name="phone" id="phone" className="input__login input-group-field profile-information" type="tel" required placeholder={i18n_.t('profile:MyProfile.Phone')} value={this.state.user.phoneNumber} onChange={this.changePhoneField} disabled={!this.state.editPhoneNumber} pattern="[0-9]+" />
                                                                <span id="phone-edit-buttons" className={this.state.editPhoneNumber ? "" : "invisible profile-information"}>
                                                                    <button id="phone-save" className="clear button primary margin-0 login-input-button-after" onClick={() => { if (this.state.user.phoneNumber !== "" && this.state.user.phoneNumber.match(/.*[0-9].*/gm)) { this.setState({ editPhoneNumber: false }, () => this.SaveChoice("phoneNumber")) } }}> {t('common:Action.Save')}</button>
                                                                    <button id="phone-cancel" className="clear button secondary margin-0 login-input-button-after" onClick={() => this.setState({ editPhoneNumber: false }, () => this.ResetChoice("phoneNumber"))}>{t('common:Action.Cancel')}</button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="grid-x grid-padding-x">
                                                        <div className="cell large-10 login-input">
                                                            <label htmlFor="organization">{i18n_.t('profile:MyProfile.Organization')} </label>
                                                            <div className="input-group login">
                                                                <input name="organization" id="organization" className="input__login input-group-field" type="text" disabled placeholder={i18n_.t('profile:MyProfile.Organization')} value={this.state.user.userOrganization?.name} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid-x grid-padding-x">
                                                        <div className="cell large-10 profile-information">
                                                            <label>{i18n_.t('profile:MyProfile.Office')}
                                                                <span id="office-location-edit">
                                                                    <svg onClick={this.OpenDropDown} aria-hidden="true" data-prefix="fas" data-icon="edit" className="svg-icon svg-inline--fa fa-edit fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" /></svg>
                                                                </span>
                                                            </label>
                                                            <div className="flex-container flex-child-grow padding-left-half grid-x">
                                                                <select id="profile-office-location" value={this.state.officeLocation} onChange={this.changeOfficeLocationField} disabled={!this.state.editOfficeLocation}>
                                                                    <option placeholder={i18n_.t('profile:MyProfile.SelectOfficeLocation')}>{i18n_.t('profile:MyProfile.SelectOfficeLocation')}</option>
                                                                </select>

                                                                <span id="office-location-edit-buttons" className={this.state.editOfficeLocation ? "flex-child-shrink profile-information" : "flex-child-shrink invisible profile-information"}>
                                                                    <button id="office-location-save" className="clear button primary margin-0" onClick={() => this.CloseDropDown("save")}>{t('common:Action.Save')}</button>
                                                                    <button id="office-location-cancel" className="clear button secondary margin-0" onClick={() => this.CloseDropDown("close")}>{t('common:Action.Cancel')}</button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="cell small-4 small-offset-4 large-3 large-offset-1 text-center small-order-1 large-order-2">
                                                    <picture>
                                                        <KPMGImageFallback
                                                            sourceSet={USER_IMAGE_CDN + this.state.user.pictureUrl + "_s.jpg, " + USER_IMAGE_CDN + this.state.user.pictureUrl + "_l.jpg 2x"}
                                                            src={this.state.userPicture}
                                                            fallbackImage={defaultAvatar}
                                                            initialImage={defaultAvatar}
                                                            alt="cool image should be here"
                                                            className="profile-image rounded bordered border-color-dark-gray border-width-fat width-100"
                                                        />
                                                    </picture>
                                                    <button className="clear button darkgray" id="profile-picture" onClick={this.openModal}>{i18n_.t('profile:MyProfile.ChangePicture')}</button>
                                                    <div id="profile-picture-modal-wrapper" className={this.state.isModalOpen ? "reveal-overlay reveal-overlay__open" : ""}>
                                                        <div className={this.state.isModalOpen ? "reveal text-color-black text-left reveal__open" : "reveal text-color-black text-left"} id="profile-picture-modal">
                                                            <h2>{t('profile:MyProfile.Modal.Title')}</h2>
                                                            <button onClick={this.cancelProfilePic} className="close-button" aria-label="Close modal" type="button">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                            <div className="grid-x grid-padding-x">
                                                                <div className="cell large-8 text-center">
                                                                    <div className="avatar-upload-wrapper rounded margin-auto position-relative circle" style={{ width: "380px", height: "380px", backgroundImage: this.props.me.pictureUrl !== "" ? `url(${USER_IMAGE_CDN!}${this.props.me.pictureUrl}_s.jpg)` : `url(./../img/profile-large.png)`, backgroundSize: 'contain', borderRadius: 'inherit' }} aria-disabled="false">
                                                                        <Dropzone
                                                                            className="position-absolute avatar-upload text-center width-100 margin-auto"
                                                                            style={this.state.image === "" ? { position: 'relative', width: '380px', height: '380px' } : {}}
                                                                            aria-disabled="false"
                                                                            ref={this.dropzoneRef}
                                                                            onDrop={this.handleDrop}
                                                                            disableClick
                                                                        >
                                                                            {(this.state.image === "" && this.state.imageToBig) && <div className="avatar-upload-text radius callout warning">{t('profile:MyProfile.Modal.TooBig')}</div>}
                                                                            {(this.state.image === "" && this.state.imageToSmall) && <div className="avatar-upload-text radius callout warning">{t('profile:MyProfile.Modal.TooSmall')}</div>}
                                                                            {this.state.uploading &&
                                                                                <div className="loading-holder">
                                                                                    <div className="loading large" />
                                                                                </div>
                                                                            }
                                                                            {(this.state.image !== "" && !this.state.imageToBig && !this.state.imageToSmall) &&
                                                                                <AvatarEditor
                                                                                    ref={this.setEditorRef}
                                                                                    image={this.state.image}
                                                                                    rotate={this.state.imageRotation}
                                                                                    width={380}
                                                                                    height={380}
                                                                                    border={0}
                                                                                    scale={this.state.zoomLevel}
                                                                                    borderRadius={5000}
                                                                                />
                                                                            }
                                                                        </Dropzone>
                                                                    </div>
                                                                    <button disabled={this.state.uploading} onClick={() => { this.dropzoneRef!.current!.open() }} className="button radius secondary margin-top-1 svg-vertical-align-top">
                                                                        <FontAwesomeIcon icon={faUpload} />
                                                                        {t('profile:MyProfile.Modal.Upload')}
                                                                    </button>
                                                                </div>
                                                                <div className="cell large-4 flex-container align-center-middle">
                                                                    <div className="grid-x grid-padding-x">
                                                                        <div className="cell text-center padding-bottom-1">
                                                                            <span className="h6 text-body-font">{t('profile:MyProfile.Modal.Scale')}</span>
                                                                            <input disabled={this.state.uploading} className="width-100 range-styled" onChange={this.zoom} type="range" min="10" max="60" defaultValue="10" />
                                                                        </div>
                                                                        <div className="cell text-center">
                                                                            <button disabled={this.state.uploading} className="button radius secondary hollow svg-vertical-align-top" data-direction="right" onClick={this.rotate}>
                                                                                <FontAwesomeIcon icon={faUndo} />
                                                                                {t('profile:MyProfile.Modal.Rotate')}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="cell">
                                                                    <h3 className="h6 text-body-font text-color-black font-bold">{t('profile:MyProfile.Modal.InfoText5')}</h3>
                                                                    <ul>
                                                                        <li>{t('profile:MyProfile.Modal.InfoText2')}</li>
                                                                        <li>{t('profile:MyProfile.Modal.InfoText3')}</li>
                                                                        <li>{t('profile:MyProfile.Modal.InfoText4')}</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="small-12 cell text-right">
                                                                    <button disabled={this.state.uploading} id="profile-picture-cancel" onClick={this.cancelProfilePic} className="clear button alert">{t('common:Action.Cancel')}</button>
                                                                    <button disabled={this.state.uploading} id="profile-picture-confirm" onClick={this.saveImage} aria-label="Save picture" className="button success text-color-white radius text-uppercase svg-vertical-align-top">{t('common:Action.Save')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
                </Translation>
            </ErrorBoundary>
        );

    }
}
export default connect(
    (state: IApplicationState) => ({ ...state.profile, ...state.authenticatedRoute }), ({
        ...ProfileStore.actionCreators,
        ...AuthenticatedRouteStore.actionCreators
    })
)(MyProfile);
