import {
    IAccountingCompany,
    ICustomer,
    IFiscalYear,
} from "../Interface/Interfaces";

export class AccoutingCompany implements IAccountingCompany {
    public id: string = "";
    public name: string = "";
    public office: string = "";
    public customerNumber: string = "";
    public imageUrl: string = "";
    public organizationalNumber: string = "";
    public fiscalYears: IFiscalYear[] = [];

    constructor(customer?: ICustomer) {
        if (customer) {
            this.id = customer.id;
            this.name = customer.name;
            this.office = "";
            this.imageUrl = customer.imageUrl;
            this.organizationalNumber = customer.organizationalNumber;
            this.customerNumber = customer.customerNumber;
            this.fiscalYears = customer.fiscalYears;
        }
    }
}
