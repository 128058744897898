import { KnownAction, ICustomerState, ICustomerGetMoreCustomersAction, ICustomerGetMyCustomersAction, ICustomerClearedMyCustomersAction, ICustomerGetMyCustomerAction, ICustomerUpdateSortState } from '../Customer';
import { CosmosCustomerPagedResult } from '../../classes/CosmosCustomerPagedResult';
import { Customer } from './../../classes/Customer';
import { CreatedInitialCustomerSort } from '../../../src/Utils/CustomerHelper';

export function CustomersReducer(state: ICustomerState, action: KnownAction) {
    switch (action.type) {
        case 'GET_MY_DROPDOWN_CUSTOMERS':
            return GetMyDropdownCustomers(state, action);
        case 'CLEAR_MY_DROPDOWN_CUSTOMERS':
            return ClearMyDropdownCustomers(state, action);
        case 'GET_MY_DROPDOWN_CUSTOMER':
            return GetMyDropdownCustomer(state, action);
        case 'UPDATE_SORT_STATE':
            return UpdateSortStateAndSortCustomers(state, action);
        case 'GET_MY_CUSTOMERS':
            return { ...state, myCustomers: action.myCustomers, isLoading: false };
        case 'GET_A_CUSTOMER':
            return { ...state, selectedCustomer: action.selectedCustomer, isOnACustomer: true };
        case 'GET_A_CUSTOMER_TICKETS':
            return { ...state, ticketsLoading: false, selectedCustomerTickets: action.tickets, selectedCustomerTicketPages: action.selectedCustomerTicketPages };
        case 'LEFT_A_CUSTOMER':
            return { ...state, isOnACustomer: false, activeCustomerId: "", selectedCustomer: new Customer(), selectedCustomerTicketPages: 0, selectedCustomerActiveTicketPage: 1 };
        case 'GET_A_CUSTOMER_ACCESSDENIED':
            return { ...state, selectedCustomer: action.selectedCustomer, isOnACustomer: false };
        case 'GET_MORE_CUSTOMERS':
            return getMoreCustomers(state, action);
        case 'GETTING_MORE_CUSTOMERS':
            return { ...state, isLoading: true };
        case 'SEARCH_TEXT_UPDATED':
            return { ...state, searchText: action.searchText };
        case 'CONNECT_CUSTOMERHUB':
            return { ...state, connectionCustomer: action.connectionCustomer, onlineCustomerHub: true };
        case 'DISCONNECTED_CUSTOMERHUB':
            return { ...state, connectionCustomer: null, onlineCustomerHub: false };
        case 'UPDATE_BELL':
            return { ...state, updateBell: action.update };
        case 'RECONNECT_USER':
            return { ...state, reconnectUser: action.reconnectUser, onlineCustomerHub: true };
        case 'CHANGE_A_CUSTOMER_TICKETS_PAGE':
            return { ...state, selectedCustomerActiveTicketPage: action.selectedCustomerTicketPage };
        case 'EMAIL_CHANGED':
            return { ...state, emailChanged: action.isChanged };
        case 'CUSTOMER_USER_REMOVED':
            return { ...state, userIsRemoved: action.userIsRemoved };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = action;
    }
    return state || {
        myCustomers: new CosmosCustomerPagedResult(), searchText: "", isLoading: true, selectedCustomer: new Customer(), connectionCustomer: null, updateBell: false, onlineCustomerHub: false, reconnectUser: false,
        selectedCustomerTickets: [], ticketsLoading: true, selectedCustomerTicketPages: 0, selectedCustomerActiveTicketPage: 1, myDropDownCustomers: [], myDropDownCustomer: new Customer(), sortCustomers: CreatedInitialCustomerSort(),
        userIsRemoved: false, emailChanged: false
    };
}

function getMoreCustomers(state: ICustomerState, action: ICustomerGetMoreCustomersAction) {
    const cosmosPagedResult = new CosmosCustomerPagedResult(state.myCustomers.results, action.moreMyCustomers.results, action.moreMyCustomers.hasNextPage, action.moreMyCustomers.nextPageToken!);
    return { ...state, allCustomers: cosmosPagedResult, isLoading: false };
}

function GetMyDropdownCustomers(state: ICustomerState, action: ICustomerGetMyCustomersAction) {
    return { ...state, myDropDownCustomers: action.myDropDownCustomers };
}

function ClearMyDropdownCustomers(state: ICustomerState, action: ICustomerClearedMyCustomersAction) {
    return { ...state, myDropDownCustomers: action.myDropDownCustomers };
}

function GetMyDropdownCustomer(state: ICustomerState, action: ICustomerGetMyCustomerAction) {
    return { ...state, myDropDownCustomer: action.myDropDownCustomer };
}

export function UpdateSortStateAndSortCustomers(state: ICustomerState, action: ICustomerUpdateSortState) {
    const update = {
        sortCustomers: action.sortCustomers,
        myCustomers: action.myCustomers
    };
    return { ...state, ...update };
}
