import * as React from 'react';
import { activateAccount } from '../apiServices/UserService';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorBoundary from './HOC/ErrorBoundary';

type ActivatingAccountProps =
    RouteComponentProps<{}>;

interface ActivatingAccountState {
    isLoading: boolean;
    accountActivated: boolean;
}

class ActivatingAccount extends React.Component<ActivatingAccountProps, ActivatingAccountState> {
    static displayName = 'ActivatingAccount';

    public token: string;

    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: true,
            accountActivated: false
        }
        this.token = this.props.match.params!["guid"];
    }

    public componentDidMount() {
        if (this.token) {
            activateAccount(this.token).then((response: any) => {
                if (response.ok) {
                    this.setState({ accountActivated: true, isLoading: false });
                } else {
                    this.setState({ accountActivated: false, isLoading: false })
                }
            });
        }
    }

    public render() {
        return (
            <ErrorBoundary>
                {this.state.isLoading === true &&
                    <div className="signed-out login width-100">
                        <div className="grid-y medium-grid-frame">
                            <div className="cell auto cell-block-container cell-block-y width-100">
                                <div className="grid-container">
                                    <div className="grid-x grid-padding-x padding-bottom-3 padding-top-1">
                                        <div className="cell small-12 large-8 large-offset-2">
                                            <div className="grid-x grid-padding-x">
                                                <div className="cell text-center padding-top-2">
                                                    <FontAwesomeIcon icon={faSpinner} size="5x" spin />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.isLoading === false && this.state.accountActivated === true &&
                    <Redirect to={{ pathname: "/", state: { didActivateAccount: true } }} />
                }
                {this.state.isLoading === false && this.state.accountActivated === false &&
                    <Redirect to="/unauthorized" />
                }
            </ErrorBoundary>
        );
    }
}

export default ActivatingAccount;
