import * as React from 'react';
import { Translation } from 'react-i18next';
import { ICustomer } from '../../../../../Interface/Interfaces';
import { Row, Col } from 'react-bootstrap';
import { UserVertical } from '@kpmgportals/genericcomponents';
import { USER_IMAGE_CDN } from '../../../../../Utils/config';
import ContactInformationService from '../../../../../apiServices/ContactInformationService';
import { MissingInfo } from '@kpmgportals/genericcomponents';
import CustomerPicture from '../../CustomerPicture';
import DateTimeHelper from '../../../../../Utils/DateTimeHelper';

export interface ICustomerProfileProps {
    customer: ICustomer;
    getCustomer: (customerNumber: string) => void;
}

export default class CustomerProfile extends React.Component<ICustomerProfileProps, {}> {
    static displayName = 'CustomerProfile';

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="mt-3">
                            <Col lg={8}>
                                <Row>
                                    <Col sm={6} lg={4} className="mb-3">
                                        <Row>
                                            <Col>
                                                <label>{t('customers:Customer.CustomerInfo.CustomerProfile.CustomerNumber')}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.props.customer.customerNumber ? this.props.customer.customerNumber : <MissingInfo />}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} lg={4} className="mb-3">
                                        <Row>
                                            <Col>
                                                <label>{t('customers:Customer.CustomerInfo.CustomerProfile.OrganizationNumber')}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.props.customer.organizationalNumber ? this.props.customer.organizationalNumber : <MissingInfo />}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} lg={4} className="mb-3">
                                        <Row>
                                            <Col>
                                                <label>{t('customers:Customer.CustomerInfo.CustomerProfile.AccountingCompany')}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.props.customer.accountingCompany.name ? this.props.customer.accountingCompany.name : <MissingInfo />}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} lg={4} className="mb-3">
                                        <Row>
                                            <Col>
                                                <label>{t('customers:Customer.CustomerInfo.CustomerProfile.FinancialYear')}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        {(!this.props.customer.fiscalYears[0] ? '' : DateTimeHelper.FormatFinancialYearPeriodLabel(this.props.customer.fiscalYears[0].fiscalStart))}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} lg={4} className="mb-3">
                                        <Row>
                                            <Col>
                                                <label>{t('customers:Customer.CustomerInfo.CustomerProfile.CustomerMainContact')}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.props.customer.mainCustomerContact && this.props.customer.mainCustomerContact.name !== "" ? (
                                                    <UserVertical
                                                        user={this.props.customer.mainCustomerContact}
                                                        displayRole
                                                        USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                        ContactInformationService={ContactInformationService}
                                                        size="small"
                                                    />
                                                ) :
                                                    (
                                                        <MissingInfo />
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} lg={4} className="mb-3">
                                        <Row>
                                            <Col>
                                                <label>{t('customers:Customer.CustomerInfo.CustomerProfile.KpmgManagers')}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.props.customer.customerResponsible && this.props.customer.customerResponsible.name !== "" &&
                                                    <>
                                                        <UserVertical
                                                            user={this.props.customer.customerResponsible}
                                                            displayRole
                                                            USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                            ContactInformationService={ContactInformationService}
                                                            size="small"
                                                        />
                                                        <div className="mb-2" />
                                                    </>
                                                }
                                                {this.props.customer.responsibleAccountant && this.props.customer.responsibleAccountant.name !== "" &&
                                                    <UserVertical
                                                        user={this.props.customer.responsibleAccountant}
                                                        displayRole
                                                        USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                        ContactInformationService={ContactInformationService}
                                                        size="small"
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4}>
                                <CustomerPicture
                                    customer={this.props.customer}
                                />
                            </Col>
                        </Row>
                    )
                }
            }
            </Translation>
        );
    }
}

