import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket, IFileAttachmentBase, IFileRequests, IFileRequest } from '../../../../Interface/Interfaces';
import GenericFilePond from '../../../CommonComponents/Inputs/TicketFilePond';
import { API_ENDPOINT } from '../../../../Utils/config';
import { Modal, Button, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TicketStatus } from 'src/Interface/Enum';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import ButtonCancel from '../../../CommonComponents/Buttons/ButtonCancel';
import Table from '../../../CommonComponents/Tables/Table';
import GenericReactSelect from '../../../CommonComponents/Inputs/GenericReactSelect';
import i18n from '../../../translationcomponent/i18n';
import { FileRequestOption } from '../../../../Utils/ReactSelectHelper';
import { createFilter } from 'react-select';

interface FileRequestMatchModalProps {
    ticket: ITicket;
    tempFileRequest: IFileRequests;
    uniqueFileRequestCategories: string[];
    fileCount: (fileCount: number) => void;
    matchFiles: (removedIds: string[]) => void;
    cancelMatch: () => void;
    fileRequestFileAdded: (file: IFileAttachmentBase) => void;
    openMatchFilesModal: () => void;
    matchFilesModalOpen: boolean;
    matchSelected: (fileId: string, file: IFileAttachmentBase) => void;
    matchingFileRequest: IFileRequests;
    acceptedFileTypes: string[];
}

interface IFileRequestMatchModalState {
    removedIds: string[]
}

export default class FileRequestMatchModal extends React.Component<FileRequestMatchModalProps, IFileRequestMatchModalState> {
    static displayName = 'FileRequestMatchModal';

    constructor(props: FileRequestMatchModalProps) {
        super(props);
        this.state = {
            removedIds: []
        };
    }

    get canEdit() {
        return this.props.ticket.ticketStatus === TicketStatus.WaitingForPartner;
    }

    isMatched(fileId: string) {
        return this.props.matchingFileRequest.fileRequests
            .findIndex(x => x.uploadedFile?.id === fileId) !== -1;
    }

    matchedFileRequest(fileId: string) {
        return this.props.tempFileRequest.fileRequests.find(x => x.uploadedFile?.id === fileId);
    }

    matchingFileRequest(fileId: string) {
        return this.props.matchingFileRequest.fileRequests.find(x => x.uploadedFile?.id === fileId);
    }

    getDefaultValue(fileId: string) {
        const fileRequest = this.matchingFileRequest(fileId);
        return fileRequest ? {
            value: fileRequest.id,
            label: fileRequest.title,
            request: fileRequest.request,
        } : {
                value: 'match',
                label: `${i18n.t('tickets:Filerequest.MatchRequest')}...`,
                request: '',
            }
    }

    matchFiles() {
        this.props.matchFiles([...this.state.removedIds]);
        this.setState({ removedIds: [] });
    }

    removeFile(file: IFileAttachmentBase) {
        this.setState(previousState => ({
            removedIds: [...previousState.removedIds, file.id]
        }));
    }

    cancelMatch() {
        this.setState({ removedIds: [] });
        this.props.cancelMatch();
    }

    createMatchObjects(fileRequests: IFileRequests, fileRequestCategories: string[]) {
        let matchObjects = fileRequestCategories.map((category: string) => {
            return (
                {
                    label: category,
                    options: fileRequests.fileRequests.filter((x: IFileRequest) => x.category === category).map(
                        (fileRequest: IFileRequest, index) => {
                            return (
                                {
                                    value: fileRequest.id,
                                    label: fileRequest.title,
                                    request: fileRequest.request,
                                    category: category,
                                    isDisabled: this.props.matchingFileRequest && !!this.props.matchingFileRequest.fileRequests.find(x => x.id === fileRequest.id)?.uploadedFile,
                                }
                            )
                        }
                    )
                }
            )
        });
        return matchObjects;
    }

    public render() {
        return (
            <Translation>
                {t => {
                    return (
                        <Modal
                            show={this.props.matchFilesModalOpen}
                            size="xl"
                            onHide={() => this.cancelMatch()}
                        >
                            <Modal.Header>
                                <Modal.Title>
                                    {t('tickets:Filerequest.MatchRequestedFiles')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        <Table>
                                            <Table.Head>
                                                <Table.Col
                                                    xs={6}
                                                    th
                                                    separator
                                                >
                                                    {t('tickets:Filerequest.UploadedFiles')}
                                                </Table.Col>
                                                <Table.Col
                                                    xs={6}
                                                    th
                                                >
                                                    {t('tickets:Filerequest.MatchRequest')}
                                                </Table.Col>
                                            </Table.Head>
                                            <Table.Body>
                                                {this.props.tempFileRequest?.unmatchedFiles?.map((file: IFileAttachmentBase) => {
                                                    const matchedFileRequest = this.matchedFileRequest(file.id);
                                                    return (
                                                        <React.Fragment key={`uploadUnmatchRow_${file.id}`}>
                                                            {!matchedFileRequest && this.state.removedIds.indexOf(file.id) === -1 &&
                                                                <Table.Row
                                                                    variant={this.isMatched(file.id) ? 'success' : 'default'}
                                                                >
                                                                    <Table.Col xs={6}>
                                                                        <Row className="flex-fill align-items-center">
                                                                            {this.isMatched(file.id) &&
                                                                                <Col xs="auto">
                                                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                                                </Col>
                                                                            }
                                                                            <Col>
                                                                                {file.originalFileName}
                                                                            </Col>
                                                                            <Col xs="auto">
                                                                                <OverlayTrigger
                                                                                    placement="auto"
                                                                                    overlay={
                                                                                        <Tooltip
                                                                                            id={`${file.id}-remove-description-popover`}
                                                                                        >
                                                                                            {t('tickets:Filerequest.Tooltip.DeleteUploadedFile')}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <Button
                                                                                        variant="light"
                                                                                        size="sm"
                                                                                        title={t('tickets:Filerequest.Tooltip.DeleteUploadedFile')}
                                                                                        onClick={() => this.removeFile(file)}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
                                                                                    </Button>
                                                                                </OverlayTrigger>
                                                                            </Col>
                                                                        </Row>
                                                                    </Table.Col>
                                                                    <Table.Col xs={6}>
                                                                        <Row className="flex-fill">
                                                                            <Col>
                                                                                <Form.Group
                                                                                    controlId={`filerequest-dropdown-${file.id}`}
                                                                                    className="mb-0"
                                                                                >
                                                                                    <GenericReactSelect
                                                                                        inputId={`filerequest-dropdown-${file.id}`}
                                                                                        options={this.createMatchObjects(this.props.tempFileRequest, this.props.uniqueFileRequestCategories)}
                                                                                        onChange={(e: any) => this.props.matchSelected(e ? e.value : 'match', file)}
                                                                                        placeholder={`${t('tickets:Filerequest.MatchRequest')}...`}
                                                                                        value={this.getDefaultValue(file.id)}
                                                                                        isClearable
                                                                                        optionComponent={FileRequestOption}
                                                                                        filterOption={
                                                                                            createFilter({
                                                                                                stringify: option => `${option.label} ${option.value} ${option.data.request} ${option.data.category}`,
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                    </Table.Col>
                                                                </Table.Row>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </Table.Body>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <GenericFilePond
                                            label={t('tickets:Filerequest.Filepond.MultipleMatching')}
                                            uploadUrl={`${API_ENDPOINT}/api/v2/filerequests/${this.props.ticket.id}/files`}
                                            customerId={this.props.ticket.customer!.id}
                                            removeFiles
                                            ticketRef={this.props.ticket.refNumber}
                                            filesWhereSaved={(success: boolean) => { return }}
                                            getFileCount={this.props.fileCount}
                                            fileSizeLimit={50}
                                            disableFilePond={!this.canEdit}
                                            fileAdded={this.props.fileRequestFileAdded}
                                            acceptedFileTypes={this.props.acceptedFileTypes}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className="justify-content-between">
                                <Col xs="auto">
                                    <ButtonCancel onClick={() => this.cancelMatch()}>
                                        {t('common:Cancel')}
                                    </ButtonCancel>
                                </Col>
                                <Col xs="auto">
                                    <Button onClick={() => this.matchFiles()}>
                                        {t('common:Action.Save')}
                                    </Button>
                                </Col>
                            </Modal.Footer>
                        </Modal>
                    );
                }}
            </Translation>
        );
    }
}
