import * as React from 'react';
import { Translation } from 'react-i18next';
import { Col, Row, Container, Button } from 'react-bootstrap';
import DateTimeHelper from '../../Utils/DateTimeHelper';

function KPMGFooter() {
    return (
        <Translation>{
            (t) => {
                return (
                    <Container fluid as="footer" className="footer-gray font-size-smaller p-5 mt-5">
                        <Row>
                            <Col>
                                <p>{t('common:Footer.Title', { year: DateTimeHelper.FormatYear().toString() })}</p>
                                <p className="mb-0">{t('common:Footer.Body')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    variant="link"
                                    as="a"
                                    href="https://home.kpmg/se/sv/home/misc/legal.html"
                                    size="sm"
                                    className="px-0 mr-3 mb-0"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('common:Footer.LegalLink')}
                                </Button>
                                <Button
                                    variant="link"
                                    as="a"
                                    href="https://home.kpmg/se/sv/home/misc/privacy.html"
                                    size="sm"
                                    className="px-0 mb-0"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('common:Footer.PrivacyLink')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                );
            }
        }
        </Translation>
    );
}

KPMGFooter.displayName = 'KPMGFooter';

export default KPMGFooter;
