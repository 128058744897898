import HttpHelper from './HttpHelper';
import StorageService from './StorageService';
import { ITicket, IComment } from './../Interface/Interfaces';
import { API_ENDPOINT } from '../../src/Utils/config';
import DateTimeHelper from '../Utils/DateTimeHelper';
import FileUtils from "../Utils/FileUtils";

export default class TicketService {
    public static CreateTicket() {
        return HttpHelper.Post(API_ENDPOINT + '/api/v2/tickets');
    }

    public static GetACustomerTicket(ticketId: string): Promise<ITicket> {
        return HttpHelper.Get(API_ENDPOINT + '/api/v2/tickets/' + ticketId);
    }

    public static UpdateTicket(ticketId: string, ticket: ITicket, customerId: string) {
        return HttpHelper.Patch(API_ENDPOINT + `/api/v2/customers/${customerId}/tickets/${ticketId}`, JSON.stringify(ticket));
    }

    public static RemoveNewTicketFile(fileId: string, customerId: string) {
        return HttpHelper.Delete(API_ENDPOINT + '/api/v2/customers/' + customerId + '/tickets/files?fileId=' + fileId);
    }

    public static AddComment(ticketId: string, comment: IComment, customerId: string): Promise<ITicket> {
        return HttpHelper.Post(`${API_ENDPOINT}/api/v2/customers/${customerId}/tickets/${ticketId}/comments`, JSON.stringify(comment));
    }

    public static RemoveNewCommentFile(fileId: string, customerId: string, ticketId: string) {
        return HttpHelper.Delete(API_ENDPOINT + '/api/v2/customers/' + customerId + '/tickets/' + ticketId + '/comments/files?fileId=' + fileId);
    }

    public static RemoveTicketDraft(customerId: string, ticketId: string) {
        return HttpHelper.Delete(`${API_ENDPOINT}/api/v2/customers/${customerId}/tickets/${ticketId}`);
    }

    public static RemoveTicketUnfinishedDraft(id: string) {
        return HttpHelper.Delete(`${API_ENDPOINT}/api/v2/tickets/${id}`);
    }

    public static DownloadFiles(url: string, ticketId: string, isDownloadAll: boolean, originalFileName: string, isComment: boolean = false, fileId: string): any {
        let responseOK: boolean = false;
        let total = 1;
        let loaded = 0;
        FileUtils.progress({ total, loaded, fileId });
        return fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": 'Bearer ' + StorageService.GetAccessToken(),
                "Access-Control-Expose-Headers": "Content-Disposition"
            },
        }).then(response => {
            if (!response.ok) {
                throw Error(response.status + ' ' + response.statusText)
            }
            // ensure ReadableStream is supported
            if (!response.body) {
                throw Error('ReadableStream not yet supported in this browser.')
            }
            // store the size of the entity-body, in bytes
            const contentLength = response.headers.get('content-length');
            // ensure contentLength is available
            if (!contentLength) {
                throw Error('Content-Length response header unavailable');
            }
            // parse the integer into a base-10 number
            total = parseInt(contentLength, 10);

            responseOK = true;
            return new Response(
                new ReadableStream({
                    start(controller) {
                        const reader = response.body!.getReader();

                        read();
                        function read() {
                            reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                loaded += value!.byteLength;
                                FileUtils.progress!({ loaded, total, fileId });
                                controller.enqueue(value!);
                                read();
                            }).catch(error => {
                                console.error(error);
                                controller.error(error)
                            })
                        }
                    }
                })
            );
        })
        .then(response =>
            // construct a blob from the data
            response.blob()
        ).then((blob) => {
            if (responseOK) {
                const fileUrl = window.URL.createObjectURL(blob);
                const linkElement = document.createElement('a');
                linkElement.setAttribute('href', fileUrl);
                if (!isDownloadAll) {
                    linkElement.setAttribute("download", originalFileName);
                } else {
                    if (isComment) {
                        linkElement.setAttribute("download", `${ticketId}_comment_${DateTimeHelper.FormatYearMonthDayWithTimeForFileNames(new Date())}.zip`);
                    } else {
                        linkElement.setAttribute("download", `${ticketId}_${DateTimeHelper.FormatYearMonthDayWithTimeForFileNames(new Date())}.zip`);
                    }
                }
                // Force a download
                const clickEvent = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                linkElement.dispatchEvent(clickEvent);
            }
        });
    }

    public static closeTicket(ticket: ITicket): Promise<ITicket> {
        return HttpHelper.Post(API_ENDPOINT + '/api/v2/customers/' + ticket.customer!.id + '/tickets/' + ticket.refNumber);
    }

    public static ReOpenTicket(ticket: ITicket) {
        return HttpHelper.Post(API_ENDPOINT + '/api/v2/customers/' + ticket.customer!.id + '/tickets/' + ticket.refNumber + "?reopen=true");
    }
}
