import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicket } from '../../../../Interface/Interfaces';
import { DeliveredStatusEnum, TicketStatus, TypeOfTicket } from '../../../../Interface/Enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip, OverlayTrigger, Badge } from 'react-bootstrap';
import { Table } from '@kpmgportals/genericcomponents';
import { TicketVariant } from '../../../../Utils/TicketsHelper';
import { UserVertical } from '@kpmgportals/genericcomponents';
import { USER_IMAGE_CDN } from '../../../../Utils/config';
import ContactInformationService from '../../../../apiServices/ContactInformationService';
import { history } from '../../../../store/configureStore';

export interface ITicketProps {
    ticket: ITicket;
    style?: any;
}

export default class TicketRow extends React.Component<ITicketProps, {}> {
    static displayName = 'TicketRow';

    public openTicket = () => {
        if (this.props.ticket.status === TicketStatus.Draft) {
            history.push({ pathname: '/tickets/new', state: { "ticketDraftId": this.props.ticket.id } });
        } else {
            history.push(`/tickets/${this.props.ticket.refNumber}`);
        }
    }

    public render() {
        const creatorPicture = this.props.ticket.authorPicture || '';

        return (
            <Translation>{
                (t) => {
                    return (
                        <Table.Row
                            variant={TicketVariant(this.props.ticket.status)}
                            onClick={this.openTicket}
                            style={this.props.style}
                            className="mx-0"
                        >
                            <Table.Col
                                separator
                                className="ticket-id flex-grow-1"
                            >
                                {this.props.ticket.refNumber}
                                {this.props.ticket.confidential &&
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={`${this.props.ticket.refNumber}-tooltip`}>
                                                {t('tickets:Status.Confidential')}
                                            </Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon icon={faEyeSlash} size="xs" className="ml-3" />
                                    </OverlayTrigger>
                                }
                            </Table.Col>
                            <Table.Col
                                className="ticket-title flex-grow-1"
                                truncate
                                separator
                            >
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`${this.props.ticket.refNumber}-title-tooltip`}>
                                            {this.props.ticket.title}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-truncate">
                                        {this.props.ticket.title}
                                    </span>
                                </OverlayTrigger>
                            </Table.Col>
                            <Table.Col
                                className="ticket-customer flex-grow-1"
                                truncate
                                separator
                            >
                                {this.props.ticket.customer != null &&
                                    <span className="text-truncate">
                                        <div>{this.props.ticket.customer.name}</div>
                                        <small>{this.props.ticket.customer.customerNumber}</small>
                                    </span>
                                }
                            </Table.Col>
                            <Table.Col
                                separator
                                className="ticket-attachments flex-grow-1"
                            >
                                {this.props.ticket.attachedFiles?.length ? t('common:Statement.Yes') : t('common:Statement.No')}
                            </Table.Col>
                            <Table.Col
                                truncate
                                separator
                                className="ticket-created flex-grow-1"
                            >
                                <span className="text-truncate">
                                    <UserVertical
                                        user={{
                                            pictureUrl: creatorPicture,
                                            name: this.props.ticket.authorText,
                                            firstName: "",
                                            lastName: "",
                                            email: "",
                                            id: this.props.ticket.author,
                                            phone: "",
                                            title: ""
                                        }}
                                        size="small"
                                        dateInfo={this.props.ticket.created}
                                        USER_IMAGE_CDN={USER_IMAGE_CDN}
                                        ContactInformationService={ContactInformationService}
                                        hoverCardAppendTo={document.body}
                                    />
                                </span>
                            </Table.Col>
                            <Table.Col
                                truncate
                                separator
                                className="ticket-changed flex-grow-1"
                            >
                                {(this.props.ticket.historyCount && this.props.ticket.historyCount > 1) ? (
                                    <span className="text-truncate">
                                        <UserVertical
                                            user={{
                                                pictureUrl: this.props.ticket.editedByPicture || '',
                                                name: this.props.ticket.editedByText,
                                                firstName: "",
                                                lastName: "",
                                                email: "",
                                                id: this.props.ticket.editedBy,
                                                phone: "",
                                                title: ""
                                            }}
                                            dateInfo={this.props.ticket.edited}
                                            USER_IMAGE_CDN={USER_IMAGE_CDN}
                                            ContactInformationService={ContactInformationService}
                                            hoverCardAppendTo={document.body}
                                        />
                                    </span>
                                )
                                    : (
                                        (null)
                                    )
                                }
                            </Table.Col>
                            <Table.Col
                                truncate
                                className="ticket-status flex-grow-1"
                            >
                                {((this.props.ticket.typeOfTicket === TypeOfTicket.PreparedByClient || this.props.ticket.typeOfTicket === TypeOfTicket.PreparedByPartner) &&
                                    this.props.ticket.fileRequestDeliveredStatus !== DeliveredStatusEnum.NotDelivered) ? (
                                        <div>
                                            <div>
                                                <Badge variant="warning">
                                                    <span>{t('tickets:Status.' + TicketStatus[this.props.ticket.status])}</span>
                                                </Badge>
                                            </div>
                                            <div>
                                                <Badge variant={this.props.ticket.fileRequestDeliveredStatus === DeliveredStatusEnum.FullyDelivered ? 'success' : 'warning'}>
                                                    <span>{t('tickets:Filerequest.DeliverStatus.' + DeliveredStatusEnum[this.props.ticket.fileRequestDeliveredStatus!])}</span>
                                                </Badge>
                                            </div>
                                        </div>
                                    ) : (
                                        (
                                            TicketStatus[this.props.ticket.status] === 'Resolved' ? (
                                                t('tickets:Status.' + TicketStatus[this.props.ticket.status])
                                            ) :
                                                (
                                                    <div>
                                                        <Badge variant="warning">
                                                            {t('tickets:Status.' + TicketStatus[this.props.ticket.status])}
                                                        </Badge>
                                                    </div>
                                                )
                                        )
                                    )}
                            </Table.Col>
                        </Table.Row>
                    );
                }
            }
            </Translation>
        );
    }
}
