import * as React from 'react';
import { ICustomerContact } from '../../../Interface/Interfaces';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../store';
import * as CustomersStore from '../../../store/Customer'
import { Table } from '@kpmgportals/genericcomponents';
import InlineOrganization from '../../CommonComponents/UserOrgs/InlineOrganization';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { UserVertical } from '@kpmgportals/genericcomponents';
import { USER_IMAGE_CDN } from '../../../Utils/config';
import ContactInformationService from '../../../apiServices/ContactInformationService';
import { CustomerTypeEnum } from '../../../Interface/Enum';
import i18n from 'i18next';
import DateTimeHelper from '../../../Utils/DateTimeHelper';

export interface ICustomerRowOwnProps {
    id: string;
    companyName: string;
    organizationNumber: string;
    customerNumber: string;
    customerManager: ICustomerContact;
    fiscalEnd: string;
    member: boolean;
    imageUrl: string;
    memberRequest: boolean;
    style: React.CSSProperties;
    industry: string;
    customerType: CustomerTypeEnum;
    organizationForm: string;
}

type ICustomerRowProps = typeof CustomersStore.actionCreators
    & ICustomerRowOwnProps;

class CustomerRow extends React.Component<ICustomerRowProps> {
    static displayName = 'CustomerRow';

    requestButton: HTMLButtonElement;

    get rowVariant() {
        if (!this.props.member) {
            return "secondary"
        }

        return "default";
    }

    getToolTipMessage(idPrefix: string): string | JSX.Element | null {
        let otherMessage = "";
        switch (idPrefix) {
            case "orgform":
                otherMessage = this.props.organizationForm;
                break;
            case "industry":
                otherMessage = this.props.industry;
                break;
        }

        if (this.props.member) {
            if (otherMessage === null || otherMessage.length === 0) {
                return null;
            }

            return otherMessage;
        }

        if (otherMessage && otherMessage.length > 0) {
            return (
                <>
                    <p>{i18n.t('customers:Tooltip.NoAccess')}</p>
                    <p>{otherMessage}</p>
                </>
            )
        }

        return i18n.t('customers:Tooltip.NoAccess');
    }

    public renderToolTip(children: React.ReactNode, idPrefix: string) {
        const message = this.getToolTipMessage(idPrefix);
        if (message === null) {
            return children;
        }

        return (
            <OverlayTrigger
                placement="auto-start"
                overlay={
                    <Tooltip id={`request-${idPrefix + this.props.customerNumber}-tooltip-popover`}>
                        {message}
                    </Tooltip>
                }
            >
                {children as any}
            </OverlayTrigger>
        )
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Table.Row
                            data-is-customer-row
                            data-customer-number={this.props.customerNumber}
                            style={this.props.style}
                            variant={this.rowVariant}
                            className="mx-0"
                        >
                            <Table.Col
                                xs={3}
                                className="flex-grow-1 h-100"
                                truncate
                                separator
                            >
                                {this.renderToolTip(
                                    <span className="text-truncate">
                                        <InlineOrganization
                                            className=""
                                            label={this.props.companyName}
                                            customerId={this.props.id}
                                            pictureUrl={this.props.imageUrl}
                                            size="small"
                                        />
                                    </span>
                                    , "name")}
                            </Table.Col>
                            <Table.Col
                                xs="auto"
                                className="flex-grow-1 h-100"
                                truncate
                                separator
                            >
                                {this.renderToolTip(
                                    <span className="text-truncate">
                                        {this.props.organizationNumber}
                                    </span>
                                    , "orgNumb")}
                            </Table.Col>
                            <Table.Col
                                xs="auto"
                                className="flex-grow-1 h-100"
                                truncate
                                separator
                            >
                                {this.renderToolTip(
                                    <span>
                                        {this.props.customerNumber}
                                    </span>
                                    , "num")}
                            </Table.Col>
                            <Table.Col
                                xs="auto"
                                className="flex-grow-1 h-100"
                                truncate
                                separator
                            >
                                {this.renderToolTip(
                                    <span>
                                        {this.props.customerManager !== null &&
                                            <UserVertical
                                                user={{
                                                    firstName: "",
                                                    lastName: "",
                                                    email: "",
                                                    name: this.props.customerManager?.name ?? "",
                                                    id: this.props.customerManager.id ?? "",
                                                    phone: "",
                                                    pictureUrl: "",
                                                    title: "",
                                                    userId: this.props.customerManager.id ?? ""
                                                }}
                                                size="small"
                                                USER_IMAGE_CDN={USER_IMAGE_CDN}
                                                ContactInformationService={ContactInformationService}
                                                hoverCardAppendTo={document.body}
                                            />
                                        }
                                    </span>
                                    , "manager")}
                            </Table.Col>
                            <Table.Col
                                xs="auto"
                                className="flex-grow-1 h-100"
                                truncate
                                separator
                            >
                                {this.renderToolTip(
                                    <span>
                                        {DateTimeHelper.FormatFinancialYearEnd(this.props.fiscalEnd)}
                                    </span>
                                    , "fy")}
                            </Table.Col>
                        </Table.Row>
                    )
                }}
            </Translation>
        );
    }
}
export default connect(
    (state: IApplicationState) => state.customer, CustomersStore.actionCreators
)(CustomerRow);
