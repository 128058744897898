import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicketAttachments } from '../../../Interface/Interfaces';
import FileUtils from '../../../Utils/FileUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DateTimeHelper from '../../../Utils/DateTimeHelper';

interface TicketHeaderProps {
    attachFile: ITicketAttachments;
    groupId: string;
    downloadFiles: (e: any, groupId: string, originalFileName: string) => void;
    newTicket?: boolean;
    removeNewTicketFile?: (file: ITicketAttachments) => void;
    showExpiredDate?: boolean;
    progressPrefix?: string;
}

export default class AttachedFile extends React.Component<TicketHeaderProps, {}> {
    static displayName = 'AttachedFile';

    public removeNewTicketFile = () => {
        this.props.removeNewTicketFile!(this.props.attachFile);
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="justify-content-between">
                            <Col>
                                <div className="generic-table-row py-1 text-truncate">
                                    <Row>
                                        <Col>
                                            {!this.props.newTicket &&
                                                <Row>
                                                    <Col>
                                                        <Button
                                                            onClick={!this.props.newTicket ? (e: any) => this.props.downloadFiles(e, this.props.groupId, this.props.attachFile.originalFileName) : () => { return }}
                                                            data-value={this.props.attachFile.id}
                                                            variant="link"
                                                            size="sm"
                                                            className="max-width-100"
                                                            disabled={(this.props.showExpiredDate && this.props.attachFile.downloadedBy && DateTimeHelper.HowManyDaysLeftThirtyDayCountdown(this.props.attachFile.downloadedBy[this.props.attachFile.downloadedBy.length - 1].dateDownloaded) <= 0)}
                                                        >
                                                            <Row noGutters>
                                                                <Col xs="auto" className="mr-2">
                                                                    {FileUtils.GetFileImage(this.props.attachFile.extension)}
                                                                </Col>
                                                                <Col className="text-truncate">
                                                                    <span>{this.props.attachFile.originalFileName}</span>
                                                                </Col>
                                                            </Row>
                                                        </Button>
                                                    </Col>
                                                    {(this.props.showExpiredDate && this.props.attachFile.downloadedBy && this.props.attachFile.downloadedBy.length > 0) ? (
                                                        <Col>
                                                            {(DateTimeHelper.HowManyDaysLeftThirtyDayCountdown(this.props.attachFile.downloadedBy[this.props.attachFile.downloadedBy.length - 1].dateDownloaded) <= 0) ? (
                                                                <OverlayTrigger
                                                                    placement="auto"
                                                                    overlay={
                                                                        <Tooltip id={`file-${this.props.attachFile.id}-expired-tooltip`}>
                                                                            {t('tickets:Ticket.Tooltip.DownloadHasExpired')}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon className="text-warning" icon={faExclamationCircle} />
                                                                </OverlayTrigger>
                                                            ) :
                                                                (
                                                                    <OverlayTrigger
                                                                        placement="auto"
                                                                        overlay={
                                                                            <Tooltip id={`file-${this.props.attachFile.id}-expired-tooltip`}>
                                                                                <span>{t('tickets:Ticket.Tooltip.FileWillBeDeleted')} {DateTimeHelper.HowManyDaysLeftThirtyDayCountdown(this.props.attachFile.downloadedBy[this.props.attachFile.downloadedBy.length - 1].dateDownloaded)} {t('tickets:Ticket.Tooltip.Days')}</span>
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon className="text-warning" icon={faExclamationCircle} />
                                                                    </OverlayTrigger>
                                                                )
                                                            }
                                                        </Col>
                                                    ) :
                                                        (
                                                            <Col>
                                                                <FontAwesomeIcon className="mr-3 text-warning" style={{ opacity: '0' }} icon={faExclamationCircle} />
                                                            </Col>
                                                        )}
                                                    <Col xs={2}>
                                                        <div id={`progress-${this.props.progressPrefix ?? ""}${this.props.attachFile.id}`}>&nbsp;</div>
                                                    </Col>
                                                </Row>

                                            }
                                            {this.props.newTicket &&
                                                <Row noGutters>
                                                    <Col xs="auto" className="mr-2">
                                                        {FileUtils.GetFileImage(this.props.attachFile.extension)}
                                                    </Col>
                                                    <Col className="text-truncate">
                                                        <span>{this.props.attachFile.originalFileName}</span>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                        {this.props.newTicket &&
                                            <Col xs="auto">
                                                <Button
                                                    onClick={this.removeNewTicketFile}
                                                    variant="link"
                                                    className="text-danger"
                                                    size="sm"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    );
                }
            }
            </Translation>
        );
    }
}
