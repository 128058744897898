import * as React from 'react';
import { Translation } from 'react-i18next';
import CustomerRow from './CustomerRow';
import { ICustomer, ISort } from '../../../Interface/Interfaces';
import { Table } from '@kpmgportals/genericcomponents';
import CustomersTableHead from './CustomersTableHead';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { Spinner } from '@kpmgportals/genericcomponents';
import { Row } from 'react-bootstrap';
import { ListChildComponentProps } from 'react-window';

export interface ICustomersTableProps {
    customers: ICustomer[],
    hasNextPage: boolean;
    isLoading: boolean;
    sortCustomers: ISort;
    updateSortState: (sortOn: string) => {};
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void | Promise<void>;
    getMoreCustomers: () => void;
}

export default class CustomersTable extends React.Component<ICustomersTableProps, {}> {
    static displayName = 'CustomersTable';

    public loadMore = async () => {
        if (!this.props.isLoading && this.props.hasNextPage) {
            this.props.getMoreCustomers();
        }
    }

    get itemCount() {
        return this.props.hasNextPage ? this.props.customers.length + 1 : this.props.customers.length;
    }

    public renderItem = (row: React.PropsWithChildren<ListChildComponentProps>, customer: ICustomer) => {
        return (
            <CustomerRow
                {...row}
                key={`tbl_row_${customer.id}`}
                id={customer.id}
                companyName={customer.name}
                customerManager={customer.customerResponsible}
                customerNumber={customer.customerNumber}
                fiscalEnd={customer.fiscalYears[0].fiscalEnd}
                organizationNumber={customer.organizationalNumber}
                member={customer.isMember}
                imageUrl={customer.imageUrl}
                memberRequest={customer.isRequested}
                industry={customer.industry}
                customerType={customer.customerType}
                organizationForm={customer.organizationForm}
            />
        )
    }

    public renderSpinner = (row: React.PropsWithChildren<ListChildComponentProps>) => {
        return (
            <Table.Row
                style={row.style}
                className="mx-0"
            >
                <Table.Col xs={12}>
                    <Spinner
                        noMargin
                        isLoading={this.props.isLoading}
                        className='w-100'
                    />
                </Table.Col>
            </Table.Row>
        )
    }

    public isItemLoaded = (index: number) => {
        return !this.props.hasNextPage || index < this.props.customers.length;
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Table onClick={this.props.onClick} minWidth="xxl">
                            <CustomersTableHead
                                changeSort={(name: string) => this.props.updateSortState(name)}
                                customersLength={this.props.customers.length}
                            />
                            <Table.Body>
                                {(this.props.customers.length > 0) ? (
                                    <InfiniteLoader
                                        isItemLoaded={this.isItemLoaded}
                                        itemCount={this.itemCount}
                                        loadMoreItems={this.loadMore}
                                    >
                                        {({ onItemsRendered, ref }) => (
                                            <FixedSizeList
                                                itemCount={this.itemCount}
                                                ref={ref}
                                                onItemsRendered={onItemsRendered}
                                                height={400}
                                                itemSize={55}
                                                className="row tr"
                                                width="initial"
                                            >
                                                {(row) => {
                                                    if (!this.isItemLoaded(row.index)) {
                                                        return this.renderSpinner(row);
                                                    } else {
                                                        const customer = this.props.customers[row.index];
                                                        return this.renderItem(row, customer);
                                                    }
                                                }}
                                            </FixedSizeList>
                                        )}
                                    </InfiniteLoader>
                                ) : (
                                    (this.props.customers.length === 0 && !this.props.isLoading) ? (
                                        <Row className="position-relative overflow-auto w-100" style={{ height: '400px' }}>
                                            <Table.Row
                                                className="mx-0 w-100"
                                                noHover
                                            >
                                                <Table.Col xs={12} className="justify-content-center">
                                                    {t('customers:NoCustomers')}
                                                </Table.Col>
                                            </Table.Row>
                                        </Row>) :
                                        (
                                            <Row className="position-relative overflow-auto w-100" style={{ height: '400px' }}>
                                                <Table.Row className="mx-0 w-100" noHover>
                                                    <Table.Col xs={12}>
                                                        <Spinner
                                                            noMargin
                                                            isLoading
                                                            className='w-100'
                                                        />
                                                    </Table.Col>
                                                </Table.Row>
                                            </Row>
                                        )
                                )
                                }
                            </Table.Body>
                        </Table>
                    )
                }
            }
            </Translation>
        );
    }
}

