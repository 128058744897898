import { IToastMessage } from "../Interface/Interfaces";
import { Reducer } from "redux";
import { ToastsActions } from "./actions/ToastsActions";
import { ToastsReducer } from "./reducers/ToastsReducers";

export interface IToastsState {
    toastMessages: IToastMessage[];
}

export interface IAddToastMessageAction { type: 'ADD_TOAST'; payload: IToastMessage; }

interface IRemoveToastMessageAction { type: 'REMOVE_TOAST'; id: string; }

export type KnownAction = IAddToastMessageAction | IRemoveToastMessageAction;

export const actionCreators = ToastsActions;

export const reducer: Reducer<IToastsState> = (state: IToastsState, action: KnownAction) => ToastsReducer(state, action);
