import * as React from 'react';
import { KpmgLogo } from '@kpmgportals/kpmgassets';

export default class SmallLogo extends React.Component {    
    public render () {
        return (
            <div className="logo logo-wider text-white old">
                <KpmgLogo className="text-white" />
                <h1>Client Access</h1>
            </div>
        );
    }
};
