import jwtDecode from 'jwt-decode';
import * as DeviceUUID from 'device-uuid';
import Logger from '../Utils/Logger';
import { API_ENDPOINT } from '../Utils/config';
import { Exception } from '@microsoft/applicationinsights-web';
import AppInsightsUtils from '../Utils/AppInsightsUtils';
import StorageService from './StorageService';

export enum TokenState {
    Initalizing,
    Expired,
    Good,
    Refreshing
}

export default class HttpHelper {
    public static Put(url: string, body?: any): Promise<any> {
        const token = StorageService.GetAccessToken();
        return new Promise<any>((resolve, reject) => {
            fetch(url, {
                credentials: 'include',
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: body
            }).then((response: Response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        Logger.log("Response " + url);
                        Logger.log("Body");
                        Logger.log(body);
                        Logger.log("response");
                        Logger.log(data);
                        resolve(data);
                    });
                } else {
                    response.json().then((errorData) => {
                        Logger.error("Error doing put to " + url, { response: response });
                        Logger.log(body);
                        reject(errorData);
                    });
                }
            });
        }).catch((e: Exception) => {
            AppInsightsUtils.TrackException(e);
        });
    }

    public static CreateDownloadGetRequestInit(): RequestInit {
        return {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + StorageService.GetAccessToken(),
                "Access-Control-Expose-Headers": "Content-Disposition"
            }
        };
    }

    public static Post(url: string, body?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            HttpHelper.fetchWithCredentials(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: body
            }).then((response: Response) => {
                if (response.ok) {
                    console.log(url);
                    console.log(response);
                    response.json().then((data) => {
                        Logger.log("Response " + url);
                        Logger.log("Body");
                        Logger.log(body);
                        Logger.log("response");
                        Logger.log(data);
                        resolve(data);
                    });
                } else {
                    Logger.log("Error doing post to " + url);
                    Logger.log(body);
                    reject(response.statusText);
                }
            });
        }).catch((e: Exception) => {
            AppInsightsUtils.TrackException(e);
        });
    }

    public static PostThatNeedsMigration(url: string, body?: any): Promise<Response> {
        return HttpHelper.fetchWithCredentials(url, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    }

    public static Get(url: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            HttpHelper.fetchWithCredentials(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then((response: Response) => {
                 console.log(url);
                console.log(response);
                if (response.ok) {
                    response.json().then((data) => {
                        Logger.log("Response " + url);
                        Logger.log("response");
                        Logger.log(data);
                        resolve(data);
                    });
                } else {
                    Logger.log("Error doing post to " + url);
                    reject(response.statusText);
                }
            });
        }).catch((e: Exception) => {
            AppInsightsUtils.TrackException(e);
        });
    }

    public static Patch(url: string, body?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            HttpHelper.fetchWithCredentials(url, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: body
            }).then((response: Response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        Logger.log("Response " + url);
                        Logger.log("Body");
                        Logger.log(body);
                        Logger.log("response");
                        Logger.log(data);
                        resolve(data);
                    });
                } else {
                    Logger.error("Error doing patch to " + url, { response: response });
                    Logger.log(body);
                    reject(response.statusText);
                }
            });
        }).catch((e: Exception) => {
            AppInsightsUtils.TrackException(e);
        });
    }

    public static Delete(url: string, body?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            HttpHelper.fetchWithCredentials(url, {
                method: 'Delete',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: body
            }).then((response: Response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        Logger.log("Response " + url);
                        Logger.log("response");
                        Logger.log(data);
                        resolve(data);
                    });
                } else {
                    Logger.error("Error doing post to " + url, { response: response });
                    reject(response);
                }
            });
        }).catch((e: Exception) => {
            AppInsightsUtils.TrackException(e);
        });
    }

    public static GetThatNeedsMigration(url: string): Promise<Response> {
        return HttpHelper.fetchWithCredentials(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
    }

    public static getDeviceId(): string {
        const deviceId = new DeviceUUID.DeviceUUID().get();
        return deviceId;
    }

    public static isOldAccessToken(): boolean {
        const jsonToken = StorageService.GetAccessToken();
        if (jsonToken !== "") {
            const decodedToken = jwtDecode(jsonToken) as any;
            const currentTime = Date.now() / 1000;
            Logger.log(decodedToken.exp);
            Logger.log(currentTime);
            return (decodedToken.exp < currentTime);
        } else {
            return true;
        }
    }

    public static getValidAccessToken(): Promise<string> {
        if (!HttpHelper.refreshInterval) {
            HttpHelper.refreshInterval = window.setInterval(HttpHelper.refresh, 300000);
        }
        return new Promise<string>((resolve, reject) => {
            const jsonToken = StorageService.GetAccessToken();
            if (jsonToken !== "") {
                resolve(jsonToken);
            } else {
                StorageService.ClearAll();
                resolve("expired");
            }
        });
    }

    public static LoadFile(ticketId: string, fileId: string, originaName: string, urlBase: string) {
        let responseOK: boolean = false;
        const data = {
            ticketID: ticketId,
            fileId: fileId,
            originalFileName: originaName
        };
        return fetch(urlBase + "api/FileDownload?ticketID=" + data.ticketID + "&fileId=" + data.fileId, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": StorageService.GetAccessToken(),
                "Access-Control-Expose-Headers": "Content-Disposition"
            },
        }).then((response: Response) => {
            if (response.status === 200) { responseOK = true; }
            return response.blob();
        })
        .then((blob) => {
            if (responseOK) {
                const url = window.URL.createObjectURL(blob);
                Logger.log(blob);
                const linkElement = document.createElement('a');
                linkElement.setAttribute('href', url);
                linkElement.setAttribute("download", data.originalFileName);

                // Force a download
                const clickEvent = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                linkElement.dispatchEvent(clickEvent);
            }
        }).catch((e: Exception) => {
            AppInsightsUtils.TrackException(e);
        });
    }

    private static refreshInterval: number;

    private static async fetchWithCredentials(url: string, options: RequestInit | undefined): Promise<Response> {
        if (!HttpHelper.refreshInterval) {
            HttpHelper.refreshInterval = window.setInterval(HttpHelper.refresh, 300000);
        }
        const jwtToken = StorageService.GetAccessToken();
        options = options || {};
        options.headers = options.headers || {};
        const headers = new Headers(options.headers);
        headers.append('Authorization', 'Bearer ' + jwtToken);
        options.headers = headers;
        const response = await fetch(url, options);
        return response;
    }

    private static async refresh(): Promise<void> {
        Logger.log("Refreshing");
        const accessToken = StorageService.GetAccessToken();
        if (accessToken !== '' && window.location.pathname !== '/login') {
            const refreshToken = StorageService.GetRefreshToken();
            const sessionId = StorageService.GetRefreshSessionId();
            const body = {
                token: accessToken,
                refreshToken: refreshToken,
                deviceId: HttpHelper.getDeviceId(),
                sessionId: sessionId
            };

            const response = await fetch(API_ENDPOINT + '/api/User/RefreshToken', {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                window.clearInterval(HttpHelper.refreshInterval);
                HttpHelper.LogOut();
                return;
            }
            const jsonResponse = await response.json();
            StorageService.UpdateToken(jsonResponse);
        }
        else {
            return new Promise((resolve, reject) => { });
        }
    }

    private static LogOut() {
        StorageService.ClearAll();
        window.location.href = "/login";
    }

    public static CreateDownloadResponse(responseOK: boolean, blob: Blob, originalFileName: string) {
        if (responseOK) {
            const blobUrl = window.URL.createObjectURL(blob);
            const linkElement = document.createElement('a');
            linkElement.setAttribute('href', blobUrl);
            linkElement.setAttribute("download", originalFileName);
            // Force a download
            const clickEvent = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        }
    }
}
