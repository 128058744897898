export enum TicketStatus {
    Invalid = -1,
    Created,
    WaitingForKPMG,
    WaitingForKPMGUpdated,
    WaitingForPartner,
    WaitingForPartnerUpdated,
    Resolved,
    Removed,
    Reopen,
    WaitingForCustomer,
    WaitingForCustomerUpdated,
    Draft,
    UnfinishedDraft
}

export enum TypeOfTicket {
    PartnerTicket = 10,
    CustomerTicket = 20,
    PreparedByClient = 30,
    PreparedByPartner = 40
}

export enum OriginEnum {
    OldTicket = 0,
    Internal = 10,
    External = 20,
    Customer = 30
}

export enum FiscalYearEnum {
    CurrentYear,
    PreviousYear,
    NoShow,
    NotSet = 10
}

export enum SeverityLevel {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4,
}
export enum InfoTypeEnum {
    deploy = 10
}

export enum FileRequestStateEnum {
    Temp = 0,
    Draft = 1,
    FileRequest = 2
}

export enum FileRequestStatusEnum {
    NotMatched = 0,
    Matched = 1,
    Delivered = 2,
    Rejected = 3
}

export enum DeliveredStatusEnum {
    NotDelivered = 0,
    PartDelivered = 1,
    FullyDelivered = 2,
}

export enum CustomerTypeEnum {
    NotSet = 0,
    Audit = 1,
    Tax = 2,
    Advisory = 3,
    NonAudit = 4
}
