import * as React from "react";
import { IFileRequest } from "../../../../Interface/Interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle, faLockAlt, faSparkles, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FileRequestStatusEnum } from "src/Interface/Enum";
import { Translation } from "react-i18next";
import FileRequestTableRowToolTip from "./FileRequestTableRowToolTip";

interface IFileRequestRowIconProps {
    request: IFileRequest;
}

interface IRowIcon {
    className: string;
    icon: IconDefinition;
}

export default class FileRequestRowIcon extends React.Component<IFileRequestRowIconProps> {
    static displayName = 'FileRequestRowIcon';

    get icon(): IRowIcon | null {
        if (this.props.request.status === FileRequestStatusEnum.Matched) {
            return {
                className: "mr-2 text-success",
                icon: faCheckCircle
            };
        }

        if (this.props.request.status === FileRequestStatusEnum.Rejected) {
            return {
                className: "mr-2 text-danger",
                icon: faExclamationCircle
            };
        }

        if (this.props.request.status === FileRequestStatusEnum.Delivered) {
            return {
                className: "mr-2 text-secondary",
                icon: faLockAlt
            };
        }

        if (this.props.request.status === FileRequestStatusEnum.NotMatched &&
            this.props.request.isRecentlyAdded) {
            return {
                className: "mr-2 text-primary",
                icon: faSparkles
            };
        }

        return null;
    }

    public render() {
        if (this.icon == null) {
            return "";
        }

        return (
            <Translation>
                {t => {
                    return (
                        <FileRequestTableRowToolTip
                            request={this.props.request}
                        >
                            <span>
                                <FontAwesomeIcon
                                    {...this.icon!}
                                    size="lg"
                                />
                            </span>
                        </FileRequestTableRowToolTip>
                    );
                }
                }
            </Translation>
        );
    }
}
