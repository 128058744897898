import React from 'react';
import AppInsightsUtils from '../../Utils/AppInsightsUtils';
import { Exception } from '@microsoft/applicationinsights-web';
import { getAppInsights } from '../../services/TelemetryService';
import { getUserId } from '../../apiServices/UserService';

interface IErrorBoundaryState {
    error: Error | null,
    info: any | null,
    hasError: boolean
}

export default class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
    static displayName = 'ErrorBoundary';

    constructor(props: any) {
        super(props);
        this.state = { error: null, info: null, hasError: false };
    }
    componentDidCatch(error: Error, info: any) {
        this.setState({
            hasError: true,
            error: error,
            info: info
        }, () => this._logErrorToCloud(error, info));        
    }

    private _logErrorToCloud = (error: Error | null, info: object) => {
        var logger = getAppInsights().core.logger;
        let exception = new Exception(logger, error !== null ? error : new Error('Error was null'), info);
        exception.exceptions = [];
        exception.exceptions.push({ hasFullStack: true, id: Number(getUserId()), message: error?.message!, outerId: 1, parsedStack: [], stack: error?.stack!, typeName: error?.name!  })
        AppInsightsUtils.TrackException(exception);
    };

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h1>Something went wrong. Close your browser and login again.</h1>
                    <p>The error: {this.state.error!.toString()}</p>
                    <p>Where it occured: {this.state.info.componentStack}</p>
                </div>
            );
        } else {
            return this.props.children;
        }
    }
};
