import { Reducer } from 'redux';
import { CustomerHubActions } from './actions/CustomerHubActions';
import { CustomerHubReducer } from './reducers/CustomerHubReducer';

export interface ICustomerHubState {
    connectionCustomer: signalR.HubConnection | null;
    connected: boolean;
}

export interface ICustomerConnectHub { type: 'CUSTOMERHUB_CONNECT_HUB'; connectionCustomer: signalR.HubConnection; }
export interface ICustomerDiconnectHub { type: 'CUSTOMERHUB_DISCONNECT_HUB'; connected: boolean; }

export type KnownAction = ICustomerConnectHub | ICustomerDiconnectHub;
export const actionCreators = CustomerHubActions;

export const reducer: Reducer<ICustomerHubState> = (state: ICustomerHubState, action: KnownAction) => CustomerHubReducer(state, action);