import * as React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Translation } from 'react-i18next';
import { ITicketAttachments } from '../../../Interface/Interfaces';
import AttachedFile from './AttachedFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/pro-solid-svg-icons';

interface TicketHeaderProps {
    attachedFiles: ITicketAttachments[];
    newTicket: boolean;
    downloadFiles: (e: any, comment: any, originalFileName: string) => void;
    comment: any;
    downloadAllId: string;
    removeNewTicketFile?: (file: ITicketAttachments) => void;
    showExpiredDate?: boolean;
}

export default class AttachedFiles extends React.Component<TicketHeaderProps, {}> {
    static displayName = 'AttachedFiles';

    public render() {
        return (
            <Translation>
                {
                    (t) => {
                        return (
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <label>{t('services:Order.DeliveryFiles.AttachedFiles')}</label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col className="generic-table">
                                            {this.props.attachedFiles.map((x, index) => (
                                                <AttachedFile
                                                    showExpiredDate={this.props.showExpiredDate}
                                                    removeNewTicketFile={this.props.removeNewTicketFile}
                                                    newTicket={this.props.newTicket}
                                                    attachFile={x}
                                                    groupId={this.props.comment.id}
                                                    downloadFiles={(e: any, commentId: string, originalFileName: string) => this.props.downloadFiles(e, this.props.comment, originalFileName)}
                                                    key={index}
                                                />
                                            )
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {!this.props.newTicket &&
                                                <Button
                                                    onClick={(e: any) => this.props.downloadFiles(e, this.props.comment, "All")}
                                                    data-value={`all-${this.props.downloadAllId}`}
                                                    size="sm"
                                                >
                                                    {t('tickets:Ticket.Files.DownloadAll')} <FontAwesomeIcon icon={faCloudDownloadAlt} className="ml-3" />
                                                </Button>
                                            }
                                        </Col>
                                        <Col xs={2}>
                                            <div id={`progress-all-${this.props.downloadAllId}`}>&nbsp;</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        );
                    }
                }
            </Translation>
        );
    }
}
