import * as React from 'react';
import { Translation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import CustomerInfo from './SmallComponents/CustomerInfo';
import * as CustomerStore from '../../store/Customer';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import ErrorBoundary from '../HOC/ErrorBoundary';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import Cardwrapper from '../CommonComponents/Containers/Cardwrapper';
import CustomerMembers from './SmallComponents/CustomerMembers/CustomerMembers';
import { history } from '../../store/configureStore';
import CustomerTickets from './SmallComponents/CustomerTickets';
import { bindActionCreators } from 'redux';

type ICustomersProps = {
    states: {
        customer: CustomerStore.ICustomerState,
    }
    actions: {
        customer: typeof CustomerStore.actionCreators
    }
};

type ICustomersCombinedProps = ICustomersProps & RouteComponentProps<{}>;

class Customer extends React.Component<ICustomersCombinedProps> {
    static displayName = 'Customer';

    private customerId: any;


    public componentDidMount() {
        this.customerId = this.props.match.params!["customerId"];
        this.props.actions.customer.getACustomer(this.customerId);
        this.props.actions.customer.getACustomerTickets(this.customerId);
    }

    public componentDidUpdate(prevProps: ICustomersCombinedProps) {
        if (this.customerId !== this.props.match.params!["customerId"]) {
            this.customerId = this.props.match.params!["customerId"];
            this.props.actions.customer.getACustomer(this.customerId);
        }
    }

    public componentWillUnmount() {
        this.props.actions.customer.leaveACustomer();
    }

    public render() {
        switch (this.props.states.customer.selectedCustomer.customerNumber) {
            case "":
                return null;
            default:
                return (
                    <ErrorBoundary>
                        <Translation>{
                            (t) => {
                                return (
                                    <>
                                        {this.props.states.customer.selectedCustomer.isMember === true &&
                                            <Row className="mt-3">
                                                {this.props.states.customer.selectedCustomer.isMember === true &&
                                                    <Col>
                                                        <Row className="mb-3">
                                                            <Col>
                                                                <h1 className="page-title">{this.props.states.customer.selectedCustomer.name}</h1>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={8}>
                                                                <Cardwrapper className="h-100">
                                                                    <CustomerInfo
                                                                        customer={this.props.states.customer.selectedCustomer}
                                                                        getCustomer={this.props.actions.customer.getACustomer}
                                                                    />
                                                                </Cardwrapper>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <Cardwrapper className="h-100">
                                                                    <CustomerMembers
                                                                        internalUsers={this.props.states.customer.selectedCustomer.internalUsers}
                                                                        externalUsers={this.props.states.customer.selectedCustomer.externalUsers}
                                                                        customer={this.props.states.customer.selectedCustomer}
                                                                    />
                                                                </Cardwrapper>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mt-4">
                                                            <Col>
                                                                <Cardwrapper>
                                                                    <CustomerTickets
                                                                        customer={this.props.states.customer.selectedCustomer}
                                                                        tickets={this.props.states.customer.selectedCustomerTickets}
                                                                    />
                                                                </Cardwrapper>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                }
                                            </Row>
                                        }
                                        {this.props.states.customer.selectedCustomer.isMember === false &&
                                            <Col xs="12" className="mt-5">
                                                <Alert variant="danger">
                                                    <Alert.Heading as="h2">
                                                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="mr-1" />
                                                        {t('common:State.AccessDenied')}
                                                    </Alert.Heading>
                                                    <Row>
                                                        <Col>
                                                            <p>{this.props.states.customer.selectedCustomer.isRequested ? t('common:AccessDenied.ReqeustedAccessHeader1') : t('common:AccessDenied.DeniedCustomerHeader1')} <strong>{this.props.states.customer.selectedCustomer.customerNumber}.</strong></p>
                                                            <p>{this.props.states.customer.selectedCustomer.isRequested ? t('common:AccessDenied.ReqeustedAccessHeader2') : t('common:AccessDenied.DeniedCustomerHeader2')}</p>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    <Row>
                                                        <Col xs="auto">
                                                            <Button
                                                                onClick={() => history.goBack()}
                                                                variant="outline-primary"
                                                            >
                                                                {t('common:Action.GoBack')}
                                                            </Button>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Button
                                                                onClick={() => history.push("/customers")}
                                                                variant="primary"
                                                            >
                                                                {t('common:AccessDenied.Buttons.ViewCustomers')}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Alert>
                                            </Col>
                                        }
                                    </>
                                )
                            }
                        }
                        </Translation>
                    </ErrorBoundary>
                );
        }
    }
}

export default connect(
    (state: IApplicationState) => ({
        states: {
            customer: state.customer,
        }
    }),
    (dispatch) => ({
        actions: {
            customer: bindActionCreators(CustomerStore.actionCreators, dispatch)
        }
    })
)(Customer);