import * as React from 'react';
import { Translation } from 'react-i18next';
import { ITicketAttachments, ITicket, IFileAttachmentBase } from '../../../Interface/Interfaces';
import AttachedFiles from './AttachedFiles';
import GenericFilePond from '../../CommonComponents/GenericFilePond';
import { Col, Row, Spinner, Form } from 'react-bootstrap';
import Cardwrapper from '../../CommonComponents/Containers/Cardwrapper';
import { API_ENDPOINT } from '../../../../src/Utils/config';
import { PortalsTextEditor, PortalsTextViewer } from '@kpmgportals/portalstexteditor';
import { ToastFactory } from '../../../Utils/ToastFactory';
import i18n from '../../translationcomponent/i18n';

interface TicketDescriptionProps {
    ticket: ITicket;
    newTicket: boolean;
    updateTicket?: (e: any) => void;
    isTicketCreated?: boolean;
    filesWhereSaved?: (success: boolean) => void;
    setIsCompleteTicket?: () => void;
    getFileCount?: (fileCount: number) => void;
    downloadFiles?: (e: any, comment: any, originalFileName: string) => void;
    loading?: boolean;
    fileAdded?: (file: IFileAttachmentBase) => void;
    removeNewTicketFile?: (file: ITicketAttachments) => void;
    updateDescription?: (newContent: string) => void;
}

export default class TicketDescription extends React.Component<TicketDescriptionProps, {}> {
    static displayName = 'TicketDescription';

    private uploadFileError = async (error: any) => {
        try {
            if (error.includes('ASCII'))
                error = i18n.t('tickets:Files.AsciiFileErrorMsg');
            ToastFactory.createAlertToast(i18n.t('tickets:Files.FileUploadError'), error);
        } catch (e) { }
    }

    public render() {
        return (
            <Translation>{
                (t) => {
                    return (
                        <Row className="grid-margin-bottom" id="ticket-description-section">
                            <Col>
                                <Cardwrapper>
                                    {this.props.loading &&
                                        <Row className="justify-content-center">
                                            <Col xs="auto">
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            {this.props.newTicket &&
                                                <Form.Group controlId="ticket-title">
                                                    <Form.Label>{t('common:Information.Title')}<sup className="required">*</sup></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Title"
                                                        data-value="title"
                                                        onChange={(e: any) => this.props.updateTicket!(e)}
                                                        value={this.props.ticket.title}
                                                    />
                                                </Form.Group>
                                            }
                                            {!this.props.newTicket &&
                                                <Form.Group controlId="ticket-title">
                                                    <Form.Label>{t('common:Information.Title')}<sup className="required">*</sup></Form.Label>
                                                    <Form.Control
                                                        plaintext
                                                        readOnly
                                                        type="text"
                                                        placeholder="Title"
                                                        data-value="title"
                                                        defaultValue={this.props.ticket.title}
                                                    />
                                                </Form.Group>
                                            }
                                        </Col>
                                        <Col xs={12} lg={6} />
                                    </Row>
                                    <Row>
                                        <Col xs={12} lg={6} className="mb-3">
                                            <label htmlFor="ticket-description">{t('tickets:NewTicket.Description')}<sup className="required hide">*</sup></label>
                                            <div>
                                                {this.props.newTicket
                                                    ? (
                                                        <PortalsTextEditor
                                                            value={this.props.ticket.description}
                                                            data-value="Description"
                                                            onChange={(newContent: string) => this.props.updateDescription!(newContent)}
                                                            height="auto"
                                                        />
                                                    ) : (
                                                        <PortalsTextViewer
                                                            value={this.props.ticket.description}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            {this.props.ticket.attachedFiles && this.props.ticket.attachedFiles!.length > 0 &&
                                                <AttachedFiles
                                                    removeNewTicketFile={this.props.removeNewTicketFile}
                                                    attachedFiles={this.props.ticket.attachedFiles!}
                                                    newTicket={this.props.newTicket}
                                                    downloadFiles={this.props.downloadFiles!}
                                                    comment="description"
                                                    showExpiredDate
                                                    downloadAllId="description"
                                                />
                                            }
                                            {this.props.newTicket &&
                                                <GenericFilePond
                                                    uploadUrl={`${API_ENDPOINT}/api/v2/customers/${this.props.ticket.customer?.id}/tickets/files`}
                                                    customerId={this.props.ticket.customer?.id}
                                                    removeFiles
                                                    saved={this.props.isTicketCreated}
                                                    ticketRef={this.props.ticket.refNumber}
                                                    filesWhereSaved={this.props.filesWhereSaved!}
                                                    getFileCount={this.props.getFileCount!}
                                                    fileSizeLimit={50}
                                                    disableFilePond={false}
                                                    fileAdded={this.props.fileAdded}
                                                    onError={this.uploadFileError}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Cardwrapper>
                            </Col>
                        </Row>
                    );
                }
            }
            </Translation>
        );
    }
}
